import React, { useState } from "react";

function Button(props) {
  return (
    <div className={`cursor-pointer ${props.cursor}`}>
      <button
        disabled={props.disabled || false} 
        className={`flex w-[100%] items-center whitespace-nowrap ${props.bg ? props.bg :"bg-purple-dark-shade" } rounded text-white cursor-pointer ${props.px} ${props.py} ${props.cursor}`}
        onClick={props.onClick}
      >
        <p className="text-center text-[0.9rem] w-[100%] text-white m-0">{props.name}</p>
      </button>
    </div>
  );
}

export default Button;
