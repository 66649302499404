import React, { useContext, useEffect, useRef, useState } from "react";
import invite from "assets/images/dashboardNav/businessInvite.svg";
import bell from "assets/images/dashboard/bell.svg";
import File from "assets/images/dashboard/File.svg";
import loopIcon from "assets/images/dashboardNav/purpleLoop.svg";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "./Layout";
import {
  ADD_BUSINESS_HOME_URL,
  BUSINESS_DASHBOARD_URL,
} from "constants/routes";
import { myContext } from "App";
import menu from "assets/images/BusinessDashboard/TopProfileNav/menu.svg";
import ProfileNav from "../Dashboard/ProfileNav";

const HeaderDashboardProfile = () => {
  const navigate = useNavigate();
  const { setUserType } = useContext(myContext);
  const [activeType, setActiveType] = useState("connect");
  const userName = JSON.parse(localStorage.getItem("user"))
    ?.full_name?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const user = JSON.parse(localStorage.getItem("user"));
  const [showPP, setShopPP] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(false);
  const { profilePic } = useContext(ProfileContext);

  useEffect(() => {
    if (profilePic) {
      setShopPP(true);
    }
  }, [profilePic]);

  const firstNameLetter = user?.full_name
    ? user?.full_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.business_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  const handleToggleActive = (type) => {
    setActiveType(type);
    if (type === "connect") {
      navigate(ADD_BUSINESS_HOME_URL);
    }
  };

  const [switchClicked, setSwitchClicked] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const sideNavRef = useRef(null);

  const handleNavigate = () => {
    setSwitchClicked(true);
    setTimeout(() => {
      navigate(BUSINESS_DASHBOARD_URL);
    }, 200);
  };

  const handleOpenSideNav = () => {
    setShowSideNav(true);
  };

  const handleCloseSideNav = () => {
    setShowSideNav(false);
  };

  const handleClickOutside = (event) => {
    if (
      sideNavRef.current && 
      !sideNavRef.current.contains(event.target) &&
      !event.target.closest("[data-ignore-click-outside]") // Check for data attribute
    ) {
      handleCloseSideNav();
    }
  };

  useEffect(() => {
    if (showSideNav) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSideNav]);

  return (
    <>
      <div className="bg-white border-b px-[1rem] py-[0.9rem] grid grid-cols-12 border-l">
        <div className="col-span-9 flex md:justify-between items-center pr-[1.5rem]">
          <button onClick={handleOpenSideNav} className="mr-[0.5rem] md:hidden">
            <img src={menu} alt="sidebar" className="" />
          </button>
          {/* desktop */}
          <h4 className="text-darkGray text-[1.3rem] m-0 md:block hidden">
            Welcome, {userName}
          </h4>
          {/* mobile */}
          <h4 className="text-darkGray text-[1.2rem] m-0 md:hidden">
            Dashboard
          </h4>
          <div className="md:flex hidden gap-[2rem]">
            {user.account_type === 1 ? (
              <div className="bg-[#F3EFF8] p-1 rounded-md font-[500]">
                <button
                  onClick={() => handleToggleActive("having")}
                  className={`px-2 py-1 text-[0.8rem] ${
                    activeType === "having"
                      ? "bg-customPurple text-white"
                      : "text-customPurple"
                  } rounded-md`}
                >
                  Having a business?
                </button>

                <button
                  onClick={() => handleToggleActive("connect")}
                  className={`px-2 py-1 text-[0.8rem] ${
                    activeType === "connect"
                      ? "bg-customPurple text-white"
                      : "text-customPurple"
                  } rounded-md`}
                >
                  Connect your Business
                </button>
              </div>
            ) : (
              <button
                onClick={handleNavigate}
                className="font-[500] bg-[#F3EFF8] text-customPurple text-[0.9rem] flex gap-2 items-center rounded-md  px-[0.8rem]"
              >
                <img
                  src={loopIcon}
                  alt=""
                  className={`w-[1.1rem] h-[1.1rem] ${
                    switchClicked ? "rotate-90" : "rotate-0"
                  }`}
                />
                Switch to Business
              </button>
            )}
            <button
              onClick={() => navigate("/sample-stakeholder")}
              className="flex gap-2 items-center text-lightGray text-[0.9rem] font-[500] rounded-md bg-[#F1F5F9] py-[0.4rem] px-[0.8rem]"
            >
              <img src={invite} alt="" /> Invite
            </button>
          </div>
        </div>

        <div className="col-span-3 md:flex hidden items-center gap-[1rem] pl-[1rem]">
          <button className="p-[0.4rem] rounded-full border">
            <img src={File} alt="" />
          </button>
          <button className="p-[0.4rem] rounded-full border">
            <img src={bell} alt="" />
          </button>

          <Link
            className="text-black no-underline right-0"
            // to={
            //   user?.account_type === 2
            //     ? "/income-tax-business-profile"
            //     : "/income-tax-profile"
            // }
            to ={"/income-tax-profile"}
          >
            <div className="flex items-center gap-1">
              {showPP === false && (
                <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                  {" "}
                  <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                    {finalLetter}
                  </p>
                </div>
              )}
              {showPP === true && (
                <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden">
                  <img
                    className="w-full h-full object-cover "
                    src={profilePic}
                    alt="profile"
                  />
                </div>
              )}
              {/* {businessProfile ? (
                <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
                  {(() => {
                  const words = user?.business_name?.split(" ");
                  return words?.length > 2
                    ? `${words[0]} ${words[1]}...`
                    : user?.business_name;
                })()}
                </p>
              ) : ( */}
                <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
                 {(() => {
                  const words = userName?.split(" ");
                  return words?.length > 2
                    ? `${words[0]} ${words[1]}...`
                    : userName;
                })()}
                </p>
              {/* )} */}
            </div>
          </Link>
        </div>
      </div>

      {/* Side Navigation for mobile*/}
      {showSideNav && (
        <div className="fixed inset-0 z-40">
          {/* Background Overlay */}
          <div className="absolute inset-0 bg-gray-300 opacity-50"></div>

          {/* Navigation Content */}
          <div className="absolute bg-white z-50" ref={sideNavRef}>
            <ProfileNav />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderDashboardProfile;
