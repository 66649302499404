import React, { useEffect, useRef, useState } from "react";
import fileDown from "assets/images/BusinessDashboard/BusinessEmployee/file-down.svg";
import upArrow from "assets/images/BusinessDashboard/BusinessEmployee/upArrow.svg";
import calender from "assets/images/BusinessDashboard/BusinessEmployee/calendar.svg";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import useGetDetailEmployee from "./data/useGetDetailEmployee";
import Payslip from "./Payslip";
import RecordLeave from "./RecordLeave";
import EditEmployee from "./EditEmployee";
import EditLeave from "./EditLeave";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import { BUSINESS_EMPLOYEE_URL } from "constants/routes";

const BusinessEmployeeDetailView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const generatePaySlipBtnRef = useRef(null);
  const openRecordLeaveBtnRef = useRef(null);
  const addNewEmployeeBtnRef=useRef(null);
  const openEditLeaveBtnRef=useRef(null);
  const [leaveId, setLeaveId]= useState("")
  const id=location.state?.id
  const [editNum, setEditNum]=useState("")
  const [showAllowances, setShowAllowances]=useState(false);
  const [showDeductions, setShowDeductions]=useState(false);
  const [openGeneratePaySlip,setOpenGeneratePaySlip]=useState(false);
  const [openRecordLeave, setOpenRecordLeave]=useState(false);
  const [recallGetApi, setRecallGetApi]=useState(false);
  const [openEditEmployee, setOpenEditEmployee]= useState(false);
  const [openEditLeave, setOpenEditLeave]=useState(false);
  const [sendLeaveData, setSendLeaveData] = useState({})
  const [employeeData, employeeError, employeeIsLoading, getEmployeeOverview]= useGetDetailEmployee();
  const [monthAndIndex, setMonthAndIndex] = useState({ month: "", index: "" });
  const [detailData, setDetailData] = useState({});
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    if (id) {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      getEmployeeOverview({ id, month, year });
    }
  }, []);

  useEffect(() => {
    if (recallGetApi) {
      if (id) {
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        getEmployeeOverview({ id, month, year });
        setRecallGetApi(false);
      }
    }
  }, [recallGetApi]);

  useEffect(()=>{
    if(openEditEmployee){
      console.log("editNum", editNum)
      addNewEmployeeBtnRef.current.click()
    }
  },[openEditEmployee])


  const resetState=()=>{
    setOpenEditEmployee(false)
  }

  const handleOpenEditEmployee=(val)=>{
    setEditNum(val)
     setOpenEditEmployee(true)
  }

  useEffect(() => {
    if (employeeData) {
      setDetailData(employeeData?.data);
    }
  }, [employeeData]);

  useEffect(() => {
    let monthIndex = new Date().getMonth();
    let monthName = monthNames[monthIndex];
    setMonthAndIndex({ month: monthName, index: monthIndex });
  }, []);

  useEffect(() => {
    if (openGeneratePaySlip) {
      generatePaySlipBtnRef.current.click();
    }
  }, [openGeneratePaySlip]);

  useEffect(() => {
    if (openRecordLeave) {
      openRecordLeaveBtnRef.current.click();
    }
  }, [openRecordLeave]);

  const handleOpenRecordLeave = () => {
    setOpenRecordLeave(true);
  };

  useEffect(()=>{
    if(openEditLeave){
      openEditLeaveBtnRef.current.click()
    }
  },[openEditLeave])

  const handleOpenEditLeave=(val)=>{
    setOpenEditLeave(true)
    setLeaveId(val)
    const updatedArr=detailData?.leaves?.leave_details?.filter((el,i)=>{
       return val === el.leave_id
    })
    setSendLeaveData(updatedArr[0])
  }

  const handleChange = (val) => {
    const date = new Date();
    const year = date.getFullYear();
    if (val === "right") {
      if (monthAndIndex.index === monthNames.length - 1) {
        const month = 1;
        getEmployeeOverview({ id, month, year });
        setMonthAndIndex({ ...monthAndIndex, month: "January", index: 0 });
        return;
      }
      if (id) {
        const month = monthAndIndex.index + 2;
        getEmployeeOverview({ id, month, year });
        setMonthAndIndex({
          ...monthAndIndex,
          month: monthNames[monthAndIndex.index + 1],
          index: monthAndIndex.index + 1,
        });
      }
    } else {
      if (monthAndIndex.index === 0) {
        const month = 12;
        getEmployeeOverview({ id, month, year });
        setMonthAndIndex({
          ...monthAndIndex,
          month: "December",
          index: monthNames.length - 1,
        });
        return;
      }
      if (id) {
        const month = monthAndIndex.index;
        getEmployeeOverview({ id, month, year });
        setMonthAndIndex({
          ...monthAndIndex,
          month: monthNames[monthAndIndex.index - 1],
          index: monthAndIndex.index - 1,
        });
      }
    }
  };

  const handleGeneratePaySlip = () => {
    setOpenGeneratePaySlip(true);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options); // 'en-GB' gives day-month format
  };
  return (
    <div className="mt-[70px] p-3">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[0.5rem] ">
          <button onClick={() => navigate(BUSINESS_EMPLOYEE_URL)}>
            <img src={previousBtn} alt="" className="w-5 h-5" />
          </button>
          <h4 className="m-0 text-[#334155] text-[1.1rem] font-[500]">
            {" "}
            {detailData?.personal_details?.name}
          </h4>
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={handleGeneratePaySlip}
            className="flex items-center gap-1.5 px-2.5 py-1 rounded-md text-[#64748B] border border-[#D9D9D9] font-[500] bg-[#FFFFFF] cursor-pointer"
          >
            <img src={fileDown} alt="fileDown" />
            Generate Payslip
          </button>
          <button
            onClick={handleOpenRecordLeave}
            className="flex items-center gap-1.5 px-2.5 py-1 rounded-md text-[#64748B] border border-[#D9D9D9] font-[500] bg-[#FFFFFF] cursor-pointer"
          >
            <img src={fileDown} alt="fileDown" />
            Record Leave
          </button>
        </div>
      </div>
      <div className="w-[100%] flex justify-between mt-3">
        <div className="w-[49%] py-2.5 px-3.5 rounded-md border border-[#D9D9D9] bg-[#FFFFFF]">
          <div className="flex justify-between">
            <p className="m-0 text-[#334155] font-[500]">Personal Details</p>
            <button onClick={()=>handleOpenEditEmployee(3)} className="px-2 py-1 border border-[#D9D9D9] text-[0.8rem] rounded-md text-[#64748B] font-[500]">
              Edit
            </button>
          </div>
          <div className="grid grid-cols-12 mt-3">
            <div className="col-span-4 flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Name</p>
              <p className="text-[#334155] m-0 text-[1rem]">
                {detailData?.personal_details?.name}
              </p>
            </div>
          </div>
          <div className="flex gap-6 mt-3">
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Date of Birth</p>
              <p className="text-[#64748B] m-0 text-[1rem]">
                {detailData?.personal_details?.date_of_birth}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Phone Number</p>
              <p className="text-[#64748B] m-0 text-[1rem]">
                {detailData?.personal_details?.phone_number}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Email</p>
              <p className="text-[#64748B] m-0 text-[1rem]">
                {detailData?.personal_details?.email}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[49%] p-2.5 rounded-md border border-[#D9D9D9] bg-[#FFFFFF]">
          <div className="flex justify-between">
            <p className="m-0 text-[#334155] font-[500]">IT and Documents</p>
            <button onClick={()=>handleOpenEditEmployee(2)} className="px-2 py-1 border border-[#D9D9D9] text-[0.8rem] rounded-md text-[#64748B] font-[500]">
              Edit
            </button>
          </div>
          <div className="grid grid-cols-12 mt-3">
            <div className="col-span-4 flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">LinkedIn</p>
              <a
                className="text-[#334155] m-0 text-[1rem] cursor-pointer"
                href={detailData?.it_and_documents?.linkedin_profile}
                target="_blank"
                rel="noopener noreferrer" // It's important for security reasons
              >
                {detailData?.it_and_documents?.linkedin_profile || ""}
              </a>
            </div>
          </div>
          <div className="flex gap-6 mt-3">
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Pan Card</p>
              {
                detailData?.it_and_documents?.pan_card ? (
                  <a
                    href={detailData?.it_and_documents?.pan_card}
                    target="_blank"
                    className="text-[#64748B] m-0 text-[1rem]"
                  >
                    View
                  </a>
                ) : (
                  <span>No PAN Card</span>
                )
              }
              {/* <a href={detailData?.it_and_documents?.pan_card ? detailData?.it_and_documents?.pan_card: "#"} target="_blank" className="text-[#64748B] m-0 text-[1rem]">View</a> */}
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Aadhar Card</p>
              {/* <a className="text-[#64748B] m-0 text-[1rem]">View</a> */}
              {
                detailData?.it_and_documents?.pan_card ? (
                  <a
                    href={detailData?.it_and_documents?.aadhar_card}
                    target="_blank"
                    className="text-[#64748B] m-0 text-[1rem]"
                  >
                    View
                  </a>
                ) : (
                  <span>No Aadhar Card</span>
                )
              }
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Joining Letter</p>
              {/* <a className="text-[#64748B] m-0 text-[1rem]">View</a> */}
              {
                detailData?.it_and_documents?.pan_card ? (
                  <a
                    href={detailData?.it_and_documents?.joining_letter}
                    target="_blank"
                    className="text-[#64748B] m-0 text-[1rem]"
                  >
                    View
                  </a>
                ) : (
                  <span>No Joining Letter</span>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex justify-between items-start mt-3">
        <div className="w-[49%] py-2.5 px-3.5 rounded-md border border-[#D9D9D9] bg-[#FFFFFF]">
          <div className="flex justify-between">
            <p className="m-0 text-[#334155] font-[500]">Job Details</p>
            <button onClick={()=>handleOpenEditEmployee(1)} className="px-2 py-1 border border-[#D9D9D9] text-[0.8rem] rounded-md text-[#64748B] font-[500]">
              Edit
            </button>
          </div>
          <div className="flex gap-6 mt-3">
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Designation</p>
              <p className="text-[#334155] m-0 text-[1rem]">
                {detailData?.job_details?.designation}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Joining Date</p>
              <p className="text-[#64748B] m-0 text-[1rem]">
                {detailData?.job_details?.joining_date}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="text-[#64748B] m-0 text-[0.8rem]">Monthly Salary</p>
              <p className="text-[#64748B] m-0 text-[1rem]">
                ₹{detailData?.job_details?.monthly_salary}
              </p>
            </div>
          </div>
          <div className="mt-3 flex flex-col gap-3">
            <p className="m-0 text-[#64748B] font-[500]">Pay Structure</p>
            <div className="border border-[#D9D9D9] rounded">
              <table className="border-collapse w-full">
                <tr className="border-b border-[#D9D9D9]">
                  <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC] rounded-tl">
                    Base Pay
                  </td>
                  <td className="text-[#64748B] px-4 py-2">
                    ₹{detailData?.job_details?.pay_structure?.base_pay}
                  </td>
                </tr>
                <tr className="border-b border-[#D9D9D9]">
                  <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC]">
                    Allowances
                  </td>
                  <td className="text-[#EA580C] px-4 py-2">
                    <div className="flex justify-between">
                      <span>
                        ₹
                        {detailData?.job_details?.pay_structure?.allowances?.reduce(
                          (acc, el) => {
                            return acc + el.amount;
                          },
                          0
                        )}
                      </span>
                      <div
                        onClick={() => setShowAllowances(!showAllowances)}
                        className="bg-[#F1F5F9] w-6 h-6 flex justify-center items-center rounded-[50%] cursor-pointer"
                      >
                        {showAllowances ? (
                          <MdKeyboardArrowUp className="text-[#334155] text-xl" />
                        ) : (
                          <MdKeyboardArrowDown className="text-[#334155] text-xl" />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                {showAllowances &&
                  detailData?.job_details?.pay_structure?.allowances?.map(
                    (el, i) => (
                      <>
                        <tr className="border-b border-[#D9D9D9]">
                          <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] ">
                            {el.name}
                          </td>
                          <td className="text-[#64748B] px-4 py-2">
                            <div className="flex justify-between">
                              <span>₹{el.amount}</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  )}
                <tr className="border-b border-[#D9D9D9]">
                  <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC]">
                    Deductions
                  </td>
                  <td className="text-[#EA580C] px-4 py-2">
                    <div className="flex justify-between">
                      <span>₹{detailData?.job_details?.pay_structure?.deductions?.reduce((acc,el)=> {
                       return acc+el.amount
                      },0)}</span>
                      <div onClick={()=>setShowDeductions(!showDeductions)} className="bg-[#F1F5F9] w-6 h-6 flex justify-center items-center rounded-[50%] cursor-pointer">
                        {
                          showDeductions ?  <MdKeyboardArrowUp className="text-[#334155] text-xl" /> :<MdKeyboardArrowDown className="text-[#334155] text-xl" /> 
                        }
                      </div>
                    </div>
                  </td>
                </tr>
                {showDeductions &&
                  detailData?.job_details?.pay_structure?.deductions?.map(
                    (el, i) => (
                      <>
                        <tr className="border-b border-[#D9D9D9]">
                          <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] ">
                            {el.name}
                          </td>
                          <td className="text-[#64748B] px-4 py-2">
                            <div className="flex justify-between">
                              <span>₹{el.amount}</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  )}
                <tr>
                  <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC] rounded-bl">
                    Net Pay
                  </td>
                  <td className="text-[#EA580C] px-4 py-2">
                    ₹{detailData?.job_details?.pay_structure?.net_pay}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="w-[49%] py-2.5 px-3.5 rounded-md border border-[#D9D9D9] bg-[#FFFFFF]">
          <div className="flex justify-between">
            <div className="flex gap-1 items-center">
              <p className="m-0 text-[#334155] font-[500]">Leaves</p>
              <span className="rounded bg-[#F8FAFC] border border-[#D9D9D9] p-[2px] text-[0.8rem]">
                {detailData?.leaves?.total_leave_days < 10
                  ? `0${detailData?.leaves?.total_leave_days}`
                  : detailData?.leaves?.total_leave_days || "00"}
              </span>
            </div>
            <div className="flex items-center justify-center space-x-4">
              {/* Left Arrow Button */}
              <button
                onClick={() => handleChange("left")}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-300 hover:bg-gray-100 focus:outline-none"
              >
                <MdKeyboardArrowLeft className="text-gray-500 text-xl" />
              </button>

              {/* Month Display */}
              <div className="flex items-center space-x-2 px-3 py-1 rounded-md border border-gray-300 bg-white">
                <span className="text-[#334155] text-[1rem]">
                  {monthAndIndex.month}
                </span>
              </div>

              {/* Right Arrow Button */}
              <button
                onClick={() => handleChange("right")}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-300 hover:bg-gray-100 focus:outline-none"
              >
                <MdKeyboardArrowRight className="text-gray-500 text-xl" />
              </button>
            </div>
          </div>
          {detailData?.leaves?.leave_details.length > 0 ? (
            <>
              <div className="flex flex-col gap-2.5 mt-2.5">
                {detailData?.leaves?.leave_details?.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="flex justify-between items-center p-3 border border-[#D9D9D9] rounded-md"
                    >
                      <div className="flex flex-col gap-2.5">
                        <div className="flex gap-1">
                          <img src={calender} alt="calender" />
                          <p className="m-0 text-[#334155]">
                            {`${formatDate(el.start_date)}-${formatDate(
                              el.end_date
                            )}`}{" "}
                            <span>({el.leave_days})</span>
                          </p>
                        </div>
                        <div className="px-1.5 py-1 flex justify-center items-center rounded bg-[#FEF2F2]">
                          <p className="m-0 text-[#64748B] font-[500]">
                            {el.leave_type}
                          </p>
                        </div>
                    </div>
                    <div onClick={()=>handleOpenEditLeave(el.leave_id)} className="px-2 py-1 flex justify-center items-center rounded bg-[#F8FAFC] cursor-pointer">
                        <p className="m-0 text-[#334155] font-[600]">Edit</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-2.5 items-center mt-4 mb-4">
                <p className="text-[#334155] m-0">No Leaves created yet</p>
                <button
                  onClick={handleOpenRecordLeave}
                  className="flex items-center gap-1.5 px-2.5 py-1 rounded-md text-[#64748B] border border-[#D9D9D9] font-[500] bg-[#FFFFFF]"
                >
                  <img src={fileDown} alt="fileDown" />
                  Record Leave
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {openGeneratePaySlip && (
        <Payslip
          openGeneratePaySlip={openGeneratePaySlip}
          setOpenGeneratePaySlip={setOpenGeneratePaySlip}
          idForPayslip={id}
          triggerElement={
            <button ref={generatePaySlipBtnRef} className="hidden">
              openDilog
            </button>
          }
        />
      )}
      {openRecordLeave && (
        <RecordLeave
          openRecordLeave={openRecordLeave}
          setOpenRecordLeave={setOpenRecordLeave}
          setRecallGetApi={setRecallGetApi}
          idForIndividualPage={id}
          triggerElement={
            <button ref={openRecordLeaveBtnRef} className="hidden">
              openDilog
            </button>
          }
        />
  )}
      {openEditEmployee && (
        <EditEmployee
          triggerElement={
            <button className="hidden" ref={addNewEmployeeBtnRef}>
              Add New Employee
            </button>
          }
          employeeData={employeeData}
          resetState={resetState}
          setRecallGetApi={setRecallGetApi}
          employeeId={id}
          editNum={editNum}
          setOpenEditEmployee={setOpenEditEmployee}
        />
      )}
      {
        openEditLeave && <EditLeave 
        openEditLeave={openEditLeave}
        setOpenEditLeave={setOpenEditLeave}
        leaveId={leaveId}
        idForIndividualPage={id}
        setRecallGetApi={setRecallGetApi}
        sendLeaveData={sendLeaveData}
          triggerElement={
            <button ref={openEditLeaveBtnRef} className="hidden">
            openDilog
           </button>
          }
        />
      }
    </div>
  );
};

export default BusinessEmployeeDetailView
