import React, { useEffect, useState } from "react";
import newest from "assets/images/moneyManager/newest.svg";
import check from "assets/images/moneyManager/check.svg";
import InputField from "components/input/InputField";
import search from "assets/images/moneyManager/search.svg";
import Button from "components/Button/BtnOrange";
import CreateNewCategory from "./CreateNewCategory";
import { useNavigate } from "react-router-dom";
import {
  MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL,
  MONEY_MANAGER_TRANSACTIONS_URL,
} from "constants/routes";
import EditCategory from "./EditCategory";
import usePostCategories from "../data/usePostCategories";
import { showSuccessToast } from "components/toaster/toastHelper";
import useGetCategoryTransaction from "../data/useGetCategories";
import usePatchCategory from "../data/usePatchCategory";
import useDeleteCategory from "../data/useDeleteCategory";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import RemoveCategory from "./RemoveCategory";

const ManageCategories = () => {
  const navigate = useNavigate();
  const [isNewestDropdownOpen, setIsNewestDropdownOpen] = useState(false);
  const [selectedNewestOption, setSelectedNewestOption] =
    useState("Newest First");
  const [isOpen, setIsOpen] = useState(false); // Assuming you want it to be open initially
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isCreateMoreToggled, setIsCreateMoreToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isRemoveCategoryDialogOpen, setIsRemoveCategoryDialogOpen] =
    useState(false);

  const [postCategoryData, categoryIsError, categoryIsLoading, postCategory] =
    usePostCategories();
  const [getCategoryData, getIsError, getIsLoading, getCategories] =
    useGetCategoryTransaction();
  const [
    patchCategoryData,
    patchcategoryIsError,
    patchcategoryIsLoading,
    patchCategory,
  ] = usePatchCategory();

  const [deleteCategoryData, deleteIsError, deleteIsLoading, deleteCategory] =
    useDeleteCategory();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (postCategoryData) {
      if (!isCreateMoreToggled) {
        setIsOpen(false);
      }
      showSuccessToast("Category Created");
      setcategoryName("");
      getCategories();
    }
  }, [postCategoryData]);

  useEffect(() => {
    if (patchCategoryData) {
      setIsOpen(false);
      setIsEditClicked(false);
      showSuccessToast("Category Updated");
      getCategories();
    }
  }, [patchCategoryData]);

  useEffect(() => {
    if (deleteCategoryData) {
      setIsRemoveCategoryDialogOpen(false);
      showSuccessToast("Category Deleted Successfully");
      getCategories();
      setIsDeleteClicked(false);
    }
  }, [deleteCategoryData]);

  const handleCreateMoreToggle = () => {
    setIsCreateMoreToggled(!isCreateMoreToggled);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  const handleOpenRemoveCategory = (id, name) => {
    setIsRemoveCategoryDialogOpen(true);
    setItemId(id);
    setItemName(name);
    setIsDeleteClicked(true);
  };

  const closeRemoveDialog = () => {
    setIsRemoveCategoryDialogOpen(false);
  };

  const handleCreateNewCategory = () => {
    setIsOpen(true);
  };

  const handleGoToCategories = () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    postCategory(formData);
  };

  const handleEditCategory = (id, name) => {
    setIsOpen(true);
    setCategoryId(id);
    setName(name);
    setIsEditClicked(true);
  };

  const handlePatchCategory = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("id", categoryId);
    patchCategory(formData);
  };

  const closeEditModal = () => {
    setIsEditClicked(false);
    setIsOpen(false);
  };

  const handleDeleteCategory = () => {
    const formData = new FormData();
    formData.append("id", itemId);
    deleteCategory(formData);
  }; 

  // newest
  const handleButtonClick = () => {
    setIsNewestDropdownOpen(!isNewestDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedNewestOption(option);
    setIsNewestDropdownOpen(false); // Close dropdown after selection
  };

  const handlePrevious = () => {
    navigate(MONEY_MANAGER_TRANSACTIONS_URL);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Converts the search query to lowercase for case-insensitive matching
  };

  return (
    <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <div className="flex items-center gap-[0.5rem]">
        <button onClick={handlePrevious}>
          <img
            src={previousBtn}
            alt="Previous"
            className="w-[1.4rem] h-[1.4rem]"
          />
        </button>
        <h4 className="  text-darkGray text-[1.4rem] m-0">Categories</h4>
      </div>
      <div className="bg-white rounded-lg p-[1rem] mt-[1.5rem] border">
        {/* header */}
        <div className="flex justify-between items-center mb-[1rem]">
          <div className="flex items-center gap-[0.5rem]">
            <div className="relative">
              <button
                onClick={handleButtonClick}
                className="flex gap-1 items-center text-darkGray text-[0.8rem] border rounded-md font-[600] px-[0.9rem] py-[0.3rem]"
              >
                <img src={newest} alt="" className="w-[1rem] h-[1rem]" />
                {selectedNewestOption}
              </button>

              {isNewestDropdownOpen && (
                <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10">
                  <div
                    onClick={() => handleOptionClick("Newest First")}
                    className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                      ${
                        selectedNewestOption === "Newest First"
                          ? "text-darkGray  flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                  >
                    Newest First
                    {selectedNewestOption === "Newest First" ? (
                      <img src={check} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    onClick={() => handleOptionClick("Oldest First")}
                    className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                      ${
                        selectedNewestOption === "Oldest First"
                          ? "text-darkGray flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                  >
                    Oldest First
                    {selectedNewestOption === "Oldest First" ? (
                      <img src={check} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
            <h4 className="text-[0.8rem] text-customPurple p-1 m-0 rounded-md bg-[#FAF8FC]">
              {getCategoryData?.data?.categories?.length || 0} Categories
            </h4>
          </div>
          <div className="flex gap-[1rem] items-center">
            {/* search */}
            <div className="bg-[#F8FAFC] border-1 border-[#D9D9D9] rounded-md flex items-center gap-1">
              <span className=" py-[0.3rem] px-[0.3rem]">
                <img src={search} alt="" className="w-[1rem] h-[1rem]" />{" "}
              </span>
              <div>
                <InputField
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search Categories"
                  className="bg-[#F8FAFC] text-lightGray focus:outline-none rounded-md font-[500] text-[0.8rem]  py-[0.3rem]"
                />
              </div>
            </div>
            <CreateNewCategory
              handleCreateMoreToggle={handleCreateMoreToggle}
              isCreateMoreToggledCategory={isCreateMoreToggled}
              setIsCreateMoreToggledCategory={setIsCreateMoreToggled}
              name={categoryName}
              setName={setcategoryName}
              isOpen={isOpen}
              handleCreateNewCategory={handleCreateNewCategory}
              handleGoToCategories={handleGoToCategories}
              closeModal={closeModal}
            />
          </div>
        </div>

        {/* table */}
        <div className="grid grid-cols-12">
          <table className="col-span-12 border">
            <tr className="col-span-12 bg-[#F1F5F9] rounded-lg text-lightGray ">
              <th className="py-[1%] px-3 col-span-1  text-[0.8rem] font-[500] ">
                Name
              </th>
              <th className="py-[1%] px-3 col-span-7  text-[0.8rem] font-[500] ">
                Transactions
              </th>
              <th className="py-[1%] px-3 col-span-4  text-[0.8rem] font-[500] ">
                Actions
              </th>
            </tr>
            <tbody className="rounded-b-lg">
              {getCategoryData?.data?.categories.length === 0 ? (
                <div className="px-3 py-2 text-[0.9rem] text-red-500 font-[500]">
                  No Categories Added.
                </div>
              ) : (
                getCategoryData?.data?.categories
                  ?.filter((item) => {
                    const searchFields = [item.name, item.transaction_count];
                    if (
                      searchQuery &&
                      !searchFields.some((field) =>
                        field?.toString().toLowerCase().includes(searchQuery)
                      )
                    ) {
                      return false;
                    }

                    return true;
                  })
                  ?.sort((a, b) => {
                    // Sorting Logic based on item.name (alphabetical)
                    if (selectedNewestOption === "Oldest First") {
                      // Sort alphabetically (Z to A)
                      if (a.name < b.name) return 1; // a comes after b (Z > A)
                      if (a.name > b.name) return -1; // a comes before b (A < Z)
                      return 0; // If they are equal
                    } else if (selectedNewestOption === "Newest First") {
                      // Sort alphabetically (A to Z)
                      if (a.name < b.name) return -1; // a comes before b (A < Z)
                      if (a.name > b.name) return 1; // a comes after b (Z > A)
                      return 0; // If they are equal
                    }

                    // Default (no sorting)
                    return 0;
                  })

                  ?.map((item) => {
                    return (
                      <tr key={item.id} className="col-span-12">
                        <td className="px-3 py-2 col-span-1 p-0 border-t font-[500] text-[0.9rem] text-darkGray">
                          {item.name}
                        </td>
                        <td className="text-customPurple px-3 py-2 col-span-7 p-0 border-t font-[500] text-[0.9rem]">
                          {item.transaction_count}
                        </td>
                        <td className="flex gap-2 items-center px-2 p-0 col-span-4 border-t font-[500] text-[0.8rem] text-darkGray ">
                          <button
                            onClick={() =>
                              handleEditCategory(item.id, item.name)
                            }
                            className="mt-1 cursor-pointer bg-[#E2E8F0] rounded-md px-[0.3rem] py-[0.1rem]"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              handleOpenRemoveCategory(item.id, item.name)
                            }
                            // onClick={() => handleDeleteCategory(item.id)}
                            className="mt-1 cursor-pointer bg-[#E2E8F0] rounded-md px-[0.3rem] py-[0.1rem]"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>

          {isEditClicked && (
            <EditCategory
              isOpen={isOpen}
              handlePatchCategory={handlePatchCategory}
              closeModal={closeEditModal}
              categoryId={categoryId}
              name={name}
              setName={setName}
            />
          )}

          {isDeleteClicked && (
            <RemoveCategory
              id={itemId}
              name={itemName}
              open={isRemoveCategoryDialogOpen}
              close={closeRemoveDialog}
              handleDeleteCategory={handleDeleteCategory}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageCategories;
