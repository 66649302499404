import { myContext } from "App";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useAPICall = (defaultData, defaultError) => {
  //handling States
  //this must be set to false
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(defaultError);
  const navigate = useNavigate();
  const location =useLocation();
  const { setIsLoggedIn } = useContext(myContext);
  const useAccessToken = new URLSearchParams(location.search).get('accessToken');

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.clear()
    navigate("/newLogin");
  };

  const callAPI = ({
    url = "",
    method = "get",
    defaultFallback = () => {},
    statusObj = [],
    body,
    // config = {},
    config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}},
    params,
    file = false
  }) => {
    // merging all the configurations
    const token =useAccessToken || sessionStorage.getItem('accessToken');
    const axiosConfigObject = {
      ...config,
      method,
      url,
      data: body,
      headers: {
        ...(config.headers || {}),
        ...(token ? { "Access-User-Token": token } : {})
      },
      params,
    };
    // console.log(axiosConfigObject)
    // console.log(axiosConfigObject.data)
    //setting loading to true pre-call
    setIsLoading(true);
    //axios call
    axios(axiosConfigObject)
      .then((res) => {
        // data fetched successfully
        // console.log(res.data.status_code,res.data.status_text)
        // console.log(statusObj)
        // debugger
        if(file){
          if (res?.status && res?.statusText) {
            //finding the matching status
            // console.log("res", res)
            // console.log("res data", res.data)
            // console.log("res status code", res.data.status_code)
            let statusID = statusObj.findIndex((status) => {
              return (
                res?.status === status?.status_code &&
                (res?.statusText || "").toLowerCase() ===
                  status?.status_text.toLowerCase()
              );
            });
  
            // if status found
            // console.log(statusID)
  
            if (statusID >= 0) {
              // running the callback
              // console.log('running')
              if (statusObj[statusID]?.callBack) {
                statusObj[statusID].callBack(res.data);
              } else {
                console.log("callback is not found for the status");
              }
            } else {
              console.log("status not found");
              // console.log(statusID)
              defaultFallback();
            }
          }
          // wrong structure
          else {
            console.log("Improper Structure");
            defaultFallback();
          }
        }else{
          if (res?.data?.status_code && res?.data?.status_text) {
            //finding the matching status
            // console.log("res", res)
            // console.log("res data", res.data)
            // console.log("res status code", res.data.status_code)
            let statusID = statusObj.findIndex((status) => {
              return (
                res.data.status_code === status?.status_code &&
                (res.data.status_text || "").toLowerCase() ===
                  status?.status_text.toLowerCase()
              );
            });
  
            // if status found
            // console.log(statusID, "res", res)
  
            if (statusID >= 0) {
              // running the callback
              // console.log('running')
              if (statusObj[statusID]?.callBack) {
                statusObj[statusID].callBack(res.data);
              } else {
                console.log("callback is not found for the status");
              }
            } else {
              console.log("status not found");
              // console.log(statusID)
              defaultFallback();
            }
          }
          // wrong structure
          else {
            console.log("Improper Structure");
            defaultFallback();
          }
        }
      })
      //handling error from API
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else {
          console.log("API error");
          console.log({ err });
          setError({ err });
          defaultFallback();
        }
      })
      .finally(() => {
        //turning the loader off
        setIsLoading(false);
      });
  };
  return [data, error, isLoading, callAPI, setData, setError, setIsLoading];
};

export default useAPICall;
