
// Layout.js
import React, { createContext, useContext, useEffect, useState } from "react";
import ProfileNav from "../Dashboard/ProfileNav";
import TopProfile from "./TopProfile";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { myContext } from "App";
import BusinessTopProfile from "../BusinessProfile/BusinessTopProfile";
import BusinessProfileNav from "../BusinessProfile/BusinessProfileNav";
import HeaderDashboardProfile from "./HeaderDashboardProfile";
import { USER_TYPE_BUSINESS, USER_TYPE_PERSONAL, USER_TYPE_PERSONAL_AND_BUSINESS } from "constants/userType";

export const ProfileContext = createContext();  

const Layout = ({ children }) => {
  const [isAccessToken, setIsAccessToken] = useState(null);
  const [incomeTaxReturnId, setIncomeTaxReturnId] = useState(null);
  const [assetIds, setAssetIds] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isReturnYear = queryParams.get("isReturnYear");
  const [profilePic, setProfilePic] = useState("");
  const { userType, setUserType } = useContext(myContext);
  const navigate = useNavigate();
  const [businessDashboardHeadings, setBusinessDashboardHeading] = useState("");
  const [businessName, setBusinessName]=useState(((JSON.parse(localStorage.getItem("user")))?.business_name ? (JSON.parse(localStorage.getItem("user")))?.business_name :"" ))
  const [personalName, setPersonalName]=useState(((JSON.parse(localStorage.getItem("user")))?.full_name ? (JSON.parse(localStorage.getItem("user")))?.full_name : ""))
  // console.log("props:", props)

  const [returnYear, setReturnYear] = useState(
    isReturnYear ? isReturnYear : ""
  );

  const useAccessToken = new URLSearchParams(location.search).get(
    "accessToken"
  );
  let permissionType = new URLSearchParams(location.search).get(
    "permissionType"
  );

  let tabId = new URLSearchParams(location.search).get("tabId");

  useEffect(() => {
    if (useAccessToken && permissionType) {
      sessionStorage.setItem("accessToken", useAccessToken);
      sessionStorage.setItem("permissionType", permissionType);
      sessionStorage.setItem("tabId", tabId);
    }
  }, [location, useAccessToken]);

  useEffect(() => {
    const storedProfilePic = JSON.parse(
      localStorage.getItem("user")
    )?.profile_picture;
    const presentReturnYear = JSON.parse(
      localStorage.getItem("user")
    )?.current_income_tax_return_year_name;
    if (presentReturnYear) {
      setReturnYear(presentReturnYear);
    }
    if (storedProfilePic) {
      setProfilePic(storedProfilePic);
    }
  }, []);

  const updateProfilePic = (newPic) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, profile_picture: newPic })
    );
    setProfilePic(newPic);
  };

  const updateIncomeTaxReturnId = (returnId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, current_income_tax_return_id: returnId })
    );
    setIncomeTaxReturnId(returnId);
  };

  const updateBusinessName = (val) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, business_name: val })
    );
    setBusinessName(val);
  };

  const updatePersonalName = (val) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, full_name: val })
    );
    setPersonalName(val);
  };

  const getReturnYear = (year) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, current_income_tax_return_year_name: year })
    );
    setReturnYear(year);
  };

  // Check if the current URL includes 'add-business'
  const hideProfileNav = location.pathname.includes("add-business");

  // Determine whether the route belongs to business or personal context
  const isBusinessRoute = location.pathname.startsWith("/business");
  return (
      <ProfileContext.Provider
        value={{
          profilePic,
          updateProfilePic,
          returnYear,
          getReturnYear,
          updateIncomeTaxReturnId,
          incomeTaxReturnId,
          assetIds,
          setAssetIds,
          businessDashboardHeadings,
          setBusinessDashboardHeading,
          updateBusinessName,
          businessName,
          personalName,
          updatePersonalName
        }}
      >
        <div className="grid grid-cols-12 ">
          {/* Conditionally render ProfileNav */}
        {userType === USER_TYPE_PERSONAL && !hideProfileNav && (
          <div className="col-span-2 hidden md:block">
            <ProfileNav />
          </div>
        )}
        {userType === USER_TYPE_BUSINESS && (
          <div className="col-span-2 hidden md:block bg-[#FFFFFF] min-h-screen border-r border-[#D9D9D9]">
            <BusinessProfileNav />
          </div>
        )}
        {userType === USER_TYPE_PERSONAL_AND_BUSINESS && (
          isBusinessRoute ?
          <div className="col-span-2 hidden md:block bg-[#FFFFFF] min-h-screen border-r border-[#D9D9D9]">
          <BusinessProfileNav />
        </div> :           
              <>
              {
                !hideProfileNav &&           
                <div className="col-span-2 hidden md:block">
                <ProfileNav />
              </div>
              }
              </>
        )}
          {/* {userType === "personal" ? (
            <>
              {!hideProfileNav && (
                <div className={`col-span-2 hidden md:block `}>
                  <ProfileNav />
                </div>
              )}{" "}
            </>
          ) : (
            <>
              <div
                className={`col-span-2 hidden md:block bg-[#FFFFFF] min-h-screen border-r border-[#D9D9D9]`}
              >
                <BusinessProfileNav />
              </div>
            </>
          )} */}
          {/* {!hideProfileNav && (
          <div className={`col-span-2 hidden md:block ${userType === "personal"? "":"bg-[#FFFFFF] min-h-screen"}`}>
            <ProfileNav />
          </div>
        )} */}
          <div
            className={`${
              hideProfileNav
                ? "col-span-12 md:col-span-12"
                : "col-span-12 md:col-span-10 relative"
            }`}
          >
            {/* {userType === "personal" ? (
              <div className=" absolute top-0 right-0 z-50 w-[100%]" >
                {location.pathname !== "/dashboard" ? <TopProfile /> : <HeaderDashboardProfile />}
              </div>
            ) : (
              <div className=" absolute top-0 right-0 z-50 w-[100%]">
                <BusinessTopProfile />
              </div>
            )} */}
            {/* {console.log("userType", userType,USER_TYPE_BUSINESS, isBusinessRoute )}

          {userType === USER_TYPE_PERSONAL && (
            <div className="absolute top-0 right-0 z-50 w-[100%]">
              {location.pathname !== "/dashboard" ? (
                <TopProfile />
              ) : (
                <HeaderDashboardProfile />
              )}
            </div>
          )}

         {userType === USER_TYPE_BUSINESS && (
            <div className="absolute top-0 right-0 z-50 w-[100%]">
              <BusinessTopProfile />
            </div>
          )}

          {
            userType === USER_TYPE_PERSONAL_AND_BUSINESS && 
             isBusinessRoute ?             
             <div className="absolute top-0 right-0 z-50 w-[100%]">
             <BusinessTopProfile />
           </div> :             
           <div className="absolute top-0 right-0 z-50 w-[100%]">
              {location.pathname !== "/dashboard" ? (
                <TopProfile />
              ) : (
                <HeaderDashboardProfile />
              )}
            </div>
          } */}

          {
            userType === USER_TYPE_PERSONAL ? (
              <div className="absolute top-0 right-0 z-50 w-[100%]">
                {location.pathname !== "/dashboard" ? (
                  <TopProfile />
                ) : (
                  <HeaderDashboardProfile />
                )}
              </div>
            ) : userType === USER_TYPE_BUSINESS ? (
              <div className="absolute top-0 right-0 z-50 w-[100%]">
                <BusinessTopProfile />
              </div>
            ) : userType === USER_TYPE_PERSONAL_AND_BUSINESS && isBusinessRoute ? (
              <div className="absolute top-0 right-0 z-50 w-[100%]">
                <BusinessTopProfile />
              </div>
            ) : (
              <div className="absolute top-0 right-0 z-50 w-[100%]">
                {location.pathname !== "/dashboard" ? (
                  <TopProfile />
                ) : (
                  <HeaderDashboardProfile />
                )}
              </div>
            )
          }
            <Outlet />

        </div>
        </div>
      </ProfileContext.Provider>
  );
};

export default Layout;
