import React, { useEffect, useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import DiscardChangesDialog from "containers/privateRoutes/Assets/DiscardChangesDialog";

const DialogBox = ({
  triggerElement,
  heading,
  content,
  width = "max-w-lg",
  titleFont = "text-xl",
  closeButton,
  progressSteps = [],
  isNotDefault,
  resetClose,
  steps,
  goRight,
  onTop,
  onClose, // New prop for state reset function
}) => {
  const [open, setOpen] = useState(false);
  const [cross, setCross] = useState(false);

  const handleCross = () => {
    setCross(true);
  };

  const handleClose = () => {
    if (isNotDefault) {
      resetClose(false);
    }
    setOpen(false);
    if (onClose) {
      onClose(); // Call the onClose function to reset parent states
    }
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const closeAllDialogs = () => {
    setCross(false);
    setOpen(false);
    if (onClose) {
      onClose(); // Reset any parent states if provided
    }
    if(isNotDefault){
      resetClose()
    }
  };

  return (
    <>
      <div onClick={handleOpen} className="cursor-pointer">
        {triggerElement ? triggerElement : ""}
      </div>
      {open && (
        <div className={`fixed inset-0  ${onTop ? "":"bg-gray-900 bg-opacity-65"} z-50 ${goRight ? "min-h-full":"flex justify-center items-center"}`}>
          <div
            className={`bg-white p-6 shadow-lg w-full ${width} overflow-auto scrollbar-hide ${goRight ? "fixed top-0 right-0 h-full rounded-l-2xl":"relative max-h-[90vh] rounded-2xl"}`}
          >
            {/* Close Button */}
            <button
              className="group absolute top-[1.5rem] right-7 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
              onClick={handleCross}
            >
              <img alt="" src={closeIcon} className="w-3 h-3 group-hover:hidden" />
              <img
                alt=""
                src={closeIconRed}
                className="w-4 h-4 hidden group-hover:block"
              />
            </button>

            <h2 className={`font-[600] mb-4 text-[#334155] ${titleFont}`}>
              {heading}
            </h2>

            {progressSteps.length > 0 && (
              <div className="flex items-center justify-between mb-4">
                {progressSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <div className="">
                      <div
                        className={`h-[3px] ${
                          steps ? "w-[229px]" : "w-[151px]"
                        } mb-1 rounded-md ${goRight ? step.visited ? "bg-[#FB923C]":step.active ? "bg-[#EA580C]":"bg-[#C1C9D2]":step.visited
                          ? "bg-[#C1A7D8]"
                          : step.active
                          ? "bg-customPurple"
                          : "bg-lightGray"}`
                      }
                      ></div>

                      <div className="flex items-center gap-1">
                        {step.visited ? (
                          <input
                            type="checkbox"
                            checked={true}
                            readOnly
                            className={`form-checkbox w-3 h-3 rounded-full border-2 ${
                              goRight ? step.visited ? "border-[#FB923C] bg-[#FB923C]":"":step.visited
                              ? "border-[#C1A7D8] bg-[#C1A7D8]"
                              : "border-lightGray"
                            } appearance-none ${goRight ? "checked:border-[#FB923C]" :"checked:border-[#C1A7D8]"} custom-checkbox2`}
                            style={{ position: "relative" }} // To position the custom checkmark
                          />
                        ) : (
                          <input
                            type="radio"
                            checked={step.active}
                            readOnly
                            className={`form-radio w-3 h-3 rounded-full ${goRight ? step.active ? "custom-radio-right text-[#EA580C]":"":step.active
                                ? "custom-radio text-customPurple"
                                : ""} ${goRight ? step.visited ? "accent-[#EA580C] checked":"":step.visited
                                  ? "accent-[#C1A7D8] text-[#C1A7D8] checked"
                                  : ""}`}
                          />
                        )}

                        <span
                          className={`font-[500] text-[0.75rem]  ${
                            step.active ? "text-darkGray" : "text-[#64748B]"
                          } ${goRight ? step.visited ? "text-[#FB923C]" : "":step.visited ? "text-[#C1A7D8]" : ""} ${step.visited ? "text-[#C1A7D8]" : ""}`}
                        >
                          {step.label}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}

            <div className={`${goRight ? "h-[90%] flex flex-col":""}`}>
              {typeof content === "function" ? content(handleClose) : content}
            </div>
            <div className="flex justify-end">
              <button onClick={handleClose}>{closeButton}</button>
            </div>
          </div>
        </div>
      )}

      {cross && (
        <DiscardChangesDialog
          open={cross}
          closeDiscardDialog={() => setCross(false)}
          close={closeAllDialogs} // Close all dialogs when discard changes is dismissed
        />
      )}
    </>
  );
};

export default DialogBox;
