import InputField from "components/input/InputField";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logout from "assets/images/dashboard/log-out.svg";
import WhiteButton from "components/Button/WhiteButton";
import Button from "components/Button/BtnOrange";
import upload from "assets/images/liabilities/upload.svg";
import uploadGreen from "assets/images/liabilities/uploadGrrenIcon.svg";
import usePostOngoingBusiness from "./data/usePostOngoingBusiness";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import { BUSINESS_DASHBOARD_URL } from "constants/routes";
import { myContext } from "App";
import { USER_TYPE_PERSONAL_AND_BUSINESS } from "constants/userType";

const AddOngoingBusiness = () => {
  const navigate = useNavigate();
  const {setUserType}=useContext(myContext)
  const user=JSON.parse(localStorage.getItem("user"))
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [onGoingBusinessForm, setOnGoingBusinessForm] = useState({
    business_name: "",
    business_category: "",
    business_type: "",
    date_of_foundation: "",
    alternate_phone: "",
    alternate_email: "",
    business_about: "",
    business_operate: "",
    employees: "",
    annual_revenue: "",
    business_registered: "",
    registration_proof: null,
    specify_business_type: "",
    specify_business_about: "",
  });
  const [postData, postError, postIsLoading, postOngoingBusiness] =
    usePostOngoingBusiness();
  useEffect(() => {
    if (postData) {
      showSuccessToast("Data Uploaded Successfully");
      const {business_id,business_type,business_name}=postData?.data;
      localStorage.setItem("user", JSON.stringify({...user,account_type:3,business_name,business_type,business_id}))
      setUserType(USER_TYPE_PERSONAL_AND_BUSINESS)
      navigate(BUSINESS_DASHBOARD_URL)
    }
    if (postError) {
      showErrorToast("Error in uploading data");
    }
  }, [postData, postError]);
  const progressSteps = [
    {
      label: "Basics",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Business Operations",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Others",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  const handleNext = () => {
    if (currentStep === 2) {
      const formData = new FormData();
      for (const key in onGoingBusinessForm) {
        if(key === "registration_proof"){
          if(onGoingBusinessForm[key]){
            formData.append(key, onGoingBusinessForm[key]);
          }
        }else{
          console.log("key", key)
          formData.append(key, onGoingBusinessForm[key]);
        }
      }
      postOngoingBusiness(formData);
      return;
    }
    setVisitedSteps((prev) => [...prev, currentStep + 1]);
    setCurrentStep((prev) => prev + 1);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log("type", type);
    if (type === "file") {
      setOnGoingBusinessForm({
        ...onGoingBusinessForm,
        [name]: e.target.files[0],
      });
    } else {
      setOnGoingBusinessForm({ ...onGoingBusinessForm, [name]: value });
    }
  };
  return (
    <div className="relative min-h-screen bg-[#F9F8FC] overflow-hidden scrollbar-hide w-full p-[2%]">
      {/* Left Triangle */}
      <div
        className="absolute left-0 w-full h-[80%] bottom-[-70px] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(0 100%, 50% 23%, 0% 0%)",
          //   transform: "translate(0, 20%)", // Align along bottom triangle's left side with slight gap
        }}
      ></div>

      {/* Right Triangle */}
      <div
        className="absolute bottom-[-70px] right-0 w-full h-[80%] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(100% 100%, 50% 23%, 100% 0%)",
          //   transform: "translate(0, -40%)", // Align along bottom triangle's right side with slight gap
        }}
      ></div>

      {/* Bottom Triangle */}
      <div
        className="absolute bottom-0 left-0 w-full h-[50%] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(10% 100%, 50% 0, 90% 100%)",
        }}
      ></div>
      <div className="absolute top-6 left-6 z-50">
        <Link
          className="no-underline text-[#64748B] flex gap-2 text-[0.9rem] font-[500] cursor-pointer"
          to={`/dashboard`}
        >
          <img src={logout} alt="logout" />
          Back to Dashboard
        </Link>
      </div>

      <div className="flex justify-center items-center z-20 mt-8 relative w-[100%]">
        <div className="w-[60%]">
          <div className="flex flex-col items-center">
            <h6 className="m-0 text-[#334155] text-[1.2rem] font-[600]">
              On Going Business
            </h6>
            <p className="m-0 text-[#64748B] text-[0.9rem]">
              Expand Your Reach, Elevate Your Business!
            </p>
          </div>
          <div className="p-3 rounded-lg shadow-md mt-2 bg-[#FFFFFF]">
            <div className="flex flex-col items-center gap-3">
              <p className="m-0 text-[#334155] text-[1.1rem] font-[500]">
                {currentStep === 0
                  ? "Let’s Start with the Basics"
                  : currentStep === 1
                  ? "Tell us about your business!"
                  : currentStep === 2
                  ? "Final Step"
                  : ""}
              </p>
              {progressSteps.length > 0 && (
                <div className="flex items-center justify-between mb-4 gap-1">
                  {progressSteps.map((step, index) => (
                    <React.Fragment key={index}>
                      <div className="">
                        <div
                          className={`h-[3px] w-[200px]
                         
                      mb-1 rounded-md ${
                        step.visited
                          ? "bg-[#C1A7D8]"
                          : step.active
                          ? "bg-customPurple"
                          : "bg-[#C1C9D2]"
                      }`}
                        ></div>

                        <div className="flex items-center gap-1">
                          {step.visited ? (
                            <input
                              type="checkbox"
                              checked={true}
                              readOnly
                              className={`form-checkbox w-3 h-3 rounded-full border-2 ${
                                step.visited
                                  ? "border-[#C1A7D8] bg-[#C1A7D8]"
                                  : "border-lightGray"
                              } appearance-none  checked:border-[#C1A7D8] custom-checkbox2`}
                              style={{ position: "relative" }} // To position the custom checkmark
                            />
                          ) : (
                            <input
                              type="radio"
                              checked={step.active}
                              readOnly
                              className={`form-radio w-3 h-3 rounded-full ${
                                step.active
                                  ? "custom-radio text-customPurple"
                                  : ""
                              } ${
                                step.visited
                                  ? "accent-[#C1A7D8] text-[#C1A7D8] checked"
                                  : ""
                              }`}
                            />
                          )}

                          <span
                            className={`font-[500] text-[0.75rem] ${
                              step.active ? "text-darkGray" : ""
                            } ${step.visited ? "text-[#C1A7D8]" : ""}`}
                          >
                            {step.label}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            {currentStep === 0 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-1.5 w-[90%]">
                        <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                          What’s the name of your business
                        </p>
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[88%] focus:outline-none px-2 py-1 bg-[#FFFFFF]"
                          placeholder="Enter Business Name"
                          value={onGoingBusinessForm.business_name}
                          onChange={handleInputChange}
                          name="business_name"
                        />
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What type of business is this?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type" // Same name for both options
                            value="1"
                            checked={onGoingBusinessForm.business_type == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Sole Proprietorship
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type" // Same name for both options
                            value="2"
                            checked={onGoingBusinessForm.business_type == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Partnership Firm
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type" // Same name for both options
                            value="3"
                            checked={onGoingBusinessForm.business_type == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          LLP (Limited Liability Partnership)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type" // Same name for both options
                            value="4"
                            checked={onGoingBusinessForm.business_type == "4"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Private Limited Company
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type" // Same name for both options
                            value="5"
                            checked={onGoingBusinessForm.business_type == "5"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Others
                        </label>
                      </div>
                      {onGoingBusinessForm.business_type === "5" && (
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF] mt-2.5"
                          placeholder="Specify Here"
                          value={onGoingBusinessForm.specify_business_type}
                          onChange={handleInputChange}
                          name="specify_business_type"
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-1 w-[90%]">
                        <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                          What industry does your business belong to?
                        </p>
                        <InputField
                          type="select"
                          name="business_category"
                          value={onGoingBusinessForm.business_category}
                          onChange={handleInputChange}
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[88%] focus:outline-none px-2 py-1 bg-[#FFFFFF]"
                          options={[
                            { value: "", label: "Select a Category" },
                            {
                              value: "Consultancy",
                              label: "Consultancy",
                            },
                            { value: "Technology", label: "Technology" },
                            { value: "Construction", label: "Construction" },
                            { value: "Clothing", label: "Clothing" },
                            {
                              value: "Agriculture",
                              label: "Agriculture",
                            },
                            {
                              value: "Salaried",
                              label: "Salaried",
                            },
                            { value: "Real Estate", label: "Real Estate" },
                            {
                              value: "Food & beverage",
                              label: "Food & beverage",
                            },
                            {
                              value: "Consulting",
                              label: "Consulting",
                            },
                            {
                              value: "Rental",
                              label: "Rental",
                            },
                            {
                              value: "Sports",
                              label: "Sports",
                            },
                            {
                              value: "Decors",
                              label: "Decors",
                            },
                            {
                              value: "Retail",
                              label: "Retail",
                            },
                            {
                              value: "Healthcare",
                              label: "Healthcare",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-1 w-[90%] ">
                        <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                          When was your business started?
                        </p>
                        <InputField
                          type="date"
                          className="border-[1px] border-[#D9D9D9] rounded-md  w-[88%] py-1 bg-[#FFFFFF] px-2 focus:outline-none "
                          value={onGoingBusinessForm.date_of_foundation}
                          name="date_of_foundation"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What’s your business about?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_about" // Same name for both options
                            value="1"
                            checked={onGoingBusinessForm.business_about == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Retail (selling products)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_about" // Same name for both options
                            value="2"
                            checked={onGoingBusinessForm.business_about == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Services (consulting, freelancing, etc.)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_about" // Same name for both options
                            value="3"
                            checked={onGoingBusinessForm.business_about == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Manufacturing
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_about" // Same name for both options
                            value="4"
                            checked={onGoingBusinessForm.business_about == "4"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Others
                        </label>
                      </div>
                      {onGoingBusinessForm.business_about === "4" && (
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF] mt-2.5"
                          placeholder="Specify Here"
                          value={onGoingBusinessForm.specify_business_about}
                          onChange={handleInputChange}
                          name="specify_business_about"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        Where does your business operate?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_operate" // Same name for both options
                            value="1"
                            checked={
                              onGoingBusinessForm.business_operate == "1"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          A physical office or store
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_operate" // Same name for both options
                            value="2"
                            checked={
                              onGoingBusinessForm.business_operate == "2"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Online or e-commerce
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_operate" // Same name for both options
                            value="3"
                            checked={
                              onGoingBusinessForm.business_operate == "3"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Both offline and online
                        </label>
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What’s your approximate annual revenue?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="annual_revenue" // Same name for both options
                            value="Upto Rs. 20 Lakhs"
                            checked={
                              onGoingBusinessForm.annual_revenue ==
                              "Upto Rs. 20 Lakhs"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Upto Rs. 20 Lakhs
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="annual_revenue" // Same name for both options
                            value="Rs. 20 - 50 Lakhs"
                            checked={
                              onGoingBusinessForm.annual_revenue ==
                              "Rs. 20 - 50 Lakhs"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Rs. 20 - 50 Lakhs
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="annual_revenue" // Same name for both options
                            value="Rs. 50 Lakhs - 1 Crore"
                            checked={
                              onGoingBusinessForm.annual_revenue ==
                              "Rs. 50 Lakhs - 1 Crore"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Rs. 50 Lakhs - 1 Crore
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="annual_revenue" // Same name for both options
                            value="Above Rs. 1 Crore"
                            checked={
                              onGoingBusinessForm.annual_revenue ==
                              "Above Rs. 1 Crore"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Above Rs. 1 Crore
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        Do you have employees in your business?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees" // Same name for both options
                            value="1"
                            checked={onGoingBusinessForm.employees == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          No, it’s just me.
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees" // Same name for both options
                            value="2"
                            checked={onGoingBusinessForm.employees == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Upto 10 employees
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees" // Same name for both options
                            value="3"
                            checked={onGoingBusinessForm.employees == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Above 10 employees.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%]">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg h-full">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        Is your business registered?
                      </p>
                      <div className="mt-1.5 flex items-center gap-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_registered" // Same name for both options
                            value="true"
                            checked={
                              onGoingBusinessForm.business_registered == "true"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Yes
                        </label>
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_registered" // Same name for both options
                            value="false"
                            checked={
                              onGoingBusinessForm.business_registered == "false"
                            }
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          No
                        </label>
                      </div>
                      {onGoingBusinessForm.business_registered === "true" && (
                        <div className="flex justify-start mt-2.5">
                          <div
                            onClick={() =>
                              document.getElementById("fileId").click()
                            }
                            className={`flex gap-1 items-center px-3 py-1.5 ${
                              onGoingBusinessForm.registration_proof
                                ? "border-[1px] border-[#94A3B8] bg-[#ECFDF3]"
                                : "border-[1px] border-[#94A3B8]"
                            } rounded-md cursor-pointer`}
                          >
                            {onGoingBusinessForm.registration_proof ? (
                              <img src={uploadGreen} alt="uploadGreen" />
                            ) : (
                              <img src={upload} alt="uploadPic" />
                            )}
                            <p
                              className={`${
                                onGoingBusinessForm.registration_proof
                                  ? "text-[#027A48]"
                                  : "text-[#334155]"
                              } m-0 font-[600] text-[0.9rem]`}
                            >
                              Upload Proof (Optional)
                            </p>
                          </div>
                          <InputField
                            id="fileId"
                            className="hidden"
                            name="registration_proof"
                            type="file"
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-[48%]">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-2 w-[100%]">
                        <p className="m-0 text-[#64748B] text-[0.73rem] font-[500]">
                          Do you have different communication details for
                          business?
                        </p>
                        <InputField
                          type="number"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF] appearance-none"
                          placeholder="Phone Number"
                          value={onGoingBusinessForm.alternate_phone}
                          onChange={handleInputChange}
                          name="alternate_phone"
                        />
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF]"
                          placeholder="Mail ID"
                          value={onGoingBusinessForm.alternate_email}
                          onChange={handleInputChange}
                          name="alternate_email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex gap-2 justify-end items-center mt-2">
              <WhiteButton px="px-2.5" py="py-1" name="Cancel" onClick={()=>navigate("/add-business")} />
              <Button px="px-2.5" py="py-1" name="Next" onClick={handleNext} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOngoingBusiness;
