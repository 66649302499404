import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const ExpensesChart = () => {
  // Chart Data
  const data = {
    labels: [
      "Fuel",
      "F&D",
      "Chit Fund",
      "Shop",
      "Utilities",
      "Gpay",
      "Paytm",
      "Bills",
      "Clothing",
      "Electronics",
      "Rent",
      "Nov Trip",
    ],
    datasets: [
      {
        label: "Expenses",
        data: [10, 5, 3, 7, 8, 2, 9, 6, 3, 8, 18, 9],
        backgroundColor: "#7C45A9", // Tailwind purple-400
        borderRadius: 5, // Rounded corners for bars
        barThickness: 35,
        barPercentage: 1
      },
    ],
  };

  // Chart Options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw} L`; // Add "L" for lakhs
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          borderDash: [4, 4], // Dotted grid
        },
        barPercentage: 1.5, // Controls individual bar width (reduce for more gap)
        categoryPercentage: 0.5,
      },
      y: {
        grid: {
          display: true,
          borderDash: [4, 4], // Dotted grid
        },
        min: 0,

        ticks: {
          stepSize: 5,
          callback: function (value) {
            return `${value}L`; // Append "L" to y-axis labels
          },
        },
      },
    },
  };

  return (
    <div className="h-94 w-full">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ExpensesChart;
