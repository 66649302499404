import React from "react";
import logo from "assets/images/dashboard/logo.svg";
import womanWalking from "assets/images/addBusiness/womanWalking.svg";
import womanLearning from "assets/images/addBusiness/womanLearning.svg";
import Button from "components/Button/BtnOrange";
import logout from "assets/images/dashboard/log-out.svg";
import { Link, useNavigate } from "react-router-dom";
import { ADD_BUSINESS_NEW_URL, ADD_BUSINESS_ONGOING_URL } from "constants/routes";

const AddBusiness = () => {
    const navigate=useNavigate()
    const handleNavigate=(val)=>{
        if(val==="onGoing"){
           navigate(ADD_BUSINESS_ONGOING_URL)
        }
        if(val==="new"){
           navigate(ADD_BUSINESS_NEW_URL)
        }
    }
  return (
    <div className="p-[2%] w-[100%] h-[100vh]">
      <div className="">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex justify-center items-center mt-8">
        <div className="w-[60%] bg-[#FFFFFF] rounded-lg p-3 shadow-sm">
          <div className="flex flex-col items-center gap-1 p-2.5">
            <h6 className="m-0 text-[#334155] text-[1.2rem] font-[500]">
              Welcome to Your Business Journey
            </h6>
            <p className="m-0 text-[0.85rem] text-[#64748B] font-[500]">
              Let’s get started by choosing what best describes your situation
            </p>
          </div>
          <div className="flex mt-2 py-2.5 gap-2">
            <div className="w-[50%] border-r-[2px] border-[#D9D9D9] flex flex-col gap-3 px-4">
              <div className="flex justify-center items-center">
                <img src={womanWalking} alt="womanWalking" width="250px" />
              </div>
              <div className="mb-1 mt-1">
                <h6 className="m-0 text-[1.2rem] text-[#334155] font-[600]">
                  Ongoing Business
                </h6>
                <p className="m-0 text-[#64748B] text-[0.9rem] mt-2 mb-2">
                  Already running a business?
                </p>
                <p className="m-0 text-[#64748B] text-[0.9rem]">
                  Let’s help you organize, manage compliance, and streamline
                  operations.
                </p>
              </div>
              <div className="flex justify-center">
                <Button name="Add Business" px="px-[1.3rem]" py="py-[0.3rem]" onClick={()=>handleNavigate("onGoing")} />
              </div>
            </div>
            <div className="w-[50%] flex flex-col gap-3 px-4">
              <div className="flex justify-center items-center">
                <img src={womanLearning} alt="womanLearning" width="250px"/>
              </div>
              <div className="mb-1 mt-1">
                <h6 className="m-0 text-[1.2rem] text-[#334155] font-[600]">
                  New Business Idea
                </h6>
                <p className="m-0 text-[#64748B] text-[0.9rem] mt-2 mb-2">Have a great idea? </p>
                <p className="m-0 text-[#64748B] text-[0.9rem]">
                  We’ll help you register your business and set up for success.
                </p>
              </div>
              <div className="flex justify-center">
                <Button name="Start New Business" px="px-[1.3rem]" py="py-[0.3rem]" onClick={()=>handleNavigate("new")}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Link className="no-underline text-[#64748B] flex gap-2 text-[0.9rem] font-[500] cursor-pointer" to={`/dashboard`}>
        <img src={logout} alt="logout"/>
        Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default AddBusiness;
