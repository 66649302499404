import React, { useContext, useEffect, useRef, useState } from "react";
import invite from "assets/images/dashboardNav/businessInvite.svg";
import bell from "assets/images/dashboard/bell.svg";
import File from "assets/images/dashboard/File.svg";
import menu from "assets/images/BusinessDashboard/TopProfileNav/menu.svg";
import { Link, useNavigate } from "react-router-dom";
import { ADD_BUSINESS_HOME_URL, BUSINESS_PROFILE_URL } from "constants/routes";
import { ProfileContext } from "../Profile/Layout";
import loop from "assets/images/BusinessDashboard/TopProfileNav/loop-right-line.svg";
import { myContext } from "App";
import BusinessProfileNav from "./BusinessProfileNav";
import { USER_TYPE_PERSONAL } from "constants/userType";

const BusinessTopProfile = () => {
  const navigate = useNavigate();
  const {businessName}=useContext(ProfileContext)
  const [activeType, setActiveType] = useState("connect");
  const userName = JSON.parse(localStorage.getItem("user"))
    ?.full_name?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const user = JSON.parse(localStorage.getItem("user"));
  const [showPP, setShopPP] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(false);
  const { profilePic, businessDashboardHeadings } = useContext(ProfileContext);
  const [switchClicked, setSwitchClicked] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const sideNavRef = useRef(null);

  const handleNavigate = () => {
    setSwitchClicked(true);
    setTimeout(() => {
      navigate("/dashboard");
    }, 200);
  };
  useEffect(() => {
    if (profilePic) {
      setShopPP(true);
    }else{
      setShopPP(false)
    }
  }, [profilePic]);

  const firstNameLetter = user?.business_name
    ? user?.business_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.full_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  const handleToggleActive = (type) => {
    setActiveType(type);
    if (type === "connect") {
      navigate(ADD_BUSINESS_HOME_URL);
    }
  };

  const handleOpenSideNav = () => {
    setShowSideNav(true);
  };

  const handleCloseSideNav = () => {
    setShowSideNav(false);
  };

  useEffect(() => {
    if (showSideNav) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSideNav]);

  const handleClickOutside = (event) => {
    if (
      sideNavRef.current &&
      !sideNavRef.current.contains(event.target) &&
      !event.target.closest("[data-ignore-click-outside]") // Check for data attribute
    ) {
      handleCloseSideNav();
    }
  };

  return (
    <>
      <div className="bg-white px-[1rem] py-[0.9rem] grid grid-cols-12 ">
        <div className="col-span-9 flex md:justify-between items-center pr-[1.5rem]">
          <button onClick={handleOpenSideNav} className="mr-[0.5rem] md:hidden">
            <img src={menu} alt="sidebar" className="" />
          </button>
          {/* desktop */}
          <h4 className="text-darkGray md:text-[1.3rem] text-[1.2rem] m-0 md:block hidden">
            {businessDashboardHeadings === "Dashboard"
              ? `Welcome, ${user?.business_name}`
              : businessDashboardHeadings}
          </h4>
          {/* mobile */}
          <h4 className="text-darkGray md:text-[1.3rem] text-[1.2rem] m-0 md:hidden">
            {businessDashboardHeadings === "Dashboard"
              ? "Dashboard"
              : businessDashboardHeadings}
          </h4>

          <div className="md:flex gap-[2rem] hidden">
            {user.account_type === 2 ? (
              <></>
            ) : (
              <button
                onClick={handleNavigate}
                className="font-[500] bg-[#FFF7ED] text-[#C2410C] text-[0.9rem] flex gap-2 items-center rounded-md  px-[0.8rem]"
              >
                <img
                  src={loop}
                  alt=""
                  className={`w-[1.1rem] h-[1.1rem] ${
                    switchClicked ? "rotate-90" : "rotate-0"
                  }`}
                />{" "}
                Switch to Personal
              </button>
            )}
            <button
              // onClick={() => navigate("/sample-stakeholder")}
              className="flex gap-2 items-center text-[#64748B] text-[0.9rem] font-[500] rounded-md bg-[#F1F5F9] py-[0.4rem] px-[0.9rem]"
            >
              <img src={invite} alt="" /> Invite
            </button>
          </div>
        </div>

        <div className="col-span-3 md:flex hidden items-center gap-[1rem] pl-[1rem]">
          <button className="p-[0.4rem] rounded-full border">
            <img src={File} alt="" />
          </button>
          <button className="p-[0.4rem] rounded-full border">
            <img src={bell} alt="" />
          </button>

          <Link
            className="text-black no-underline right-0"
            to={BUSINESS_PROFILE_URL}
          >
            <div className="flex items-center gap-1">
              {showPP === false && (
                <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden bg-[#EA580C] flex items-center">
                  {" "}
                  <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                    {finalLetter}
                  </p>
                </div>
              )}
              {showPP === true && (
                <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden">
                  <img
                    className="w-full h-full object-cover "
                    src={profilePic}
                    alt="profile"
                  />
                </div>
              )}
              {/* comment */}
              <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
                {(() => {
                  const words = businessName?.split(" ");
                  return words?.length > 2
                    ? `${words[0]} ${words[1]}...`  
                    : businessName;
                })()}
              </p>
            </div>
          </Link>
        </div>
      </div>
      {/* Side Navigation for mobile*/}
      {showSideNav && (
        <div className="fixed inset-0 z-40">
          {/* Background Overlay */}
          <div className="absolute inset-0 bg-gray-300 opacity-50"></div>

          {/* Navigation Content */}
          <div className="absolute bg-white z-50" ref={sideNavRef}>
            <BusinessProfileNav />
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessTopProfile;
