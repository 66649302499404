import useAPICall from "hooks/useAPICall";
import { GET_BUSINESS_MEMBERS_URL } from "constants/apis";

const useGetBusinessMembers = () => {
  const [
    data,
    error,
    isLoading,
    callGetBusinessMembers,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getBusinessMembers = (body) => {
    const url = GET_BUSINESS_MEMBERS_URL;
    // var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    callGetBusinessMembers({
      url,
      method: "GET",
      statusObj,
      // config,
      defaultFallback,
    });
  };

  return [
    data,
    error,
    isLoading,
    getBusinessMembers,
    setSuccessData,
    setError,
  ];
};

export default useGetBusinessMembers;
