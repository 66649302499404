import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import datePicker from "assets/images/incomeTax/datePicker.png";
import { useNavigate } from "react-router-dom";
import WelcomeMoneyManagerDialog from "./WelcomeMoneyManagerDialog";
import usePostInitialAccount from "./data/usePostInitialAccount";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import useGetCategoriesList from "./data/useGetCategoriesList";
import useGetAccountsList from "./data/useGetListAccounts";
import usePostTransactions from "./data/usePostTransactions";
import usePostCategories from "./data/usePostCategories";
import check from "assets/images/moneyManager/check.svg";
import arrow from "assets/images/navbar/down-arrow.svg";
import CreateNewCategory from "./Transactions/CreateNewCategory";

const AddInitialAccount = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Assuming you want it to be open initially
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [accounts, setAccounts] = useState([{ id: 1, type: "", fields: {} }]);

  const handleAccountTypeChange = (id, type) => {
    setAccounts((prev) =>
      prev.map((acc) => (acc.id === id ? { ...acc, type, fields: {} } : acc))
    );
  };
  const isCashSelected = accounts.some((acc) => acc.type === "cash");
  const handleFieldChange = (id, field, value) => {
    setAccounts((prev) =>
      prev.map((acc) =>
        acc.id === id
          ? { ...acc, fields: { ...acc.fields, [field]: value } }
          : acc
      )
    );
  };

  const addAccount = () => {
    setAccounts((prev) => [
      ...prev,
      { id: prev.length + 1, type: "", fields: {} },
    ]);
  };

  const removeAccount = (id) => {
    setAccounts((prev) => prev.filter((acc) => acc.id !== id));
  };

  // income
  const [amountIncome, setamountIncome] = useState("");
  const [descIncome, setdescIncome] = useState("");
  const [dateOfTranIncome, setDateOfTranIncome] = useState("");
  const [accountTypeIncome, setaccountTypeIncome] = useState("");
  const [categoryIncome, setcategoryIncome] = useState("");

  // expense
  const [amountExpense, setamountExpense] = useState("");
  const [descExpense, setdescExpense] = useState("");
  const [dateOfTranExpense, setDateOfTranExpense] = useState("");
  const [accountTypeExpense, setaccountTypeExpense] = useState("");
  const [categoryExpense, setcategoryExpense] = useState("");

  const [errors, setErrors] = useState({});

  const [postData, isError, isLoading, postInitialAccount] =
    usePostInitialAccount();
  const [
    postTransactionsData,
    postTransactionsIsError,
    postTransactionsIsLoading,
    postTransactions,
  ] = usePostTransactions();

  const [
    getCategoryData,
    getCategoryIsError,
    getCategoryIsLoading,
    getCategoriesList,
  ] = useGetCategoriesList();
  const [
    getAccountData,
    getAccountIsError,
    getAccountIsLoading,
    getAccountsList,
  ] = useGetAccountsList();

  const [postCategoryData, categoryIsError, categoryIsLoading, postCategory] =
    usePostCategories();

  useEffect(() => {
    getCategoriesList();
    getAccountsList();
  }, []);

  useEffect(() => {
    if (currentStep === 1) {
      getCategoriesList();
      getAccountsList();
    }
  },[currentStep]);

  useEffect(() => {
    if (isError) {
      const err =
        isError?.err?.response?.data?.data?.errors[0]?.error?.account_type ||
        "Check the fields/account no.";

      showErrorToast(err);
    }
  }, [isError]);

  useEffect(() => {
    if (postTransactionsData) {
      showSuccessToast("Transaction Posted");
      setIsOpen(true);
    }
  }, [postTransactionsData]);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Account Created Successfully");
      setCurrentStep(currentStep + 1);
    }
  }, [postData]);

  const progressSteps = [
    {
      label: "Account(s)",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Income",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Expense",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleGoToNextPage = () => {
    setIsOpen(false);
    navigate("/money-manager/home/");
  };

  const [validationErrors, setValidationErrors] = useState("");
  const validateAccounts = () => {
    let isValid = true;
    const errors = {};

    accounts.forEach((account) => {
      const accountErrors = {};

      if (account.type === "cash") {
        if (!account.fields.cashBalance || account.fields.cashBalance <= 0) {
          accountErrors.cashBalance =
            "Current balance is required and must be greater than 0.";
          isValid = false;
        }
      } else if (account.type === "bank") {
        if (!account.fields.bankName) {
          accountErrors.bankName = "Bank name is required.";
          isValid = false;
        }
        if (!account.fields.accNo || isNaN(account.fields.accNo)) {
          accountErrors.accNo = "Account number is required.";
          isValid = false;
        }
        if (
          !account.fields.bankCurrentBal ||
          account.fields.bankCurrentBal <= 0
        ) {
          accountErrors.bankCurrentBal =
            "Current balance is required and must be greater than 0.";
          isValid = false;
        }
      } else if (account.type === "credit") {
        if (!account.fields.creditBankName) {
          accountErrors.creditBankName = "Bank name is required.";
          isValid = false;
        }
        if (!account.fields.creditAccNo || isNaN(account.fields.creditAccNo)) {
          accountErrors.creditAccNo = "Account number is required.";
          isValid = false;
        }
        if (
          !account.fields.creditMonthlyLimit ||
          account.fields.creditMonthlyLimit <= 0
        ) {
          accountErrors.creditMonthlyLimit =
            "Monthly limit is required and must be greater than 0.";
          isValid = false;
        }
      }

      if (Object.keys(accountErrors).length > 0) {
        errors[account.id] = accountErrors;
      }
    });

    setValidationErrors(errors); // Save errors in state to display
    return isValid;
  };

  const postAccount = () => {
    const accountsData = accounts.map((account) => {
      if (account.type === "cash") {
        return {
          account_type: "Cash",
          account_name: "Cash",
          current_balance: account.fields.cashBalance,
        };
      } else if (account.type === "bank") {
        return {
          account_type: "Bank",
          account_name: account.fields.bankName,
          account_number: account.fields.accNo,
          current_balance: account.fields.bankCurrentBal,
        };
      } else if (account.type === "credit") {
        return {
          account_type: "Credit Card",
          account_name: account.fields.creditBankName,
          account_number: account.fields.creditAccNo,
          current_balance: account.fields.creditMonthlyLimit,
        };
      }
      return null; // Handle unexpected cases
    });
    postInitialAccount(accountsData);
  };

  const createAccount = () => {
    if (validateAccounts()) {
      postAccount(); // Submit data if valid
    }
  };

  const handleAddFirstTransaction = () => {
    const transactions = [
      {
        account: accountTypeIncome,
        amount: amountIncome,
        description: descIncome,
        date_of_transaction: dateOfTranIncome,
        amount_type: "Income",
        // ...(categoryIncome && { category: categoryIncome }),
        ...(selectedCategory && { categeory: selectedCategoryID }),
      },
      {
        account: accountTypeExpense,
        amount: amountExpense,
        description: descExpense,
        date_of_transaction: dateOfTranExpense,
        amount_type: "Expense",
        // ...(categoryExpense && { category: categoryExpense }),
        ...(selectedCategoryExpense && {
          categeory: selectedCategoryIDExpense,
        }),
      },
    ];
    postTransactions(transactions);
  };

  const validateForm = () => {
    const newErrors = {};
    if (currentStep == 1) {
      // Validate Amount
      if (!amountIncome || Number(amountIncome) <= 0) {
        newErrors.amountIncome = "Amount is required.";
      }

      // Validate Description
      if (!descIncome.trim()) {
        newErrors.descIncome = "Description is required.";
      }

      // Validate Date
      if (!dateOfTranIncome) {
        newErrors.dateOfTranIncome = "Please select a valid Transaction date.";
      }

      // Validate Account Type
      if (!accountTypeIncome) {
        newErrors.accountTypeIncome = "Please select an account type.";
      }
    }
    if (currentStep == 2) {
      if (!amountExpense || Number(amountExpense) <= 0) {
        newErrors.amountExpense = "Amount is required.";
      }

      // Validate Description
      if (!descExpense.trim()) {
        newErrors.descExpense = "Description is required.";
      }

      // Validate Date
      if (!dateOfTranExpense) {
        newErrors.dateOfTranExpense = "Please select a valid Transaction date.";
      }

      // Validate Account Type
      if (!accountTypeExpense) {
        newErrors.accountTypeExpense = "Please select an account type.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    const isValid = validateForm();
    if (isValid) {
      if (currentStep < 2) {
        setCurrentStep(currentStep + 1);
      } else {
        handleAddFirstTransaction();
      }
    } else {
      console.log("Validation failed.");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // income
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(""); // Tracks the selected category
  const [selectedCategoryID, setSelectedCategoryID] = useState(""); // Tracks the selected category
  const [newCategoryInput, setNewCategoryInput] = useState(""); // For "Create New +" input
  const [categoryName, setCategoryName] = useState("");
  const [openCategoryDialogBox, setOpenCategoryDialogBox] = useState(false);

  // expense
  const [categoryOpenExpense, setCategoryOpenExpense] = useState(false);
  const [selectedCategoryExpense, setSelectedCategoryExpense] = useState(""); // Tracks the selected category
  const [selectedCategoryIDExpense, setSelectedCategoryIDExpense] =
    useState(""); // Tracks the selected category
  const [newCategoryInputExpense, setNewCategoryInputExpense] = useState(""); // For "Create New +" input

  useEffect(() => {
    if (postCategoryData) {
      showSuccessToast("Category Added");
      getCategoriesList();
      setCategoryName("");
      setOpenCategoryDialogBox(false);
    }
  }, [postCategoryData]);

  const handleClose = () => {
    setOpenCategoryDialogBox(false);
  };

  const handleOpen = () => {
    setOpenCategoryDialogBox(true);
  };

  const handlePostnewCategory = () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    postCategory(formData);
  };

  // income
  const handleOpenCategoryDialog = () => {
    setCategoryOpen(!categoryOpen);
  };

  const handleCategorySelect = (categoryName, categoryId) => {
    if (categoryName === "Create New+") {
      setSelectedCategory("Create New+");
    } else {
      setSelectedCategory(categoryName);
      setSelectedCategoryID(categoryId);
      setCategoryOpen(false);
    }
  };

  const handleNewCategoryInputChange = (e) => {
    setNewCategoryInput(e.target.value);
  };

  const handleNewCategorySubmit = () => {
    // setSelectedCategory(newCategoryInput);
    setSelectedCategory("");
    const formData = new FormData();
    formData.append("name", newCategoryInput);
    postCategory(formData);
    setNewCategoryInput("");
    setCategoryOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNewCategorySubmit();
    }
  };

  // expense
  const handleOpenCategoryDialogExpense = () => {
    setCategoryOpenExpense(!categoryOpen);
  };

  const handleCategorySelectExpense = (categoryName, categoryId) => {
    if (categoryName === "Create New+") {
      setSelectedCategoryExpense("Create New+");
    } else {
      setSelectedCategoryExpense(categoryName);
      setSelectedCategoryIDExpense(categoryId);
      setCategoryOpenExpense(false);
    }
  };

  const handleNewCategoryInputChangeExpense = (e) => {
    setNewCategoryInputExpense(e.target.value);
  };

  const handleNewCategorySubmitExpense = () => {
    setSelectedCategoryExpense("");
    const formData = new FormData();
    formData.append("name", newCategoryInputExpense);
    postCategory(formData);
    setNewCategoryInputExpense("");
    setCategoryOpenExpense(false);
  };

  const handleKeyPressExpense = (e) => {
    if (e.key === "Enter") {
      handleNewCategorySubmitExpense();
    }
  };

  return (
    <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 ">
      <h4 className="  text-darkGray text-[1.4rem] m-0">Money Manger</h4>
      <div className="bg-white rounded-lg p-[1rem] mt-[1.5rem] border">
        <h4 className="text-darkGray text-[1rem] font-[500]">
          Let's start with your accounts
        </h4>

        {/* progressSteps */}
        <div className="my-[1rem]">
          {progressSteps.length > 0 && (
            <div className="flex items-center justify-between mb-4">
              {progressSteps.map((step, index) => (
                <React.Fragment key={index}>
                  <div className="">
                    <div
                      className={`h-[3px] w-[350px]
                         
                      mb-1 rounded-md ${
                        step.visited
                          ? "bg-[#C1A7D8]"
                          : step.active
                          ? "bg-customPurple"
                          : "bg-[#C1C9D2]"
                      }`}
                    ></div>

                    <div className="flex items-center gap-1">
                      {step.visited ? (
                        <input
                          type="checkbox"
                          checked={true}
                          readOnly
                          className={`form-checkbox w-3 h-3 rounded-full border-2 ${
                            step.visited
                              ? "border-[#C1A7D8] bg-[#C1A7D8]"
                              : "border-lightGray"
                          } appearance-none  checked:border-[#C1A7D8] custom-checkbox2`}
                          style={{ position: "relative" }} // To position the custom checkmark
                        />
                      ) : (
                        <input
                          type="radio"
                          checked={step.active}
                          readOnly
                          className={`form-radio w-3 h-3 rounded-full ${
                            step.active ? "custom-radio text-customPurple" : ""
                          } ${
                            step.visited
                              ? "accent-[#C1A7D8] text-[#C1A7D8] checked"
                              : ""
                          }`}
                        />
                      )}

                      <span
                        className={`font-[500] text-[0.75rem] ${
                          step.active ? "text-darkGray" : ""
                        } ${step.visited ? "text-[#C1A7D8]" : ""}`}
                      >
                        {step.label}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        {currentStep === 0 ? (
          <>
            {accounts.map((account) => (
              <div
                key={account.id}
                className="bg-[#F8FAFC] rounded-md p-[1rem] mt-[1rem]"
              >
                <div className="flex justify-between items-center">
                  <h4 className="text-darkGray text-[0.9rem] font-[500] mb-[1rem]">
                    Account #{account.id}
                  </h4>
                  {account.id > 1 && (
                    <button
                      className="text-red-500 text-[0.8rem] "
                      onClick={() => removeAccount(account.id)}
                    >
                      Remove
                    </button>
                  )}
                </div>

                {account.type ? (
                  <>
                    {/* <h4 className="text-darkGray text-[0.9rem] font-[500] mb-[1rem]">
                    Account #{account.id}
                  </h4> */}
                    <div className="flex gap-[1rem]">
                      <button
                        className="text-center text-white text-[0.9rem] font-[600] px-[2rem] py-[0.1rem] bg-darkGray border rounded"
                        disabled
                      >
                        {account.type.charAt(0).toUpperCase() +
                          account.type.slice(1)}
                      </button>
                      <button
                        className="text-center text-lightGray text-[0.9rem] font-[500] py-[0.1rem]"
                        onClick={() => handleAccountTypeChange(account.id, "")}
                      >
                        Change
                      </button>
                    </div>{" "}
                  </>
                ) : (
                  // Show All Options
                  <>
                    <h4 className="text-darkGray text-[0.9rem] font-[500] ">
                      Choose your Account Type
                    </h4>
                    <div className="flex gap-[1rem]">
                      <button
                        className={`text-center text-darkGray text-[0.9rem] font-[600] m-0 px-[2rem] py-[0.1rem] border rounded ${
                          account.type === "cash"
                            ? "bg-darkGray text-white"
                            : ""
                        } ${isCashSelected ? "hidden" : ""}`}
                        onClick={() =>
                          handleAccountTypeChange(account.id, "cash")
                        }
                        // disabled={isCashSelected}
                      >
                        Cash
                      </button>
                      <button
                        className={`text-center text-darkGray text-[0.9rem] font-[600] m-0 px-[2rem] py-[0.1rem] border rounded ${
                          account.type === "bank"
                            ? "bg-darkGray text-white"
                            : ""
                        }`}
                        onClick={() =>
                          handleAccountTypeChange(account.id, "bank")
                        }
                      >
                        Bank
                      </button>
                      <button
                        className={`text-center text-darkGray text-[0.9rem] font-[600] m-0 px-[1.8rem] py-[0.1rem] border rounded ${
                          account.type === "credit"
                            ? "bg-darkGray text-white"
                            : ""
                        }`}
                        onClick={() =>
                          handleAccountTypeChange(account.id, "credit")
                        }
                      >
                        Credit Card
                      </button>
                    </div>{" "}
                  </>
                )}

                {account.type === "cash" && (
                  <div className="mt-4">
                    <h4 className="text-darkGray text-[0.8rem] font-[600] ">
                      Current Balance
                    </h4>{" "}
                    <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[20%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        value={account.fields.cashBalance || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            account.id,
                            "cashBalance",
                            e.target.value
                          )
                        }
                        className="w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                        type="number"
                      />
                    </div>
                    {validationErrors[account.id]?.cashBalance && (
                      <span className="text-red-500 text-[0.8rem]">
                        {validationErrors[account.id]?.cashBalance}
                      </span>
                    )}
                  </div>
                )}

                {account.type === "bank" && (
                  <div className="mt-4">
                    <h4 className="text-darkGray text-[0.9rem] font-[600] ">
                      Bank Details
                    </h4>
                    <div className="flex gap-[1rem] my-[1rem]">
                      <div>
                        <h4 className="text-lightGray text-[0.7rem] font-[500] ">
                          Bank Name
                        </h4>
                        <div>
                          <InputField
                            value={account.fields.bankName || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                account.id,
                                "bankName",
                                e.target.value
                              )
                            }
                            className="border rounded-md w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                            type="text"
                          />
                        </div>
                        {validationErrors[account.id]?.bankName && (
                          <span className="text-red-500 text-[0.8rem]">
                            {validationErrors[account.id]?.bankName}
                          </span>
                        )}
                      </div>

                      <div>
                        <h4 className="text-lightGray text-[0.7rem] font-[500] ">
                          Account Number
                        </h4>
                        <div>
                          <InputField
                            value={account.fields.accNo || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                account.id,
                                "accNo",
                                e.target.value
                              )
                            }
                            className=" border rounded-md w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                            type="number"
                          />
                        </div>
                        {validationErrors[account.id]?.accNo && (
                          <span className="text-red-500 text-[0.8rem]">
                            {validationErrors[account.id]?.accNo}
                          </span>
                        )}
                      </div>
                    </div>

                    <h4 className="text-darkGray text-[0.8rem] font-[600] ">
                      Current Balance
                    </h4>
                    <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[20%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        value={account.fields.bankCurrentBal || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            account.id,
                            "bankCurrentBal",
                            e.target.value
                          )
                        }
                        className="w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                        type="number"
                      />
                    </div>
                    {validationErrors[account.id]?.bankCurrentBal && (
                      <span className="text-red-500 text-[0.8rem]">
                        {validationErrors[account.id]?.bankCurrentBal}
                      </span>
                    )}
                  </div>
                )}

                {account.type === "credit" && (
                  <div className="mt-4">
                    <h4 className="text-darkGray text-[0.9rem] font-[600] ">
                      Credit Details
                    </h4>
                    <div className="flex gap-[1rem] my-[1rem]">
                      <div>
                        <h4 className="text-lightGray text-[0.7rem] font-[500] ">
                          Bank Name
                        </h4>
                        <div>
                          <InputField
                            value={account.fields.creditBankName || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                account.id,
                                "creditBankName",
                                e.target.value
                              )
                            }
                            className="border rounded-md w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                            type="text"
                          />
                        </div>{" "}
                        {validationErrors[account.id]?.creditBankName && (
                          <span className="text-red-500 text-[0.8rem]">
                            {validationErrors[account.id]?.creditBankName}
                          </span>
                        )}
                      </div>

                      <div>
                        <h4 className="text-lightGray text-[0.7rem] font-[500] ">
                          Account Number
                        </h4>
                        <div>
                          <InputField
                            value={account.fields.creditAccNo || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                account.id,
                                "creditAccNo",
                                e.target.value
                              )
                            }
                            className=" border rounded-md w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                            type="number"
                          />
                        </div>{" "}
                        {validationErrors[account.id]?.creditAccNo && (
                          <span className="text-red-500 text-[0.8rem]">
                            {validationErrors[account.id]?.creditAccNo}
                          </span>
                        )}
                      </div>
                    </div>
                    <h4 className="text-darkGray text-[0.8rem] font-[600] ">
                      Monthly Avaialable Limit
                    </h4>
                    <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[20%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        value={account.fields.creditMonthlyLimit || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            account.id,
                            "creditMonthlyLimit",
                            e.target.value
                          )
                        }
                        className="w-[100%] text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                        type="number"
                      />
                    </div>
                    {validationErrors[account.id]?.creditMonthlyLimit && (
                      <span className="text-red-500 text-[0.8rem]">
                        {validationErrors[account.id]?.creditMonthlyLimit}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          ""
        )}

        <div className="bg-[#F8FAFC] rounded-md p-[1rem]">
          {currentStep === 1 ? (
            <StepIncome
              getAccountData={getAccountData}
              amountIncome={amountIncome}
              setamountIncome={setamountIncome}
              descIncome={descIncome}
              setdescIncome={setdescIncome}
              dateOfTranIncome={dateOfTranIncome}
              setDateOfTranIncome={setDateOfTranIncome}
              accountTypeIncome={accountTypeIncome}
              setaccountTypeIncome={setaccountTypeIncome}
              categoryIncome={categoryIncome}
              setcategoryIncome={setcategoryIncome}
              //
              handleOpenCategoryDialog={handleOpenCategoryDialog}
              categoryOpen={categoryOpen}
              handleCategorySelect={handleCategorySelect}
              newCategoryInput={newCategoryInput}
              handleNewCategoryInputChange={handleNewCategoryInputChange}
              handleNewCategorySubmit={handleNewCategorySubmit}
              getCategoryData={getCategoryData}
              handleKeyPress={handleKeyPress}
              selectedCategory={selectedCategory}
              setCategoryName={setCategoryName}
              categoryName={categoryName}
              openCategoryDialogBox={openCategoryDialogBox}
              handleClose={handleClose}
              handleOpen={handleOpen}
              handlePostnewCategory={handlePostnewCategory}
              errors={errors}
            />
          ) : (
            ""
          )}

          {currentStep === 2 ? (
            <StepExpense
              getAccountData={getAccountData}
              amountExpense={amountExpense}
              setamountExpense={setamountExpense}
              descExpense={descExpense}
              setdescExpense={setdescExpense}
              dateOfTranExpense={dateOfTranExpense}
              setDateOfTranExpense={setDateOfTranExpense}
              accountTypeExpense={accountTypeExpense}
              setaccountTypeExpense={setaccountTypeExpense}
              categoryExpense={categoryExpense}
              setcategoryExpense={setcategoryExpense}
              //
              setCategoryName={setCategoryName}
              handleOpenCategoryDialog={handleOpenCategoryDialogExpense}
              categoryOpen={categoryOpenExpense}
              handleCategorySelect={handleCategorySelectExpense}
              newCategoryInput={newCategoryInputExpense}
              handleNewCategoryInputChange={handleNewCategoryInputChangeExpense}
              handleNewCategorySubmit={handleNewCategorySubmitExpense}
              getCategoryData={getCategoryData}
              handleKeyPress={handleKeyPressExpense}
              selectedCategory={selectedCategoryExpense}
              categoryName={categoryName}
              openCategoryDialogBox={openCategoryDialogBox}
              handleClose={handleClose}
              handleOpen={handleOpen}
              handlePostnewCategory={handlePostnewCategory}
              errors={errors}
            />
          ) : (
            ""
          )}
        </div>

        {/* back and next button */}
        <div className="flex justify-between mt-[1rem]">
          {currentStep === 0 ? (
            <WhiteButton
              onClick={addAccount}
              name="Add another Account"
              px={"px-[1rem]"}
              py={"py-[0.2rem]"}
            />
          ) : (
            <div></div>
          )}
          <div className="flex justify-end gap-[1rem]">
            <WhiteButton
              onClick={handleBack}
              name="Back"
              px={"px-[1rem]"}
              py={"py-[0.2rem]"}
            />
            <Button
              onClick={currentStep === 0 ? createAccount : handleNext}
              // onClick={handleNext}
              name="Next"
              px={"px-[1rem]"}
              py={"py-[0.2rem]"}
            />
          </div>
        </div>
      </div>

      {currentStep === 2 && isOpen && (
        <WelcomeMoneyManagerDialog
          isOpen={isOpen}
          handleGoToNextPage={handleGoToNextPage}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default AddInitialAccount;

const StepIncome = ({
  getAccountData,
  amountIncome,
  setamountIncome,
  descIncome,
  setdescIncome,
  dateOfTranIncome,
  setDateOfTranIncome,
  accountTypeIncome,
  setaccountTypeIncome,
  categoryIncome,
  setcategoryIncome,

  //
  handleOpenCategoryDialog,
  categoryOpen,
  handleCategorySelect,
  newCategoryInput,
  handleNewCategoryInputChange,
  getCategoryData,
  handleKeyPress,
  selectedCategory,
  handleOpen,
  handlePostnewCategory,
  handleClose,
  openCategoryDialogBox,
  categoryName,
  setCategoryName,
  errors,
}) => {
  return (
    <div className="">
      <h4 className="text-center text-darkGray text-[0.9rem] font-[500] ">
        What was your last income?
      </h4>
      <div className="flex flex-col gap-[1rem] items-center justify-center mt-[1rem]">
        {/* amount */}
        <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[10%]">
          <span className="group-focus-within:text-customPurple p-[0.3rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
            Rs.
          </span>
          <InputField
            value={amountIncome}
            onChange={(e) => setamountIncome(e.target.value)}
            className="w-[100%] text-green-800 text-[0.9rem] bg-white focus:outline-none p-[0.3rem] rounded-r"
            type="number"
          />
        </div>
        {errors.amountIncome && (
          <p className="text-red-600 text-[0.7rem] m-0">
            {errors.amountIncome}
          </p>
        )}

        {/* description */}
        <div className="group focus:outline-customPurple flex items-center border rounded-md w-[10%]">
          <InputField
            value={descIncome}
            onChange={(e) => setdescIncome(e.target.value)}
            className="w-[100%] text-[#334155] text-[0.8rem] bg-white focus:outline-customPurple p-[0.4rem] rounded-r"
            type="text"
            placeholder="Add Description"
          />
        </div>
        {errors.descIncome && (
          <p className="text-red-600 text-[0.7rem] m-0">{errors.descIncome}</p>
        )}

        {/* date category */}
        <div className="flex gap-[1rem]">
          {/* date */}
          <div className="w-[30%] group focus-within:border-customPurple flex items-center border rounded-md">
            <span className="p-[0.4rem] rounded-l-md">
              <img src={datePicker} className="w-4 h-3" alt="" />
            </span>
            <InputField
              value={dateOfTranIncome}
              onChange={(e) => setDateOfTranIncome(e.target.value)}
              className="w-[85%] text-[#334155] text-[0.8rem] bg-white focus:outline-none p-[0.4rem] rounded-r "
              type="date"
            />
          </div>

          {/* category */}
          <div className="relative border w-[45%] bg-white rounded-md">
            <button
              className="flex gap-1 items-center justify-center focus:outline-none text-[0.8rem] p-[0.4rem] "
              onClick={handleOpenCategoryDialog}
            >
              {selectedCategory || "Category"}
              <img
                src={arrow}
                className={`transition-transform ${
                  categoryOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                }`}
                alt="Arrow"
              />
            </button>
            {categoryOpen && (
              <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10 w-[7.5rem]">
                <div
                  onClick={handleOpen}
                  // onClick={() => handleCategorySelect("Create New+", null)}
                  className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer"
                >
                  Create New +
                  {/* {selectedCategory === "Create New+" ? (
                    <div className="">
                      <InputField
                        onKeyDown={handleKeyPress}
                        type="text"
                        value={newCategoryInput}
                        onChange={handleNewCategoryInputChange}
                        className="font-[500] text-lightGray py-[0.2rem] text-[0.8rem] w-[100%] focus:outline-none"
                        placeholder="Create New"
                      />
                     
                    </div>
                  ) : (
                    "Create New +"
                  )} */}
                </div>

                {/* <div
                      onClick={() => handleCategorySelect("Create New+", null)}
                      className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer"
                    >
                      Create New +
                    </div> */}
                <hr className="m-0" />
                {getCategoryData?.data?.map((category) => (
                  <button
                    key={category.id}
                    onClick={() =>
                      handleCategorySelect(category.name, category.id)
                    }
                    className={`flex text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer
                  ${
                    selectedCategory === category.name
                      ? "text-darkGray flex gap-1"
                      : "text-lightGray"
                  }
                `}
                  >
                    {category.name}
                    {selectedCategory === category.name ? (
                      <img src={check} alt="" />
                    ) : (
                      ""
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
          {openCategoryDialogBox && (
            <CreateNewCategory
              name={categoryName}
              setName={setCategoryName}
              isOpen={openCategoryDialogBox}
              handleGoToCategories={handlePostnewCategory}
              closeModal={handleClose}
            />
          )}
          {/* <InputField
            type="select"
            className="w-[60%] border focus:outline-customPurple rounded-md bg-white text-[0.8rem] p-[0.4rem]"
            options={[
              { value: "", label: "Category" },
            ]}
            value={categoryIncome}
            onChange={(e) => setcategoryIncome(e.target.value)}
          /> */}

          {/* cash */}
          <InputField
            type="select"
            className="w-[60%] border focus:outline-customPurple rounded-md bg-white text-[0.8rem] p-[0.4rem]"
            options={[
              { label: "Account Type" },
              ...getAccountData?.data?.results?.map((option) => ({
                value: option.id,
                label: option.account_name,
              })),
            ]}
            value={accountTypeIncome}
            onChange={(e) => setaccountTypeIncome(e.target.value)}
          />
        </div>

        <div className="flex gap-[1rem]">
          {errors.dateOfTranIncome && (
            <p className="text-red-600 text-[0.7rem] m-0">
              {errors.dateOfTranIncome}
            </p>
          )}
          {errors.accountTypeIncome && (
            <p className="text-red-600 text-[0.7rem] m-0">
              {errors.accountTypeIncome}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const StepExpense = ({
  getAccountData,
  amountExpense,
  setamountExpense,
  descExpense,
  setdescExpense,
  dateOfTranExpense,
  setDateOfTranExpense,
  accountTypeExpense,
  setaccountTypeExpense,
  categoryExpense,
  setcategoryExpense,
  // //
  handleOpenCategoryDialog,
  categoryOpen,
  handleCategorySelect,
  newCategoryInput,
  handleNewCategoryInputChange,
  getCategoryData,
  handleKeyPress,
  selectedCategory,

  handleOpen,
  handlePostnewCategory,
  handleClose,
  openCategoryDialogBox,
  categoryName,
  setCategoryName,
  errors,
}) => {
  return (
    <div className="">
      <h4 className="text-center text-darkGray text-[0.9rem] font-[500] ">
        What was your last expense?
      </h4>
      <div className="flex flex-col gap-[1rem] items-center justify-center">
        {/* amount */}
        <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[10%]">
          <span className="group-focus-within:text-customPurple p-[0.3rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
            Rs.
          </span>
          <InputField
            value={amountExpense}
            onChange={(e) => setamountExpense(e.target.value)}
            className="w-[100%] text-red-500 text-[0.9rem] bg-white focus:outline-none p-[0.3rem] rounded-r"
            type="number"
          />
        </div>
        {errors.amountExpense && (
          <p className="text-red-600 text-[0.7rem] m-0">
            {errors.amountExpense}
          </p>
        )}

        {/* description */}
        <div className="group focus:outline-customPurple flex items-center border rounded-md w-[10%]">
          <InputField
            className="w-[100%] text-[#334155] text-[0.8rem] bg-white focus:outline-customPurple p-[0.4rem] rounded-r"
            type="text"
            value={descExpense}
            onChange={(e) => setdescExpense(e.target.value)}
            placeholder="Add Description"
          />
        </div>
        {errors.descExpense && (
          <p className="text-red-600 text-[0.7rem] m-0">{errors.descExpense}</p>
        )}

        {/* date category */}
        <div className="flex gap-[1rem]">
          {/* date */}
          <div className="w-[30%] group focus-within:border-customPurple flex items-center border rounded-md">
            <span className="p-[0.4rem] rounded-l-md">
              <img src={datePicker} className="w-4 h-3" alt="" />
            </span>
            <InputField
              value={dateOfTranExpense}
              onChange={(e) => setDateOfTranExpense(e.target.value)}
              className="w-[85%] text-[#334155] text-[0.8rem] bg-white focus:outline-none p-[0.4rem] rounded-r "
              type="date"
            />
          </div>

          {/* category */}
          {/* <InputField
            type="select"
            className="w-[60%] border focus:outline-customPurple rounded-md bg-white text-[0.8rem] p-[0.4rem]"
            options={[
              { value: "", label: "Category" },
              // { value: "Create New +", label: "Create New +" },
            ]}
            value={categoryExpense}
            onChange={(e) => setcategoryExpense(e.target.value)}
          /> */}
          <div className="relative border w-[45%] bg-white rounded-md">
            <button
              className="flex gap-1 items-center justify-center focus:outline-none text-[0.8rem] p-[0.4rem] "
              onClick={handleOpenCategoryDialog}
            >
              {selectedCategory || "Category"}
              <img
                src={arrow}
                className={`transition-transform ${
                  categoryOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                }`}
                alt="Arrow"
              />
            </button>
            {categoryOpen && (
              <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10 w-[7.5rem]">
                <div
                  onClick={handleOpen}
                  // onClick={() => handleCategorySelect("Create New+", null)}
                  className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer"
                >
                  Create New +
                  {/* {selectedCategory === "Create New+" ? (
                    <div className="">
                      <InputField
                        onKeyDown={handleKeyPress}
                        type="text"
                        value={newCategoryInput}
                        onChange={handleNewCategoryInputChange}
                        className="font-[500] text-lightGray py-[0.2rem] text-[0.8rem] w-[100%] focus:outline-none"
                        placeholder="Create New"
                      />
                     
                    </div>
                  ) : (
                    "Create New +"
                  )} */}
                </div>

                {/* <div
                      onClick={() => handleCategorySelect("Create New+", null)}
                      className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer"
                    >
                      Create New +
                    </div> */}
                <hr className="m-0" />
                {getCategoryData?.data?.map((category) => (
                  <button
                    key={category.id}
                    onClick={() =>
                      handleCategorySelect(category.name, category.id)
                    }
                    className={`flex text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer
                  ${
                    selectedCategory === category.name
                      ? "text-darkGray flex gap-1"
                      : "text-lightGray"
                  }
                `}
                  >
                    {category.name}
                    {selectedCategory === category.name ? (
                      <img src={check} alt="" />
                    ) : (
                      ""
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
          {openCategoryDialogBox && (
            <CreateNewCategory
              name={categoryName}
              setName={setCategoryName}
              isOpen={openCategoryDialogBox}
              handleGoToCategories={handlePostnewCategory}
              closeModal={handleClose}
            />
          )}

          {/* cash */}
          <InputField
            type="select"
            className="w-[60%] border focus:outline-customPurple rounded-md bg-white text-[0.8rem] p-[0.4rem]"
            options={[
              { label: "Account Type" },
              ...getAccountData?.data?.results?.map((option) => ({
                value: option.id,
                label: option.account_name,
              })),
            ]}
            value={accountTypeExpense}
            onChange={(e) => setaccountTypeExpense(e.target.value)}
          />
        </div>

        <div className="flex gap-[1rem]">
          {errors.dateOfTranExpense && (
            <p className="text-red-600 text-[0.7rem] m-0">
              {errors.dateOfTranExpense}
            </p>
          )}
          {errors.accountTypeExpense && (
            <p className="text-red-600 text-[0.7rem] m-0">
              {errors.accountTypeExpense}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
