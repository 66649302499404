import React from "react";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import {
  BUSINESS_CUSTOMERS_URL,
  BUSINESS_NEW_CUSTOMERS_URL,
} from "constants/routes";
import { useNavigate } from "react-router-dom";

const SelectOptionCustomers = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 pt-[5.3rem] h-screen px-[1.2rem] ">
    <div className="bg-white p-[1rem] rounded-md border">
      <div className="flex items-center gap-[0.5rem]">
        <button onClick={() => navigate(BUSINESS_CUSTOMERS_URL)}>
          <img src={previousBtn} alt="" className="w-[1rem] h-[1rem]" />
        </button>
        <h4 className="text-darkGray text-[0.9rem] m-0">New Customer</h4>
      </div>
      <div className="flex flex-col justify-center items-center py-[13.2rem]">
        <h4 className="text-[1rem] text-darkGray">
          Is your customer GST registered?
        </h4>
        <div className="flex gap-[0.8rem] items-center">
          <button
            onClick={() =>
              navigate(
                BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "registered")
              )
            }
            className="font-[500] bg-[#F3F4F6] rounded-md px-[0.5rem] py-[0.3rem] text-[0.8rem] text-darkGray"
          >
            Yes, registered
          </button>
          <h4 className="text-[0.8rem] text-lightGray m-0">OR</h4>
          <button
            onClick={() =>
              navigate(
                BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "unregistered")
              )
            }
            className="font-[500] bg-[#F3F4F6] rounded-md px-[0.5rem] py-[0.3rem] text-[0.8rem] text-darkGray"
          >
            No, unregistered
          </button>
        </div>
      </div>
    </div>
     </div>
  );
};

export default SelectOptionCustomers;
