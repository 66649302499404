import React, { useEffect, useState } from "react";
import avatar from "assets/images/BusinessDashboard/BusinessCustomers/avatar.svg";
import userIcon from "assets/images/BusinessDashboard/BusinessCustomers/user.svg";
import mail from "assets/images/BusinessDashboard/BusinessCustomers/mail.svg";
import phone from "assets/images/BusinessDashboard/BusinessCustomers/phone.svg";
import downArrow from "assets/images/BusinessDashboard/BusinessCustomers/downArrow.svg";
import search from "assets/images/BusinessDashboard/BusinessCustomers/search.svg";
import addUser from "assets/images/BusinessDashboard/BusinessCustomers/addUser.svg";
import rightArrow from "assets/images/BusinessDashboard/BusinessCustomers/rightArrow.svg";
import InputField from "components/input/InputField";
import { useNavigate } from "react-router-dom";
import {
  BUSINESS_CUSTOMER_DETAILVIEW,
  BUSINESS_NEW_CUSTOMERS_URL,
} from "constants/routes";
import useGetCompanyNameSuggesstion from "./data/useGetCompanyNameSuggestion";
const CustomersList = ({
  getData,
  getCustomersOverview,
  initialPage,
  setPage,
  getError,
  setOrder,
  order,
  setSearchQuery,
  searchQuery,
  type,
  setType,
}) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const businessId = JSON.parse(localStorage.getItem("user"))?.business_id;
  const [nameOpen, setNameOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [infiniteLoader, setInfiniteLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [searchNameSuggestion, setSearchNameSuggestion] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [
    searchCompanyNameData,
    searchCompanyNameError,
    searchCompanyNameIsLoading,
    getSearchCompanyName,
  ] = useGetCompanyNameSuggesstion();

  // Scroll Event Handler
  const handleScroll = () => {
    const scrollTop = window.scrollY; // Current scroll position
    const windowHeight = window.innerHeight; // Height of the visible window
    const documentHeight = document.documentElement.scrollHeight; // Total document height
    // Check if the user is near the bottom of the page
    // console.log(
    //   scrollTop + windowHeight >= documentHeight - 100,
    //   hasMore,
    //   !infiniteLoader
    // );
    if (
      scrollTop + windowHeight >= documentHeight - 100 &&
      hasMore &&
      !infiniteLoader
    ) {
      setInfiniteLoader(true);
      setPage((prevPage) => prevPage + 1); // Increment page to fetch next set of data
    }
  };

  useEffect(() => {
    if (getData?.results?.length > 0) {
      setHasMore(true);
      setInfiniteLoader(false);
      setData((prev) => [...prev, ...getData?.results]);
    } else {
      setHasMore(false);
      setInfiniteLoader(false);
    }
  }, [getData]);

  useEffect(() => {
    if (getError) {
      setHasMore(false);
    }
  }, [getError]);

  // Attach and clean up the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore, infiniteLoader]);

  const IndustryTypeChoices = {
    1: "Consultancy",
    2: "Technology",
    3: "Construction",
    4: "Clothing",
    5: "Agriculture",
    6: "Salaried",
    7: "Real Estate",
    8: "Food & Beverage",
    9: "Consulting",
    10: "Rental",
    11: "Sports",
    12: "Decors",
    13: "Retail",
    14: "Healthcare",
  };

  const toggleDropdown = (dropdown) => {
    if (dropdown === "name") {
      setNameOpen(!nameOpen);
      setTypeOpen(false);
      setStatusOpen(false);
    } else if (dropdown === "type") {
      setTypeOpen(!typeOpen);
      setNameOpen(false);
      setStatusOpen(false);
    } else if (dropdown === "status") {
      setStatusOpen(!statusOpen);
      setNameOpen(false);
      setTypeOpen(false);
    }
  };

  // search filter
  useEffect(() => {
    if (searchCompanyNameData) {
      setSearchNameSuggestion(searchCompanyNameData?.data?.company_names);
    }
  }, [searchCompanyNameData]);

  const handleSearchCompany = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setSearchNameSuggestion([]);
      getCustomersOverview(user?.business_id, {
        page: initialPage,
      });
      return;
    }
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(() => {
      if (e.target.value.trim()) {
        getSearchCompanyName(user?.business_id, e.target.value);
      }
    }, 300);

    setDebounceTimeout(newTimeout);
  };

  const handleSetName = (name) => {
    setSearchQuery(name);
    setPage(1);
    setData([]);
    setSearchNameSuggestion([]);
  };

  useEffect(() => {
    let params = { page: initialPage };
  
    if (searchQuery.trim()) {
      params.search = searchQuery;
    }
  
    if (order) {
      params.ordering = order === "desc" ? "-company_name" : "company_name";
    }
  
    if (type === "registered") {
      params.is_customer_gst_registered = "true";
    } else if (type === "unregistered") {
      params.is_customer_gst_registered = "false";
    }
  
    getCustomersOverview(user?.business_id, params);
  }, [searchQuery, order, type, initialPage]);

  const handleOrderingChange = (newOrder) => {
    setOrder(newOrder);
    setPage(1); // Reset to page 1 for new ordering
    setData([]); // Clear current data
  };

  // type filter
  const handleType = (type) => {
    setType(type);
    setPage(1); // Reset to page 1 for new ordering
    setData([]);
  };

  const handleNavigate = (id) => {
    navigate(BUSINESS_CUSTOMER_DETAILVIEW, { state: { id } });
  };
  return (
    <div className="mt-16 p-4">
      <div className="bg-[#FFFFFF] rounded-lg p-3 flex flex-col gap-3">
        <div className="flex justify-between">
          <div className="flex gap-3.5">
            {/* Name Dropdown */}
            <div className="relative">
              <div
                onClick={() => toggleDropdown("name")}
                className="flex gap-1.5 px-2.5 py-2 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer"
              >
                <button className="text-[#1F2937] text-[0.85rem] font-[600]">
                  Name
                </button>
                <img src={downArrow} alt="downArrow" />
              </div>
              {nameOpen && (
                <div className="absolute left-0 mt-2 w-24 bg-white border border-gray-300 rounded-md shadow-lg z-10 mb-0">
                  <ul className="pl-0 text-[0.85rem] font-[500] mb-0">
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      // onClick={() => handleSort("asc")}
                      onClick={() => handleOrderingChange("asc")}
                    >
                      A to Z
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      // onClick={() => handleSort("desc")}
                      onClick={() => handleOrderingChange("desc")}
                    >
                      Z to A
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Type Dropdown */}
            <div className="relative">
              <div
                onClick={() => toggleDropdown("type")}
                className="flex gap-1.5 px-2.5 py-2 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer"
              >
                <button className="text-[#1F2937] text-[0.85rem] font-[600]">
                  Type
                </button>
                <img src={downArrow} alt="downArrow" />
              </div>
              {typeOpen && (
                <div className="absolute left-0 mt-2 w-32 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <ul className="pl-0 text-[0.85rem] w-[100%] font-[500] mb-0">
                    <li
                      onClick={() => handleType("all")}
                      className="w-[100%] flex justify-start items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <span className="pl-4">All</span>
                    </li>
                    <li
                      onClick={() => handleType("registered")}
                      className="w-[100%] flex justify-start items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <span className="pl-4">Registered</span>
                    </li>
                    <li
                      onClick={() => handleType("unregistered")}
                      className="w-[100%] flex justify-start items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <span className="pl-4">Unregistered</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Transaction Status Dropdown */}
            <div className="relative">
              <div
                onClick={() => toggleDropdown("status")}
                className="flex gap-1.5 px-2.5 py-2 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer"
              >
                <button className="text-[#1F2937] text-[0.85rem] font-[500]">
                  Transaction Status
                </button>
                <img src={downArrow} alt="downArrow" />
              </div>
              {statusOpen && (
                <div className="absolute left-0 mt-2 w-32 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <ul className="pl-0 text-[0.85rem] font-[500] mb-0">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      All
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Overdue
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Pending
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Success
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-3.5">
            <div className="relative">
              <div className="border border-gray-300 rounded-md shadow-sm flex">
                <InputField
                  value={searchQuery}
                  onChange={(e) => handleSearchCompany(e)}
                  placeholder="Search Customers"
                  type="search"
                  className="w-[100%] focus:outline-none px-[0.7rem] py-1.5 rounded-l text-[0.9rem] border-r border-gray-300"
                />
                <img src={search} alt="search" className="px-[0.7rem] py-1.5" />
              </div>
              {searchNameSuggestion.length > 0 && (
                <div className="absolute top-full left-0 w-full bg-white border border-[#D9D9D9] shadow-md rounded mt-1 z-10">
                  {searchNameSuggestion.map((name, index) => (
                    <div
                      key={index}
                      onClick={() => handleSetName(name)}
                      className="px-3 py-2 hover:bg-[#F1F5F9] cursor-pointer text-[0.9rem] text-[#64748B]"
                    >
                      {name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={() =>
                navigate(BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "select"))
              }
              className="border border-gray-300 rounded-md shadow-sm flex items-center gap-1 px-[0.7rem] py-1.5 cursor-pointer"
            >
              <img src={addUser} alt="addUser" />
              <p className="text-[0.85rem] text-[#1F2937] font-[600] m-0">
                Add New Customer
              </p>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3">
          {data?.map((item, index) => {
            return (
              <div className="rounded-md border border-[#E5E7EB]" key={index}>
                <div className="flex justify-between items-center bg-[#F8FAFC] p-2 rounded-t-md">
                  <div className="flex gap-1 items-center">
                    <div>
                      <img src={avatar} alt="avatar" />
                    </div>
                    <div>
                      <p className="m-0 text-[#1F2937] font-[600]">
                        {item?.company_name}
                      </p>
                      <p className="m-0 text-[#6B7280] font-[500] text-[0.8rem]">
                        {IndustryTypeChoices[item?.Industry_type] ||
                          "Unknown Industry"}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => handleNavigate(item?.id)}
                      className="p-2 rounded-full bg-[#FFFFFF] cursor-pointer"
                    >
                      <img src={rightArrow} alt="rightArrow" />
                    </div>
                  </div>
                </div>
                <div className="p-3 border-b border-[#E5E7EB]">
                  <div className="flex flex-col gap-1.5">
                    <div className="flex gap-1.5">
                      <img src={userIcon} alt="user" />
                      <p className="m-0 text-[#6B7280] font-[500] text-[0.9rem]">
                        {/* Dheeraj Rao */}
                        {item?.contact_persons?.filter(
                          (el) => el.is_primary
                        )?.[0]?.name
                          ? item?.contact_persons?.filter(
                              (el) => el.is_primary
                            )?.[0]?.name
                          : "-"}
                        {/* set name, email, phone of contact_persons, whose is_primary is true */}
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img src={phone} alt="phone" />
                      <p className="m-0 text-[#6B7280] font-[500] text-[0.9rem]">
                        {item?.contact_persons?.filter(
                          (el) => el.is_primary
                        )?.[0]?.phone
                          ? item?.contact_persons?.filter(
                              (el) => el.is_primary
                            )?.[0]?.phone
                          : "-"}
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img src={mail} alt="mail" />
                      <p className="m-0 text-[#6B7280] font-[500] text-[0.9rem]">
                        {item?.contact_persons?.filter(
                          (el) => el.is_primary
                        )?.[0]?.email
                          ? item?.contact_persons?.filter(
                              (el) => el.is_primary
                            )?.[0]?.email
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-3 rounded-b-md flex justify-between items-center">
                  <p className="m-0 text-[#6B7280] text-[0.85rem]">
                    Transaction Status
                  </p>
                  <div className="rounded bg-[#FEF9C3] p-1.5">
                    <p className="m-0 text-[#854D0E] text-[0.8rem] font-[500]">
                      Pending
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
