import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import calender from "assets/images/BusinessDashboard/BusinessEmployee/calendar-fill.svg";
import useGetEmployeeWithId from "./data/useGetEmployeeWithId";
import usePostRecordLeave from "./data/usePostRecordLeave";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const RecordLeave = ({
  openRecordLeave,
  setOpenRecordLeave,
  triggerElement,
  idForIndividualPage,
  setRecallGetApi,
  getEmployeeOverview,
}) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [dateRangeView, setDateRangeView] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [idSavedData, setIdSavedData] = useState([]);
  const [recordLeaveForm, setRecordLeaveForm] = useState({
    leave_type: "",
    start_date: "",
    end_date: "",
    reason: "",
    employee_id: "",
  });
  const [idData, idError, idIsLoading, getEmployeeId] = useGetEmployeeWithId();
  const [postData, postError, postIsLoading, postRecordLeave] =
    usePostRecordLeave();
  useEffect(() => {
    getEmployeeId();
  }, []);

  useEffect(() => {
    if (idForIndividualPage) {
      setRecordLeaveForm({
        ...recordLeaveForm,
        employee_id: idForIndividualPage,
      });
    }
  }, [idForIndividualPage]);

  useEffect(() => {
    if (idData) {
      console.log("idData", idData);
      setIdSavedData(idData?.data?.employees);
    }
  }, [idData]);
  const resetState = () => {
    setOpenRecordLeave(false);
  };

  useEffect(() => {
    if (postData) {
      console.log("postData", postData);
      showSuccessToast(`${postData?.data?.message}`);
      if(getEmployeeOverview){
        getEmployeeOverview();
      }
      if (setRecallGetApi) {
        setRecallGetApi(true);
      }
      resetState();
    }
  }, [postData]);

  useEffect(() => {
    if (postError) {
      const error = postError?.err?.response?.data?.data?.message[0];
      console.log("error:", error);
      showErrorToast(error, "Employee already added.");
    }
  }, [postError]);

  const formatDate = (date) => {
    if (!date) return ""; // Handle invalid or empty dates
    return date.toLocaleDateString("en-GB"); // Format as dd/mm/yyyy
  };

  const formatDateToSend = (date) => {
    if (!date) return ""; // Handle invalid or empty dates

    // Ensure the date is a Date object
    const validDate = new Date(date);

    const year = validDate.getFullYear();
    const month = String(validDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(validDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Format as yyyy-mm-dd
  };

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection.startDate, ranges.selection.endDate]);
    setDateRangeView(ranges);
    setRecordLeaveForm({
      ...recordLeaveForm,
      start_date: formatDateToSend(ranges.selection.startDate)
        ? formatDateToSend(ranges.selection.startDate)
        : "",
      end_date: formatDateToSend(ranges.selection.endDate)
        ? formatDateToSend(ranges.selection.endDate)
        : "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecordLeaveForm({ ...recordLeaveForm, [name]: value });
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setDateRangeView("");
    setDateRange([new Date(), new Date()]);
  };
  const handleAdd = () => {
    setShowDatePicker(false);
  };

  const handleSubmit = () => {
    postRecordLeave(recordLeaveForm);
  };
  const getContent = () => {
    return (
      <>
        <div>
          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <div className="flex flex-col gap-1 ">
                <p className="m-0 text-[#64748B] text-[0.83rem] md:text-[0.9rem] font-[500]">
                  Employee
                </p>
                {idForIndividualPage ? (
                  <>
                    <InputField
                      type="select"
                      name="employee_id"
                      readOnly={true}
                      value={recordLeaveForm.employee_id}
                      onChange={handleInputChange}
                      options={[
                        { value: "", label: "Select Employee" },
                        ...idSavedData.map((el) => {
                          return {
                            value: el.employee_id,
                            label: el.employee_name,
                          };
                        }),
                      ]}
                      className="text-[#334155] cursor-pointer md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded border border-[#D9D9D9]  focus:outline-none"
                    />
                  </>
                ) : (
                  <InputField
                    type="select"
                    name="employee_id"
                    // readOnly={idForIndividualPage ? "true" : "false"}
                    value={recordLeaveForm.employee_id}
                    onChange={handleInputChange}
                    options={[
                      { value: "", label: "Select Employee" },
                      ...idSavedData.map((el) => {
                        return {
                          value: el.employee_id,
                          label: el.employee_name,
                        };
                      }),
                    ]}
                    className="text-[#334155] cursor-pointer md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded border border-[#D9D9D9]  focus:outline-none"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-1.5 mt-2">
            <div className="col-span-4">
              <div className="flex flex-col gap-1 ">
                <p className="m-0 text-[#64748B] text-[0.83rem] md:text-[0.9rem] font-[500]">
                  Type
                </p>
                <InputField
                  type="select"
                  name="leave_type"
                  value={recordLeaveForm.leave_type}
                  options={[
                    { value: "", label: "Select" },
                    {
                      value: "1",
                      label: "Earned Leave",
                    },
                    {
                      value: "2",
                      label: "Paid Leave",
                    },
                  ]}
                  onChange={handleInputChange}
                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded border border-[#D9D9D9]  focus:outline-none"
                />
              </div>
            </div>
            <div
              className={`${
                dateRangeView?.selection?.startDate
                  ? "col-span-6"
                  : "col-span-4 "
              }`}
            >
              <div className="flex flex-col gap-1 ">
                <p className="m-0 text-[#64748B] text-[0.83rem] md:text-[0.9rem] font-[500]">
                  Select Date
                </p>
                {/* <InputField
                  type="date"
                  // name={`unlisted_share_holdings.${index}.company_type`}
                  // register={register}
                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded border border-[#D9D9D9]  focus:outline-none"
                /> */}
                <div
                  onClick={() => setShowDatePicker(true)}
                  className="flex gap-1 text-[#334155] border border-[#D9D9D9] px-2.5 py-1.5 rounded-md text-[0.9rem] cursor-pointer"
                >
                  <img src={calender} alt="calender" />
                  {dateRangeView?.selection?.startDate && (
                    <span>
                      {formatDate(dateRangeView?.selection?.startDate)}
                      {dateRangeView?.selection?.endDate
                        ? ` -> ${formatDate(dateRangeView?.selection?.endDate)}`
                        : ""}
                    </span>
                  )}
                  {!dateRangeView?.selection?.startDate && (
                    <span>DD/MM/YYY</span>
                  )}
                </div>
                {showDatePicker && (
                  <>
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={[
                        {
                          startDate: dateRange[0],
                          endDate: dateRange[1],
                          key: "selection",
                        },
                      ]}
                      rangeColors={["#FFEDD5"]} // Customize selection color
                      className="custom-calendar-leave custom-calendar "
                      shownDate={
                        dateRange[0] instanceof Date ? dateRange[0] : new Date()
                      }
                    />
                    <div className="flex gap-1 relative bottom-12 left-44">
                      <button
                        onClick={() => handleCancel()}
                        className="px-2.5 py-1.5 rounded border border-[#D9D9D9] text-[#64748B]"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleAdd()}
                        className="px-2.5 py-1.5 rounded bg-[#EA580C] text-[#FFFFFF]"
                      >
                        Add
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 mt-2">
            <div className="col-span-12">
              <div className="flex flex-col gap-1 ">
                <p className="m-0 text-[#64748B] text-[0.83rem] md:text-[0.9rem] font-[500]">
                  Reason (Optional)
                </p>
                <InputField
                  name="reason"
                  placeholder="Optional"
                  type="text"
                  value={recordLeaveForm.reason}
                  onChange={handleInputChange}
                  className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <DialogBox
        triggerElement={triggerElement}
        onClose={resetState}
        heading={
          <p className="font-[600] text-[1rem] text-[#334155]">
            Record New Leave
          </p>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end items-end gap-[1rem] mt-[2rem] h-[60%]">
              <button
                onClick={handleClose}
                className="px-[0.6rem] py-[0.4rem] rounded border border-[#D9D9D9] text-[#64748B] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-[0.6rem] py-[0.4rem] rounded bg-[#EA580C] text-[#FFFFFF]"
              >
                Add
              </button>
            </div>
          </>
        )}
      />
    </>
  );
};

export default RecordLeave;
