import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import InputField from "components/input/InputField";
import usePostGoal from "./data/usePostGoal";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePostInitialAccount from "./data/usePostInitialAccount";
import { Troubleshoot } from "@mui/icons-material";

const AddNewAccount = ({
  triggerElement,
  setIsAccountOpen,
  isAccountOpen,
  resetState,
  isReload,
  setIsReload,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [showBankOrCredit, setShowBankOrCredit] = useState("");
  const [postData, postError, postIsLoading, postAddAccounts ] = usePostInitialAccount();

  useEffect(()=>{
    if(postData){
      showSuccessToast("Account Added Successfully")
      setIsClose(true)
      setIsReload(true)
    }
    if(postError){
      showErrorToast("Error in adding account")
    }
  },[postData,postError])

  const handleView = (val) => {
    setShowBankOrCredit(val);
    setShowFields(true);
  };

  const [submitForm, setSubmitForm] = useState({
    account_type: "",
    account_name: "",
    account_number: "",
    current_balance: "",
  });

  const handleChange = () => {
    if (showBankOrCredit === "bank") {
      setShowBankOrCredit("credit");
    } else {
      setShowBankOrCredit("bank");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubmitForm({ ...submitForm, [name]: value });
  };

  const handleSubmitForm = () => {
    let accountTypeName = "";
    if (showBankOrCredit === "bank") {
      accountTypeName = "Bank";
    } else {
      accountTypeName = "Credit Card";
    }

    console.log({ ...submitForm, account_type: accountTypeName });
    postAddAccounts({ ...submitForm, account_type: accountTypeName })
  };
  const getContent = () => {
    return (
      <>
        <div>
          {showFields ? (
            <>
              {showBankOrCredit === "bank" ? (
                <>
                  <div className="flex items-center gap-3 mt-2">
                    <div className="px-5 py-1 bg-[#334155] rounded-md">
                      <p className="m-0 text-[0.9rem] text-[#FFFFFF] font-[600]">
                        Bank
                      </p>
                    </div>
                    <div onClick={handleChange} className="">
                      <p className="m-0 text-[0.9rem] text-[#334155] font-[500] cursor-pointer">
                        Change
                      </p>
                    </div>
                  </div>
                  <div className="mt-2.5">
                    <p className="text-[#334155] font-[600] m-0 ">
                      Bank Details
                    </p>
                    <div className="grid grid-cols-12 mt-2 gap-3">
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                          Bank Name
                        </label>
                        <InputField
                          type="select"
                          className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                          options={[
                            { value: "", label: "Select Bank" },
                            { value: "Axis", label: "Axis" },
                            { value: "Sbi", label: "SBI" },
                            { value: "Hdfc", label: "HDFC" },
                          ]}
                          name="account_name"
                          value={submitForm.account_name}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* Upfront investment */}
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                          Account Number
                        </label>
                        <InputField
                          type="number"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                          placeholder="Account Number"
                          value={submitForm.account_number}
                          onChange={(e) => handleInputChange(e)}
                          name="account_number"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-12 mt-2 gap-3">
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#334155] text-[0.9rem]">
                          Current Balance
                        </label>
                        <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                          <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            name="current_balance"
                            value={submitForm.current_balance}
                            onChange={(e) => handleInputChange(e)}
                            className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center gap-3 mt-2">
                    <div className="px-3 py-1 bg-[#334155] rounded-md">
                      <p className="m-0 text-[0.9rem] text-[#FFFFFF] font-[600]">
                        Credit Card
                      </p>
                    </div>
                    <div className="">
                      <p
                        onClick={handleChange}
                        className="m-0 text-[0.9rem] text-[#334155] cursor-pointer font-[500]"
                      >
                        Change
                      </p>
                    </div>
                  </div>
                  <div className="mt-2.5">
                    <p className="text-[#334155] font-[600] m-0 ">
                      Card Details
                    </p>
                    <div className="grid grid-cols-12 mt-2 gap-3">
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                          Bank Name
                        </label>
                        <InputField
                          type="select"
                          className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                          options={[
                            { value: "", label: "Select Bank" },
                            { value: "Axis", label: "Axis" },
                            { value: "Sbi", label: "SBI" },
                            { value: "Hdfc", label: "HDFC" },
                          ]}
                          name="account_name"
                          value={submitForm.account_name}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* Upfront investment */}
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                          Card Number
                        </label>
                        <InputField
                          type="number"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                          placeholder="Account Number"
                          value={submitForm.account_number}
                          onChange={(e) => handleInputChange(e)}
                          name="account_number"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-12 mt-2 gap-3">
                      <div className="col-span-6 w-[100%] group">
                        <label className="group-focus-within:text-customPurple font-[500] text-[#334155] text-[0.9rem]">
                          Monthly Available Limit
                        </label>
                        <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                          <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            name="current_balance"
                            value={submitForm.current_balance}
                            onChange={(e) => handleInputChange(e)}
                            className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <p className="text-[#334155] font-[500] m-0">
                Choose your Account Type
              </p>
              <div className="flex items-center gap-3 mt-2">
                <div
                  onClick={() => handleView("bank")}
                  className="px-5 py-1 border-[1px] border-[#D9D9D9] rounded-md cursor-pointer"
                >
                  <p className="m-0 text-[0.9rem] text-[#334155] font-[600]">
                    Bank
                  </p>
                </div>
                <div
                  onClick={() => handleView("credit")}
                  className="px-[0.975rem] py-1 border-[1px] border-[#D9D9D9] rounded-md cursor-pointer"
                >
                  <p className="m-0 text-[0.9rem] text-[#334155] font-[600]">
                    Credit Card
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        resetClose={setIsAccountOpen}
        isNotDefault={isAccountOpen}
        triggerElement={triggerElement}
        heading={
          <p className="font-[500] m-0 text-[#64748B]">Add New Account</p>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div>
              <div className="flex justify-end gap-[1rem] ">
                <button
                  onClick={handleSubmitForm}
                  className="px-[0.6rem] py-[0.4rem] text-[0.9rem] font-[600] text-[#FFFFFF] bg-[#94A3B8] rounded"
                >
                  Add
                </button>
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewAccount;
