import React, { useEffect, useRef, useState } from "react";
import CustomizableDrawer from "../Reports/Drawer";
import "../IncomeTax/IncomeTaxSummary/ITI.css";
import Button from "components/Button/BtnOrange";
import RemoveCard from "./RemoveCard";

// images
import edit from "assets/images/taxProfile/edit.svg";
import success from "assets/images/taxProfile/success.svg";
import eye from "assets/images/taxProfile/eye.svg";
import down_arrow from "assets/images/taxProfile/down-arrow.svg";
import add from "assets/images/taxProfile/add.svg";
import check from "assets/images/taxProfile/check.svg";
import no from "assets/images/taxProfile/x.svg";
import editMobile from "assets/images/taxProfile/editMobile.svg";
import viewMobile from "assets/images/taxProfile/viewMobile.svg";
import purpleEye from "assets/images/taxProfile/purpleEye.svg";
import download from "assets/images/taxProfile/download-cloud.svg";
import compareIcon from "assets/images/taxProfile/file-text.svg";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";

// APIs
import useGetGovtID from "./data/useGetGovtID";
import usePostGovtID from "./data/usePostGovtID";
import HeadingProfile from "./HeadingProfile";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import { useNavigate } from "react-router-dom";
import BackToPersonalDashboard from "./BackToPersonalDashboard";

const GovtIds = () => {
  const navigate = useNavigate();
  const [showDetailedComparison, setShowDetailedComparison] = useState(false);
  const [addAadharCard, setAddAadharCard] = useState(false);
  const [addPANCard, setAddPANCard] = useState(false);
  const [addDrivingLicense, setAddDrivingLicense] = useState(false);
  const [addVoterID, setAddVoterID] = useState(false);
  const [addRationCard, setAddRationCard] = useState(false);
  const [addPassport, setAddPassport] = useState(false);

  const [getGovtIDData, getGovtIDError, getGovtIDIsLoading, getGovtID] =
    useGetGovtID();
  const [postData, postError, postIsLoading, postGovtID] = usePostGovtID();

  const detailedArray = [
    {
      id: 1,
      Details: "Name",
      According_to_your_PAN: "Dheeraj",
      According_to_your_Aadhar: "Dheeraj",
      Accuracy: check,
    },
    {
      id: 2,
      Details: "DOB",
      According_to_your_PAN: "29/05/1998",
      According_to_your_Aadhar: "29/05/1998",
      Accuracy: check,
    },
    {
      id: 3,
      Details: "Gender",
      According_to_your_PAN: "Male",
      According_to_your_Aadhar: "Male",
      Accuracy: check,
    },
    {
      id: 4,
      Details: "Mobile Number",
      According_to_your_PAN: "+91-9878786786",
      According_to_your_Aadhar: "+91-9878786786",
      Accuracy: check,
    },
    {
      id: 5,
      Details: "Mail ID",
      According_to_your_PAN: "dheerajrao@gmail.com",
      According_to_your_Aadhar: "dheerajrao@gmail.com",
      Accuracy: no,
    },
    {
      id: 6,
      Details: "Address",
      According_to_your_PAN: "Telangana-504106",
      According_to_your_Aadhar: "Telangana-504106",
      Accuracy: no,
    },
  ];

  useEffect(() => {
    getGovtID();
  }, []);

  useEffect(() => {
    if (postError) {
      const error = postError?.err?.response?.data?.error?.errors[0]?.detail;
      showErrorToast(error);
    }
  }, [postError]);

  useEffect(() => {
    if(postData){
      showSuccessToast("Details posted");
    }
  }, [postData]);

  const PANCard = () => {
    const [panNo, setPanNo] = useState("");
    const [panCard, setPanCard] = useState(null);
    const [isPanVerified, setIsPanVerified] = useState("");
    const [Pdata, setPData] = useState(false);
    const fileInputRef = useRef(null);
    const [editPAN, setEditPAN] = useState(false);
    const [fileError, setFileError] = useState("");
    const [error, setError] = useState("");

    const handlePanButtonClick = () => {
      fileInputRef.current.click();
    };

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.pan_no) {
          setPData(true);
        }
        setPanNo(IDdata?.pan_no);
        setPanCard(IDdata?.pan_card);
        setIsPanVerified(IDdata?.is_pan_verified);
      }
    }, [getGovtIDData]);

    useEffect(() => {
      if (postData) {
        if (postData?.data?.pan_no) {
          setPData(true);
        }

        setPanNo(postData?.data?.pan_no);
        setPanCard(postData?.data?.pan_card);
        // showSuccessToast("Pan Details posted");
      }
    }, [postData]);

    const handlePostPan = () => {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

      if (!panRegex.test(panNo)) {
        setError("Invalid PAN number. It should be in the format ABCDE1234F.");
        return;
      }
      setError("");

      if (!panCard) {
        setFileError("file is required");
        return;
      }
      setFileError("");
      const formData = new FormData();
      formData.append("pan_no", panNo);
      if (panCard) {
        formData.append("pan_card", panCard);
      }
      postGovtID(formData);
    };

    const handleCancelPan = () => {
      setAddPANCard(false);
    };

    return (
      <div className="bg-white md:col-span-6 col-span-12">
        {!Pdata && !addPANCard && (
          <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              PAN Card
            </h4>
            <CustomizableDrawer
              triggerElement={
                <img src={add} alt="add icon" className="md:hidden" />
              }
              placement="bottom"
              height="500"
              heading={"Pan Card"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="px-3 py-3">
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        PAN Number
                      </h6>
                      <input
                        type="text"
                        className="input-box"
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                    )}
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Name as per ID
                      </h6>
                      <input className="input-box" />
                    </div>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Mobile Number
                      </h6>
                      <input type="number" className="input-box" />
                    </div>
                    <div className="flex mb-[1rem]  gap-2">
                      <button
                        onClick={handlePanButtonClick}
                        className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                      >
                        Upload ID Proof
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={(e) => setPanCard(e.target.files[0])}
                      />

                      <RemoveCard />
                    </div>
                    {fileError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {fileError}
                      </p>
                    )}
                    <div className="">
                      <Button
                        px={"px-[1rem]"}
                        py={"py-[0.5rem]"}
                        name="Save Details"
                        onClick={handlePostPan}
                      />
                      <button
                        onClick={handleClose}
                        className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            />
            <button
              className="md:block hidden"
              onClick={() => {
                setAddPANCard(true);
              }}
            >
              <img src={add} alt="add icon" />
            </button>
          </div>
        )}

        {addPANCard && !Pdata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] ">
                  PAN Card
                </h4>
                {isPanVerified && <img src={success} alt="success icon" />}
              </div>

              <div>
                <button
                  onClick={handlePanButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setPanCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            {/* input fields */}
            <div className="md:mb-0 mb-2">
              <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                PAN Number
              </h6>
              <div className="flex justify-between md:w-[50%] relative">
                <input
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                  value={panNo}
                  onChange={(e) => setPanNo(e.target.value)}
                />
                <img
                  src={eye}
                  alt="eye icon"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              </div>
              {error && (
                <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
              )}
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={download} alt="download icon" />
                <h4 className="text-[#9065B4] text-[1rem] font-[600] m-0">
                  Get E-PAN
                </h4>
              </div>
            </div>
            {/* delete buttons */}
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={handleCancelPan}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostPan}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {editPAN && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] ">
                  PAN Card
                </h4>
                <img src={success} alt="success icon" />
              </div>

              <div>
                <button
                  onClick={handlePanButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setPanCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>
            {/* input fields */}
            <div className="md:mb-0 mb-2">
              <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                PAN Number
              </h6>
              <div className="flex justify-between md:w-[50%] relative">
                <input
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                  value={panNo}
                  onChange={(e) => setPanNo(e.target.value)}
                />
                <img
                  src={eye}
                  alt="eye icon"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              </div>
              {error && (
                <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
              )}
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={download} alt="download icon" />
                <h4 className="text-[#9065B4] text-[1rem] font-[600] m-0">
                  Get E-PAN
                </h4>
              </div>
            </div>
            {/* delete buttons */}

            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={() => setEditPAN(!editPAN)}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostPan}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {Pdata && !editPAN && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] ">
                  PAN Card
                </h4>
                {isPanVerified && <img src={success} alt="success icon" />}
              </div>

              {/* view button */}

              <div className="flex gap-3 items-center">
                <button
                  onClick={() => {
                    window.open(panCard, "_blank");
                  }}
                  className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                >
                  View Proof
                </button>
                <button
                  onClick={() => {
                    window.open(panCard, "_blank");
                  }}
                >
                  <img
                    src={viewMobile}
                    alt="view icon"
                    className="w-12 h-12 md:hidden "
                  />
                </button>

                <button className="md:block hidden border-1 border-[#9065B4] text-[#9065B4] rounded py-1 px-2 font-[500] text-[0.9rem]">
                  Get E-PAN
                </button>
                <button
                  onClick={() => {
                    setEditPAN(true);
                  }}
                >
                  <img
                    src={edit}
                    alt="edit icon"
                    className="w-6 h-6 md:block hidden"
                  />
                </button>
                <CustomizableDrawer
                  triggerElement={
                    <img
                      src={editMobile}
                      alt="edit icon"
                      className="w-10 h-10 md:hidden"
                    />
                  }
                  placement="bottom"
                  height="500"
                  heading={"Pan Card"}
                  content={({ handleClose }) => (
                    <>
                      <hr className="m-0" />
                      <div className="px-3 py-3">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            PAN Number
                          </h6>
                          <input
                            type="text"
                            className="input-box"
                            value={panNo}
                            onChange={(e) => setPanNo(e.target.value)}
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {error}
                          </p>
                        )}

                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name as per ID
                          </h6>
                          <input type="text" className="input-box" />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Mobile Number
                          </h6>
                          <input type="number" className="input-box" />
                        </div>
                        <div className="flex mb-[1rem]  gap-2">
                          <button
                            onClick={handlePanButtonClick}
                            className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                          >
                            Upload ID Proof
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(e) => setPanCard(e.target.files[0])}
                          />
                          <RemoveCard />
                        </div>
                        {fileError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {fileError}
                          </p>
                        )}
                        <div className="">
                          <Button
                            px={"px-[1rem]"}
                            py={"py-[0.5rem]"}
                            name="Save Details"
                            onClick={handlePostPan}
                          />
                          <button
                            onClick={handleClose}
                            className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            {/* input fields */}
            <div className="md:mb-0 mb-2">
              <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                PAN Number
              </h6>
              <div className="flex justify-between md:w-[50%] relative">
                <input
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                  value={panNo}
                  onChange={(e) => setPanNo(e.target.value)}
                />
                <img
                  src={eye}
                  alt="eye icon"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              </div>
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={download} alt="download icon" />
                <h4 className="text-[#9065B4] text-[1rem] font-[600] m-0">
                  Get E-PAN
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const AadhaarCard = () => {
    const [aadharNo, setAadharNo] = useState("");
    const [aadharCard, setAadharCard] = useState(null);
    const [isAadharVerified, setIsAadharVerified] = useState("");
    const fileInputRef = useRef(null);
    const [Adata, setAData] = useState(false);
    const [editAadhar, setEditAadhar] = useState(false);
    const [iscancel, setIsCancel] = useState(false);

    const handleCancelAadhar = () => {
      setAddAadharCard(false);
    };

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    const [fileError, setFileError] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.aadhar_no) {
          setAData(true);
        }
        setAadharNo(IDdata?.aadhar_no);
        setAadharCard(IDdata?.aadhar_card);
        setIsAadharVerified(IDdata?.is_aadhaar_verified);
      }
    }, [getGovtIDData]);

    useEffect(() => {
      if (postData) {
        if (postData?.data?.aadhar_no) {
          setAData(true);
        }

        setAadharNo(postData?.data?.aadhar_no);
        setAadharCard(postData?.data?.aadhar_card);
        // showSuccessToast("Aadhar Details posted");
      }
    }, [postData]);

    const handleAadharChange = (e) => {
      const value = e.target.value;
      if (/^\d{0,12}$/.test(value)) {
        setAadharNo(value);
        setError("");
      } else {
        setError("Aadhar number must be a 12-digit number.");
      }
    };

    const handlePostAadhar = () => {
      if (!aadharNo || aadharNo.length !== 12) {
        setError("Aadhar number must have 12 digits.");
        return;
      }
      setError("");

      if (!aadharCard) {
        setFileError("File is required");
        return;
      }
      setFileError("");

      const formData = new FormData();
      formData.append("aadhar_no", aadharNo);
      formData.append("aadhar_card", aadharCard);
      postGovtID(formData);
    };

    return (
      <div className="bg-white md:col-span-6 col-span-12 ">
        {!addAadharCard && !Adata && (
          <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
            <h4 className="text-[#334155] md:text-[1.1rem] text-[1rem] font-[500] m-0">
              Aadhaar Card
            </h4>
            <CustomizableDrawer
              triggerElement={
                <img src={add} alt="add icon" className="md:hidden" />
              }
              placement="bottom"
              height="500"
              heading={"Aadhaar Card"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="px-3 py-3">
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Adhaar No
                      </h6>
                      <input
                        type="text"
                        className="input-box"
                        value={aadharNo}
                        onChange={(e) => setAadharNo(e.target.value)}
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                    )}
                    <h6 className="text-[0.9rem] font-[500] text-[#334155] mb-[1rem] text-center">
                      OR
                    </h6>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Adhaar Enrollment No
                      </h6>
                      <input type="text" className="input-box" />
                    </div>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Name as per ID
                      </h6>
                      <input type="text" className="input-box" />
                    </div>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Mobile Number
                      </h6>
                      <input type="number" className="input-box" />
                    </div>
                    <div className="flex mb-[1rem]  gap-2">
                      <button
                        onClick={handleButtonClick}
                        className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                      >
                        Upload ID Proof
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={(e) => setAadharCard(e.target.files[0])}
                      />

                      <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                        Remove Card
                      </button>
                    </div>
                    {fileError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {fileError}
                      </p>
                    )}
                    <div className="">
                      <Button
                        px={"px-[1rem]"}
                        py={"py-[0.5rem]"}
                        name="Save Details"
                        onClick={handlePostAadhar}
                      />
                      <button
                        onClick={handleClose}
                        className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            />
            <button
              className="md:block hidden"
              onClick={() => {
                setAddAadharCard(!addAadharCard);
              }}
            >
              <img src={add} alt="add icon" />
            </button>
          </div>
        )}

        {/* add data */}
        {addAadharCard && !Adata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] md:text-[1.1rem] text-[1rem] font-[500] ">
                  Aadhaar Card
                </h4>
                {isAadharVerified && <img src={success} alt="success icon" />}
              </div>
              <div>
                <button
                  onClick={handleButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setAadharCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            {/* input fields */}
            <div className="md:flex gap-3 ">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Aadhar Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={aadharNo}
                    onChange={handleAadharChange}
                  />

                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              {/* <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Phone Number
                </h6>
                <input
                  type="number"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div> */}
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    if (isAadharVerified) {
                      window.open(aadharCard, "_blank");
                    }
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>

            {/* delete buttons */}
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={handleCancelAadhar}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostAadhar}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {editAadhar && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] md:text-[1.1rem] text-[1rem] font-[500] ">
                  Aadhaar Card
                </h4>
                {isAadharVerified && <img src={success} alt="success icon" />}
              </div>
              <div>
                <button
                  onClick={handleButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setAadharCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            {/* input fields */}
            <div className="md:flex gap-3 ">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Aadhar Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={aadharNo}
                    onChange={handleAadharChange}
                  />

                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Phone Number
                </h6>
                <input
                  type="number"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    if (isAadharVerified) {
                      window.open(aadharCard, "_blank");
                    }
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>

            {/* delete buttons */}
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={() => setEditAadhar(!editAadhar)}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostAadhar}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* view data*/}
        {Adata && !editAadhar && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2 items-center">
                <h4 className="text-[#334155] md:text-[1.1rem] text-[1rem] font-[500] ">
                  Aadhaar Card
                </h4>
                {isAadharVerified && <img src={success} alt="success icon" />}
              </div>

              <div className="flex gap-3 items-center">
                <button
                  onClick={() => {
                    window.open(aadharCard, "_blank");
                  }}
                  className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                >
                  View Proof
                </button>
                <button className="md:block hidden border-1 border-[#9065B4] text-[#9065B4] rounded py-1 px-2 font-[500] text-[0.9rem]">
                  Get E-Aadhar
                </button>
                <button
                  onClick={() => {
                    setEditAadhar(true);
                  }}
                >
                  <img
                    src={edit}
                    alt="edit icon"
                    className="w-6 h-6 md:block hidden"
                  />
                </button>
                <CustomizableDrawer
                  triggerElement={
                    <img
                      src={editMobile}
                      alt="edit icon"
                      className="w-10 h-10 md:hidden"
                    />
                  }
                  placement="bottom"
                  height="500"
                  heading={"Aadhaar Card"}
                  content={({ handleClose }) => (
                    <>
                      <hr className="m-0" />
                      <div className="px-3 py-3">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Adhaar No
                          </h6>
                          <input
                            type="text"
                            className="input-box"
                            value={aadharNo}
                            onChange={(e) => setAadharNo(e.target.value)}
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {error}
                          </p>
                        )}
                        <h6 className="text-[0.9rem] font-[500] text-[#334155] mb-[1rem] text-center">
                          OR
                        </h6>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Adhaar Enrollment No
                          </h6>
                          <input type="text" className="input-box" />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name as per ID
                          </h6>
                          <input type="text" className="input-box" />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Mobile Number
                          </h6>
                          <input type="number" className="input-box" />
                        </div>
                        <div className="flex mb-[1rem]  gap-2">
                          <button
                            onClick={handleButtonClick}
                            className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                          >
                            Upload ID Proof
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(e) => setAadharCard(e.target.files[0])}
                          />
                          <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                            Remove Card
                          </button>
                        </div>
                        {fileError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {fileError}
                          </p>
                        )}
                        <div className="">
                          <Button
                            px={"px-[1rem]"}
                            py={"py-[0.5rem]"}
                            name="Save Details"
                            onClick={handlePostAadhar}
                          />
                          <button
                            onClick={handleClose}
                            className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            {/* input fields */}
            <div className="md:flex gap-3 ">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Aadhar Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={aadharNo}
                    onChange={handleAadharChange}
                  />

                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Phone Number
                </h6>
                <input
                  type="number"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(aadharCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const DrivingLicense = () => {
    const [licenseNo, setLicenseNo] = useState("");
    const [licenseCard, setLicenseCard] = useState(null);
    const [licenseValidity, setLicenseValidity] = useState("");
    const [isLicenseVerified, setIsLicenseVerified] = useState("");
    const [editLicense, setEditLicense] = useState(false);
    const fileInputRef = useRef(null);
    const [fileError, setFileError] = useState("");
    const [validityError, setvalidityError] = useState("");
    const [error, setError] = useState("");
    const [Ddata, setDData] = useState(false);
    const handleDrivingButtonClick = () => {
      fileInputRef.current.click();
    };

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.driving_license_no) {
          setDData(true);
        }
        setLicenseNo(IDdata?.driving_license_no);
        setLicenseCard(IDdata?.driving_license_card);
        setLicenseValidity(IDdata?.driving_license_validity);
        setIsLicenseVerified(IDdata?.is_driving_license_verified);
      }
    }, [getGovtIDData]);
    useEffect(() => {
      if (postData) {
        if (postData?.data?.driving_license_no) {
          setDData(true);
        }

        setLicenseNo(postData?.data?.driving_license_no);
        setLicenseCard(postData?.data?.driving_license_card);
        setLicenseValidity(postData?.data?.driving_license_validity);
        setIsLicenseVerified(postData?.data?.is_driving_license_verified);
        // showSuccessToast("License Details posted");
      }
    }, [postData]);

    const handlePostLicense = () => {
      const licenseRegex = /^[A-Z]{2}\d{13}$/;

      if (!licenseRegex.test(licenseNo)) {
        setError(
          "Invalid Driving License number. It should be in the format AB1234567890123."
        );
        return;
      }

      setError("");
      if (!licenseValidity) {
        setvalidityError("Date is required");
        return;
      }
      setvalidityError("");
      if (!licenseCard) {
        setFileError("file is required");
        return;
      }
      setFileError("");
      const formData = new FormData();
      formData.append("driving_license_no", licenseNo);
      formData.append("driving_license_card", licenseCard);
      formData.append("driving_license_validity", licenseValidity);
      // console.log('FormData to be sent:', formData.get('aadhar_no'), formData.get('aadhar_card'));
      postGovtID(formData);
    };

    const handleCancelDriving = () => {
      setAddDrivingLicense(false);
    };

    return (
      <div className="bg-white md:col-span-6 col-span-12">
        {!addDrivingLicense && !Ddata && (
          <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Driving License
            </h4>

            <CustomizableDrawer
              triggerElement={
                <img src={add} alt="add icon" className="md:hidden" />
              }
              placement="bottom"
              height="500"
              heading={"Driving License"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="px-3 py-3">
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        DLN No
                      </h6>
                      <input
                        value={licenseNo}
                        onChange={(e) => setLicenseNo(e.target.value)}
                        type="text"
                        className="input-box"
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                    )}

                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Name as per ID
                      </h6>
                      <input type="text" className="input-box" />
                    </div>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Valid Upto
                      </h6>
                      <input
                        value={licenseValidity}
                        onChange={(e) => setLicenseValidity(e.target.value)}
                        type="date"
                        className="input-box"
                      />
                    </div>
                    {validityError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {validityError}
                      </p>
                    )}
                    <div className="flex mb-[1rem]  gap-2">
                      <button
                        onClick={handleDrivingButtonClick}
                        className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                      >
                        Upload ID Proof
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={(e) => setLicenseCard(e.target.files[0])}
                      />
                      <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                        Remove Card
                      </button>
                    </div>
                    {fileError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {fileError}
                      </p>
                    )}
                    <div className="">
                      <Button
                        px={"px-[1rem]"}
                        py={"py-[0.5rem]"}
                        name="Save Details"
                        onClick={handlePostLicense}
                      />
                      <button
                        onClick={handleClose}
                        className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            />
            <button
              className="md:block hidden"
              onClick={() => {
                setAddDrivingLicense(!addDrivingLicense);
              }}
            >
              <img src={add} alt="add icon" />
            </button>
          </div>
        )}
        {/* add data */}
        {addDrivingLicense && !Ddata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Driving License
              </h4>
              <div>
                <button
                  onClick={handleDrivingButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setLicenseCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={licenseNo}
                    onChange={(e) => setLicenseNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={licenseValidity}
                  onChange={(e) => setLicenseValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
                {validityError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">
                    {validityError}
                  </p>
                )}
              </div>

              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(licenseCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={handleCancelDriving}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostLicense}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* edit */}
        {editLicense && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Driving License
              </h4>

              <button
                onClick={handleDrivingButtonClick}
                className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
              >
                Upload Proof
              </button>

              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={(e) => setLicenseCard(e.target.files[0])}
              />
              {fileError && (
                <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
              )}
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={licenseNo}
                    onChange={(e) => setLicenseNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={licenseValidity}
                  onChange={(e) => setLicenseValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              {validityError && (
                <p className="text-red-500 text-[0.7rem] mt-1">
                  {validityError}
                </p>
              )}
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(licenseCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={() => setEditLicense(!editLicense)}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostLicense}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* view data */}
        {!editLicense && Ddata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Driving License
              </h4>

              <div className="flex gap-3 items-center">
                <button
                  onClick={() => {
                    window.open(licenseCard, "_blank");
                  }}
                  className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                >
                  View Proof
                </button>

                <button
                  onClick={() => {
                    setEditLicense(!editLicense);
                  }}
                >
                  <img
                    src={edit}
                    alt="edit icon"
                    className="w-6 h-6 md:block hidden"
                  />
                </button>
                <CustomizableDrawer
                  triggerElement={
                    <img
                      src={editMobile}
                      alt="edit icon"
                      className="w-10 h-10 md:hidden"
                    />
                  }
                  placement="bottom"
                  height="500"
                  heading={"Driving License"}
                  content={({ handleClose }) => (
                    <>
                      <hr className="m-0" />
                      <div className="px-3 py-3">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            DLN No
                          </h6>
                          <input
                            value={licenseNo}
                            onChange={(e) => setLicenseNo(e.target.value)}
                            type="text"
                            className="input-box"
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {error}
                          </p>
                        )}
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name as per ID
                          </h6>
                          <input type="text" className="input-box" />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Valid Upto
                          </h6>
                          <input
                            value={licenseValidity}
                            onChange={(e) => setLicenseValidity(e.target.value)}
                            type="date"
                            className="input-box"
                          />
                        </div>
                        {validityError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {validityError}
                          </p>
                        )}
                        <div className="flex mb-[1rem]  gap-2">
                          <button
                            onClick={handleDrivingButtonClick}
                            className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                          >
                            Upload ID Proof
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(e) => setLicenseCard(e.target.files[0])}
                          />
                          <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                            Remove Card
                          </button>
                        </div>
                        {fileError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {fileError}
                          </p>
                        )}
                        <div className="">
                          <Button
                            px={"px-[1rem]"}
                            py={"py-[0.5rem]"}
                            name="Save Details"
                            onClick={handlePostLicense}
                          />
                          <button
                            onClick={handleClose}
                            className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={licenseNo}
                    onChange={(e) => setLicenseNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={licenseValidity}
                  onChange={(e) => setLicenseValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(licenseCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const RationCard = () => {
    const [rationCardNo, setRationCardNo] = useState("");
    const [rationCardFile, setRationCardFile] = useState(null);
    const [isRationCardVerified, setisRationCardVerified] = useState("");
    const [editRationCard, setEditRationCard] = useState(false);
    const fileInputRef = useRef(null);
    const [Rdata, setRData] = useState(false);
    const [fileError, setFileError] = useState("");
    const [error, setError] = useState("");

    const handleRationButtonClick = () => {
      fileInputRef.current.click();
    };

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.ration_card_no) {
          setRData(true);
        }
        setRationCardNo(IDdata?.ration_card_no);
        setRationCardFile(IDdata?.ration_card);
        setisRationCardVerified(IDdata?.is_ration_card_verified);
      }
    }, [getGovtIDData]);

    useEffect(() => {
      if (postData) {
        if (postData?.data?.ration_card_no) {
          setRData(true);
        }

        setRationCardNo(postData?.data?.ration_card_no);
        setRationCardFile(postData?.data?.ration_card);
        setisRationCardVerified(postData?.data?.is_ration_card_verified);
      }
    }, [postData]);

    const handlePostRation = () => {
      const rationCardRegex = /^[A-Z0-9]{10,12}$/;

      if (!rationCardRegex.test(rationCardNo)) {
        setError(
          "Invalid Ration Card number. It should be 10 to 12 characters long and alphanumeric."
        );
        return;
      }

      setError("");
      if (!rationCardFile) {
        setFileError("file is required");
        return;
      }
      setFileError("");
      const formData = new FormData();
      formData.append("ration_card_no", rationCardNo);
      formData.append("ration_card", rationCardFile);
      postGovtID(formData);
    };

    const handleCancelRation = () => {
      setAddRationCard(false);
    };
    return (
      <>
        <div className="bg-white md:col-span-6 col-span-12 ">
          {!addRationCard && !Rdata && (
            <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Ration Card
              </h4>
              <CustomizableDrawer
                triggerElement={
                  <img src={add} alt="add icon" className="md:hidden" />
                }
                placement="bottom"
                height="500"
                heading={"Ration Card"}
                content={({ handleClose }) => (
                  <>
                    {/* <hr className="m-0" /> */}
                    <div className="px-3 py-3">
                      <div className="input-container mb-[1rem]">
                        <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                          Ration Card No
                        </h6>
                        <input
                          value={rationCardNo}
                          onChange={(e) => setRationCardNo(e.target.value)}
                          type="text"
                          className="input-box"
                        />
                      </div>
                      {error && (
                        <p className="text-red-500 text-[0.7rem] mt-1">
                          {error}
                        </p>
                      )}
                      <div className="input-container mb-[1rem]">
                        <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                          Name as per ID
                        </h6>
                        <input type="text" className="input-box" />
                      </div>
                      <div className="input-container mb-[1rem]">
                        <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                          Mobile Number
                        </h6>
                        <input type="number" className="input-box" />
                      </div>
                      <div className="flex mb-[1rem]  gap-2">
                        <button
                          onClick={handleRationButtonClick}
                          className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                        >
                          Upload ID Proof
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          className="hidden"
                          onChange={(e) => setRationCardFile(e.target.files[0])}
                        />
                        <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                          Remove Card
                        </button>
                      </div>
                      {fileError && (
                        <p className="text-red-500 text-[0.7rem] mt-1">
                          {fileError}
                        </p>
                      )}
                      <div className="">
                        <Button
                          px={"px-[1rem]"}
                          py={"py-[0.5rem]"}
                          name="Save Details"
                          onClick={handlePostRation}
                        />
                        <button
                          onClick={handleClose}
                          className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}
              />
              <button
                className="md:block hidden"
                onClick={() => {
                  setAddRationCard(!addRationCard);
                }}
              >
                <img src={add} alt="add icon" />
              </button>
            </div>
          )}
          {/* add data */}
          {addRationCard && !Rdata && (
            <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Ration Card
                </h4>
                <div>
                  <button
                    onClick={handleRationButtonClick}
                    className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                  >
                    Upload Proof
                  </button>

                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={(e) => setRationCardFile(e.target.files[0])}
                  />
                  {fileError && (
                    <p className="text-red-500 text-[0.7rem] mt-1">
                      {fileError}
                    </p>
                  )}
                </div>
              </div>

              <div className="md:flex gap-3">
                <div className="md:mb-0 mb-2">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Card Number
                  </h6>
                  <div className="flex justify-between  relative">
                    <input
                      className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                      value={rationCardNo}
                      onChange={(e) => setRationCardNo(e.target.value)}
                    />
                    <img
                      src={eye}
                      alt="eye icon"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                  )}
                </div>

                <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                  <img src={purpleEye} alt="eye icon" />
                  <h4
                    onClick={() => {
                      window.open(rationCardFile, "_blank");
                    }}
                    className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                  >
                    View Proof
                  </h4>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-[1rem]">
                <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                  Delete Card
                </button>
                <button
                  onClick={handleCancelRation}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
                >
                  Cancel
                </button>
                <button
                  onClick={handlePostRation}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
                >
                  Done
                </button>
              </div>
            </div>
          )}
          {/* edit data */}
          {editRationCard && (
            <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Ration Card
                </h4>
                <div>
                  <button
                    onClick={handleRationButtonClick}
                    className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                  >
                    Upload Proof
                  </button>

                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={(e) => setRationCardFile(e.target.files[0])}
                  />
                  {fileError && (
                    <p className="text-red-500 text-[0.7rem] mt-1">
                      {fileError}
                    </p>
                  )}
                </div>
              </div>

              <div className="md:flex gap-3">
                <div className="md:mb-0 mb-2">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Card Number
                  </h6>
                  <div className="flex justify-between  relative">
                    <input
                      className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                      value={rationCardNo}
                      onChange={(e) => setRationCardNo(e.target.value)}
                    />
                    <img
                      src={eye}
                      alt="eye icon"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                  )}
                </div>

                <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                  <img src={purpleEye} alt="eye icon" />
                  <h4
                    onClick={() => {
                      window.open(rationCardFile, "_blank");
                    }}
                    className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                  >
                    View Proof
                  </h4>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-[1rem]">
                <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                  Delete Card
                </button>
                <button
                  onClick={() => setEditRationCard(!editRationCard)}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
                >
                  Cancel
                </button>
                <button
                  onClick={handlePostRation}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
                >
                  Done
                </button>
              </div>
            </div>
          )}
          {/* view data */}
          {!editRationCard && Rdata && (
            <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Ration Card
                </h4>

                <div className="flex gap-3 items-center">
                  <button
                    onClick={() => {
                      // if (isRationCardVerified) {
                        window.open(rationCardFile, "_blank");
                      // }
                    }}
                    className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                  >
                    View Proof
                  </button>

                  <button
                    onClick={() => {
                      setEditRationCard(!editRationCard);
                    }}
                  >
                    <img
                      src={edit}
                      alt="edit icon"
                      className="w-6 h-6 md:block hidden"
                    />
                  </button>
                  <CustomizableDrawer
                    triggerElement={
                      <img
                        src={editMobile}
                        alt="edit icon"
                        className="w-10 h-10 md:hidden"
                      />
                    }
                    placement="bottom"
                    height="500"
                    heading={"Ration Card"}
                    content={({ handleClose }) => (
                      <>
                        <hr className="m-0" />
                        <div className="px-3 py-3">
                          <div className="input-container mb-[1rem]">
                            <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                              Ration Card No
                            </h6>
                            <input
                              value={rationCardNo}
                              onChange={(e) => setRationCardNo(e.target.value)}
                              type="text"
                              className="input-box"
                            />
                          </div>
                          {error && (
                            <p className="text-red-500 text-[0.7rem] mt-1">
                              {error}
                            </p>
                          )}
                          <div className="input-container mb-[1rem]">
                            <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                              Name as per ID
                            </h6>
                            <input type="text" className="input-box" />
                          </div>
                          <div className="input-container mb-[1rem]">
                            <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                              Mobile Number
                            </h6>
                            <input type="number" className="input-box" />
                          </div>
                          <div className="flex mb-[1rem]  gap-2">
                            <button
                              onClick={handleRationButtonClick}
                              className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                            >
                              Upload ID Proof
                            </button>
                            <input
                              type="file"
                              ref={fileInputRef}
                              className="hidden"
                              onChange={(e) =>
                                setRationCardFile(e.target.files[0])
                              }
                            />
                            <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                              Remove Card
                            </button>
                          </div>
                          {fileError && (
                            <p className="text-red-500 text-[0.7rem] mt-1">
                              {fileError}
                            </p>
                          )}
                          <div className="">
                            <Button
                              px={"px-[1rem]"}
                              py={"py-[0.5rem]"}
                              name="Save Details"
                              onClick={handlePostRation}
                            />
                            <button
                              onClick={handleClose}
                              className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="md:flex gap-3">
                <div className="md:mb-0 mb-2">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Card Number
                  </h6>
                  <div className="flex justify-between  relative">
                    <input
                      className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                      value={rationCardNo}
                      onChange={(e) => setRationCardNo(e.target.value)}
                    />
                    <img
                      src={eye}
                      alt="eye icon"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                  <img src={purpleEye} alt="eye icon" />
                  <h4
                    onClick={() => {
                      window.open(rationCardFile, "_blank");
                    }}
                    className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                  >
                    View Proof
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const Passport = () => {
    const [passportNo, setPassportNo] = useState("");
    const [passportFile, setPassportFile] = useState("");
    const [passportValidity, setPassportValidity] = useState("");
    const [isPassportVerified, setIsPassportVerified] = useState("");
    const [editPassport, setEditPassport] = useState(false);
    const fileInputRef = useRef(null);
    const [Passdata, setPassData] = useState(false);
    const [fileError, setFileError] = useState("");
    const [error, setError] = useState("");
    const [validityError, setValidityError] = useState("");

    const handlePassportButtonClick = () => {
      fileInputRef.current.click();
    };

    const handleCancelPassport = () => {
      setAddPassport(false);
    };

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.passport_no) {
          setPassData(true);
        }
        setPassportNo(IDdata?.passport_no);
        setPassportFile(IDdata?.passport);
        setPassportValidity(IDdata?.passport_validity);
        setIsPassportVerified(IDdata?.is_passport_verified);
      }
    }, [getGovtIDData]);

    useEffect(() => {
      if (postData) {
        if (postData?.data?.passport_no) {
          setPassData(true);
        }
        setPassportNo(postData?.data?.passport_no);
        setPassportFile(postData?.data?.passport);
        setPassportValidity(postData?.data?.passport_validity);
        setIsPassportVerified(postData?.data?.is_passport_verified);
      }
    }, [postData]);

    const handlePostPassport = () => {
      const passportRegex = /^[A-Z][0-9]{7}$/;

      if (!passportRegex.test(passportNo)) {
        setError(
          "Invalid Passport number. It should be in the format A1234567."
        );
        return;
      }

      setError("");
      if (!passportValidity) {
        setValidityError("Date is required");
        return;
      }
      setFileError("");
      if (!passportFile) {
        setFileError("file is required");
        return;
      }
      setFileError("");
      const formData = new FormData();
      formData.append("passport_no", passportNo);
      formData.append("passport", passportFile);
      formData.append("passport_validity", passportValidity);
      // console.log('FormData to be sent:', formData.get('aadhar_no'), formData.get('aadhar_card'));
      postGovtID(formData);
    };
    return (
      <div className="bg-white md:col-span-6 col-span-12 ">
        {!addPassport && !Passdata && (
          <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Passport
            </h4>
            <CustomizableDrawer
              triggerElement={
                <img src={add} alt="add icon" className="md:hidden" />
              }
              placement="bottom"
              height="500"
              heading={"Passport"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="px-3 py-3">
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Passport ID
                      </h6>
                      <input
                        value={passportNo}
                        onChange={(e) => setPassportNo(e.target.value)}
                        type="text"
                        className="input-box"
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                    )}
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Valid Upto
                      </h6>
                      <input
                        value={passportValidity}
                        onChange={(e) => setPassportValidity(e.target.value)}
                        type="date"
                        className="input-box"
                      />
                    </div>
                    {validityError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {validityError}
                      </p>
                    )}
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Name as per ID
                      </h6>
                      <input type="text" className="input-box" />
                    </div>
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Mobile Number
                      </h6>
                      <input type="number" className="input-box" />
                    </div>
                    <div className="flex mb-[1rem]  gap-2">
                      <button
                        onClick={handlePassportButtonClick}
                        className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                      >
                        Upload ID Proof
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={(e) => setPassportFile(e.target.files[0])}
                      />
                      <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                        Remove Card
                      </button>
                    </div>
                    {fileError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {fileError}
                      </p>
                    )}
                    <div className="">
                      <Button
                        px={"px-[1rem]"}
                        py={"py-[0.5rem]"}
                        name="Save Details"
                        onClick={handlePostPassport}
                      />
                      <button
                        onClick={handleClose}
                        className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            />
            <button
              className="md:block hidden"
              onClick={() => {
                setAddPassport(!addPassport);
              }}
            >
              <img src={add} alt="add icon" />
            </button>
          </div>
        )}
        {/* add data */}
        {addPassport && !Passdata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Passport
              </h4>
              <div>
                <button
                  onClick={handlePassportButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setPassportFile(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={passportNo}
                    onChange={(e) => setPassportNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={passportValidity}
                  onChange={(e) => setPassportValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
                {validityError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">
                    {validityError}
                  </p>
                )}
              </div>

              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(passportFile, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={handleCancelPassport}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostPassport}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* edit data */}
        {editPassport && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Passport
              </h4>
              <div>
                <button
                  onClick={handlePassportButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setPassportFile(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={passportNo}
                    onChange={(e) => setPassportNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={passportValidity}
                  onChange={(e) => setPassportValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              {validityError && (
                <p className="text-red-500 text-[0.7rem] mt-1">
                  {validityError}
                </p>
              )}
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(passportFile, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={() => setEditPassport(!editPassport)}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostPassport}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* view data */}
        {!editPassport && Passdata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Passport
              </h4>

              <div className="flex gap-3 items-center">
                <button
                  onClick={() => {
                    window.open(passportFile, "_blank");
                  }}
                  className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                >
                  View Proof
                </button>

                <button
                  onClick={() => {
                    setEditPassport(!editPassport);
                  }}
                >
                  <img
                    src={edit}
                    alt="edit icon"
                    className="w-6 h-6 md:block hidden"
                  />
                </button>
                <CustomizableDrawer
                  triggerElement={
                    <img
                      src={editMobile}
                      alt="edit icon"
                      className="w-10 h-10 md:hidden"
                    />
                  }
                  placement="bottom"
                  height="500"
                  heading={"Passport"}
                  content={({ handleClose }) => (
                    <>
                      <hr className="m-0" />
                      <div className="px-3 py-3">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Passport ID
                          </h6>
                          <input
                            value={passportNo}
                            onChange={(e) => setPassportNo(e.target.value)}
                            type="text"
                            className="input-box"
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {error}
                          </p>
                        )}

                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Valid Upto
                          </h6>
                          <input
                            value={passportValidity}
                            onChange={(e) =>
                              setPassportValidity(e.target.value)
                            }
                            type="date"
                            className="input-box"
                          />
                        </div>
                        {validityError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {validityError}
                          </p>
                        )}
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name as per ID
                          </h6>
                          <input type="text" className="input-box" />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Mobile Number
                          </h6>
                          <input type="number" className="input-box" />
                        </div>
                        <div className="flex mb-[1rem]  gap-2">
                          <button
                            onClick={handlePassportButtonClick}
                            className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                          >
                            Upload ID Proof
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(e) => setPassportFile(e.target.files[0])}
                          />
                          <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                            Remove Card
                          </button>
                        </div>
                        {fileError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {fileError}
                          </p>
                        )}
                        <div className="">
                          <Button
                            px={"px-[1rem]"}
                            py={"py-[0.5rem]"}
                            name="Save Details"
                            onClick={handlePostPassport}
                          />
                          <button
                            onClick={handleClose}
                            className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={passportNo}
                    onChange={(e) => setPassportNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
              </div>

              <div>
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Valid Upto
                </h6>
                <input
                  value={passportValidity}
                  onChange={(e) => setPassportValidity(e.target.value)}
                  type="date"
                  className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                />
              </div>
              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(passportFile, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Voter = () => {
    const [voterIDNo, setVoterIDNo] = useState("");
    const [editVoterID, setEditVoterID] = useState(false);
    const [voterIDCard, setVoterIDCard] = useState("");
    const [isVoterIDVerified, setisVoterIDVerified] = useState("");
    const fileInputRef = useRef(null);
    const [Vdata, setVData] = useState(false);
    const [fileError, setFileError] = useState("");
    const [error, setError] = useState("");

    const handleVoterButtonClick = () => {
      fileInputRef.current.click();
    };

    const handleCancelVoter = () => {
      setAddVoterID(false);
    };

    useEffect(() => {
      const IDdata = getGovtIDData?.data?.results[0];
      if (IDdata) {
        if (IDdata?.voter_id_no) {
          setVData(true);
        }
        setVoterIDNo(IDdata?.voter_id_no);
        setVoterIDCard(IDdata?.voter_id_card);
        setisVoterIDVerified(IDdata?.is_voter_id_verified);
      }
    }, [getGovtIDData]);

    useEffect(() => {
      if (postData) {
        if (postData?.data?.voter_id_no) {
          setVData(true);
        }
        setVoterIDNo(postData?.data?.voter_id_no);
        setVoterIDCard(postData?.data?.voter_id_card);
        setisVoterIDVerified(postData?.data?.is_voter_id_verified);
      }
    }, [postData]);

    const handlePostVoter = () => {
      const voterIdRegex = /^[A-Z]{2,3}[0-9]{7,10}$/;

      if (!voterIdRegex.test(voterIDNo)) {
        setError(
          "Invalid Voter ID number. It should be in the format ABC1234567."
        );
        return;
      }

      setError("");
      if (!voterIDCard) {
        setFileError("file is required");
        return;
      }
      setFileError("");
      const formData = new FormData();
      formData.append("voter_id_no", voterIDNo);
      formData.append("voter_id_card", voterIDCard);
      // console.log('FormData to be sent:', formData.get('aadhar_no'), formData.get('aadhar_card'));
      postGovtID(formData);
    };
    return (
      <div className="bg-white md:col-span-6 col-span-12 ">
        {!addVoterID && !Vdata && (
          <div className="flex justify-between items-center border-dashed border-2 border-[#E2E8F0] px-3 py-[1rem] rounded">
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Voter ID Card
            </h4>
            <CustomizableDrawer
              triggerElement={
                <img src={add} alt="add icon" className="md:hidden" />
              }
              placement="bottom"
              height="420"
              heading={"Voter ID Card"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="px-3 py-3">
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Voter ID
                      </h6>
                      <input
                        value={voterIDNo}
                        onChange={(e) => setVoterIDNo(e.target.value)}
                        type="text"
                        className="input-box"
                      />
                    </div>
                    {error && (
                      <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                    )}
                    <div className="input-container mb-[1rem]">
                      <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                        Name as per ID
                      </h6>
                      <input type="text" className="input-box" />
                    </div>

                    <div className="flex mb-[1rem]  gap-2">
                      <button
                        onClick={handleVoterButtonClick}
                        className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                      >
                        Upload ID Proof
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={(e) => setVoterIDCard(e.target.files[0])}
                      />
                      <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                        Remove Card
                      </button>
                    </div>
                    {fileError && (
                      <p className="text-red-500 text-[0.7rem] mt-1">
                        {fileError}
                      </p>
                    )}
                    <div className="">
                      <Button
                        px={"px-[1rem]"}
                        py={"py-[0.5rem]"}
                        name="Save Details"
                        onClick={handlePostVoter}
                      />
                      <button
                        onClick={handleClose}
                        className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            />
            <button
              className="md:block hidden"
              onClick={() => {
                setAddVoterID(!addVoterID);
              }}
            >
              <img src={add} alt="add icon" />
            </button>
          </div>
        )}
        {/* add data */}
        {addVoterID && !Vdata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Voter ID Card
              </h4>

              <div>
                <button
                  onClick={handleVoterButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setVoterIDCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </div>
            </div>
            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={voterIDNo}
                    onChange={(e) => setVoterIDNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(voterIDCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={handleCancelVoter}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostVoter}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* edit data */}
        {editVoterID && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Voter ID Card
              </h4>

              <>
                <button
                  onClick={handleVoterButtonClick}
                  className="bg-[#F1F5F9] text-[#334155] rounded py-1 px-2 font-[600] text-[0.9rem]"
                >
                  Upload Proof
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={(e) => setVoterIDCard(e.target.files[0])}
                />
                {fileError && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{fileError}</p>
                )}
              </>
            </div>
            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={voterIDNo}
                    onChange={(e) => setVoterIDNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-[0.7rem] mt-1">{error}</p>
                )}
              </div>

              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    if (isVoterIDVerified) {
                      window.open(voterIDCard, "_blank");
                    }
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-[1rem]">
              <button className="bg-[#FEF2F2] text-[#DC2626] rounded py-2 px-[1rem] font-[600] text-[0.9rem]">
                Delete Card
              </button>
              <button
                onClick={() => setEditVoterID(!editVoterID)}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Cancel
              </button>
              <button
                onClick={handlePostVoter}
                className="bg-[#F1F5F9] text-[#334155] rounded py-2 px-[1rem] font-[600] text-[0.9rem]"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* view data */}
        {!editVoterID && Vdata && (
          <div className="border border-[#E2E8F0] px-3 py-[1rem] rounded">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Voter ID Card
              </h4>

              <div className="flex gap-3 items-center">
                <button
                  onClick={() => {
                    window.open(voterIDCard, "_blank");
                  }}
                  className="md:block hidden bg-[#F1F5F9] text-[#64748B] rounded py-1 px-2 font-[500] text-[0.9rem]"
                >
                  View Proof
                </button>

                <button
                  onClick={() => {
                    setEditVoterID(!editVoterID);
                  }}
                >
                  <img
                    src={edit}
                    alt="edit icon"
                    className="w-6 h-6 md:block hidden"
                  />
                </button>
                <CustomizableDrawer
                  triggerElement={
                    <img
                      src={editMobile}
                      alt="edit icon"
                      className="w-10 h-10 md:hidden"
                    />
                  }
                  placement="bottom"
                  height="420"
                  heading={"Voter ID Card"}
                  content={({ handleClose }) => (
                    <>
                      <hr className="m-0" />
                      <div className="px-3 py-3">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Voter ID
                          </h6>
                          <input
                            value={voterIDNo}
                            onChange={(e) => setVoterIDNo(e.target.value)}
                            type="text"
                            className="input-box"
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {error}
                          </p>
                        )}
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name as per ID
                          </h6>
                          <input type="text" className="input-box" />
                        </div>

                        <div className="flex mb-[1rem]  gap-2">
                          <button
                            onClick={handleVoterButtonClick}
                            className="bg-[#EAE1F3] text-[#9065B4] rounded border py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]"
                          >
                            Upload ID Proof
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={(e) => setVoterIDCard(e.target.files[0])}
                          />
                          <button className="bg-[#FEF2F2] text-[#DC2626] rounded border py-[0.8rem] px-[1.3rem] font-[500] text-[0.9rem]">
                            Remove Card
                          </button>
                        </div>
                        {fileError && (
                          <p className="text-red-500 text-[0.7rem] mt-1">
                            {fileError}
                          </p>
                        )}
                        <div className="">
                          <Button
                            px={"px-[1rem]"}
                            py={"py-[0.5rem]"}
                            name="Save Details"
                            onClick={handlePostVoter}
                          />
                          <button
                            onClick={handleClose}
                            className="w-[100%] mt-[1rem] font-[400] px-[1rem] py-[0.5rem] rounded-md bg-[#E8EAED]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="md:flex gap-3">
              <div className="md:mb-0 mb-2">
                <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                  Card Number
                </h6>
                <div className="flex justify-between  relative">
                  <input
                    className="border border-[#E2E8F0] rounded text-[0.9rem] font-[500] p-1 bg-[#F8FAFC] pr-10 w-full"
                    value={voterIDNo}
                    onChange={(e) => setVoterIDNo(e.target.value)}
                  />
                  <img
                    src={eye}
                    alt="eye icon"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                </div>
              </div>

              <div className="md:hidden flex mt-[1rem] gap-2 items-center">
                <img src={purpleEye} alt="eye icon" />
                <h4
                  onClick={() => {
                    window.open(voterIDCard, "_blank");
                  }}
                  className="cursor-pointer text-[#9065B4] text-[1rem] font-[600] m-0"
                >
                  View Proof
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const DesktopDetailedComparisonPAN = () => {
    return (
      <>
        <div
          className="md:flex hidden  gap-3 mt-[2rem] mb-[1rem] items-center"
          onClick={() => {
            setShowDetailedComparison(!showDetailedComparison);
          }}
        >
          <h4 className="text-[#334155] text-[1.1rem] font-[500] cursor-pointer">
            Detailed Comparison of your PAN and Aadhaar
          </h4>
          <div className="cursor-pointer">
            <img
              src={down_arrow}
              alt=""
              className={`${
                showDetailedComparison
                  ? "transform -rotate-180 duration-200"
                  : ""
              } `}
            />
          </div>
        </div>
        {showDetailedComparison && (
          <>
            <div className="grid grid-cols-12 gap-1 mb-[1rem] border border-[#E2E8F0] rounded">
              <table className="col-span-12  ">
                <tr className="bg-[#F3EFF8] rounded ">
                  <th className="py-[2%] px-4 border-2 border-white  rounded col-span-2 text-[#334155] text-[1rem] font-[500]  ">
                    Details
                  </th>
                  <th className="px-4  col-span-4 border-2 border-white rounded text-[#334155] text-[1rem] font-[500]">
                    According to your PAN
                  </th>
                  <th className="px-4  col-span-4 border-2 border-white rounded text-[#334155] text-[1rem] font-[500]">
                    According to your Aadhar
                  </th>
                  <th className="px-4  col-span-2 border-2 border-white rounded text-[#334155] text-[1rem] font-[500]">
                    Accuracy
                  </th>
                </tr>

                {detailedArray.map((item) => {
                  return (
                    <tr
                      className={` rounded text-[#64748B] ${
                        item.Accuracy == no ? "bg-[#FEF2F2]" : "bg-[#FAF8FC]"
                      }`}
                    >
                      <td className="px-4 py-3 p-0  border-2 border-white text-[#334155]">
                        {item.Details}
                      </td>
                      <td className="py-3 px-4 p-0  border-2 border-white">
                        {item.According_to_your_PAN}
                      </td>
                      <td className="py-3 px-4 p-0 border-2 border-white">
                        {item.According_to_your_Aadhar}
                      </td>
                      <td className="px-4 py-3  p-0 border-2 border-white">
                        <img src={item.Accuracy} alt="accuracy icon" />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="flex justify-end items-center text-center gap-3">
              <h4 className="text-[#DC2626] text-[0.8rem] font-[500] m-0">
                Mail & Address Mismatch Detected-Please Update.
              </h4>
              <button className=" bg-[#F3EFF8] text-[#9065B4] rounded py-1 px-2 font-[600] text-[0.9rem]">
                Pan Card
              </button>
              <h4 className="text-[#334155] text-[0.8rem] font-[500] m-0">
                OR
              </h4>
              <button className=" bg-[#F3EFF8] text-[#9065B4] rounded py-1 px-2 font-[600] text-[0.9rem]">
                Aadhar Card
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  const MobileComparePAN = () => {
    return (
      <>
        <CustomizableDrawer
          placement="bottom"
          height="500"
          triggerElement={
            <div className="flex gap-1 items-center md:hidden my-[1.5rem] ">
              <img src={compareIcon} alt="compare icon" />
              <h4
                className="m-0 text-decoration-line: underline text-[#F97316] md:text-[1rem] text-[0.9rem] font-[500] cursor-pointer"
                onClick={() => {}}
              >
                Compare you PAN with Aadhaar
              </h4>
            </div>
          }
          heading={"Detailed Comparison"}
          content={
            <>
              {/* <hr className="m-0" /> */}
              <div className="grid grid-cols-12 gap-1 my-[1rem] border border-[#E2E8F0] rounded-3xl">
                <table className="col-span-12 ">
                  <tr className="bg-[#F3EFF8] rounded">
                    <th className=" py-[1rem] text-center  col-span-6 text-[#334155] text-[1.1rem] font-[500]  ">
                      Details
                    </th>

                    <th className=" text-center py-[1rem]  col-span-6 text-[#334155] text-[1.1rem] font-[500]">
                      Accuracy
                    </th>
                  </tr>

                  {detailedArray.map((item) => {
                    return (
                      <tr
                        className={` rounded text-[#64748B] ${
                          item.Accuracy == no ? "bg-[#FEF2F2]" : "bg-[#FAF8FC]"
                        } `}
                      >
                        <td className="text-center  py-2 p-0 text-[#334155] col-span-6 ">
                          {item.Details}
                        </td>

                        <td className="  py-2 p-0 col-span-6 items-center justify-center flex">
                          <img src={item.Accuracy} alt="accuracy icon" />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>

              <div className=" items-center text-center ">
                <h4 className="text-[#DC2626] text-[0.9rem] font-[500] m-0">
                  Mail & Address Mismatch Detected!! <br />
                  Please Update.
                </h4>
                <div className="flex gap-3 items-center justify-center mt-[2rem]">
                  <button className=" bg-[#F3EFF8] text-[#9065B4] rounded py-2 px-3 font-[600] text-[0.9rem]">
                    Pan Card
                  </button>
                  <h4 className="text-[#334155] text-[0.8rem] font-[500] m-0">
                    OR
                  </h4>
                  <button className=" bg-[#F3EFF8] text-[#9065B4] rounded py-2 px-3 font-[600] text-[0.9rem]">
                    Aadhar Card
                  </button>
                </div>
              </div>
            </>
          }
        />
      </>
    );
  };

  // Main component
  return (
    <div className="pt-[4.5rem] md:bg-gray-100 pb-[2rem] md:mb-0 mb-[12rem]">
      {/* md:py-[5.5rem] */}
      <div className="px-3">
        {/* <HeadingProfile /> */}
        <div className="md:border border-[#E2E8F0] rounded ">
          <div className="flex md:hidden">
            <button>
              <img src={previousBtn} alt="" className="w-4 h-4" />
            </button>
            <h4 className="text-[#334155] text-[1.2rem] font-[500] px-3 py-3 m-0 rounded">
              Government IDs
            </h4>
          </div>

          <h4 className="md:block hidden text-[#334155] text-[1.2rem] font-[500] bg-[#FAF8FC] px-3 py-3 m-0 rounded">
            Government IDs
          </h4>

          <div className="md:bg-white md:px-3 py-3 rounded">
            <div className="grid grid-cols-12 gap-3">
              <PANCard />
              <AadhaarCard />
            </div>

            <DesktopDetailedComparisonPAN />
            <hr className="md:block hidden" />
            <MobileComparePAN />

            <div className="py-[1.2rem] md:mb-0 mb-[5rem]">
              <h4 className="text-[#334155] text-[0.9rem] font-[500] m">
                Other IDs
              </h4>
              <div className="grid grid-cols-12 pt-[1rem] gap-3 ">
                <DrivingLicense />
                <Voter />
                <Passport />
                <RationCard />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackToPersonalDashboard/>
    </div>
  );
};

export default GovtIds;
