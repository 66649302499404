// // This page is called by backend with a token. We need to save the token in local storage.
// import { myContext } from "App";
// import { DASHBOARD_PAGE_URL } from "constants/routes";
// import WelcomeModal from "containers/BusinessLoginSignUp.js/WelcomeModal";
// import { useContext, useEffect, useState } from "react";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// const GoogleSuccessLoginRedirect = () => {
//   // let [searchParams] = useSearchParams();
//   // const token = searchParams?.get("token");
//   const navigate = useNavigate();
//   console.log("Inside Google SIgnin component", token);

//   const [isOpen, setIsOpen] = useState(true); // Assuming you want it to be open initially

//   const closeModal = () => {
//     setIsOpen(false);
//   };

//   const handleGoToNextPage = () => {
//     setIsOpen(false);
//     navigate("/dashboard");
//   };

//   // useEffect(() => {
//   //   if (token) {
//   //     localStorage.setItem("token", token);
//   //     // navigate(DASHBOARD_PAGE_URL);
//   //   }
//   // }, []);
//   // Parse the URL
// const url = new URL(window.location.href); // Get the full URL

// // Extract the token
// const token = url.searchParams.get('token'); // Extract the token
// if (token) {
//     localStorage.setItem('token', token); // Save the token in localStorage
// }

// // Extract other parameters and store them in a user object
// const user = {
//     id: url.searchParams.get('id'),
//     fullName: url.searchParams.get('full_name'),
//     email: url.searchParams.get('email'),
//     mobileNumber: url.searchParams.get('mobile_number'),
//     dateOfBirth: url.searchParams.get('date_of_birth'),
//     panNo: url.searchParams.get('pan_no'),
//     accountType: url.searchParams.get('account_type'),
//     businessName: url.searchParams.get('business_name'),
//     businessType: url.searchParams.get('business_type'),
//     profilePicture: url.searchParams.get('profile_picture'),
//     currentIncomeTaxReturnId: url.searchParams.get('current_income_tax_return_id'),
//     currentIncomeTaxReturnYearName: url.searchParams.get('current_income_tax_return_year_name'),
// };

// // Store the user object in localStorage
// localStorage.setItem('user', JSON.stringify(user)); // Convert the object to a JSON string


//   if (token) {
//     return (
//       <WelcomeModal
//         isOpen={isOpen}
//         handleGoToNextPage={handleGoToNextPage}
//         closeModal={closeModal}
//       />
//     );
//   }

//   return <></>;
// };

// export default GoogleSuccessLoginRedirect;

import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import WelcomeModal from "containers/BusinessLoginSignUp.js/WelcomeModal";
import { myContext } from "App";
import { BUSINESS_DASHBOARD_URL } from "constants/routes";
import { USER_TYPE_BUSINESS, USER_TYPE_PERSONAL, USER_TYPE_PERSONAL_AND_BUSINESS } from "constants/userType";

const GoogleSuccessLoginRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {setUserType, userType, setIsLoggedIn}=useContext(myContext)

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      // Store token in localStorage
      localStorage.setItem("token", token);

      // Extract other parameters
      const user = {
        id: searchParams.get("id"),
        full_name: searchParams.get("full_name"),
        email: searchParams.get("email"),
        mobile_number: searchParams.get("mobile_number"),
        date_of_birth: searchParams.get("date_of_birth"),
        pan_no: searchParams.get("pan_no"),
        account_type: Number(searchParams.get("account_type")),
        business_name: searchParams.get("business_name"),
        business_type: searchParams.get("business_type"),
        profile_picture: searchParams.get("profile_picture"),
        current_income_tax_return_id: searchParams.get("current_income_tax_return_id"),
        current_income_tax_return_year_name: searchParams.get("current_income_tax_return_year_name"),
        business_id: searchParams.get("business_id")
      };


      localStorage.setItem("user",JSON.stringify(user))

      const is_user_new=searchParams.get("is_user_new")

      if(user.account_type === 3){
        setUserType(USER_TYPE_PERSONAL_AND_BUSINESS)
      }else if(user.account_type === 2){
        setUserType(USER_TYPE_BUSINESS)
      }else if(user.account_type === 1){
        setUserType(USER_TYPE_PERSONAL)
      }
      if(is_user_new==="false" && user.account_type === 2){
          if(searchParams.get("is_active")==="true" && searchParams.get("is_business_user") === "true"){
            setIsOpen(true)
          }else if(searchParams.get("is_active")==="true" && searchParams.get("is_business_user")=== "false"){
            navigate("/businessDetails", {
              state: { user_id: user?.id},
            });
          }else if(searchParams.get("is_active")==="false"){
            navigate("/signUpMobileVerification")
          }
      }else if(is_user_new==="false" && user.account_type === 1){
        if(searchParams.get("is_active")==="true"){
          setIsOpen(true)
        }else{
          navigate("/signUpMobileVerification")
        } 
      }
      else{
          navigate("/signUpMobileVerification")
      }
    } else {
      // Handle missing token (optional)
      navigate("/login");
    }
  }, [searchParams, navigate]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleGoToNextPage = () => {
    setIsOpen(false);
    setIsLoggedIn(true);
    if(userType === "business"){
      navigate(BUSINESS_DASHBOARD_URL);
    }else{
      navigate("/dashboard")
    }
  };

  return (
    <>
      <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
        <div className="loader mt-48"></div>
      </div>
       {isOpen && (
        <WelcomeModal
          isOpen={isOpen}
          handleGoToNextPage={handleGoToNextPage}
          closeModal={closeModal}
        />
      )}
    </>
    // <>
    //   {isOpen && (  
    //     <WelcomeModal
    //       isOpen={isOpen}
    //       handleGoToNextPage={handleGoToNextPage}
    //       closeModal={closeModal}
    //     />
    //   )}
    // </>   
  );
};

export default GoogleSuccessLoginRedirect;  

