import { BUSINESS_DASHBOARD_URL } from "constants/routes";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logout from "assets/images/dashboard/log-out.svg";

const BackToBusinessDashboard = () => {
  const navigate = useNavigate();
  return (
    // <div className="ml-[1rem] mt-[1rem]">
      <Link
        className="ml-[1rem] mt-[1rem] no-underline text-[#64748B] flex gap-2 text-[0.9rem] font-[500] cursor-pointer"
        to={BUSINESS_DASHBOARD_URL}
      >
        <img src={logout} alt=""/>
        Back to Dashboard
      </Link>
    // </div>
  );
};

export default BackToBusinessDashboard;
