import useAPICall from "hooks/useAPICall";
import { PATCH_BUSINESS_CUSTOMERS, POST_BUSINESS_UNREGISTERED_CUSTOMERS_URL } from "constants/apis";

const usePatchCustomerDetail = () => {
  const [data, error, isLoading, callPatchCustomerDetail, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
          setError(undefined);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const patchCustomerDetail = (body, business_id, user_id) => {
    const url = PATCH_BUSINESS_CUSTOMERS.replace(":business_id", business_id).replace(":user_id",user_id);
    callPatchCustomerDetail({
      url,
      method: "PATCH",
      statusObj,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, patchCustomerDetail, setSuccessData, setError];
};

export default usePatchCustomerDetail;
