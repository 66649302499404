import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import upload from "assets/images/BusinessDashboard/BusinessEmployee/upload.svg";
import upArrow from "assets/images/BusinessDashboard/BusinessEmployee/upArrow.svg";
import plus from "assets/images/BusinessDashboard/BusinessEmployee/plus.svg";
import minus from "assets/images/BusinessDashboard/BusinessEmployee/minus.svg";
import info from "assets/images/incomeTax/info1.svg";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import usePostAddEmployee from "./data/usePostAddEmployee";

const AddNewEmployee = ({ triggerElement, resetState, handleCloseTypeAndAddEmployee, handleReloadGetApi}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const user= JSON.parse(localStorage.getItem("user"));
  const [deductionAmount, setDeductionAmount]=useState(0)
  const [postEmployeeData, postEmployeeError, postEmployeeIsLoading, postEmployee]=usePostAddEmployee()
  const [newEmployeeForm, setNewEmployeeForm]=useState({
    "business_user": user.business_id,
    "employee_name": "",
    "email": "",
    "date_of_birth": "",
    "phone_number": "",
    "team": "",
    "designation": "",
    "joining_date": "",
    "pay_type": "",
    "pay": "",
    "linkedin_profile_url": "",
    "aadhar_card": null,
    "pan_card": null,
    "joining_letter": null,
    "allowances": [
    ],
    "deductions": [
    ]
})

 useEffect(()=>{
  if(postEmployeeData){
    const data = postEmployeeData?.data?.message;
    showSuccessToast(data);
    handleCloseTypeAndAddEmployee()
    handleReloadGetApi()
  }
  if(postEmployeeError){
    showErrorToast("Error in adding employee")
  }
 },[postEmployeeData, postEmployeeError])
  const progressSteps = [
    {
      label: "Personal Details",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Job Details",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "IT and Documents",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];
  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  //   temperory for ui
  const [showPayStructure, setShowPayStructure] = useState(false);

  const handleInputChange=(e)=>{
    const {value, name, type}=e.target
    if(type==="file"){
      setNewEmployeeForm({...newEmployeeForm,[name]:e.target.files[0]})
    }else{
      setNewEmployeeForm({...newEmployeeForm,[name]:value})
    }
  }

  const handleAllowanceChange=(e,index)=>{
    const { name, value } = e.target;
    const updatedAllowances = [...newEmployeeForm.allowances];
    updatedAllowances[index] = { ...updatedAllowances[index], [name]: value };
    setNewEmployeeForm({ ...newEmployeeForm, allowances: updatedAllowances });
  }
  
  const handleDeductionChange=(e,index)=>{
    const { name, value } = e.target;
    const updatedDeductions = [...newEmployeeForm.deductions];
    updatedDeductions[index] = { ...updatedDeductions[index], [name]: value };
    setNewEmployeeForm({ ...newEmployeeForm, deductions: updatedDeductions });
    const amount=updatedDeductions.reduce((acc,el)=>{
      return acc + Number(el.amount) 
    },0)
    setDeductionAmount(amount)
  }

  const handleAddDeduction=()=>{
    setNewEmployeeForm({...newEmployeeForm,deductions:[...newEmployeeForm?.deductions,{"name": "", "amount": 0, "description": ""}]})
  }

  const handleAddAllowance=()=>{
    setNewEmployeeForm({...newEmployeeForm,allowances:[...newEmployeeForm?.allowances,{"name": "", "amount": 0, "description": ""}]})
  }

  const handleBack=()=>{
    if(currentStep>0){
    setCurrentStep(currentStep - 1);
    setVisitedSteps((prevSteps) => prevSteps.slice(0, -1));
  }
  }

  const handleRemoveAllowance=(index)=>{
   const updatedAllowances=[...newEmployeeForm.allowances].filter((el,i)=>{
     return index !== i
   })
    setNewEmployeeForm({ ...newEmployeeForm, allowances: updatedAllowances });
  }

  const handleRemoveDeduction=(index)=>{
     const updatedDeductions=[...newEmployeeForm.deductions].filter((el,i)=>{
     return index !== i
   })
    setNewEmployeeForm({ ...newEmployeeForm, deductions: updatedDeductions });
    const amount=updatedDeductions.reduce((acc,el)=>{
      return acc + Number(el.amount) 
    },0)
    setDeductionAmount(amount)
  }

  const isValid=()=>{
    const addAllowances= newEmployeeForm.allowances.reduce((acc, el)=>{
      return acc+Number(el.amount)
    },0)
    console.log("addAllowances", addAllowances)
    if(addAllowances > ((newEmployeeForm.pay * 40 )/100) || addAllowances < ((newEmployeeForm.pay * 40 )/100)){
      showErrorToast(`Allowances should be equal to ${(newEmployeeForm.pay * 40 )/100}`)
      return false
    }
    return true
  }

  const handleNext = () => {
    if(currentStep === 2){
      if(isValid()){
        const formData=new FormData()
        Object.keys(newEmployeeForm).forEach((el,i)=>{
          if(el === "allowances"){
            newEmployeeForm[el].forEach((child,index)=>{
              formData.append(`allowances[${index}]name`,child.name)
              formData.append(`allowances[${index}]amount`,child.amount)
              formData.append(`allowances[${index}]description`,child.description)
            })
          }else if(el === "deductions"){
            newEmployeeForm[el].forEach((child,index)=>{
              formData.append(`deductions[${index}]name`,child.name)
              formData.append(`deductions[${index}]amount`,child.amount)
              formData.append(`deductions[${index}]description`,child.description)
            })
          }else{
            formData.append(el,newEmployeeForm[el])
          }
        })
        postEmployee(formData)
      }
    }
    if (currentStep < progressSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOpenPdf=(val)=>{
    const pdfUrl = window.URL.createObjectURL(val)
    window.open(pdfUrl)
  }

  const handleRemovePdf=(val)=>{
    if(val === "aadhar"){
      setNewEmployeeForm({...newEmployeeForm,aadhar_card:null })
    }else if(val === "pan"){
      setNewEmployeeForm({...newEmployeeForm,pan_card:null })
    }else if(val === "joining"){
      setNewEmployeeForm({...newEmployeeForm,joining_letter:null })
    }
  }

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Personal Details
            </h4>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Employee Name
                </label>
                <InputField
                  type="text"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  placeholder="Name"
                  value={newEmployeeForm.employee_name}
                  name="employee_name"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Date of Birth
                </label>
                <InputField
                  type="date"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  value={newEmployeeForm.date_of_birth}
                  name="date_of_birth"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Email
                </label>
                <InputField
                  type="email"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  placeholder="Email"
                  value={newEmployeeForm.email}
                  name="email"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Phone Number
                </label>
                <InputField
                  type="number"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none appearance-none"
                  placeholder="Phone Number"
                  value={newEmployeeForm.phone_number}
                  name="phone_number"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Job Details
            </h4>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Team
                </label>
                <InputField
                  type="text"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  placeholder="Team"
                  value={newEmployeeForm.team}
                  name="team"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Role
                </label>
                <InputField
                  type="text"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  placeholder="Role"
                  value={newEmployeeForm.designation}
                  name="designation"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-3 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Joining Date
                </label>
                <InputField
                  type="date"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  value={newEmployeeForm.joining_date}
                  name="joining_date"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
              <div className="col-span-3 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Pay Type
                </label>
                <InputField
                  type="select"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none"
                  options={[
                    { value: "", label: "Select"},
                    { value: "1", label: "Daily" },
                    { value: "2", label: "Weekly" },
                    { value: "3", label: "Monthly" },
                    { value: "4", label: "Yearly" }
                  ]}
                  name="pay_type"
                  value={newEmployeeForm.pay_type}
                  onChange={(e)=>{
                    handleInputChange(e);
                    if(e.target.value === ""){
                      setShowPayStructure(false)
                    }else{
                      setShowPayStructure(true)
                    }
                  }}
                />
              </div>
              <div className="col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Pay
                </label>
                <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                  <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem]">
                    Rs.
                  </span>
                  <InputField
                    type="number"
                    name="pay"
                    value={newEmployeeForm.pay}
                    onChange={(e)=>handleInputChange(e)}
                    className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none appearance-none"
                  />
                </div>
              </div>
            </div>
            {showPayStructure && (
              <>
                <div className="overflow-auto scrollbar-hide rounded-md border border-[#D9D9D9] mt-2">
                  <table className="w-full border-collapse">
                    <tbody>
                      <tr className="">
                        <td className="border-b border-r border-[#D9D9D9] px-4 py-2 text-[#334155] font-[600] flex gap-1 items-center bg-[#F1F5F9] h-[50px]">
                          Base Pay <img src={info} alt="info" height=""/>
                        </td>
                        <td className="border-b border-l border-[#D9D9D9] px-4 py-2 h-[50px]">
                          ₹{((newEmployeeForm.pay * 60 )/100).toFixed(2) || 0}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-r border-[#D9D9D9] px-4 py-2 text-[#334155] font-[600] flex gap-1 bg-[#F1F5F9] items-center h-[50px]">
                          Allowances <img src={info} alt="info" />
                        </td>
                        <td className="border-b border-l border-[#D9D9D9] px-4 py-2 text-[#EA580C] h-[50px]">
                          <div className="flex items-center justify-between">
                            <span>₹{((newEmployeeForm.pay *40 )/100).toFixed(2) || 0}</span>
                            <img src={upArrow} alt="upArrow" height="20px"/>
                          </div>
                        </td>
                      </tr>
                      {
                        newEmployeeForm.allowances.map((el,i)=>{
                          return(
                            <tr key={i}>
                            <td className="border-b border-r border-[#D9D9D9] px-4 py-2 text-[#334155] flex gap-1 h-[60px] items-center">
                            <InputField
                              type="select"
                              className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none"
                              options={[
                                { value: "", label: "Select" },
                                { value: "HRA", label: "HRA" },
                                { value: "PF", label: "PF" },
                                { value: "Professional Tax", label: "Professional Tax" },
                                { value: "ESI", label: "ESI" },
                                { value: "TDS", label: "TDS" },
                                { value: "Other", label: "Other" }
                              ]}
                              name="name"
                              value={el.name}
                              onChange={(e)=>handleAllowanceChange(e,i)}
                            />
                            </td>
                            <td className="border-b border-l border-[#D9D9D9] px-4 py-2 text-[#EA580C] h-[60px]">
                              <div className="flex items-center justify-between">
                                {/* <span className="text-[#64748B]">₹0</span> */}
                                <InputField
                                  type="number"
                                  name="amount"
                                  value={el.amount}
                                  onChange={(e)=>handleAllowanceChange(e,i)}
                                  className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none appearance-none"
                                />
                                <img src={minus} alt="minus" height="20px" onClick={()=>handleRemoveAllowance(i)} className="cursor-pointer"/>
                              </div>
                            </td>
                          </tr>
                          )
                        })
                      }
                      <tr className="border-b border-[#D9D9D9]">
                        <td className="px-4 py-2 h-[50px]">
                        <div className="flex gap-1.5 items-center cursor-pointer" onClick={handleAddAllowance}>
                            <img src={plus} alt="plus" height="15px"/>
                            <p className="m-0 text-[#64748B] text-[0.9rem] font-[500]">Add Allowance</p>
                        </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-r border-[#D9D9D9] px-4 py-2 text-[#334155] font-[600] flex gap-1 bg-[#F1F5F9] h-[50px] items-center">
                          Deductions
                        </td>
                        <td className="border-b border-l border-[#D9D9D9] px-4 py-2 text-[#EA580C] h-[50px]">
                          <div className="flex items-center justify-between">
                            {/* <span>₹{newEmployeeForm?.deductions?.length > 0 ? (newEmployeeForm?.deductions?.reduce((acc, el)=>{
                              return acc + el.amount
                            })) : 0}</span> */}
                            <span>{deductionAmount}</span>
                            <img src={upArrow} alt="upArrow" height="20px"/>
                          </div>
                        </td>
                      </tr>
                      {
                        newEmployeeForm.deductions.map((el,i)=>{
                          return(
                            <tr key={i}>
                            <td className="border-b border-r border-[#D9D9D9] px-4 py-2 text-[#334155] flex gap-1 h-[60px] items-center">
                            <InputField
                              type="select"
                              className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none"
                              options={[
                                { value: "", label: "Select" },
                                { value: "TDS", label: "TDS" }
                              ]}
                              name="name"
                              value={el.name}
                              onChange={(e)=>handleDeductionChange(e,i)}
                            />
                            </td>
                            <td className="border-b border-l border-[#D9D9D9] px-4 py-2 text-[#EA580C] h-[60px]">
                              <div className="flex items-center justify-between">
                                {/* <span className="text-[#64748B]">₹0</span> */}
                                <InputField
                                  type="number"
                                  name="amount"
                                  value={el.amount}
                                  onChange={(e)=>handleDeductionChange(e,i)}
                                  className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none appearance-none"
                                />
                                <img src={minus} alt="minus" height="20px" onClick={()=>handleRemoveDeduction(i)} className="cursor-pointer"/>
                              </div>
                            </td>
                          </tr>
                          )
                        })
                      }
                      <tr className="border-b border-[#D9D9D9]">
                        <td className="px-4 py-2 h-[50px]">
                        <div className="flex gap-1.5 items-center cursor-pointer" onClick={handleAddDeduction}>
                            <img src={plus} alt="plus" height="15px"/>
                            <p className="m-0 text-[#64748B] text-[0.9rem] font-[500]">Add Deductions</p>
                        </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-r border-[#D9D9D9] px-4 py-2 text-[#334155] font-[600] flex gap-1 bg-[#F1F5F9] h-[50px] items-center">
                          Net pay
                        </td>
                        <td className="border-l border-[#D9D9D9] px-4 py-2 text-[#EA580C] h-[50px]">
                          <div className="flex items-center justify-between">
                            <span>₹{(((newEmployeeForm.pay *60 )/100) + ((newEmployeeForm.pay *40 )/100) - deductionAmount)?.toFixed(2)  || 0}</span>
                            <img src={upArrow} alt="upArrow" height="20px"/>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        );

      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              IT and Documents
            </h4>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Linkedin Profile URL
                </label>
                <InputField
                  type="text"
                  className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none"
                  placeholder="Link URL"
                  value={newEmployeeForm.linkedin_profile_url}
                  name="linkedin_profile_url"
                  onChange={(e)=>handleInputChange(e)}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Employee Aadhar Card
                </label>
                <div>
                  {
                    newEmployeeForm.aadhar_card ? <>
                     <div className="flex gap-1.5">
                         <p onClick={()=>handleOpenPdf(newEmployeeForm.aadhar_card)} className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">View</p>
                         <p onClick={()=>handleRemovePdf("aadhar")} className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">Remove</p>
                     </div>
                    </>:                  
                    <button
                    onClick={() => document.getElementById("file-aadhar").click()}
                    className="text-[#64748B] bg-white font-[600] text-[0.9rem] border-1 border-[#D9D9D9] py-1.5 px-[1rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="uploadPic" />
                    Upload Aadhar
                  </button>
                  }
                  <InputField 
                    id={`file-aadhar`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="aadhar_card"
                    />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Employee PAN Card
                </label>
                <div>
                  {
                    newEmployeeForm.pan_card ? <>
                      <div className="flex gap-1.5">
                         <p onClick={()=>handleOpenPdf(newEmployeeForm.pan_card)}  className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">View</p>
                         <p onClick={()=>handleRemovePdf("pan")} className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">Remove</p>
                     </div>
                    </>:<>
                  <button
                    onClick={() => document.getElementById("file-pan").click()}
                    className="text-[#64748B] bg-white font-[600] text-[0.9rem] border-1 border-[#D9D9D9] py-1.5 px-[1rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="uploadPic" />
                    Upload PAN
                  </button>
                    </>
                  }
                  <InputField 
                    id={`file-pan`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="pan_card"
                    />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 py-1 gap-3">
              <div className="input-container col-span-6 w-[100%] group">
                <label className="font-[500] text-[#64748B] text-[0.8rem]">
                  Joining Letter (Optional)
                </label>
                <div>
                  {
                    newEmployeeForm.joining_letter ? <>
                      <div className="flex gap-1.5">
                         <p onClick={()=>handleOpenPdf(newEmployeeForm.joining_letter)} className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">View</p>
                         <p onClick={()=>handleRemovePdf("joining")} className="m-0 cursor-pointer text-[#64748B] font-[500] border-b-[2px] border-[#64748B]">Remove</p>
                     </div>
                    </> : <>
                  <button
                    onClick={() => document.getElementById("file-joining").click()}
                    className="text-[#64748B] bg-white font-[600] text-[0.9rem] border-1 border-[#D9D9D9] py-1.5 px-[1rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="uploadPic" />
                    Upload Offer Letter
                  </button>
                    </>
                  }
                  <InputField 
                    id={`file-joining`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="joining_letter"
                    />
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return (
    <>
      <DialogBox
        triggerElement={triggerElement}
        onClose={resetState}
        progressSteps={progressSteps}
        goRight={true}
        onTop={true}
        heading={
          <p className="font-[600] text-[1rem] text-[#334155]">
            Add New Employee
          </p>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end items-end gap-[1rem] mt-[2rem] flex-grow mb-2">
              <button onClick={handleBack} className="px-[0.6rem] py-[0.4rem] rounded border border-[#D9D9D9] text-[#64748B] text-[0.9rem]">
                Back
              </button>
              <button
                className="px-[0.6rem] py-[0.4rem] rounded bg-[#EA580C] text-[#FFFFFF]"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
      />
    </>
  );
};

export default AddNewEmployee;
