import React from 'react'
import SelectOptionCustomers from './SelectOptionCustomers';
import NewCustomerUnregistered from './NewCustomerUnregistered';
import { useParams } from 'react-router-dom';
import NewCustomerRegistered from './NewCustomerRegistered';

const CustomersComponent = () => {
    const {type} = useParams();
    let displayText = "";
  
    switch (type) {
      case "select":
        displayText = <SelectOptionCustomers />;
        break;
      case "unregistered":
        displayText = <NewCustomerUnregistered />;
        break;
      case "registered":
        displayText = <NewCustomerRegistered />;
        break;
      
      default:
        displayText = "Redirected to wrong page!!";
    }
  return (
    <div>
        {displayText}
        </div>
  )
}

export default CustomersComponent