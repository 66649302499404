import Button from 'components/Button/BtnOrange'
import WhiteButton from 'components/Button/WhiteButton'
import DialogBox from 'components/DialogBox/DialogBox'
import warning from "assets/images/BusinessDashboard/BusinessCustomers/deleteWarning.svg"
import React, { useEffect, useState } from 'react'
import useDeleteCustomer from '../data/useDeleteCustomer'
import { showErrorToast, showSuccessToast } from 'components/toaster/toastHelper'
import { useNavigate } from 'react-router-dom'
import { BUSINESS_CUSTOMERS_URL } from 'constants/routes'

const EditProfileDetail = ({triggerElement, openProfileEdit, setOpenProfileEdit, detailData}) => {
    const [deleteData, deleteError, deleteIsLoading, deleteCustomer]=useDeleteCustomer()
    const [isClose, setIsClose]=useState(false);
    const businessId=JSON.parse(localStorage.getItem("user")).business_id;
    const [userId, setUserId]=useState("");
    const [companyName, setCompanyName]=useState("")
    const navigate=useNavigate()
    useEffect(()=>{
     if(detailData){
        setUserId(detailData?.customer?.id)
        setCompanyName(detailData?.customer?.company_name)
     }
    },[detailData])
    useEffect(()=>{
        if(deleteData){
            showSuccessToast("Customer Deleted Successfully")
            setOpenProfileEdit(false)
            navigate(BUSINESS_CUSTOMERS_URL)
        }
        if(deleteError){
        showErrorToast("Error in Deleting Customer")
        }
    },[deleteData, deleteError])
    const handleSubmit=()=>{
      deleteCustomer(businessId, userId)
    }
    const resetState=()=>{
        if(openProfileEdit){
            setOpenProfileEdit(false)
        }
    }
    const getContent=()=>{
        return(
            <div className='flex flex-col items-center gap-2 mb-2 p-2'>
                <div>
                   <img src={warning} alt='warning'/>
                </div>
                <div>
                    <p className="text-[#1F2937] font-[600] m-0 text-center">Remove {companyName}</p>
                </div>
                <div>
                    <p className='text-[#6B7280] text-[0.8rem] m-0 text-center'>Are you sure you would like to remove {companyName} from your Customers?</p>
                </div>
            </div>
        )
    }
  return (
    <div>
     <DialogBox
        triggerElement={
           triggerElement
        }
        heading=""
        width='max-w-80'
        onClose={resetState}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-center gap-[1rem]">
              <WhiteButton
                onClick={handleSubmit}
                name="Remove"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button onClick={handleClose} name={"Cancel"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} bg={"bg-[#FB923C]"} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  )
}

export default EditProfileDetail