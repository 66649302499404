import React, { useEffect, useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import grayBin from "assets/images/reports/grayBin.svg";
import uploadFile from "assets/images/reports/uploadFile.svg";
import fileSuccess from "assets/images/reports/fileSuccess.svg";
import InputField from "components/input/InputField";
import WhiteButton from "components/Button/WhiteButton";
import Button from "components/Button/BtnOrange";

const BusinessEditDocument = ({
  openAddDocumentDialog,
  handleCloseAddDocDialog,
  docName,
  setDocName,
  uniqueNo,
  setUniqueNo,
  dateOfReg,
  setDateOfReg,
  setDocImg,
  validUpto,
  setValidUpto,
  docImg,
  handleAddDocument,
  documentId
}) => {
  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files; // Get dropped files
    handleDrop(files); // Call the handleDrop method
  };

  const handleDrop = (files) => {
    setDocImg(files[0]);
  };

    const [errors, setErrors] = useState({});
    const [formValid, setFormValid] = useState(true);
    const [touched, setTouched] = useState(false);
  useEffect(() => {
    if(!touched) return ;
    const newErrors = {};
    if(!docName){
      newErrors.docName = "Doc Name is required.";
    }
    if(!uniqueNo){
      newErrors.uniqueNo = "Unique No is required.";
    }
    if(!dateOfReg){
      newErrors.dateOfReg = "Reg Date is required.";
    }
    if(!validUpto){
      newErrors.validUpto = "Valid Upto is required.";
    }
    if(!docImg){
      newErrors.docImg = "Doc upload is required.";
    }
    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).length === 0); 

  }, [docName, touched, docImg, validUpto, dateOfReg, uniqueNo]);

  const handleSave = () => {
    setTouched(true); 
    if (formValid) {
      handleAddDocument(); 
    }
  }

  const resetState = () =>{
    setErrors({});
    setTouched(false);
    setFormValid(true);
  }

  return (
    <>
      {openAddDocumentDialog && (
        <div className=" fixed inset-0 bg-gray-900  bg-opacity-60 flex justify-center items-center z-50">
          <div className="min-h-[450px] w-[75%] sm:w-[70%] md:w-[500px] bg-[#FFFFFF] rounded-2xl px-6 py-4 relative">
            <div>
              <button
                className="group absolute top-[1rem] right-6 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
                onClick={() => {handleCloseAddDocDialog(); 
                  resetState();
                }}
              >
                <img
                  alt=""
                  src={closeIcon}
                  className="w-3 h-3 group-hover:hidden"
                />
                <img
                  alt=""
                  src={closeIconRed}
                  className="w-4 h-4 hidden group-hover:block"
                />
              </button>
              <h4 className="text-[1rem] font-[600] text-darkGray text-center">
                Add Document
              </h4>
            </div>
            <div
              className="border rounded-md bg-[#F8FAFC] my-[0.5rem]"
              onDrop={(e) => handleFileDrop(e)}
              onDragOver={(e) => e.preventDefault()}
            >
              {docImg ? (
                <div className="px-[1rem] py-[0.5rem]">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-center text-[#334155] font-[600] m-0 text-[0.9rem]">
                        {docImg.name}
                      </p>
                      <p className="text-center text-lightGray font-[400] m-0 text-[0.7rem]">
                      {Math.round(docImg.size / 1024)}KB
                      </p>
                    </div>
                    <div className="flex gap-[0.5rem] ">
                      <img src={fileSuccess} alt="" />
                      <button onClick={() => setDocImg(null)}>
                        {" "}
                        <img src={grayBin} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-[0.5rem] mt-[0.2rem] items-center">
                    <div className="bg-customPurple w-[80%] h-[5px] rounded-md"></div>
                    <h6 className="text-darkGray text-[0.9rem] m-0">100%</h6>
                  </div>
                </div>
              ) : (
                <div
                  className="cursor-pointer flex flex-col justify-center items-center py-[2rem]"
                  onClick={() =>
                    document.querySelector(`#file-input-land-photo`).click()
                  }
                >
                  <img src={uploadFile} alt="" />
                  <h4 className="text-darkGray text-[0.9rem] font-[500]">
                    Drop your files here or{" "}
                    <span className="text-customPurple">browse</span>
                  </h4>
                  <h6 className="text-[0.8rem] text-lightGray m-0 font-[400]">
                    Maximum size: 50MB
                  </h6>
                </div>
              )}
            </div>
            {/* upload doc imput field */}
            <InputField
              type="file"
              // accept=".jpeg, .png, .jpg"
              id={`file-input-land-photo`}
              className="hidden"
              onChange={(e) => setDocImg(e.target.files[0])}
             
            />
             {touched && errors.docImg && (
                    <p className="text-red-500 text-xs">
                      {errors.docImg}
                    </p>
                  )}
            {/* input fields */}
            <div className="grid grid-cols-2 gap-[0.5rem] mt-[0.5rem]">
              <div>
                <h4 className="text-lightGray text-[0.8rem]">Document Name</h4>
                <InputField
                  value={docName}
                  onChange={(e) => setDocName(e.target.value)}
                  placeholder="Enter Document Name"
                  className="text-lightGray text-[0.9rem] border rounded-md p-[0.4rem]"
                  // onBlur={() => handleBlur("docName")}
                />
                  {touched && errors.docName && (
                    <p className="text-red-500 text-xs">
                      {errors.docName}
                    </p>
                  )}
              </div>
              <div>
                <h4 className="text-lightGray text-[0.8rem]">
                  Registration Date
                </h4>
                <InputField
                  type="date"
                  value={dateOfReg}
                  onChange={(e) => setDateOfReg(e.target.value)}
                  placeholder="Enter Registration Date"
                  // onBlur={() => handleBlur("dateOfReg")}
                  className="w-[90%] text-lightGray text-[0.9rem] border rounded-md p-[0.4rem]"
                />
                  {touched && errors.dateOfReg && (
                    <p className="text-red-500 text-xs">
                      {errors.dateOfReg}
                    </p>
                  )}
              </div>
              <div>
                <h4 className="text-lightGray text-[0.8rem]">Valid Upto</h4>
                <InputField
                  type="date"
                  value={validUpto}
                  onChange={(e) => setValidUpto(e.target.value)}
                  placeholder="Enter valid upto"
                  // onBlur={() => handleBlur("validUpto")}
                  className="w-[90%] text-lightGray text-[0.9rem] border rounded-md p-[0.4rem]"
                />
                {touched && errors.validUpto && (
                    <p className="text-red-500 text-xs">
                      {errors.validUpto}
                    </p>
                  )}
              </div>
              <div>
                <h4 className="text-lightGray text-[0.8rem]">Unique No.</h4>
                <InputField
                  type="text"
                  value={uniqueNo}
                  onChange={(e) => setUniqueNo(e.target.value)}
                  placeholder="Enter unique no."
                  // onBlur={() => handleBlur("uniqueNo")}
                  className="text-lightGray text-[0.9rem] border rounded-md p-[0.4rem]"
                />
                {touched && errors.uniqueNo && (
                    <p className="text-red-500 text-xs">
                      {errors.uniqueNo}
                    </p>
                  )}
              </div>
            </div>

            {/* buttons */}
            <div className="flex justify-end gap-[0.5rem] mt-[1rem]">
              <WhiteButton
                onClick={handleCloseAddDocDialog}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={handleSave}
                name="Add Document"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </div>
        </div>  
      )}
    </>
  );
};

export default BusinessEditDocument;
