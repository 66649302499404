import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate, useLocation } from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import { myContext } from "App";
import { useState, useContext, useEffect } from "react";
import usePostLogout from "containers/privateRoutes/Orders/data/usePostLogout";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

export default function LogoutDialog() {
  const [open, setOpen] = useState(false);
  const [logOutClicked, setLogOutClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);

  const [logoutData, LogoutError, LogoutIsLoading, postLogout] =
    usePostLogout();

  useEffect(() => {
    if (logOutClicked) {
      postLogout();
      localStorage.clear();
      setIsLoggedIn(false);
      navigate("/");
    }
  }, [logOutClicked]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="text-[0.9rem] text-[#344054]"
      >
        Logout
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: "90%", maxWidth: "500px", height: "28%" },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleClose();
          },
        }}
      >
        <DialogContent>
          <b className="text-[#0F172A] font-[600] text-[1.2rem]">Log Out</b>
          <p className="text-[#64748B] font-[500] text-[1rem]">
            Are you sure you want to log out?
          </p>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: 0,
            height: "29%",
            paddingBottom: "25px",
            paddingRight: "8px",
          }}
        >
          <button
            data-ignore-click-outside
            onClick={handleClose}
            className=" no-underline bg-purple-light-shade py-2 px-4 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setLogOutClicked(true);
            }}
            data-ignore-click-outside
            className={`mx-2 my-4 py-2 px-4 rounded-md  text-white ${
              location.pathname.includes("/business")
                ? "bg-[#EA580C]"
                : "bg-purple-dark-shade"
            }`}
          >
            Log Out
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
