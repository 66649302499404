import React, { useEffect, useRef, useState } from "react";
import deleteIcon from "assets/images/assetsAndLiabilities/trash-2.svg";
import downloadIcon from "assets/images/incomeTax/download.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import BackToBusinessDashboard from "./BackToBusinessDashboard";
import { Document, Page, pdfjs } from "react-pdf";
import useGetBusinessDocuments from "./data/useGetBusinessDocuments";
import useDeleteBusinessDocuments from "./data/useDeleteBusinessDocument";
import closedEye from "assets/images/incomeTax/closedEye.png";
import { MdContentCopy } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import BusinessAddDocument from "./BusinessAddDocument";
import usePostBusinessDoc from "./data/usePostBusinessDoc";
import BusinessEditDocument from "./EditBusinessDocument";
import usePatchBusinessDoc from "./data/usePatchBusinessDoc";
import { IoEllipsisVertical } from "react-icons/io5";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://stage-beyondtaxindia.s3.amazonaws.com/media/public/business/government_documents/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
const BusinessDocuments = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [
    getBusinessDocData,
    getBusinessDocIsError,
    getBusinessDocIsLoading,
    getBusinessDoc,
  ] = useGetBusinessDocuments();
  const [deleteData, deleteIsError, deleteIsLoading, deleteDocuments] =
    useDeleteBusinessDocuments();
  const [postData, postIsError, postIsLoading, postDocuments] =
    usePostBusinessDoc();
  const [patchData, patchIsError, patchIsLoading, patchDocuments] =
    usePatchBusinessDoc();
    
  useEffect(() => {
    getBusinessDoc(user?.business_id);
  }, []);

  useEffect(() => {
    if (deleteData) {
      showSuccessToast("Document deleted successfully");
      getBusinessDoc(user?.business_id);
    }
  }, [deleteData]);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Document posted successfully");
      getBusinessDoc(user?.business_id);
      handleCloseAddDocDialog();
    }
  }, [postData]);
  useEffect(() => {
    if (patchData) {
      showSuccessToast("Document updated successfully");
      getBusinessDoc(user?.business_id);
      handleCloseEditDocDialog();
    }
  }, [patchData]);

  const docImageRenderer = (cardImg ) => {
    // const [numPages, setNumPages] = useState(null);
    // const onDocumentLoadSuccess = ({ numPages }) => {
    //   setNumPages(numPages);
    // };

    const isPDF = cardImg?.endsWith(".pdf");

    if (isPDF) {
      return (
        <div className="flex justify-center items-center">
        <Document file={cardImg} >
          <Page pageNumber={1} width={180} />  
        </Document>
        </div>  
      );
    }
   
    return (
      <img
        src={cardImg}
        alt=""
        className="w-[14rem] h-[12rem] shadow-x-xl shadow-t-xl ml-[1.5rem]"
      />
    );
  };

  const handleDeleteDocuments = (doc_id) => {
    deleteDocuments(user?.business_id, doc_id);
  };

  const [visibleStates, setVisibleStates] = useState({});

  const toggleVisibility = (id) => {
    setVisibleStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const [openPopoverIndex, setOpenPopoverIndex] = useState(null); // Track which popover is open
  const popoverRef = useRef(null);

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setOpenPopoverIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option, index) => {
    // console.log(`Option '${option}' selected for row ${index}`);
    setOpenPopoverIndex(null); // Close the popover after selection
  };

  const [openAddDocumentDialog, setOpenAddDocumentDialog] = useState(false);
  const [docName, setDocName] = useState("");
  const [uniqueNo, setUniqueNo] = useState("");
  const [validUpto, setValidUpto] = useState("");
  const [dateOfReg, setDateOfReg] = useState("");
  const [docImg, setDocImg] = useState("");

  // edit states
  const [openEditDocumentDialog, setOpenEditDocumentDialog] = useState(false);
  const [EditdocName, setEditDocName] = useState("");
  const [EdituniqueNo, setEditUniqueNo] = useState("");
  const [EditvalidUpto, setEditValidUpto] = useState("");
  const [EditdateOfReg, setEditDateOfReg] = useState("");
  const [EditdocImg, setEditDocImg] = useState("");

  useEffect(() => {
    setDocName("");
    setUniqueNo("");
    setValidUpto("");
    setDateOfReg("");
    setDocImg("");
  }, [openAddDocumentDialog]);

  const handleAddDocument = () => {
    // setOpenAddDocumentDialog(true);
    const formData = new FormData();
    formData.append("document_name", docName);
    formData.append("unique_number", uniqueNo);
    formData.append("valid_upto", validUpto);
    formData.append("date_of_registration", dateOfReg);
    formData.append("document", docImg);
    postDocuments(user?.business_id, formData);
  };
  const [documentId, setDocumentId] = useState("");

  const handleEditDocument = () => {
    // setOpenEditDocumentDialog(true);
    const formData = new FormData();
    formData.append("document_name", EditdocName);
    formData.append("unique_number", EdituniqueNo);
    formData.append("valid_upto", EditvalidUpto);
    formData.append("date_of_registration", EditdateOfReg);

    if (EditdocImg) {
      if (typeof EditdocImg === "string") {
        console.log("Taking old document");
      } else {
        // If the image is already a File object
        formData.append("document", EditdocImg);
      }
    }
    patchDocuments(formData, user?.business_id, documentId);
  };

  const handleCloseAddDocDialog = () => {
    setOpenAddDocumentDialog(false);
  };
  const handleCloseEditDocDialog = () => {
    setOpenEditDocumentDialog(false);
  };

  return (
    <>
      {getBusinessDocIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="bg-gray-100 pb-[2rem] pt-[5.2rem]  px-3">
        <div className="border border-[#E2E8F0] rounded">
          <div className="flex justify-between items-center px-[1rem] py-[0.8rem] bg-[#FAF8FC]">
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Government IDs
            </h4>
            <button
              onClick={() => setOpenAddDocumentDialog(true)}
              // onClick={() => {
              //   setIsAddingAccount(true);
              //   handleAddAccount();
              // }}
              className=" border-1 border-[#9065B4] text-[#9065B4] rounded py-[0.4rem] px-[1rem] font-[500] text-[0.9rem]"
            >
              Add Document
            </button>
          </div>
          {getBusinessDocData?.data?.length === 0 ? (
            // when no ID is added
            <div className="md:bg-white md:px-3 py-3 rounded ">
              <div className="text-center py-[2rem]">
                <h4 className="font-[400] text-[1rem] text-darkGray">
                  No Government ID Added!
                </h4>
                <h6 className="font-[400] text-[0.9rem] text-lightGray">
                  Please add a govt. Id to see here
                </h6>
              </div>
            </div>
          ) : (
            <div className="bg-white p-[1rem]">
              <div className="grid grid-cols-2 gap-[1rem]">
                {getBusinessDocData?.data?.map((item, index) => (
                  <div
                    className="bg-[#F1F5F9] border rounded-md px-[1rem] pt-[1rem]"
                    key={index}
                  >
                    <h4 className="text-[0.9rem] font-[500] m-0">
                      {item.document_name} -{" "}
                      {visibleStates[item.id] ? item.unique_number : "XXXXX"}
                    </h4>
                    <div className="grid grid-cols-2 mt-[1rem]">
                      {/* <img
                        src={item.document}
                        alt=""
                        className="w-[11rem] h-[9rem] ml-[1.5rem] shadow-xl"
                      /> */}
                      {docImageRenderer(item.document)}
                      {/* <DocImageRenderer cardImg={item.document} /> */}
                      <div className="flex justify-end items-end gap-[0.5rem] mb-[0.5rem]">
                        <button onClick={() => toggleVisibility(item.id)}>
                          {visibleStates[item.id] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="w-5 h-5 text-gray-600"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4.5C7.755 4.5 4.07 7.32 2.5 12c1.57 4.68 5.255 7.5 9.5 7.5s7.93-2.82 9.5-7.5c-1.57-4.68-5.255-7.5-9.5-7.5zm0 10.5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
                              />
                            </svg>
                          ) : (
                            <img src={closedEye} alt="closedEye" className="" />
                          )}
                        </button>
                        <button onClick={() => handleDeleteDocuments(item.id)}>
                          <img
                            src={deleteIcon}
                            alt=""
                            className="w-[1.3rem] h-[1.3rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="text-[0.9rem] text-lightGray mt-[1rem]">
                All Documents
              </h4>
              {/* table */}
              <div className="rounded mt-[1rem]">
                <table className="w-full text-left">
                  <thead className="bg-[#F8FAFC] rounded-md text-lightGray text-[0.9rem] font-medium">
                    <tr>
                      <th className="px-2 py-2">Name</th>
                      <th className="px-2 py-2">Registration Date</th>
                      <th className="px-4 py-2">Valid upto</th>
                      <th className="px-4 py-2">Unique No</th>
                      <th className="px-4 py-2">Date & Time Created</th>
                      <th className="px-4 py-2">Download</th>
                      {/* <th className="px-4 py-2">Share</th>
                      <th className="px-4 py-2">Delete</th> */}
                    </tr>
                  </thead>
                  <tbody className="text-[0.9rem] text-darkGray">
                    {getBusinessDocData?.data?.map((doc, index) => (
                      <tr key={index} className={`border-b `}>
                        <td className="px-2 py-2 ">{doc.document_name}</td>
                        <td className="px-3 py-2">
                          {doc.date_of_registration}
                        </td>
                        <td className="px-3 py-2">{doc.valid_upto}</td>
                        <td className="px-2 py-2">{doc.unique_number}</td>
                        <td className="px-4 py-2">{doc.updated_at}</td>
                        <td className="px-4 py-2 flex gap-[1rem] items-center">
                          <button
                            onClick={() => {
                              if (doc.document !== "") {
                                window.open(doc.document, "_blank");
                              } else {
                                showErrorToast("Document not present");
                              }
                            }}
                          >
                            <img
                              src={downloadIcon}
                              alt=""
                              className="w-[1rem] h-[1rem] ml-[0.7rem]"
                            />
                          </button>
                          <div className="relative inline-block">
                            <button
                              className=""
                              onClick={() =>
                                setOpenPopoverIndex(
                                  openPopoverIndex === index ? null : index
                                )
                              } // Toggle the popover for the specific index
                            >
                              <IoEllipsisVertical color="#64748B" />
                            </button>

                            {openPopoverIndex === index && (
                              <div
                                ref={popoverRef}
                                className="px-[0.8rem] py-[0.2rem] absolute right-2 w-[6rem] top-0 bg-white border border-gray-200 rounded-lg shadow-lg"
                              >
                                <button
                                  className="py-[0.3rem] pr-[2rem] mb-[0.1rem] text-[0.8rem] text-darkGray font-[500] cursor-pointer"
                                  onClick={() => {
                                    setOpenEditDocumentDialog(true);
                                    setDocumentId(doc.id);
                                    setEditDocName(doc.document_name);
                                    setEditUniqueNo(doc.unique_number);
                                    setEditValidUpto(doc.valid_upto);
                                    setEditDateOfReg(doc.date_of_registration);
                                    setEditDocImg(doc.document);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="py-[0.3rem] pr-[2rem] mb-[0.1rem] text-[0.8rem] text-darkGray font-[500] cursor-pointer"
                                  onClick={() =>
                                    handleOptionClick("Share", index)
                                  }
                                >
                                  Share
                                </button>
                                <button
                                  className="py-[0.3rem] pr-[2rem] text-[0.8rem] text-darkGray font-[500] cursor-pointer"
                                  // onClick={() => handleOptionClick("Delete", index)}
                                  onClick={() => handleDeleteDocuments(doc.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <BackToBusinessDashboard />
      </div>
      {openAddDocumentDialog && (
        <BusinessAddDocument
          handleAddDocument={handleAddDocument}
          openAddDocumentDialog={openAddDocumentDialog}
          handleCloseAddDocDialog={handleCloseAddDocDialog}
          docName={docName}
          setDocName={setDocName}
          uniqueNo={uniqueNo}
          setUniqueNo={setUniqueNo}
          validUpto={validUpto}
          setValidUpto={setValidUpto}
          dateOfReg={dateOfReg}
          setDateOfReg={setDateOfReg}
          setDocImg={setDocImg}
          docImg={docImg}
        />
      )}
      {openEditDocumentDialog && (
        <BusinessEditDocument
          handleAddDocument={handleEditDocument}
          openAddDocumentDialog={openEditDocumentDialog}
          handleCloseAddDocDialog={handleCloseEditDocDialog}
          docName={EditdocName}
          setDocName={setEditDocName}
          uniqueNo={EdituniqueNo}
          setUniqueNo={setEditUniqueNo}
          validUpto={EditvalidUpto}
          setValidUpto={setEditValidUpto}
          dateOfReg={EditdateOfReg}
          setDateOfReg={setEditDateOfReg}
          setDocImg={setEditDocImg}
          docImg={EditdocImg}
          documentId={EditdocImg}
        />
      )}
    </>
  );
};

export default BusinessDocuments;
