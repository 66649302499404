import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import manageCategory from "assets/images/moneyManager/manageCategory.svg";
import newest from "assets/images/moneyManager/newest.svg";
import uncategorized from "assets/images/moneyManager/uncategorized.svg";
import search from "assets/images/moneyManager/search.svg";
import check from "assets/images/moneyManager/check.svg";
import arrow from "assets/images/navbar/down-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL,
  MONEY_MANAGER_TRANSACTIONS_URL,
} from "constants/routes";
import AddTransaction from "./AddTransaction";
import usePostTransactions from "../data/usePostTransactions";
import { showSuccessToast } from "components/toaster/toastHelper";
import useGetTransactionsList from "../data/useGetTransactionsList";
import useGetCategoriesList from "../data/useGetCategoriesList";
import useGetAccountsList from "../data/useGetListAccounts";
import EditTransaction from "./EditTransaction";
import usePatchTransaction from "../data/usePatchEditTransaction";
import editIcon from "assets/images/moneyManager/pencil.svg";
import { format } from "date-fns";
import { DateRange, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main styles
import "react-date-range/dist/theme/default.css"; // Theme styles
import usePostCategories from "../data/usePostCategories";
import CreateNewCategory from "./CreateNewCategory";
// import { formatDate } from "date-fns";

const TransactionsDashboard = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Assuming you want it to be open initially
  const [isNewestDropdownOpen, setIsNewestDropdownOpen] = useState(false);
  const [selectedNewestOption, setSelectedNewestOption] =
    useState("Newest First");
  const [isAmountDropdownOpen, setIsAmountDropdownOpen] = useState(false);
  const [selectedAmountOption, setSelectedAmountOption] = useState("All");
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState("All");
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [selectedAccountOption, setSelectedAccountOption] = useState("All");
  const [categoryToEdit, setCategoryToEdit]=useState(null)
  const [id, setId] = useState("");

  // main filter
  const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false);
  const [selectedMainOption, setSelectedMainOption] = useState("All time");

  const [isAddCategoryDropdownOpen, setIsAddCategoryDropdownOpen] =
    useState(false);
  const [selectedAddCategoryOption, setSelectedAddCategoryOption] =
    useState("Add Category");

  const [account, setaccount] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [dateOfTran, setDateOfTran] = useState("");
  const [amountType, setAmountType] = useState("Expense");
  const [category, setcategory] = useState("");
  const [isToggled, setIsToggled] = useState(false); //for recurring
  const [isCreateMoreToggled, setIsCreateMoreToggled] = useState(false);
  const [isCreateMoreToggledCategory, setIsCreateMoreToggledCategory]= useState(false)
  const [frequency, setFrequency] = useState("");
  const [dateOfRecc, setDateOfRecc] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  // for edit states
  const [accountEdit, setaccountEdit] = useState("");
  const [amountEdit, setAmountEdit] = useState("");
  const [descEdit, setDescEdit] = useState("");
  const [dateOfTranEdit, setDateOfTranEdit] = useState("");
  const [amountTypeEdit, setAmountTypeEdit] = useState("Expense");
  const [categoryEdit, setcategoryEdit] = useState("");
  const [isToggledEdit, setIsToggledEdit] = useState(false); //for recurring
  const [frequencyEdit, setFrequencyEdit] = useState("");
  const [dateOfReccEdit, setDateOfReccEdit] = useState("");

  // add category
  const [isUncategorizedClicked, setIsUncategorizedClicked] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const toggleUncategorized = () => {
    setIsUncategorizedClicked(!isUncategorizedClicked);
  };

  const [postData, postIsError, postIsLoading, postTransactions] =
    usePostTransactions();
  const [getData, getIsError, getIsLoading, getTransactionsList] =
    useGetTransactionsList();
  const [
    getCategoryData,
    getCategoryIsError,
    getCategoryIsLoading,
    getCategoriesList,
  ] = useGetCategoriesList();
  const [
    getAccountData,
    getAccountIsError,
    getAccountIsLoading,
    getAccountsList,
  ] = useGetAccountsList();

  useEffect(() => {
    getCategoriesList();
    getAccountsList();
    // getTransactionsList(`?filter_type=${"all_time"}`);
  }, []);

  const location = useLocation();
  const dataFromCashFlow = location.state;

  useEffect(() => {
    const formatOption = (option) =>
      option?.toLowerCase().replace(/\s+/g, "_") || ""; // Helper function to format options

    // Initialize filter options with defaults or from location.state
    const formattedAmountOption = dataFromCashFlow?.amount_type
      ? dataFromCashFlow.amount_type
      : selectedAmountOption.toLowerCase() !== "all"
      ? selectedAmountOption
      : "";
    const formattedCategoryOption = dataFromCashFlow?.category_name
      ? formatOption(dataFromCashFlow.category_name)
      : selectedCategoryOption.toLowerCase() !== "all"
      ? formatOption(selectedCategoryOption)
      : "";
    const formattedAccountOption = dataFromCashFlow?.account_name
      ? formatOption(dataFromCashFlow.account_name)
      : selectedAccountOption.toLowerCase() !== "all"
      ? formatOption(selectedAccountOption)
      : "";

    // const formattedMainOption = formatOption(selectedMainOption);
    const formattedMainOption = dataFromCashFlow
      ? "date_range"
      : formatOption(selectedMainOption);
    const formattedNewestOption = selectedNewestOption
      ? selectedNewestOption.split(" ")[0].toLowerCase() // Extract the first word and convert to lowercase
      : "";

    // Use dates from dataFromCashFlow if provided
    const formatDate = (date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;

    const startDate = dataFromCashFlow?.start_date
      ? dataFromCashFlow.start_date
      : dateRange[0]
      ? formatDate(dateRange[0])
      : null;
    const endDate = dataFromCashFlow?.end_date
      ? dataFromCashFlow.end_date
      : dateRange[1]
      ? formatDate(dateRange[1])
      : null;

    let params = [];

    if (formattedMainOption === "date_range") {
      params = [
        formattedMainOption && `?filter_type=${formattedMainOption}`,
        formattedAmountOption && `amount_type=${formattedAmountOption}`,
        formattedAccountOption && `account_name=${formattedAccountOption}`,
        startDate && `start_date=${startDate}`,
        endDate && `end_date=${endDate}`,
      ];
    } else {
      params = [
        formattedMainOption && `?filter_type=${formattedMainOption}`,
        formattedNewestOption && `sort_order=${formattedNewestOption}`,
        formattedAmountOption && `amount_type=${formattedAmountOption}`,
        formattedCategoryOption && `category_name=${formattedCategoryOption}`,
        formattedAccountOption && `account_name=${formattedAccountOption}`,
      ];
    }

    // Construct the query string and fetch the data
    const queryString = params.filter(Boolean).join("&");
    getTransactionsList(queryString);
  }, [
    selectedMainOption,
    selectedNewestOption,
    selectedAmountOption,
    selectedCategoryOption,
    selectedAccountOption,
    dateRange, // Include dateRange in dependencies
    dataFromCashFlow, // Add dataFromCashFlow to dependencies
  ]);
  
  useEffect(() => {
    if (postData) {
      showSuccessToast("Transaction Created Successfully");
      if (!isCreateMoreToggled) {
        setIsOpen(false);
      }
      navigate(MONEY_MANAGER_TRANSACTIONS_URL);
      getTransactionsList("");
      setaccount("");
      setAmount("");
      setDesc("");
      setDateOfTran("");
      setAmountType("Expense");
      setcategory("");
      setIsToggled(false);
      setIsCreateMoreToggled(false);
      getTransactionsList(`?filter_type=${"all_time"}`);
    }
  }, [postData]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleAddTransaction = () => {
    setIsOpen(true);
  };

  const handleEditTransaction = (
    id,
    account,
    amount,
    desc,
    dateOfTrans,
    categoryId,
    amountType,
    frequency,
    dateOfRecc,
    recurring,
    category
  ) => {
    setIsEditOpen(true);
    setIsEditClicked(true);
    setId(id);
    setaccountEdit(account);
    setAmountEdit(amount);
    setDescEdit(desc);
    setDateOfTranEdit(dateOfTrans);
    setcategoryEdit(categoryId);
    setAmountTypeEdit(amountType);
    setFrequencyEdit(frequency);
    setDateOfReccEdit(dateOfRecc);
    setIsToggledEdit(recurring);
    setCategoryToEdit(category)
  };
  const [patchData, patchIsError, patchIsLoading, patchTransactions] =
    usePatchTransaction();

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details Updated");
      setIsEditClicked(false);
      setIsEditOpen(false);
      setaccount("");
      setAmount("");
      setDesc("");
      setDateOfTran("");
      setAmountType("Expense");
      setcategory("");
      setIsToggled(false);
      setIsCreateMoreToggled(false);
      getTransactionsList(`?filter_type=${"all_time"}`);
    }
  }, [patchData]);

  const handleEditTransactionDetails = () => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("account", accountEdit);
    formData.append("amount", amountEdit);
    formData.append("description", descEdit);
    formData.append("date_of_transaction", dateOfTranEdit);
    formData.append("amount_type", amountTypeEdit);
    formData.append("categeory", categoryEdit);
    formData.append("reccuring_transaction", isToggledEdit);
    if (isToggledEdit === true) {
      formData.append("frequency", frequencyEdit);
      formData.append("date_of_reccuring", dateOfReccEdit);
    }
    patchTransactions(formData);
  };
  const closeEditModel = () => {
    setIsEditClicked(false);
    setIsEditOpen(false);
  };

  const handleGoToDashboard = () => {
    const formData = new FormData();
    formData.append("account", account);
    formData.append("amount", amount);
    formData.append("description", desc);
    formData.append("date_of_transaction", dateOfTran);
    formData.append("amount_type", amountType);
    formData.append("categeory", category);
    formData.append("reccuring_transaction", isToggled);
    if (isToggled === true) {
      formData.append("frequency", frequency);
      formData.append("date_of_reccuring", dateOfRecc);
    }
    postTransactions(formData);
  };

  // newest
  const handleButtonClick = () => {
    setIsNewestDropdownOpen(!isNewestDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedNewestOption(option);
    setIsNewestDropdownOpen(false); // Close dropdown after selection
  };

  // amount
  const handleAmountClick = () => {
    setIsAmountDropdownOpen(!isAmountDropdownOpen);
  };

  const handleAmountOptionClick = (option) => {
    setSelectedAmountOption(option);
    setIsAmountDropdownOpen(false); // Close dropdown after selection
  };

  // category
  const handleCategoryClick = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const handleCategoryOptionClick = (option) => {
    setSelectedCategoryOption(option);
    setIsCategoryDropdownOpen(false); // Close dropdown after selection
  };

  // account
  const handleAccountClick = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
  };

  const handleAccountOptionClick = (option) => {
    setSelectedAccountOption(option);
    setIsAccountDropdownOpen(false); // Close dropdown after selection
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // main filter
  const handleMainClick = () => {
    setIsMainDropdownOpen(!isMainDropdownOpen);
    setIsCalendarOpen(false); // Close calendar if open
  };

  const handleMainOptionClick = (option) => {
    setSelectedMainOption(option);
    setIsMainDropdownOpen(false);
    if (option === "Date Range") {
      setIsCalendarOpen(true); // Open calendar for date range
    } else {
      setIsCalendarOpen(false);
    }
  };

  // const handleDateChange = (ranges) => {
  //   const { startDate, endDate } = ranges.selection;

  //   // Validate that startDate and endDate are Date objects
  //   setDateRange([
  //     startDate instanceof Date ? startDate : new Date(),
  //     endDate instanceof Date ? endDate : new Date(),
  //   ]);
  //   // setIsCalendarOpen(false);
  // };

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection.startDate, ranges.selection.endDate]);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Converts the search query to lowercase for case-insensitive matching
  };

  const [openCategoryDropdownId, setOpenCategoryDropdownId] = useState(null); // Track which row's dropdown is open
  const [categories, setCategories] = useState({}); // Store categories for each row
  const [xopen, setXopen] = useState(false); // Handle dropdown visibility
  const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(false); // Track if the user is in "Create New+" mode
  const [newCategoryName, setNewCategoryName] = useState(""); // Track new category name input
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedAmountType, setSelectedAmountType] = useState(null);
  const [postCategoryData, categoryIsError, categoryIsLoading, postCategory] =
    usePostCategories();

  const [openCategoryDialogBox, setOpenCategoryDialogBox] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const handleOpen = () => {
    setOpenCategoryDialogBox(true);
  };
  const handleClose = () => {
    setOpenCategoryDialogBox(false);
  };
  const handlePostnewCategory = () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    postCategory(formData);
  };

  useEffect(() => {
    if (postCategoryData) {
      showSuccessToast("Category Added");
      if(!isCreateMoreToggledCategory){
        setOpenCategoryDialogBox(false);
      }
      // setTimeout(() => {
      //   edit();
      // }, 5000);
      setXopen(false)
      getCategoriesList();
      setIsCreateMoreToggledCategory(false)
    }
  }, [postCategoryData]);

  const editCategoryPresent = (id, category, account_id, amount_type, amount) => {
    const formDataTransaction = new FormData();
    formDataTransaction.append("categeory", category);
    formDataTransaction.append("id", id); //for transaction patch
    formDataTransaction.append("account", account_id);
    formDataTransaction.append("amount_type", amount_type);
    formDataTransaction.append("amount", amount)
    patchTransactions(formDataTransaction);
  };

  const handleCategoryChange = (
    category,
    itemId,
    account_id,
    categoryId,
    amount_type,
    amount
  ) => {
    if (category === "Create New+") {
      setIsCreatingNewCategory(true); // Enable the "Create New+" mode
      setSelectedItemId(itemId);
      setSelectedAccountId(account_id);
      setSelectedAmountType(amount_type);
      return; // Do not close the dialog box yet
    }

    setCategories((prev) => ({
      ...prev,
      [itemId]: category, // Set category for the specific row by item id
    }));
    setSelectedAccountId(account_id);
    setSelectedAmountType(amount_type);
    editCategoryPresent(itemId, categoryId, account_id, amount_type, amount);
    setOpenCategoryDropdownId(null); // Close dropdown after category is selected
    setXopen(false); // Close the dropdown
    setIsCreatingNewCategory(false); // Exit "Create New+" mode
  };

  // Handle input change for new category
  const handleNewCategoryInputChange = (e) => {
    setNewCategoryName(e.target.value);
  };

  // Handle submit for new category
  const handleSubmitNewCategory = () => {
    if (newCategoryName.trim()) {
      setCategories((prev) => ({
        ...prev,
        [openCategoryDropdownId]: newCategoryName, // Set new category for the specific row
      }));
      const formData = new FormData();
      formData.append("name", newCategoryName);
      postCategory(formData);

      setIsCreatingNewCategory(false); // Exit "Create New+" mode
      setNewCategoryName(""); // Clear the input field
    }
  };

  const edit = () => {
    const formDataTransaction = new FormData();
    formDataTransaction.append("categeory", postCategoryData?.data?.id);
    formDataTransaction.append("id", selectedItemId); //for transaction patch
    formDataTransaction.append("account", selectedAccountId);
    formDataTransaction.append("amount_type", selectedAmountType);
    patchTransactions(formDataTransaction);
  };

  // Handle key press in the input field (Enter key)
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmitNewCategory(); // Submit category when Enter is pressed

      // Set a timer for 3 seconds before calling edit()
    }
  };

  const optionClicked = (id) => {
    setOpenCategoryDropdownId(id);
    setXopen(!xopen); // Toggle dropdown visibility
    setIsCreatingNewCategory(false); // Reset "Create New+" mode
  };

  return (
    <>
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100">
        <h4 className="  text-darkGray text-[1.4rem] m-0">Transactions</h4>

        <div className="bg-white rounded-lg p-[1rem] mt-[1.5rem] border">
          {/* buttons */}
          <div className="flex justify-between items-center mb-[1rem]">
            <div className="flex items-center gap-[0.5rem]">
              <div className="relative">
                <button
                  className="flex gap-1 items-center justify-center font-[600] border rounded-md w-[7rem] text-darkGray text-[0.8rem] py-[0.3rem] px-[0.4rem]"
                  onClick={handleMainClick}
                >
                  {/* <span className="flex gap-1 item-center"> */}
                  {selectedMainOption}

                  <img
                    src={arrow}
                    className={`transition-transform ${
                      isMainDropdownOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                    }`}
                    alt="Arrow"
                  />
                  {/* </span> */}
                </button>
                {isMainDropdownOpen && (
                  <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10 w-[100%] ">
                    <div
                      onClick={() => handleMainOptionClick("All time")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "All time"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      All time
                      {selectedMainOption === "All time" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      onClick={() => handleMainOptionClick("This Week")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "This Week"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      This Week
                      {selectedMainOption === "This Week" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      onClick={() => handleMainOptionClick("This Month")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "This Month"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      This Month
                      {selectedMainOption === "This Month" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      onClick={() => handleMainOptionClick("This Year")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "This Year"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      This Year
                      {selectedMainOption === "This Year" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      onClick={() => handleMainOptionClick("Date Range")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "Date Range"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      Date Range
                      {selectedMainOption === "Date Range" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      onClick={() => handleMainOptionClick("Recurring")}
                      className={`text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                   ${
                     selectedMainOption === "Recurring"
                       ? "text-darkGray  flex gap-1"
                       : "text-lightGray"
                   }
                 `}
                    >
                      Recurring
                      {selectedMainOption === "Recurring" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {/* Calendar for Date Range */}

                {isCalendarOpen && (
                  <div className="absolute ml-[10rem] mt-[4rem] z-10">
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={[
                        {
                          startDate: dateRange[0],
                          endDate: dateRange[1],
                          key: "selection",
                        },
                      ]}
                      rangeColors={["#9065B4"]} // Customize selection color
                      className="custom-calendar"
                      shownDate={
                        dateRange[0] instanceof Date ? dateRange[0] : new Date()
                      }
                    />
                    {/* OK Button */}
                    <div className="flex justify-end mt-2">
                      <button
                        className="text-[0.8rem] px-[0.5rem] py-[0.2rem] bg-[#9065B4] text-white rounded-md"
                        onClick={() => {
                          setIsCalendarOpen(false); // Close the calendar
                        }}
                      >
                        Set
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <h4 className="text-[0.8rem] text-customPurple p-1 m-0 rounded-md bg-[#FAF8FC]">
                {getData?.data?.total_transactions || 0} Transactions
              </h4>
              <h4 className="text-[0.8rem] text-[#15803D] p-1 m-0 rounded-md bg-[#F0FDF4]">
                +{getData?.data?.total_income} Rs.
              </h4>
              <h4 className="text-[0.8rem] text-[#DC2626] p-1 m-0 rounded-md bg-[#FEF2F2]">
                -{getData?.data?.total_expenses} Rs.
              </h4>
            </div>
            <div className="flex gap-[1rem]">
              <button
                onClick={() =>
                  navigate(MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL)
                }
                className="flex gap-1 items-center text-darkGray text-[0.8rem] border rounded-md font-[600] px-[0.9rem] py-[0.3rem]"
              >
                <img
                  src={manageCategory}
                  alt=""
                  className="w-[1rem] h-[1rem]"
                />
                Manage Categories
              </button>
              <AddTransaction
                getCategoriesList={getCategoriesList}
                frequency={frequency}
                setFrequency={setFrequency}
                dateOfRecc={dateOfRecc}
                setDateOfRecc={setDateOfRecc}
                isCreateMoreToggled={isCreateMoreToggled}
                setIsCreateMoreToggled={setIsCreateMoreToggled}
                account={account}
                setaccount={setaccount}
                amount={amount}
                setAmount={setAmount}
                desc={desc}
                setDesc={setDesc}
                dateOfTran={dateOfTran}
                setDateOfTran={setDateOfTran}
                category={category}
                setcategory={setcategory}
                getCategoryData={getCategoryData}
                getAccountData={getAccountData}
                amountType={amountType}
                setAmountType={setAmountType}
                isToggled={isToggled}
                setIsToggled={setIsToggled}
                isOpen={isOpen}
                handleAddTransaction={handleAddTransaction}
                handleGoToDashboard={handleGoToDashboard}
                closeModal={closeModal}
              />
            </div>
          </div>

          <div className="flex justify-between mb-[1rem]">
            <div className="flex gap-[1rem]">
              <div className="relative">
                <button
                  onClick={handleButtonClick}
                  className="flex gap-1 items-center text-darkGray text-[0.8rem] border rounded-md font-[600] px-[0.9rem] py-[0.3rem]"
                >
                  <img src={newest} alt="" className="w-[1rem] h-[1rem]" />
                  {selectedNewestOption}
                </button>

                {isNewestDropdownOpen && (
                  <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10">
                    <div
                      onClick={() => handleOptionClick("Newest First")}
                      className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                      ${
                        selectedNewestOption === "Newest First"
                          ? "text-darkGray  flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                    >
                      Newest First
                      {selectedNewestOption === "Newest First" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      onClick={() => handleOptionClick("Oldest First")}
                      className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                      ${
                        selectedNewestOption === "Oldest First"
                          ? "text-darkGray flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                    >
                      Oldest First
                      {selectedNewestOption === "Oldest First" ? (
                        <img src={check} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
              {getData?.data?.data?.some((item) => item.categeory === null) && (
                <button
                  onClick={toggleUncategorized}
                  className={`${
                    isUncategorizedClicked
                      ? "border-1 border-darkGray bg-[#F1F5F9]"
                      : "border"
                  } flex gap-1 items-center text-darkGray text-[0.8rem] rounded-md font-[600] px-[0.9rem] py-[0.3rem]`}
                >
                  <img
                    src={uncategorized}
                    alt=""
                    className="w-[1rem] h-[1rem]"
                  />
                  Uncategorised
                </button>
              )}
            </div>
            {/* search */}
            <div className="bg-[#F8FAFC] border-1 border-[#D9D9D9] rounded-md flex items-center gap-1">
              <span className=" py-[0.3rem] px-[0.3rem]">
                <img src={search} alt="" className="w-[1rem] h-[1rem]" />{" "}
              </span>
              <div>
                <InputField
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search Transactions"
                  className="bg-[#F8FAFC] text-lightGray focus:outline-none rounded-md font-[500] text-[0.8rem]  py-[0.3rem]"
                />
              </div>
            </div>
          </div>

          {/* table */}
          <div className="grid grid-cols-12 ">
            <table className="col-span-12 border">
              <tr className="bg-[#F1F5F9] rounded-lg text-lightGray ">
                <th className="py-[1%] px-3 col-span-2  text-[0.8rem] font-[500] ">
                  Date
                </th>
                <th
                  onClick={handleAmountClick}
                  className={`cursor-pointer   px-2  col-span-2 text-[0.8rem] font-[500] 
                  ${isAmountDropdownOpen ? "relative" : ""}`}
                >
                  <span className="flex gap-1 items-center">
                    Amount
                    <img
                      src={arrow}
                      className={`transition-transform ${
                        isAmountDropdownOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                      }`}
                      alt="Arrow"
                    />
                  </span>
                  {isAmountDropdownOpen && (
                    <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10">
                      <div
                        onClick={() => handleAmountOptionClick("All")}
                        className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                      ${
                        selectedAmountOption === "All"
                          ? "text-darkGray  flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                      >
                        All
                        {selectedAmountOption === "All" ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        onClick={() => handleAmountOptionClick("Income")}
                        className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                      ${
                        selectedAmountOption === "Income"
                          ? "text-darkGray flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                      >
                        Income
                        {selectedAmountOption === "Income" ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        onClick={() => handleAmountOptionClick("Expense")}
                        className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                      ${
                        selectedAmountOption === "Expense"
                          ? "text-darkGray flex gap-1"
                          : "text-lightGray"
                      }
                    `}
                      >
                        Expense
                        {selectedAmountOption === "Expense" ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}{" "}
                </th>
                <th className=" px-2 col-span-4 text-[0.8rem] font-[500] ">
                  Description
                </th>
                <th
                  onClick={handleCategoryClick}
                  className={`cursor-pointer   px-2  col-span-2 text-[0.8rem] font-[500] 
                ${isCategoryDropdownOpen ? "relative" : ""}`}
                >
                  <span className="flex gap-1 items-center">
                    Category
                    <img
                      src={arrow}
                      className={`transition-transform ${
                        isCategoryDropdownOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                      }`}
                      alt="Arrow"
                    />
                  </span>
                  {isCategoryDropdownOpen && (
                    <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10">
                      <div
                        onClick={() => handleCategoryOptionClick("All")}
                        className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                    ${
                      selectedCategoryOption === "All"
                        ? "text-darkGray  flex gap-1"
                        : "text-lightGray"
                    }
                  `}
                      >
                        All
                        {selectedCategoryOption === "All" ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </div>

                      {getCategoryData?.data?.map((item) => {
                        return (
                          <div
                            className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                        ${
                          selectedCategoryOption === item.name
                            ? "text-darkGray flex gap-1"
                            : "text-lightGray"
                        }
                      `}
                            onClick={() => handleCategoryOptionClick(item.name)}
                            key={item.id}
                          >
                            {item.name}
                            {selectedCategoryOption === item.name ? (
                              <img src={check} alt="" />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}{" "}
                </th>
                {/* account type */}
                <th
                  onClick={handleAccountClick}
                  className={`cursor-pointer   px-2  col-span-2 text-[0.8rem] font-[500] 
                ${isAccountDropdownOpen ? "relative" : ""}`}
                >
                  <span className="flex gap-1 items-center">
                    Account
                    <img
                      src={arrow}
                      className={`transition-transform ${
                        isAccountDropdownOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                      }`}
                      alt="Arrow"
                    />
                  </span>
                  {isAccountDropdownOpen && (
                    <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10">
                      <div
                        onClick={() => handleAccountOptionClick("All")}
                        className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100 
                    ${
                      selectedAccountOption === "All"
                        ? "text-darkGray  flex gap-1"
                        : "text-lightGray"
                    }
                  `}
                      >
                        All
                        {selectedAccountOption === "All" ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </div>
                      {getAccountData?.data?.results?.map((item) => {
                        return (
                          <div
                            className={`text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer hover:bg-gray-100
                          ${
                            selectedAccountOption === item.account_name
                              ? "text-darkGray flex gap-1"
                              : "text-lightGray"
                          }
                        `}
                            onClick={() =>
                              handleAccountOptionClick(item.account_name)
                            }
                            key={item.id}
                          >
                            {item.account_name}
                            {selectedAccountOption === item.account_name ? (
                              <img src={check} alt="" />
                            ) : (
                              ""
                            )}
                          </div>
                        ); // Ensure there's a unique `key`
                      })}
                    </div>
                  )}{" "}
                </th>
              </tr>

              <tbody className="rounded-b-lg">
                {getData?.data?.data
                  ?.filter((item) => {
                    if (isUncategorizedClicked && item.categeory) {
                      return false;
                    }

                    // Search Filter: Check if any field matches the search query
                    const searchFields = [
                      item.date_of_transaction,
                      item.amount,
                      item.description,
                      item.categeory,
                      item.account,
                    ];

                    // If searchQuery exists, filter based on search
                    if (
                      searchQuery &&
                      !searchFields.some((field) =>
                        field?.toString().toLowerCase().includes(searchQuery)
                      )
                    ) {
                      return false;
                    }

                    return true; // Passes all filters
                  })
                  ?.sort((a, b) => {
                    // Sorting Logic
                    const dateA = new Date(a.date_of_transaction);
                    const dateB = new Date(b.date_of_transaction);

                    if (selectedNewestOption === "Newest First") {
                      return dateB - dateA; // Newest first
                    } else if (selectedNewestOption === "Oldest First") {
                      return dateA - dateB; // Oldest first
                    }
                    return 0; // Default (no sorting)
                  })
                  ?.map((item) => {
                    return (
                      <tr
                        key={item.id}
                        className="relative group hover:bg-gray-100"
                      >
                        <td className="px-3 py-2 p-0 border-t font-[500] text-[0.9rem] text-lightGray">
                          {item.date_of_transaction}
                        </td>
                        <td
                          className={`px-2 p-0 border-t font-[500] text-[0.9rem] ${
                            item.amount_type === "Expense"
                              ? "text-red-500"
                              : "text-green-800"
                          }`}
                        >
                          {item.amount || 0}
                        </td>
                        <td className="px-2 p-0 border-t font-[500] text-[0.9rem] text-darkGray">
                          {item.description}
                        </td>
                        <td className="px-2 p-0 border-t font-[500] text-[0.8rem] text-darkGray ">
                          <span className="relative bg-[#E2E8F0] rounded-md px-[0.2rem] py-[0.1rem]">
                            {" "}
                            {item.categeory ? (
                              item.categeory
                            ) : (
                              <>
                                <button
                                  className="font-[500] text-[0.8rem] text-darkGray"
                                  onClick={() => optionClicked(item.id)} // Open dropdown for the selected row
                                >
                                  {categories[item.id] || "Add category"}
                                </button>
                                {openCategoryDropdownId === item.id &&
                                  xopen && (
                                    <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10 w-[7.5rem]">
                                      <div
                                        onClick={handleOpen}
                                        // onClick={() =>
                                        //   handleCategoryChange(
                                        //     "Create New+",
                                        //     item.id, item.account_id, null, item.amount_type
                                        //   )
                                        // }
                                        className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem]  cursor-pointer "
                                      >
                                        Create New +
                                        {/* {isCreatingNewCategory ? (
                                      <div className="flex">
                                        <InputField
                                          onKeyDown={handleKeyPress}
                                          type="text"
                                          value={newCategoryName}
                                          onChange={
                                            handleNewCategoryInputChange
                                          }
                                          className="font-[500] text-lightGray py-[0.2rem] text-[0.8rem] w-[100%] focus:outline-none"
                                          placeholder="Create New"
                                        />
                                      </div>
                                    ) : (
                                      "Create New  +"
                                    )} */}
                                      </div>
                                      <hr className="m-0" />
                                      {getCategoryData?.data?.map(
                                        (category) => (
                                          <button
                                            key={category.id}
                                            className={`text-[0.8rem] font-[600] px-[0.9rem] flex flex-col py-[0.3rem] cursor-pointer 
                                        ${
                                          categories[item.id] === category.name
                                            ? "text-darkGray flex gap-1 "
                                            : "text-lightGray"
                                        }
                                        `}
                                            onClick={() =>
                                              handleCategoryChange(
                                                category.name,
                                                item.id,
                                                item.account_id,
                                                category.id,
                                                item.amount_type,
                                                item.amount
                                              )
                                            }
                                          >
                                            {category.name}
                                            {categories[item.id] ===
                                            category.name ? (
                                              <img src={check} alt="" />
                                            ) : (
                                              ""
                                            )}
                                          </button>
                                        )
                                      )}
                                    </div>
                                  )}
                              </>
                            )}
                          </span>
                        </td>
                        <td className="px-2 p-0 border-t font-[500] text-[0.9rem] text-lightGray">
                          {item.account}
                          <div className="absolute right-4 top-1/2 transform -translate-y-1/2 hidden group-hover:flex gap-2">
                            <button
                              onClick={() =>
                                handleEditTransaction(
                                  item.id,
                                  item.account_id,
                                  item.amount,
                                  item.description,
                                  item.date_of_transaction,
                                  // item.categeory,
                                  item.category_id,
                                  item.amount_type,
                                  item.frequency,
                                  item.date_of_reccuring,
                                  item.reccuring_transaction,
                                  item.categeory
                                )
                              }
                              className="text-[0.8rem] px-2 py-1"
                            >
                              <img src={editIcon} alt="" />
                            </button>

                            {/* <button
                            onClick={() => deleteTransaction(item.id)}
                            className="text-[0.8rem] px-2 py-1 rounded"
                          >
                            <img src={deleteIcon} alt="" />
                          </button> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {isEditClicked && (
              <EditTransaction
                getCategoriesList={getCategoriesList}
                isOpen={isEditOpen}
                closeModal={closeEditModel}
                handleEditTransactionDetails={handleEditTransactionDetails}
                account={accountEdit}
                setaccount={setaccountEdit}
                amount={amountEdit}
                setAmount={setAmountEdit}
                desc={descEdit}
                setDesc={setDescEdit}
                dateOfTran={dateOfTranEdit}
                setDateOfTran={setDateOfTranEdit}
                category={categoryEdit}
                setcategory={setcategoryEdit}
                getCategoryData={getCategoryData}
                getAccountData={getAccountData}
                amountType={amountTypeEdit}
                setAmountType={setAmountTypeEdit}
                isToggled={isToggledEdit}
                setIsToggled={setIsToggledEdit}
                frequency={frequencyEdit}
                setFrequency={setFrequencyEdit}
                dateOfRecc={dateOfReccEdit}
                setDateOfRecc={setDateOfReccEdit}
                categoryToEdit={categoryToEdit}
              />
            )}

            {openCategoryDialogBox && (
              <CreateNewCategory
                name={categoryName}
                setName={setCategoryName}
                isOpen={openCategoryDialogBox} 
                // handleCreateNewCategory={handleCreateNewCategory}
                handleGoToCategories={handlePostnewCategory}
                closeModal={handleClose}
                isCreateMoreToggledCategory={isCreateMoreToggledCategory}
                setIsCreateMoreToggledCategory={setIsCreateMoreToggledCategory}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsDashboard;
