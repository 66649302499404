import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfDay,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaEllipsisV } from "react-icons/fa"; // For DotsVerticalIcon
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"; // For ChevronLeftIcon and ChevronRightIcon
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import user from "assets/images/BusinessDashboard/BusinessEmployee/user.svg";
import usePostLeaveOverview from "./data/usePostLeaveOverview";

const leaves = [
  { name: "Nethaji", startDate: "2024-12-12", endDate: "2024-12-15" },
  { name: "Ajji", startDate: "2024-12-14", endDate: "2024-12-15" },
];

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomCalendar({
  leaveData,
  getEmployeeOverview,
  setDateToGet,
}) {
  let today = startOfToday();
  let [leaveDataEmployees, setLeaveDataEmployees] = useState({});
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  const [postLeaveData, postLeaveError, postLeaveIsLoading, postLeaveOverview]= usePostLeaveOverview()

  useEffect(() => {
    function formatDate(dateString) {
      // Parse the input date string into a Date object
      const date = new Date(dateString);

      // Format the date into 'yyyy-mm-dd'
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }
    const formattedDate = formatDate(selectedDay);
    setDateToGet(formattedDate);
    // console.log("formattedDate", formattedDate)
    postLeaveOverview({date : formattedDate})
    // getEmployeeOverview({ formattedDate: formattedDate });
  }, [selectedDay]);

  useEffect(()=>{
   if(postLeaveData){
    setLeaveDataEmployees(postLeaveData?.data)
   }
  },[postLeaveData, postLeaveError])

  useEffect(() => {
    if (leaveData) {
      setLeaveDataEmployees(leaveData);
    }
  }, [leaveData]);

  let days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  // Get people on leave for the selected day
  const selectedDayLeaves = leaves.filter((leave) => {
    const start = parseISO(leave.startDate);
    const end = parseISO(leave.endDate);
    const normalizedSelectedDay = startOfDay(selectedDay); // Normalize selectedDay to the start of the day
    return normalizedSelectedDay >= start && normalizedSelectedDay <= end;
  });

  return (
    <div className="bg-white">
      <div className="grid grid-cols-12 mt-2.5">
        <div className="col-span-7 rounded-l-lg bg-[#F1F5F9] p-[1rem]">
          <div className="flex items-center justify-evenly">
            <div
              onClick={previousMonth}
              className="w-8 h-8 cursor-pointer flex items-center justify-center rounded-full border border-[#D9D9D9] bg-[#FFFFFF]"
            >
              <MdKeyboardArrowLeft className="text-[#64748B] text-xl" />
            </div>
            <div className="rounded-md px-3 py-1.5 bg-[#FFFFFF] border border-[#D9D9D9]">
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                {format(firstDayCurrentMonth, "MMMM yyyy")}
              </p>
            </div>
            <div
              onClick={nextMonth}
              className="w-8 h-8 cursor-pointer flex items-center justify-center rounded-full border border-[#D9D9D9] bg-[#FFFFFF]"
            >
              <MdKeyboardArrowRight className="text-[#64748B] text-xl" />
            </div>
          </div>
          <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-center text-[#64748B]">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="grid grid-cols-7 mt-1 text-sm">
            {days.map((day, dayIdx) => {
              // const dayLeaves = leaves.filter((leave) => {
              //   const start = parseISO(leave.startDate);
              //   const end = parseISO(leave.endDate);
              //   return day >= start && day <= end;
              // });

              return (
                <div
                  key={day.toString()}
                  className={classNames(
                    dayIdx === 0 && colStartClasses[getDay(day)],
                    "py-1.5"
                  )}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    className={classNames(
                      isEqual(day, selectedDay) && "text-white",
                      !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        "text-[#EA580C]",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-900",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-400",
                      isEqual(day, selectedDay) &&
                        isToday(day) &&
                        "bg-[#EA580C]",
                      isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        "bg-gray-900",
                      !isEqual(day, selectedDay) && "hover:bg-gray-200",
                      (isEqual(day, selectedDay) || isToday(day)) &&
                        "font-semibold",
                      "mx-auto flex h-6 w-6 items-center justify-center rounded-full"
                    )}
                  >
                    <time dateTime={format(day, "yyyy-MM-dd")}>
                      {format(day, "d")}
                    </time>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <section className="col-span-5 rounded-r-lg bg-[#F8FAFC] p-3">
          <div className="h-full">
            <div className="flex gap-2.5 items-center h-[15%]">
              <div className="px-2.5 py-1 rounded  border border-[#D9D9D9] bg-[#FFFFFF]">
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  {leaveDataEmployees?.number_of_employees_on_leave}
                </p>
              </div>
              <div>
                <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                  On Leave
                </p>
              </div>
            </div>
            {/* <div className='mt-2.5 h-[85%]'>
              {
                leaveDataEmployees?.leave_details?.length >0 ? leaveDataEmployees?.leave_details?.map((leave,index)=>(
                  <div className='flex gap-1.5 items-center rounded-md border border-[#D9D9D9] bg-[#FFFFFF] mb-2 px-3.5 py-1'>
                  <img src={user} alt='userPic'/>
                  <p className='m-0 text-[#334155] font-[400] text-[0.9rem]'>{leave.employee_name}</p>
                </div>
                )):<div className='flex h-full justify-center items-center'>
                  <p className='m-0 text-[#333333]'>No one is on Leave</p>
                </div>   
              }
            </div> */}

            {/* temp */}
            <div className="mt-2.5 h-[85%]">
              {leaveDataEmployees?.leave_details?.length > 0 ? (
                <div
                  className="h-full overflow-y-auto scrollbar-hide"
                  style={{ maxHeight: "calc(4.5 * 3rem)" }}
                >
                  {leaveDataEmployees.leave_details.map((leave, index) => (
                    <div
                      key={index}
                      className="flex gap-1.5 items-center rounded-md border border-[#D9D9D9] bg-[#FFFFFF] mb-2 px-3.5 py-1"
                    >
                      <img src={user} alt="userPic" />
                      <p className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                        {leave.employee_name}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex h-full justify-center items-center">
                  <p className="m-0 text-[#333333]">No one is on Leave</p>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
