import useAPICall from "hooks/useAPICall";
import { PATCH_USER_TYPE_URL, POST_ADD_EMPLOYEE_URL, POST_RECORD_LEAVE_URL } from "constants/apis";

const usePatchEditLeave = () => {
  const [data, error, isLoading, callPatchEditLeave, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const patchEditLeave = (body,obj) => {
    const url = POST_RECORD_LEAVE_URL;
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    // const params={"id":obj.employeeId}
    callPatchEditLeave({
      url,
      method : "PATCH",
      statusObj,
      
      config,
      defaultFallback,
      body,
    //   params
    });
  };
  return [data, error, isLoading, patchEditLeave, setSuccessData, setError];
};

export default usePatchEditLeave;
