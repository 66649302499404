import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import InputField from "components/input/InputField";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePostArtAndFurniture from "../../data/usePostArtAndFurniture";
import { useNavigate } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";

const AddNewFurniture = ({
  triggerElement,
  estateType,
  setIsArt,
  isArt,
  categoryId,
  setIsReload,
  defaultState,
  setDefaultState
}) => {
  const [postArtData, postArtError, postArtIsLoading, postArtAndFurniture] =
    usePostArtAndFurniture();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [errors, setErrors]=useState({})
  const [isClose, setIsClose]=useState(false)
  const navigate = useNavigate()

  useEffect(()=>{
    if(postArtData){
     showSuccessToast("Posted Successfully")
     if (window.location.href.includes("personal-asset-furniture")) {
      setIsReload(true);
    }else{
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "personal-asset-furniture")}/?id=${categoryId}`
      );
    }
     setIsClose(true)
    }
    if (postArtError) {
      showErrorToast("Error in Posting");
    }
  }, [postArtData, postArtError]);

  const [artForm, setArtForm] = useState({
    item_type: "",
    item_name: "",
    artist_creator: "",
    description: "",
    purchase_date: "",
    purchase_price: "",
    estimated_sale_value: "",
    loan_taken: false,
    invoice: null,
  });

  const resetState = () => {
    setArtForm([
      {
        item_type: "",
        item_name: "",
        artist_creator: "",
        description: "",
        purchase_date: "",
        purchase_price: "",
        estimated_sale_value: "",
        loan_taken: false,
        invoice: null,
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
    setDefaultState({...defaultState,art:false})
  };

  const handleInputChange = (e) => {
    let { name, value, type, files } = e.target;
    if (type === "radio") {
      const bool = value === "true" ? true : value === "false" ? false : null;
      value = bool;
    }
    setArtForm((prevForm) => ({
      ...prevForm,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const validateArtForm = () => {
    const newErrors = {};

    // Item Type: Required field
    if (!artForm.item_type) {
      newErrors.item_type = "Item type is required.";
    }

    // Item Name: Required field, only letters and spaces allowed
    const namePattern = /^[a-zA-Z\s]+$/;
    if (!artForm.item_name) {
      newErrors.item_name = "Item name is required.";
    } else if (!namePattern.test(artForm.item_name)) {
      newErrors.item_name = "Item name should only contain letters and spaces.";
    }

    // Artist/Creator: Required field, only letters and spaces allowed
    // if (!artForm.artist_creator) {
    //   newErrors.artist_creator = "Artist/Creator name is required.";
    // } else if (!namePattern.test(artForm.artist_creator)) {
    //   newErrors.artist_creator =
    //     "Artist/Creator name should only contain letters and spaces.";
    // }

    // // Description: Required field
    // if (!artForm.description) {
    //   newErrors.description = "Description is required.";
    // }

    // if (currentStep === 1) {
    //   // Purchase Date: Required, should be a valid date
    //   if (!artForm.purchase_date) {
    //     newErrors.purchase_date = "Purchase date is required.";
    //   } else if (isNaN(new Date(artForm.purchase_date).getTime())) {
    //     newErrors.purchase_date = "Purchase date should be a valid date.";
    //   }

    //   // Purchase Price: Required, numeric, and positive
    //   if (!artForm.purchase_price || isNaN(artForm.purchase_price)) {
    //     newErrors.purchase_price =
    //       "Purchase price is required and should be a number.";
    //   } else if (artForm.purchase_price <= 0) {
    //     newErrors.purchase_price = "Purchase price must be greater than 0.";
    //   }

    //   // Estimated Sale Value: Required, numeric, and positive
    //   if (
    //     !artForm.estimated_sale_value ||
    //     isNaN(artForm.estimated_sale_value)
    //   ) {
    //     newErrors.estimated_sale_value =
    //       "Estimated sale value is required and should be a number.";
    //   } else if (artForm.estimated_sale_value <= 0) {
    //     newErrors.estimated_sale_value =
    //       "Estimated sale value must be greater than 0.";
    //   }

    //   // Invoice File: Required, must be a valid file format (e.g., PDF, JPG, PNG)
    //   const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
    //   if (!artForm.invoice) {
    //     newErrors.invoice = "Invoice file is required.";
    //   } else if (!filePattern.test(artForm.invoice.name)) {
    //     newErrors.invoice =
    //       "Invoice should be a valid file format (PDF, JPG, or PNG).";
    //   }
    // }
    // Set error state and check if no errors remain
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateArtForm()) return;
  
    // Create a new FormData object
    const formData = new FormData();
  
    // Helper function to check if a value is not empty
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append non-empty values to the FormData
    if (isNotEmpty(artForm.item_type)) {
      formData.append("item_type", artForm.item_type);
    }
    if (isNotEmpty(artForm.item_name)) {
      formData.append("item_name", artForm.item_name);
    }
    if (isNotEmpty(artForm.artist_creator)) {
      formData.append("artist_creator", artForm.artist_creator);
    }
    if (isNotEmpty(artForm.description)) {
      formData.append("description", artForm.description);
    }
    if (isNotEmpty(artForm.purchase_date)) {
      formData.append("purchase_date", artForm.purchase_date);
    }
    if (isNotEmpty(artForm.purchase_price)) {
      formData.append("purchase_price", artForm.purchase_price);
    }
    if (isNotEmpty(artForm.estimated_sale_value)) {
      formData.append("estimated_sale_value", artForm.estimated_sale_value);
    }
    if (isNotEmpty(artForm.loan_taken)) {
      formData.append("loan_taken", artForm.loan_taken);
    }
  
    // Append file if it exists
    if (artForm.invoice) {
      formData.append("invoice", artForm.invoice);
    }
  
    // Print FormData values for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    // Send formData to the API
    postArtAndFurniture(formData, categoryId);
  };
  

  const progressSteps = [
    {
      label: "Item Details",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `Purchase Details`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleNext = () => {
    if (currentStep === 2) {
      handleSubmit();
    }
    if (currentStep < 2) {
      if (validateArtForm()) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      console.log("Reached the last step");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Item Details
            </h4>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Painting", label: "Painting" },
                      { value: "Sculpture", label: "Sculpture" },
                      { value: "Photograph", label: "Photograph" },
                      { value: "Other", label: "Other" },
                    ]}
                    name="item_type"
                    value={artForm.item_type}
                    onChange={handleInputChange}
                  />
                  {errors.item_type && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.item_type}
                    </p>
                  )}
                </div>
                <div className="input-container col-span-6 w-[100%] flex flex-col gap-1.5 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Item Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.item_name}
                    onChange={handleInputChange}
                    name="item_name"
                  />
                  {errors.item_name && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.item_name}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Artist/Creator
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.artist_creator}
                    onChange={handleInputChange}
                    name="artist_creator"
                  />
                  {errors.artist_creator && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.artist_creator}
                    </p>
                  )}
                </div>

                <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Description
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.description}
                    onChange={handleInputChange}
                    name="description"
                  />
                  {errors.description && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Purchase Details
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <div className="grid grid-cols-12 gap-3 mb-3 mt-1.5">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={artForm.purchase_date}
                      name="purchase_date"
                      onChange={handleInputChange}
                    />
                    {errors.purchase_date && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.purchase_date}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mb-3">
                  <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Price
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="purchase_price"
                        value={artForm.purchase_price}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.purchase_price && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.purchase_price}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Estimated Sale Value
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={artForm.estimated_sale_value}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.estimated_sale_value && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.estimated_sale_value}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="input-container">
                    <label className="input-label">Taken any loan?</label>
                    <div className="flex gap-3 items-center mt-[0.5rem]">
                      <label className="input-label focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={true}
                          name="loan_taken"
                          checked={artForm.loan_taken === true}
                          onChange={handleInputChange}
                          className="mr-2 custom-radio"
                        />
                        Yes
                      </label>
                      <label className="input-label focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={false}
                          name="loan_taken"
                          checked={artForm.loan_taken === false}
                          onChange={handleInputChange}
                          className="mr-2 custom-radio"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mb-3">
                  <div className="col-span-4">
                    <WhiteButton
                      name="Upload Invoice"
                      px={"px-[0.6rem]"}
                      py={"py-[0.52rem]"}
                      text={"text-[0.9rem]"}
                      mt={"mt-[1.2rem]"}
                      border={"!border-[1px] !border-[#94A3B8]"}
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                    />
                    {errors.invoice && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.invoice}
                      </p>
                    )}
                  </div>
                  <InputField
                    id={`file-upload`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="invoice"
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review your details
            </h4>
            <div className="flex justify-between mb-3 mt-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Item Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(0)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem]">
              <div className="mt-2.5 flex flex-col gap-1.5">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Type
                </p>
                <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                  {artForm.item_type}
                </p>
              </div>
              <div className="mt-2.5 flex flex-col gap-1.5">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Item Name
                </p>
                <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                  {artForm.item_name}
                </p>
              </div>
              <div className="mt-2.5 flex flex-col gap-1.5">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Artist/Creator
                </p>
                <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                  {artForm.artist_creator}
                </p>
              </div>
              <div className="mt-2.5 flex flex-col gap-1.5">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Description
                </p>
                <p className="text-[#64748B] m-0 text-[o.9rem]">
                  {artForm.description}
                </p>
              </div>
            </div>
            <div className="flex justify-between mt-3 mb-3">
              <div>
                <p className="m-0 text-[#334155] font-[500]">
                  Purchase Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(1)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] w-[80%] mb-3">
              <div className="flex gap-16 mt-2.5">
                <div className="w-[41%] flex flex-col gap-1.5'">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {artForm.purchase_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Price
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {artForm.purchase_price}{" "}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
              </div>
              <div className="flex gap-16 mt-2.5">
                <div className="w-[41%] flex flex-col gap-1.5'">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Estimated Sale Value
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {artForm.estimated_sale_value}{" "}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Loan Taken?
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {artForm.loan_taken ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        steps="2"
        resetClose={setIsArt}
        isNotDefault={isArt}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Art and Collectibles</span> to
            your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div>
              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={currentStep === 0 ? handleClose : handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 2 ? (
                  <Button
                    onClick={handleNext}
                    name={"Next"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Finish"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewFurniture;
