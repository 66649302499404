import React, { useEffect, useRef, useState } from "react";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import profile from "assets/images/BusinessDashboard/BusinessCustomers/profile-text.svg";
import transaction from "assets/images/BusinessDashboard/BusinessCustomers/transaction-table-properties.svg";
import document1 from "assets/images/BusinessDashboard/BusinessCustomers/documents-files.svg";
import editDots from "assets/images/BusinessDashboard/BusinessCustomers/EditDots.svg";
import avatar from "assets/images/BusinessDashboard/BusinessCustomers/Avatar (1).svg";
import mail from "assets/images/BusinessDashboard/BusinessCustomers/mail.svg";
import phone from "assets/images/BusinessDashboard/BusinessCustomers/phone.svg";
import globe from "assets/images/BusinessDashboard/BusinessCustomers/globe.svg";
import download from "assets/images/BusinessDashboard/BusinessCustomers/download.svg";
import location1 from "assets/images/BusinessDashboard/BusinessCustomers/location.svg";
import paperClip from "assets/images/BusinessDashboard/BusinessCustomers/paperclip.svg";
import rightArrow from "assets/images/BusinessDashboard/BusinessCustomers/arrowRightDetail.svg";
import leftArrow from "assets/images/BusinessDashboard/BusinessCustomers/leftArrow.svg";
import EditCompanyDetail from "./Edit/EditCompanyDetail";
import EditProfileDetail from "./Edit/EditProfileDetail";
import EditPrimaryContact from "./Edit/EditPrimaryContact";
import { useLocation, useNavigate } from "react-router-dom";
import useGetCustomerDetailView from "./data/useGetCustomerDetailView";
import { BUSINESS_CUSTOMERS_URL } from "constants/routes";
import EditPrimaryContactDelete from "./Edit/EditPrimaryContactDelete";
const CustomerDetailView = () => {
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [showCompanyDetailsEdit, setShowCompanyDetailsEdit] = useState(false);
  const [showContactEdit, setShowContactEdit] = useState(false);
  const openEditCompanyDetailRef = useRef(null);
  const openEditProfileRef = useRef(null);
  const openEditPrimaryContactRef = useRef(null);
  const openContactDeleteRef = useRef(null);
  const [openCompanyDetails, setOpenCompanyDetails] = useState(false);
  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const [openPrimaryContactEdit, setOpenPrimaryContactEdit] = useState(false);
  const [openContactDelete, setOpenContactDelete]=useState(false);
  const [detailData, setDetailData] = useState({});
  const [addressCards, setAddressCards]=useState([]);
  const [highlightBg, setHighlightBg]=useState("profile");
  const [industryName, setIndustryName]=useState("")
  const location = useLocation();
  const navigate = useNavigate();
  const [reCallApi, setReCallApi]=useState(false);
  const [contactId, setContactId]=useState("")
  const {id}=location?.state;
  const business_id=JSON.parse(localStorage.getItem("user"))?.business_id
  const [getData, getIsLoading, getIsError, getCustomerDetailView] =
    useGetCustomerDetailView();

    const array=[
      { value: "", label: "Select Industry" },
      { value: "1", label: "Consultancy" },
      { value: "2", label: "Technology" },
      { value: "3", label: "Construction" },
      { value: "4", label: "Clothing" },
      { value: "5", label: "Agriculture" },
      { value: "6", label: "Salaried" },
      { value: "7", label: "Real Estate" },
      { value: "8", label: "Food & Beverage" },
      { value: "9", label: "Consulting" },
      { value: "10", label: "Rental" },
      { value: "11", label: "Sports" },
      { value: "12", label: "Decors" },
      { value: "13", label: "Retail" },
      { value: "14", label: "Healthcare" },
    ]

  useEffect(()=>{
   if(id){
    getCustomerDetailView(business_id, id)
   }
  },[id])

  useEffect(()=>{
    if(reCallApi){
      getCustomerDetailView(business_id, id)
      setReCallApi(false)
    }
  },[reCallApi])

  useEffect(()=>{
   if(getData?.data){
    console.log("getData?.data", getData?.data)
    setDetailData(getData?.data);
    setAddressCards(getData?.data?.customer?.contact_persons?.slice(0,3));
    const industryObj=array.filter((el,i)=>{
          return el.value ==  getData?.data?.customer?.Industry_type;
    })
    setIndustryName(industryObj?.[0]?.label ? industryObj?.[0]?.label : "-")
   }
  },[getData])
  useEffect(() => {
    if (openCompanyDetails) {
      openEditCompanyDetailRef.current.click();
      setShowCompanyDetailsEdit(false)
    }
  }, [openCompanyDetails]);

  useEffect(() => {
    if (openProfileEdit) {
      openEditProfileRef.current.click();
      setShowProfileEdit(false)
    }
  }, [openProfileEdit]);

  useEffect(() => {
    if (openPrimaryContactEdit) {
      openEditPrimaryContactRef.current.click();
      setShowContactEdit(false)
    }
  }, [openPrimaryContactEdit]);

  useEffect(()=>{
    if(openContactDelete){
      openContactDeleteRef.current.click();
      setShowContactEdit(false)
    }
  },[openContactDelete])

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % addressCards?.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? addressCards?.length - 1 : prevIndex - 1
    );
  };
  
  const handleEdit=(val)=>{
    if(val === "profile"){
      setShowProfileEdit(!showProfileEdit)
      setShowCompanyDetailsEdit(false)
      setShowContactEdit(false)
    }else if(val === "company"){
      setShowCompanyDetailsEdit(!showCompanyDetailsEdit)
      setShowProfileEdit(false)
      setShowContactEdit(false)
    }else if(val === "contact"){
      setShowContactEdit(!showContactEdit)
      setShowProfileEdit(false)
      setShowCompanyDetailsEdit(false)
    }
  }

  const handleScrollView=(sectionId)=>{
    setHighlightBg(sectionId)
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleEditContact=(id)=>{
    setOpenPrimaryContactEdit(true)
    setContactId(id)
  }

  const handleDeleteContact=(id)=>{
    setOpenContactDelete(true)
    setContactId(id)
  }
  return (
    <>
      <div className="mt-16 p-4 flex flex-col gap-3.5">
        <div className="rounded-lg bg-[#FFFFFF] p-3 flex justify-between items-center">
          <div className="flex items-center gap-x-3.5">
            <div>
              <img src={previousBtn} alt="previousBtn" onClick={()=>navigate(BUSINESS_CUSTOMERS_URL)} className="cursor-pointer" />
            </div>
            <div className="w-16 h-16 rounded-full overflow-hidden">
              <img
                src={detailData?.customer?.company_logo ? detailData?.customer?.company_logo : avatar}
                alt="company_logo"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="m-0 text-[0.85rem] text-[#1F2937] font-[500]">
                {detailData?.customer?.company_name}
              </p>
              <div className="flex gap-x-3">
                <div onClick={()=>handleScrollView("profile")} className={`flex gap-1 items-center cursor-pointer ${highlightBg === "profile" ? "bg-[#F3F4F6] p-2 rounded-md" : ""}`}>
                  <img src={profile} alt="profile" />
                  <p className="m-0 text-[#1F2937] text-[0.8rem]">Profile</p>
                </div>
                <div onClick={()=>handleScrollView("transaction")} className={`flex gap-1 items-center cursor-pointer ${highlightBg === "transaction" ? "bg-[#F3F4F6] p-2 rounded-md" : ""}`}>
                  <img src={transaction} alt="transaction" />
                  <p className="m-0 text-[#1F2937] text-[0.8rem]">
                    Transactions
                  </p>
                </div>
                <div onClick={()=>handleScrollView("documents")} className={`flex gap-1 items-center cursor-pointer ${highlightBg === "documents" ? "bg-[#F3F4F6] p-2 rounded-md" : ""}`}>
                  <img src={document1} alt="document1" />
                  <p className="m-0 text-[#1F2937] text-[0.8rem]">Documents</p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              src={editDots}
              alt="editDots"
              onClick={()=>handleEdit("profile")}
              className="cursor-pointer"
            />
            {showProfileEdit && (
              <div
                className="absolute top-6 right-3 border border-[#E5E7EB] rounded-md shadow-sm px-3 py-2.5 bg-[#FFFFFF] cursor-pointer"
                onClick={() => setOpenProfileEdit(!openProfileEdit)}
              >
                <p className="m-0 text-[0.75rem] font-[500] text-[#1F2937]">
                  Delete
                </p>
              </div>
            )}
          </div>
        </div>
        <div id="profile" className="grid grid-cols-12 gap-3">
          <div className="col-span-8 flex flex-col gap-3.5 p-3.5 rounded-lg border border-[#D9D9D9] bg-[#FFFFFF]">
            <div className="flex justify-between items-center">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                Company Details
              </p>
              <div className="relative">
                <img
                  src={editDots}
                  alt="editDots"
                  onClick={()=>handleEdit("company")}
                  className="cursor-pointer"
                />
                {showCompanyDetailsEdit && (
                  <div
                    className="absolute top-6 right-3 border border-[#E5E7EB] rounded-md shadow-sm px-3 py-2.5 bg-[#FFFFFF] cursor-pointer"
                    onClick={() => setOpenCompanyDetails(true)}
                  >
                    <p className="m-0 text-[0.75rem] font-[500] text-[#1F2937]">
                      Edit
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-5 p-2.5 flex flex-col gap-2 border border-[#E5E7EB] bg-[#F8FAFC] rounded-md">
                <div>
                  <p className="m-0 text-[#64748B] text-[0.7rem]">
                    Registrations
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.75rem]">GST No.</p>
                  <p className="m-0 text-[#000000] text-[0.8rem] font-[500]">
                    {detailData?.customer?.gst_number ? detailData?.customer?.gst_number : "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.75rem]">PAN No.</p>
                  <p className="m-0 text-[#000000] text-[0.8rem] font-[500]">
                  {detailData?.customer?.pan_no ? detailData?.customer?.pan_no : "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.75rem]">
                    Entity Type
                  </p>
                  <p className="m-0 text-[#000000] text-[0.8rem] font-[500]">
                  {detailData?.customer?.entity_type ? detailData?.customer?.entity_type : "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.75rem]">Industry</p>
                  <p className="m-0 text-[#000000] text-[0.8rem] font-[500]">
                     {industryName}
                  </p>
                </div>
              </div>
              <div className="col-span-7 p-2.5 border border-[#E5E7EB] bg-[#F8FAFC] rounded-md flex flex-col gap-1.5">
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.7rem]">Contact</p>
                  <div className="flex gap-1.5 items-center">
                    <img src={phone} alt="phone" />
                    <p className="m-0 text-[#64748B] text-[0.75rem]">
                    {detailData?.customer?.phone ? detailData?.customer?.phone : "-"}
                    </p>
                  </div>
                  <div className="flex gap-1.5 items-center">
                    <img src={mail} alt="mail" />
                    <p className="m-0 text-[#64748B] text-[0.75rem]">
                    {detailData?.customer?.email ? detailData?.customer?.email : "-"}
                    </p>
                  </div>
                  <div className="flex gap-1.5 items-center">
                    <img src={globe} alt="globe" />
                    <p className="m-0 text-[#64748B] text-[0.75rem]">
                    {detailData?.customer?.website ? detailData?.customer?.website : "-"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.7rem]">
                    Registered Address
                  </p>
                  <div className="flex gap-1.5 items-center">
                    <img src={location1} alt="location" />
                    <p className="m-0 text-[#64748B] text-[0.75rem]">
                    {detailData?.customer?.address_registered ? detailData?.customer?.address_registered : "-"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="m-0 text-[#64748B] text-[0.7rem]">
                    Shipping/Billing Address
                  </p>
                  <div className="flex gap-1.5 items-center">
                    <img src={location1} alt="location" />
                    <p className="m-0 text-[#64748B] text-[0.75rem]">
                    {detailData?.customer?.address_communication ? detailData?.customer?.address_communication : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 p-3.5 flex flex-col gap-3.5 rounded-lg border border-[#D9D9D9] bg-[#FFFFFF]">
            <div className="flex justify-between items-center">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                Contacts
              </p>
              <div className="border border-[#E5E7EB] rounded-md shadow-sm px-2 py-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">
                  Add New Person
                </p>
              </div>
            </div>
            {
              addressCards?.map((el,i)=>{
                if(i===currentIndex){
                  return(
                    <div className="border border-[#E5E7EB] rounded-md">
                    <div className="flex justify-between items-center bg-[#F8FAFC] rounded-t-md">
                      <div className="px-2.5 py-3.5">
                        <p className="m-0 text-[#000000] text-[0.9rem] font-[600]">
                          {el?.name}
                        </p>
                        <p className="m-0 text-[#6B7280] text-[0.8rem] font-[500]">
                          {el?.role}
                        </p>
                      </div>
                      <div className="px-2.5 py-3.5 relative">
                        <img
                          src={editDots}
                          alt="editDots"
                          onClick={()=>handleEdit("contact")}
                          className="cursor-pointer"
                        />
                        {showContactEdit && (
                          <div className="absolute top-6 right-3 border border-[#E5E7EB] rounded-md shadow-sm px-3 py-2.5 bg-[#FFFFFF] flex flex-col gap-2">
                            <p
                              className="m-0 text-[0.75rem] font-[500] text-[#1F2937] cursor-pointer"
                              // onClick={() => setOpenPrimaryContactEdit(true)}
                              onClick={()=>handleEditContact(el?.id)}
                            >
                              Edit
                            </p>
                            <p onClick={()=>handleDeleteContact(el?.id)} className="m-0 text-[0.75rem] font-[500] text-[#1F2937] cursor-pointer">
                              Delete
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-2.5 py-3.5 flex flex-col gap-1.5">
                      <div className="flex gap-1.5 items-center">
                        <img src={phone} alt="phone" />
                        <p className="m-0 text-[#6B7280] text-[0.8rem]">
                          {el?.phone ? el?.phone : "-"}
                        </p>
                      </div>
                      <div className="flex gap-1.5 items-center">
                        <img src={mail} alt="mail" />
                        <p className="m-0 text-[#6B7280] text-[0.8rem]">
                          {el?.email ? el?.email : "-"}
                        </p>
                      </div>
                      <div className="flex gap-1.5 items-center">
                        <img src={paperClip} alt="paperClip" />
                        <p className="m-0 text-[#6B7280] text-[0.75rem]">
                          {el?.pan_card ? el?.pan_card : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                   )
                }
              })
            }
            <div className="flex items-center justify-center gap-2">
              <div
                onClick={handlePrev}
                className="p-2 rounded-full bg-[#FFFFFF] cursor-pointer border border-[#D0D5DD]"
              >
                <img src={leftArrow} alt="leftArrow" />
              </div>
              <div className="flex items-center gap-1.5">
                {addressCards?.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${
                      index === currentIndex ? "bg-[#EA580C]" : "bg-[#E5E7EB]"
                    }`}
                  ></div>
                ))}
              </div>
              <div
                onClick={handleNext}
                className="p-2 rounded-full bg-[#FFFFFF] cursor-pointer border border-[#D0D5DD]"
              >
                <img src={rightArrow} alt="rightArrow" />
              </div>
            </div>
          </div>
        </div>
        <div id="transaction" className="rounded-lg bg-[#FFFFFF] p-3.5 flex flex-col gap-3 border border-[#D9D9D9]">
          <div className="flex justify-between items-center">
            <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
              Transactions
            </p>
            <div className="border border-[#E5E7EB] rounded-md shadow-sm px-2 py-1.5">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                New Invoice
              </p>
            </div>
          </div>
          <div className="border border-[#E5E7EB] rounded-md">
            <div className="flex justify-between items-center rounded-t-md bg-[#F8FAFC] w-[100%] border-b border-[#E5E7EB]">
              <div className="px-2.5 py-2.5 w-[50%]">
                <p className="m-0 text-[#6B7280] text-[0.75rem]">PARTICULARS</p>
              </div>
              <div className="flex items-center justify-end gap-2.5 px-2 py-2.5 w-[50%]">
                <p className="m-0 text-[#6B7280] text-[0.75rem] w-[20%] flex items-center justify-center">
                  DATE
                </p>
                <p className="m-0 text-[#6B7280] text-[0.75rem] w-[20%] flex items-center justify-center">
                  DUE AMOUNT
                </p>
                <p className="m-0 text-[#6B7280] text-[0.75rem] w-[20%] flex items-center justify-center">
                  AMOUNT PAID
                </p>
                <p className="m-0 text-[#6B7280] text-[0.75rem] w-[20%] flex items-center justify-center">
                  BALANCE
                </p>
              </div>
            </div>
            {detailData?.transactions?.map((el, i) => {
              return (
                <div
                  className={`flex justify-between items-center rounded-t-md w-[100%] ${
                    i === detailData?.transactions?.length - 1
                      ? ""
                      : "border-b border-[#E5E7EB]"
                  }`}
                >
                  <div className="px-2.5 py-2.5 w-[50%]">
                    <p className="m-0 text-[#1F2937] text-[0.75rem] font-[500]">
                      {el?.particulars ? el?.particulars :"-"}
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-2.5 px-2 py-2.5 w-[50%]">
                    <p className="m-0 text-[#1F2937] text-[0.75rem] w-[20%] flex items-center justify-center font-[500]">
                      {el?.date ? el?.date : '-' }
                    </p>
                    <p className="m-0 text-[#1F2937] text-[0.75rem] w-[20%] flex items-center justify-center font-[500]">
                      {el?.due_amount}
                    </p>
                    <p className="m-0 text-[#1F2937] text-[0.75rem] w-[20%] flex items-center justify-center font-[500]">
                      {el?.amount_paid}
                    </p>
                    <p className="m-0 text-[#1F2937] text-[0.75rem] w-[20%] flex items-center justify-center font-[500]">
                      {el?.balance }
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div id="documents" className="rounded-lg bg-[#FFFFFF] p-3.5 flex flex-col gap-3.5 border border-[#D9D9D9]">
          <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
            Documents
          </p>
          <div className="flex justify-between items-center">
            <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
              Quotations
            </p>
            <div className="border border-[#E5E7EB] rounded-md shadow-sm px-2 py-1.5">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                Create Quotations
              </p>
            </div>
          </div>
          <div className="border border-[#E5E7EB] rounded-md">
            {detailData?.documents?.quotations?.map((el, i) => {
              return (
                <div
                  className={`flex justify-between items-center rounded-t-md ${
                    i === detailData?.documents?.quotations?.length - 1
                      ? ""
                      : "border-b border-[#E5E7EB]"
                  }`}
                >
                  <div className="px-2.5 py-2.5">
                    <p className="m-0 text-[#1F2937] text-[0.85rem] font-[500]">
                      {el.file_name}
                    </p>
                  </div>
                  <div className="flex gap-2.5 px-2.5 py-2.5">
                    <a className="no-underline cursor-pointer" href={`${el?.download_link}`} target="_blank"   
                      download // Triggers a download if the link points to a downloadable file
                    >
                    <img src={download} alt="download" />
                    </a>
                    <p className="m-0 text-[#1F2937] text-[0.75rem] flex items-center justify-center font-[500]">
                      Download
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between items-center">
            <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
              Reports
            </p>
            <div className="border border-[#E5E7EB] rounded-md shadow-sm px-2 py-1.5">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                Create Report
              </p>
            </div>
          </div>
          <div className="border border-[#E5E7EB] rounded-md">
            {detailData?.documents?.reports?.map((el, i) => {
              return (
                <div
                  className={`flex justify-between items-center rounded-t-md ${
                    i === detailData?.documents?.reports?.length - 1
                      ? ""
                      : "border-b border-[#E5E7EB]"
                  }`}
                >
                  <div className="px-2.5 py-2.5">
                    <p className="m-0 text-[#1F2937] text-[0.85rem] font-[500]">
                      {el.file_name}
                    </p>
                  </div>
                  <div className="flex gap-2.5 px-2.5 py-2.5">
                    <img src={download} alt="download" />
                    <p className="m-0 text-[#1F2937] text-[0.75rem] flex items-center justify-center font-[500]">
                      Download
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="rounded-lg bg-[#FFFFFF] p-3.5 flex flex-col gap-3.5 border border-[#D9D9D9]">
          <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">History</p>
          <div className="flex items-center gap-3.5">
            <div className="flex flex-col gap-1.5">
              <p className="m-0 text-[0.75rem] text-[#64748B]">Last Edited</p>
              <p className="m-0 text-[0.75rem] text-[#64748B] font-[500]">
                {detailData?.history?.last_updated ? detailData?.history?.last_updated : "-"}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="m-0 text-[0.75rem] text-[#64748B]">
                Added to Beyondtax
              </p>
              <p className="m-0 text-[0.75rem] text-[#64748B] font-[500]">
              {detailData?.history?.added_to_beyondtax ? detailData?.history?.added_to_beyondtax : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {openCompanyDetails && (
        <EditCompanyDetail
          setReCallApi={setReCallApi}
          detailData={detailData}
          openCompanyDetails={openCompanyDetails}
          setOpenCompanyDetails={setOpenCompanyDetails}
          triggerElement={
            <button ref={openEditCompanyDetailRef} className="hidden">
              Open
            </button>
          }
        />
      )}
      {openProfileEdit && (
        <EditProfileDetail
         detailData={detailData}
          openProfileEdit={openProfileEdit}
          setOpenProfileEdit={setOpenProfileEdit}
          triggerElement={
            <button ref={openEditProfileRef} className="hidden">
              Open
            </button>
          }
        />
      )}
      {openPrimaryContactEdit && (
        <EditPrimaryContact
          detailData={detailData}
          setReCallApi={setReCallApi}
          openProfileEdit={openPrimaryContactEdit}
          setOpenProfileEdit={setOpenPrimaryContactEdit}
          contactId={contactId}
          triggerElement={
            <button ref={openEditPrimaryContactRef} className="hidden">
              Open
            </button>
          }
        />
      )}
      {
        openContactDelete && (
          <EditPrimaryContactDelete 
            detailData={detailData}
            setReCallApi={setReCallApi}
            openProfileEdit={openContactDelete}
            setOpenProfileEdit={setOpenContactDelete}
            contactId={contactId}
            triggerElement={
              <button ref={openContactDeleteRef} className="hidden">
                Open
              </button>
          }
          />
        )
      }
    </>
  );
};

export default CustomerDetailView;
