import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const IncomeTaxInsightsChartStatic = () => {
  // Chart data
  const data = {
    labels: ["FY 2021-22", "FY 2022-23", "FY 2023-24", "FY 2024-25"],
    datasets: [
      {
        label: "Income",
        data: [100000, 150000, 250000, 280000], // Add actual data values
        backgroundColor: [
          "#276EF1", // Blue for the first three bars
          "#276EF1",
          "#276EF1",
          "#EEF3FE", // Light color for "Upcoming Year"
        ],
        borderRadius: 6, // Rounded bar corners
        barThickness: 35,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `₹${(context.raw / 100000)}L`, // Format tooltip labels
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#6B7280", // Gray color for x-axis labels
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 100000,
          callback: (value) => `₹${value / 100000}L`, // Format y-axis ticks
          color: "#6B7280",
        },
      },
    },
  };

  return (
    <div className="h-60">
      <Bar data={data} options={options} />
    </div>
  );
};

export default IncomeTaxInsightsChartStatic;
