import React, { useEffect, useRef, useState } from "react";
import eyeClose from "assets/images/moneyManager/eyeClose.svg";
import cash from "assets/images/moneyManager/banknote.svg";
import eyeCloseBlack from "assets/images/moneyManager/eyeCloseBlack.svg";
import bank from "assets/images/moneyManager/box-select.svg";
import creditCard from "assets/images/moneyManager/credit-card.svg";
import shieldCheck from "assets/images/moneyManager/shield-check.svg";
import star from "assets/images/moneyManager/star.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import {
  MONEY_MANAGER_ACCOUNTS_URL,
  MONEY_MANAGER_TRANSACTIONS_URL,
} from "constants/routes";
import InputField from "components/input/InputField";
import AddNewGoal from "./AddNewGoal";
import useGetManagerHome from "./data/usegetManagerHome";
import useGetDashboardCashFlow from "./data/useGetDashboardCashFlow";

// Register required chart elements
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MoneyManagerHome = () => {
  const [managerData, setManagerData] = useState({});
  const [isGoal, setIsGoal]= useState(false);
  const [isReload, setIsReload]= useState(false)
  const addGoalBtnRef=useRef(null);
  const [dropDownValue, setDropDownValue] = useState("");
  const [showBalance, setShowBalance] = useState({
    balance: false,
    cash: false,
    bank: false,
    credit: false
  })
  const [getManagerData, getManagerError, getManagerIsLoading, getManager] =
    useGetManagerHome();
  const [getCashData, getCashError, getCashIsLoading, getCash] =
    useGetDashboardCashFlow();
  const navigate = useNavigate();
  useEffect(() => {
    getManager("");
    const currentMonth = new Date().getMonth() + 1;
    getCash({"month":currentMonth})
  }, []);

  
  useEffect(()=>{
    if(isReload){
      getManager("");
      setIsReload(false)
    }
  },[isReload])

  useEffect(() => {
    if (getManagerData?.data) {
      setManagerData(getManagerData?.data);
    }
  }, [getManagerData]);

  useEffect(()=>{
    if(getCashData?.data){
     setDropDownValue(Number(getCashData?.data?.month))
    }
  },[getCashData])


  const budgetTransactions = [
    {
      date: "20-11-2024",
      amountDeducted: "-1000",
      company: "Zomato",
    },
    {
      date: "20-11-2024",
      amountDeducted: "1000",
      company: "Reliance",
    },
    {
      date: "20-11-2024",
      amountDeducted: "-1000",
      company: "Swiggy",
    },
  ];


  // Function to format the date as "Dec 02"
  const formatDate = (dateString) => {
    const options = { month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleNavigate = () => {
    navigate(MONEY_MANAGER_TRANSACTIONS_URL);
  };

  const handleNavigateAccounts = () => {
    navigate(MONEY_MANAGER_ACCOUNTS_URL);
  };

  const handleInputChange = (e) => {
    setDropDownValue(Number(e.target.value))
    getCash({'month':e.target.value})
  };

  const handleOpenDilog=()=>{
    setIsGoal(true)
}
  useEffect(()=>{
    if(isGoal){
        addGoalBtnRef.current.click()
    }
    },[isGoal])
  
  const resetState=()=>{
    setIsGoal(false)
}

function formatDateLastUpdate(input) {
  if (!input) {
    return "unavailable"; // Fallback message
  }
  // Create a Date object from the input string
  const date = new Date(input?.replace(" ", "T"));

  // Define an array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract parts of the date
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero if needed

  // Format the output as required
  return `${month} ${day}, ${year}, ${hours}:${minutes}`;
}
  return (
    <div className="bg-gray-100 md:px-[1rem] px-6 pt-[2rem] py-4">
      <h4 className="text-darkGray text-[1.4rem] m-0">Money Manager</h4>
      <div className="flex items-center gap-4 mt-8">
        <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[63.9%] h-[250px]">
          <div className="border-b-[1px] border-[#D9D9D9]">
            <div className="px-3 py-3.5 flex justify-between items-center">
              <div className="flex items-center gap-1.5">
                <p className="text-[#64748B] m-0 font-[500]">Balance</p>
                <div className="bg-[#F0FDF4] p-1 flex gap-[3px] rounded-sm">
                  <p className="text-[#15803D] m-0 font-[600] text-[0.9rem]">
                  ₹{showBalance.balance ? managerData?.cash_total + managerData?.bank_total + managerData?.credit_card_total: "*****"}{" "}
                  </p>
                  <span className="cursor-pointer" onClick={()=>setShowBalance({...showBalance, balance:!showBalance.balance})}>
                     {
                      showBalance.balance ? <FaEyeSlash size={18} style={{ color: "#15803D" }} /> : <FaEye size={18} style={{ color: "#15803D" }}/>
                     }
                  </span>
                </div>
              </div>
              <div>
                <p
                  onClick={handleNavigateAccounts}
                  className="m-0 text-[#64748B] font-[500] cursor-pointer"
                >
                  Manage Accounts
                </p>
              </div>
            </div>
          </div>
          <div className="px-3.5 py-4">
            <div className="flex items-center justify-evenly gap-2.5">
              <div className="p-3 rounded-md border-[1px] border-[#D9D9D9] w-[30%] h-[110px] flex flex-col justify-between">
                <div className="flex gap-1">
                  <img src={cash} alt="cash" />
                  <p className="m-0 text-[#64748B] font-[500]">Cash</p>
                </div>
                <div className="flex gap-1 justify-end">
                  <h6 className="m-0 text-[#334155] text-[1.1rem]">
                    ₹{showBalance.cash ? managerData?.cash_total:"*****"}
                  </h6>
                  <span className="cursor-pointer" onClick={()=>setShowBalance({...showBalance, cash:!showBalance.cash})}>
                     {
                      showBalance.cash ? <FaEyeSlash size={18} style={{ color: "#334155" }} /> : <FaEye size={18} style={{ color: "#334155" }}/>
                     }
                  </span>
                  {/* <img src={eyeCloseBlack} alt="eye" /> */}
                </div>
              </div>
              <div className="p-3 rounded-md border-[1px] border-[#D9D9D9] w-[30%] h-[110px] flex flex-col justify-between">
                <div className="flex gap-1">
                  <img src={bank} alt="bank" />
                  <p className="m-0 text-[#64748B] font-[500]">Bank</p>
                </div>
                <div className="flex gap-1 justify-end">
                  <h6 className="m-0 text-[#334155] text-[1.1rem]">
                    ₹{showBalance.bank ? managerData?.bank_total : "*****"}
                  </h6>
                  {/* <img src={eyeCloseBlack} alt="eye" /> */}
                  <span className="cursor-pointer" onClick={()=>setShowBalance({...showBalance, bank:!showBalance.bank})}>
                     {
                      showBalance.bank ? <FaEyeSlash size={18} style={{ color: "#334155" }} /> : <FaEye size={18} style={{ color: "#334155" }}/>
                     }
                  </span>
                </div>
              </div>
              <div className="p-3 rounded-md border-[1px] border-[#D9D9D9] w-[30%] h-[110px] flex flex-col justify-between">
                <div className="flex gap-1">
                  <img src={creditCard} alt="creditCard" />
                  <p className="m-0 text-[#64748B] font-[500]">Credit</p>
                </div>
                <div className="flex gap-1 justify-end">
                  <h6 className="m-0 text-[#334155] text-[1.1rem]">
                    ₹{showBalance.credit ? managerData?.credit_card_total : "*****"}
                  </h6>
                  {/* <img src={eyeCloseBlack} alt="eye" /> */}
                  <span className="cursor-pointer" onClick={()=>setShowBalance({...showBalance, credit:!showBalance.credit})}>
                     {
                      showBalance.credit ? <FaEyeSlash size={18} style={{ color: "#334155" }} /> : <FaEye size={18} style={{ color: "#334155" }}/>
                     }
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-2.5 px-3.5">
              <p className="m-0 text-[#334155] text-[0.9rem]">
                Last Updated on {formatDateLastUpdate(managerData?.updated_at)}
              </p>
            </div>
          </div>
        </div>
        <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[34%] h-[250px]">
          <div className=" border-b border-[#D9D9D9]">
            <div className="flex justify-between items-center px-3 py-3.5">
              <div className="flex items-center gap-1.5">
                <p className="text-[#64748B] m-0">Cash Flow</p>
              </div>
              <div>
                <InputField
                  type="select"
                  className="bg-[#F1F5F9] border rounded px-2 py-1 text-sm focus:outline-none"
                  options={[
                    { value: "", label: "Select Type" },
                    { value: 1, label: "January" },
                    { value: 2, label: "February" },
                    { value: 3, label: "March" },
                    { value: 4, label: "April" },
                    { value: 5, label: "May" },
                    { value: 6, label: "June" },
                    { value: 7, label: "July" },
                    { value: 8, label: "August" },
                    { value: 9, label: "September" },
                    { value: 10, label: "October" },
                    { value: 11, label: "November" },
                    { value: 12, label: "December" },
                  ]}
                  onChange={(e) => handleInputChange(e)}
                 value={dropDownValue} 
                />
              </div>
            </div>
          </div>
          {/* <div className="px-4 pt-[2rem] md:block">
            <div className="flex rounded gap-[1rem] items-center">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#22C55E]"
                style={{ width:`${managerData?.total_income ? "100%":"0"}` }}
              ></div>
              <h6 className="m-0 text-[#15803D] bg-[#F0FDF4] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                +₹{managerData?.total_income || 0}
              </h6>
            </div>

            <div className="flex rounded gap-[1rem] items-center my-[1rem]">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#EF4444]"
                style={{
                  width: `${
                    (managerData?.total_expense * 100) /
                    (managerData?.total_income || 1)
                  }%`,
                }}
              ></div>
              <h6 className="m-0 text-[#DC2626] bg-[#FEF2F2] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                - ₹{managerData?.total_expense || 0}
              </h6>
            </div>

            <div className="flex rounded gap-[1rem] items-center">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#9065B4]"
                style={{
                  width: `${
                    (managerData?.total_budget * 100) /
                    (managerData?.total_income || 1)
                  }%`,
                }}
              ></div>
              <h6 className="m-0 text-[#9065B4] bg-[#F3EFF8] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                + ₹{managerData?.total_budget || 0}
              </h6>
            </div>
          </div> */}
          <div className="px-4 pt-[2rem] md:block">
            {/* Income Bar */}
            <div className="flex rounded gap-[1rem] items-center">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#22C55E]"
                style={{
                  width:
                    getCashData?.data?.cash_flow?.total_expense > getCashData?.data?.cash_flow?.total_income
                      ? `${(getCashData?.data?.cash_flow?.total_income / getCashData?.data?.cash_flow?.total_expense) * 75}%`
                      : getCashData?.data?.cash_flow?.total_income === 0 ? "0%" : "75%",
                }}
              ></div>
              <h6 className="m-0 text-[#15803D] bg-[#F0FDF4] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                +₹{getCashData?.data?.cash_flow?.total_income || 0}
              </h6>
            </div>

            {/* Expense Bar */}
            <div className="flex rounded gap-[1rem] items-center my-[1rem]">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#EF4444]"
                style={{
                  width:
                  getCashData?.data?.cash_flow?.total_expense > getCashData?.data?.cash_flow?.total_income
                      ? "75%"
                      : `${
                          (getCashData?.data?.cash_flow?.total_expense * 75) /
                          (getCashData?.data?.cash_flow?.total_income || 1)
                        }%`,
                }}
              ></div>
              <h6 className="m-0 text-[#DC2626] bg-[#FEF2F2] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                - ₹{getCashData?.data?.cash_flow?.total_expense || 0}
              </h6>
            </div>

            {/* Budget Bar */}
            <div className="flex rounded gap-[1rem] items-center">
              <div
                className="shadow-none h-6 flex flex-col whitespace-nowrap rounded justify-center bg-[#9065B4]"
                style={{
                  width: `${
                    (getCashData?.data?.cash_flow?.total_budget * 75) / (getCashData?.data?.cash_flow?.total_income || 1)
                  }%`,
                }}
              ></div>
              <h6 className="m-0 text-[#9065B4] bg-[#F3EFF8] rounded p-[0.1rem] font-[500] text-[0.9rem]">
                 = ₹{getCashData?.data?.cash_flow?.total_budget || 0}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-2.5">
        <div className="flex justify-between items-center p-2.5">
          <p className="font-[500] text-[#64748B] m-0">Recent Transactions</p>
        </div>
        {/* Transaction List */}
        <div className="flex justify-between items-center px-2.5 py-[2px] bg-[#F8FAFC] text-[0.75rem] font-[500] text-[#64748B]">
          <div className="flex w-[60%] items-center">
            <div className="w-[30%]">Date</div>
            <div className="w-[30%]">Amount</div>
            <div className="w-[30%]">Name</div>
          </div>
          <div className="flex w-[30%]">
            <div className="w-[45%]">Category</div>
            <div className="w-[45%]">Mode</div>
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          {managerData?.latest_transactions
            ?.slice(0, 3)
            .map((transaction, index) => (
              <div
                key={transaction.id}
                className="flex justify-between items-center px-2.5 py-2 "
              >
                <div className="flex w-[60%] items-center">
                  <div className="text-sm text-[#64748B] w-[30%]">
                    {formatDate(transaction.date_of_transaction)}
                  </div>
                  <div
                    className={`px-2 ${
                      transaction.amount_type === "Expense"
                        ? "text-red-500"
                        : "text-green-500"
                    }  w-[30%]`}
                  >
                    {transaction.amount}
                  </div>
                  <div className="text-sm text-[#334155] w-[30%]">
                    {transaction.description}
                  </div>
                </div>
                <div className="flex w-[30%] ">
                  <div className="w-[45%]">
                    <span
                      className={`px-2 py-1 rounded-sm text-xs ${
                        transaction.tag === "Bill"
                          ? "bg-[#F3EFF8] text-[#334155]"
                          : "bg-[#E2E8F0] text-[#334155]"
                      }`}
                    >
                      {transaction.category_name}
                    </span>
                  </div>
                  <div className="text-sm text-[#64748B] w-[45%]">
                    {transaction.account_name}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="mt-2.5 flex items-center justify-between">
        <div className="w-[49%] border-[1px] border-[#D9D9D9] rounded-xl min-h-[178px] bg-[#FFFFFF]">
          {managerData?.recurring_transactions?.length > 0 ? (
            <>
              <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF] ">
                <div className="p-2 flex items-center gap-1.5">
                  <p className="m-0 text-[#64748B] font-[500]">Recurring </p>
                  <p className="m-0 text-[#C2410C] font-[500] bg-[#FFF7ED] px-1.5 py-1 rounded-sm text-[0.85rem]">
                    {managerData?.recurring_transactions.length} Upcoming Dues
                  </p>
                </div>
                <div className="flex justify-evenly px-2 py-[2px] bg-[#F8FAFC] text-[0.75rem] font-[500] text-[#64748B]">
                  <div className="w-[30%]">Name</div>
                  <div className="w-[30%]">Date</div>
                  <div className="w-[20%]">Amount</div>
                  <div className="w-[20%] flex justify-center">Mode</div>
                </div>
              </div>
              <div className="divide-y divide-gray-200 bg-[#FFFFFF] rounded-b-xl">
                {managerData?.recurring_transactions?.map((item, index) => (
                  <>
                    <div className="p-2 flex justify-evenly">
                      <div className="text-sm text-[#334155] w-[30%]">
                        {item.description}
                      </div>
                      <div className="text-sm text-[#64748B] w-[30%]">
                        {item.date_of_reccuring}
                      </div>
                      <div className="text-sm text-[#DC2626] w-[20%]">
                        {item.amount}
                      </div>
                      <div className="text-sm text-[#64748B] w-[20%] flex justify-center">
                        {item.account_name}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF] border-b-[2px] border-[#D9D9D9]">
                <div className="p-2">
                  <p className="m-0 text-[#64748B] font-[500]">Recurring </p>
                </div>
              </div>
              <div className="px-5 py-2.5 flex flex-col items-center">
                <div className="flex gap-2">
                  <div>
                    <img src={shieldCheck} alt="shieldCheck" />
                  </div>
                  <div className="w-[200px]">
                    <p className="m-0 text-[#000000] font-[600] text-[0.9rem]">
                      Future-Proof
                    </p>
                    <p className="m-0 text-[#64748B] text-[0.85rem]">
                      Stay on top of your commitments and plan your monthly
                      budgets ahead
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-[49%] border-[1px] border-[#D9D9D9] rounded-xl min-h-[178px] bg-[#FFFFFF]">
          {budgetTransactions?.length > 0 ? (
            <>
              <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF] ">
                <div className="p-2 flex items-center gap-1.5">
                  <p className="m-0 text-[#64748B] font-[500]">Budget </p>
                  <p className="m-0 text-[#15803D] font-[500] bg-[#F0FDF4] px-1.5 py-1 rounded-sm text-[0.85rem]">
                    On-Track
                  </p>
                </div>
                <div className="flex justify-evenly px-2 py-[2px] bg-[#F8FAFC] text-[0.75rem] font-[500] text-[#64748B]">
                  <div className="w-[30%]">Category</div>
                  <div className="w-[30%]">Expense</div>
                  <div className="w-[30%]">Budget Left</div>
                </div>
              </div>
              <div className="divide-y divide-gray-200 bg-[#FFFFFF] rounded-b-xl">
                {budgetTransactions.map((item, index) => (
                  <>
                    <div className="p-2 flex justify-evenly">
                      <div className="text-sm text-[#64748B] w-[30%] font-[500]">
                        {item.date}
                      </div>
                      <div
                        className={`text-sm  w-[30%] ${
                          item.amountDeducted > 0
                            ? "text-[#15803D]"
                            : "text-[#DC2626]"
                        }`}
                      >
                        {item.amountDeducted}
                      </div>
                      <div className="text-sm text-[#334155] w-[30%]">
                        {item.company}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF] border-b-[2px] border-[#D9D9D9]">
                <div className="p-2">
                  <p className="m-0 text-[#64748B] font-[500]">Budget </p>
                </div>
              </div>
              <div className="px-5 py-2.5 flex flex-col items-center">
                <div className="flex gap-2">
                  <div>
                    <img src={shieldCheck} alt="shieldCheck" />
                  </div>
                  <div className="w-[200px]">
                    <p className="m-0 text-[#000000] font-[600] text-[0.9rem]">
                      Future-Proof
                    </p>
                    <p className="m-0 text-[#64748B] text-[0.85rem]">
                      Stay on top of your commitments and plan your monthly
                      budgets ahead
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-2.5 border-[1px] border-[#D9D9D9] rounded-xl">
        {managerData?.top_goals?.length > 0 ? (
          <>
            <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF]">
              <div className="p-2 flex items-center gap-1.5">
                <p className="m-0 text-[#64748B] font-[500]">Top 3 Goals </p>
              </div>
              <div className="flex justify-evenly px-2 py-[2px] bg-[#F8FAFC] text-[0.75rem] font-[500] text-[#64748B]">
                  <div className="w-[5%]">S.no</div>
                  <div className="w-[30%]">Name</div>
                  <div className="w-[10%]">Percentage</div>
                  <div className="w-[20%] flex justify-center">Date</div>
                  <div className="w-[20%] flex justify-center">Goal</div>
                  <div className="w-[20%] flex justify-center">Collected</div>
              </div>
            </div>
            <div className="divide-y divide-gray-200 bg-[#FFFFFF] rounded-b-xl">
              {managerData?.top_goals?.map((item, index) => {
                // Convert the date to the desired format
                const formattedDate = new Date(
                  item.target_date
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                });

                return (
                  <div className="p-2 flex justify-evenly" key={index}>
                    <div className="text-sm text-[#334155] w-[5%]">
                      {index + 1}
                    </div>
                    <div className="text-sm text-[#334155] font-[500] w-[30%]">
                      {item.goal_name}
                    </div>
                    <div className="text-sm text-[#334155] bg-[#FEF2F2] p-[2px] rounded-sm">
                      {item.percentage || "10%"}
                    </div>
                    <div className="text-sm text-[#64748B] w-[20%] flex justify-center">
                      {formattedDate}
                    </div>
                    <div className="text-sm text-[#9065B4] w-[20%] flex justify-center">
                      {item.present_value_of_goal}
                    </div>
                    <div className="w-[20%] flex justify-center items-center gap-1.5">
                      <p className="text-sm text-[#15803D] m-0">
                        {item.invest_upfront}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="rounded-tr-xl rounded-tl-xl bg-[#FFFFFF] border-b-[2px] border-[#D9D9D9]">
              <div className="p-2">
                <p className="m-0 text-[#64748B] font-[500]">Goals </p>
              </div>
            </div>
            <div className="px-5 py-2.5 flex flex-col items-center">
              <div className="flex gap-2">
                <div>
                  <img src={star} alt="star" />
                </div>
                <div className="w-[200px]">
                  <p className="m-0 text-[#000000] font-[600] text-[0.9rem]">
                    Set Your Direction
                  </p>
                  <p className="m-0 text-[#64748B] text-[0.85rem]">
                    Transform your financial goals from dreams to achievable
                    milestones
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MoneyManagerHome;
