import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import buildingGray from "assets/images/assets/buildingGray.svg";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_REAL_ESTATE_DETAILED_URL } from "constants/routes";
import AddNewLand from "./Add/AddNewLand";
import useGetBuildingDetails from "../data/useGetBuildingDetail";
import Button from "components/Button/BtnOrange";

const Building = () => {
  const location = useLocation();
  const addNewBtnRef = useRef(null);
  const [selectedEstate, setSelectedEstate] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [reload, setIsReload] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const buildingId =
    location.state?.buildingId || decodeURIComponent(queryParams.get("id"));

  const [getData, getError, isLoading, getBuildindDetails] =
    useGetBuildingDetails();

  useEffect(() => {
    getBuildindDetails(buildingId);
  }, []);

  useEffect(() => {
    if (reload) {
      getBuildindDetails(buildingId);
    }
  }, [reload]);

  const handleAddNew = (estateType, assetId) => {
    setSelectedEstate(estateType);
    setSelectedAssetId(assetId);
    addNewBtnRef.current.click();
  };

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
        <AssetsHeading asset={"Real Estate"} asset_type={"Buildings"} />
        <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
          <div className=" p-[1rem] col-span-3">
            <h4 className="text-darkGray font-[500] text-[1rem]">
              Total Assets Value
            </h4>
            <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
              ₹{getData?.data[0]?.total_assets_value}
            </h4>
          </div>

          <div className="p-[1rem] col-span-2">
            <h4 className="text-darkGray font-[500] text-[1rem]">
              Total Assets
            </h4>
            <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
              {getData?.data[0]?.total_assets}
            </h4>
          </div>

          <div className="p-[1rem] col-span-2">
            <h4 className="text-darkGray font-[500] text-[1rem]">
              Active Assets
            </h4>
            <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
              {getData?.data[0]?.active_assets}
            </h4>
          </div>

          <div className="p-[1rem] col-span-2">
            <h4 className="text-darkGray font-[500] text-[1rem]">
              Idle Assets
            </h4>
            <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
              {getData?.data[0]?.idle_assets}
            </h4>
          </div>
          <div className="flex justify-center items-center p-[1rem] col-span-3">
            <Button
              onClick={() => handleAddNew("Buildings", buildingId)}
              px={"px-[0.9rem]"}
              py={"py-[0.3rem]"}
              name="+ Add New Building"
            />
          </div>
        </div>

        {/* building */}
        <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
          <div className="grid grid-cols-4 gap-2 ">
            {getData ? (
              getData?.data?.map((item) => (
                <Link
                  to={`${ASSETS_REAL_ESTATE_DETAILED_URL.replace(
                    ":estate_type",
                    "building"
                  )}?id=${buildingId}/?building_id=${item.id}`}
                  key={item.id}
                  state={{
                    assetId: buildingId,
                    itemId: item.id,
                    assetCategory: item.building_type,
                    asset_type: "Buildings",
                  }}
                  className="no-underline"
                >
                  <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                    <img
                      src={buildingGray}
                      alt="land"
                      className="w-7 h-7 mb-2"
                    />
                    <h5 className="text-lightGray font-[600] text-[0.9rem]">
                      {item.building_type}
                    </h5>
                    <div className="flex justify-end mt-[1.8rem]">
                      <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                        ₹ {item.total_cost_with_mandates_and_others}
                      </h4>
                    </div>{" "}
                  </div>
                </Link>
              ))
            ) : (
              <div className="rounded-md border-2 border-dashed p-[1rem]">
                <img
                  src={buildingGray}
                  alt="building"
                  className="w-7 h-7 mb-2"
                />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Building
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                    Add New
                  </button>
                </div>{" "}
              </div>
            )}
          </div>
        </div>

        <AddNewLand
          setIsReload={setIsReload}
          assetId={selectedAssetId}
          estateType={selectedEstate}
          triggerElement={
            <button ref={addNewBtnRef} className="hidden">
              Add New
            </button>
          }
        />
      </div>
    </>
  );
};

export default Building;
