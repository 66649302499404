import React, { useEffect, useRef, useState } from "react";
import bankNote from "assets/images/moneyManager/banknoteAccounts.svg";
import creditCard from "assets/images/moneyManager/credit-cardAccount.svg";
import boxNote from "assets/images/moneyManager/box-selectAccount.svg";
import InputField from "components/input/InputField";
import AddNewAccount from "./AddNewAccount";
import useGetAccountsList from "./data/useGetListAccounts";
import AddAccountUpdate from "./AddAccountUpdate";
import usePatchAccount from "./data/usePatchAccount";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const MoneyManagerAccounts = () => {
  const addAccountBtnRef = useRef(null);
  const updateAccountBtnRef = useRef(null);
  const [showPrimaryAccount, setShowPrimaryAccount] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isUpdateAccountOpen, setIsUpdateAccountOpen] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [checkBoxValue, setCheckBoxValue]=useState("");
  const [checkBoxPatchData, setCheckBoxPatchData]=useState([])
  const [getAccountsData, getAccountsError, getAccountsIsLoading, getAccounts] =
    useGetAccountsList();
    const [patchData, patchError, patchIsLoading, patchAccounts ] = usePatchAccount();
    useEffect(()=>{
     if(patchData){
      showSuccessToast("Added Primary Account Successfully");
      setShowPrimaryAccount(false);
      getAccounts()
     }
     if(patchError){
      showErrorToast("Error in adding primary account")
     }
    },[patchData,patchError])
  useEffect(() => {
    getAccounts();
  }, []);
  useEffect(() => {
    if (getAccountsData?.data) {
      setAccountList(getAccountsData?.data?.results);
      const checkBoxIdObj=getAccountsData?.data?.results?.filter((el)=>{
          return el.is_primary
      })
      setCheckBoxValue(checkBoxIdObj?.[0]?.id)
    }
  }, [getAccountsData]);
  useEffect(() => {
    if (isReload) {
      getAccounts();
      setIsReload(false);
    }
  }, [isReload]);
  useEffect(() => {
    if (isAccountOpen) {
      addAccountBtnRef.current.click();
    }
  }, [isAccountOpen]);
  useEffect(() => {
    if (isUpdateAccountOpen) {
      updateAccountBtnRef.current.click();
    }
  }, [isUpdateAccountOpen]);
  const handlePrimaryAccountChange = () => {
    setShowPrimaryAccount(true);
  };

  const handleUpdateDetails = (id) => {
    const updatedData = accountList.filter((el) => el.id === id);
    setUpdateData(updatedData);
    setIsUpdateAccountOpen(true);
  };

  const handleCheckboxChange = (e, id) => {
    setCheckBoxValue(id);
    const modifiedIsPrimary=accountList.map((el,i)=>{
      if(el.id===id){
        return {...el, "is_primary":true}
      }else{
        return {...el, "is_primary":false}
      }
    })
    setCheckBoxPatchData(modifiedIsPrimary)
  };
  const handleSubmitPrimaryAccount=()=>{
    patchAccounts(checkBoxPatchData)
  }
  const primaryAccountList = accountList.filter((el) => el.is_primary);
  const otherAccountList = accountList.filter((el) => !el.is_primary);
  function formatDateLastUpdate(input) {
    if (!input) {
      return "unavailable"; // Fallback message
    }
    const [datePart, timePart, period] = input.split(" ");
    const [day, month, year] = datePart.split("-").map(Number); // Split and convert to numbers
    let [hours, minutes] = timePart.split(":").map(Number); // Split and convert to numbers

    // Adjust hours for 12-hour format
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Create a new Date object
    const date = new Date(year, month - 1, day, hours, minutes);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract formatted parts
    const formattedMonth = monthNames[date.getMonth()];
    const formattedDay = date.getDate();
    const formattedYear = date.getFullYear();
    const formattedHours = date.getHours();
    const formattedMinutes = date.getMinutes().toString().padStart(2, "0");

    // Return formatted string
    return `${formattedMonth} ${formattedDay}, ${formattedYear}, ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <div className="bg-gray-100 h-screen md:px-[1rem] px-6 pt-[2rem] py-4">
      <h4 className="text-darkGray text-[1.4rem] m-0">Accounts</h4>
      {showPrimaryAccount ? (
        <>
          <div className="mt-8 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl">
            <div className="py-2.5 px-3 bg-[#FAF8FC] rounded-t-xl flex items-center justify-between">
              <div>
                <h6 className="text-[#334155] text-[1.1rem] m-0">
                  Select Primary Account
                </h6>
              </div>
            </div>
            <div className="py-2.5 px-3">
              {accountList.map((item, index) => {
                if (item.account_type === "Cash") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <InputField
                            type="radio"
                            className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
                            onChange={(e)=>handleCheckboxChange(e,item.id)}// Pass the onChange handler
                            name="is_primary"
                            value={item.id}
                            checked={item.id === checkBoxValue}
                          />
                        </div>
                        <div>
                          <img src={bankNote} alt="bankNote" />
                        </div>
                        <div className="w-[15%]">
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              Cash
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && accountList.length === 1) ||
                          accountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Bank") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <InputField
                            type="radio"
                            className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
                            onChange={(e)=>handleCheckboxChange(e,item.id)} // Pass the onChange handler
                            name="is_primary"
                            value={item.id}
                            checked={item.id === checkBoxValue}
                          />
                        </div>
                        <div>
                          <img src={boxNote} alt="boxNote" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Bank Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Account Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && accountList.length === 1) ||
                          accountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Credit Card") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <InputField
                            type="radio"
                            className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
                            onChange={(e)=>handleCheckboxChange(e,item.id)} // Pass the onChange handler
                            name="is_primary"
                            value={item.id}
                            checked={item.id === checkBoxValue}
                          />
                        </div>
                        <div>
                          <img src={creditCard} alt="creditCard" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && accountList.length === 1) ||
                          accountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                }
              })}
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-2 items-center">
            <p onClick={()=>setShowPrimaryAccount(false)} className="text-[#334155] cursor-pointer font-[600] text-[0.85rem] m-0">
              Discard Changes
            </p>
            <button onClick={handleSubmitPrimaryAccount} className="text-[#FFFFFF] px-2.5 py-1.5 rounded-md text-[0.85rem] font-[600] bg-[#9065B4]">
              Save as Primary
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-8 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl">
            <div className="py-2.5 px-3 bg-[#FAF8FC] rounded-t-xl flex items-center justify-between">
              <div>
                <h6 className="text-[#334155] text-[1.1rem] m-0">
                  Primary Account
                </h6>
                <p className="text-[#64748B] m-0 font-[500] text-[0.85rem]">
                  Account you use to Pay most of the times
                </p>
              </div>
              <div>
                <button
                  onClick={handlePrimaryAccountChange}
                  className="border-[1px] border-[#9065B4] text-[#9065B4] text-[0.95rem] px-2.5 font-[500] py-1 rounded-lg"
                >
                  Change
                </button>
              </div>
            </div>
            <div className="py-2.5 px-3 w-[100%]">
              {primaryAccountList.map((item, index) => {
                if (item.account_type === "Cash") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <img src={bankNote} alt="bankNote" />
                        </div>
                        <div className="w-[15%]">
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              Cash
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Current Balance
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance || 0}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="bg-[#F8FAFC] cursor-pointer px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && primaryAccountList.length === 1) ||
                          primaryAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Bank") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <img src={boxNote} alt="boxNote" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Bank Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name || ""}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Account Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Current Balance
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="bg-[#F8FAFC] cursor-pointer px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          index === 0 && primaryAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Credit Card") {
                  return (
                    <>
                      <div className="flex gap-4 items-end mt-2">
                        <div>
                          <img src={creditCard} alt="creditCard" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Monthly Available Limit
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="bg-[#F8FAFC] cursor-pointer px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          index === 0 && primaryAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                }
              })}
            </div>
          </div>
          <div className="mt-2.5 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl">
            <div className="py-2.5 px-3 bg-[#FAF8FC] rounded-t-xl flex items-center justify-between">
              <div>
                <h6 className="text-[#334155] text-[1.1rem] m-0">
                  Other Accounts
                </h6>
              </div>
              <div>
                <button
                  onClick={() => setIsAccountOpen(true)}
                  className="border-[1px] border-[#9065B4] text-[#9065B4] text-[0.95rem] px-2.5 font-[500] py-1 rounded-lg"
                >
                  Add Account
                </button>
              </div>
            </div>
            <div className="py-2.5 px-3 w-[100%]">
              {otherAccountList.map((item, index) => {
                if (item.account_type === "Cash") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <img src={bankNote} alt="bankNote" />
                        </div>
                        <div className="w-[15%]">
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              Cash
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Current Balance
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance || 0}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="cursor-pointer bg-[#F8FAFC] px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && otherAccountList.length === 1) ||
                          otherAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Bank") {
                  return (
                    <>
                      <div className="flex gap-4 items-end">
                        <div>
                          <img src={boxNote} alt="boxNote" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Bank Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name || ""}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Account Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Current Balance
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="bg-[#F8FAFC] cursor-pointer px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && otherAccountList.length === 1) ||
                          otherAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                } else if (item.account_type === "Credit Card") {
                  return (
                    <>
                      <div className="flex gap-4 items-end mt-2">
                        <div>
                          <img src={creditCard} alt="creditCard" />
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Name
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_name}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Card Number
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              {item.account_number}
                            </p>
                          </div>
                        </div>
                        <div className="w-[15%]">
                          <p className="m-0 text-[#64748B] text-[0.85rem] font-[500]">
                            Monthly Available Limit
                          </p>
                          <div className="bg-[#F8FAFC] px-2.5 py-1.5 rounded-sm">
                            <p className="m-0 text-[#334155] text-[0.9rem]">
                              ₹{item.current_balance}
                            </p>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <div
                            onClick={() => handleUpdateDetails(item.id)}
                            className="bg-[#F8FAFC] cursor-pointer px-2 py-1.5 rounded-sm flex items-center justify-center"
                          >
                            <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                              Edit Details
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr
                        className={`border-0 h-[2px] bg-[#E2E8F0] mt-2 mb-2 ${
                          (index === 0 && otherAccountList.length === 1) ||
                          otherAccountList.length - 1 === index
                            ? "hidden"
                            : ""
                        }`}
                      />
                    </>
                  );
                }
              })}
            </div>
          </div>
          <div className="mt-2.5 flex justify-end items-center">
            <p className="m-0 text-[#334155] text-[0.8rem]">
              Last Updated on {formatDateLastUpdate(getAccountsData?.data?.updated_at)}
            </p>
          </div>
          {isAccountOpen && (
            <AddNewAccount
              setIsAccountOpen={setIsAccountOpen}
              isAccountOpen={isAccountOpen}
              setIsReload={setIsReload}
              isReload={isReload}
              //  resetState={resetState}
              triggerElement={
                <button ref={addAccountBtnRef} className="hidden">
                  Add New Account
                </button>
              }
            />
          )}
          {isUpdateAccountOpen && (
            <AddAccountUpdate
              setIsUpdateAccountOpen={setIsUpdateAccountOpen}
              isUpdateAccountOpen={isUpdateAccountOpen}
              setIsReload={setIsReload}
              isReload={isReload}
              updateData={updateData[0]}
              //  resetState={resetState}
              triggerElement={
                <button ref={updateAccountBtnRef} className="hidden">
                  Update Account
                </button>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default MoneyManagerAccounts;
