import React, { useContext, useEffect, useState } from "react";
import edit_icon from "assets/images/incomeTax/edit-profile.svg";
import eye from "assets/images/incomeTax/eye.svg";
import plus from "assets/images/incomeTax/plus.svg";
import editPen from "assets/images/incomeTax/edit-3.svg";
import CustomizableDrawer from "../Reports/Drawer";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_PAGE_URL } from "constants/routes";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import InputField from "components/input/InputField";
import useGetBusinessProfile from "./data/useGetBusinessProfile";
import usePatchBusinessBasicDetail from "./data/usePatchBusinessBasicDetail";
import usePatchKycData from "./data/usePatchKycData";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import usePostKycData from "./data/usePostKycData";
import usePostTaxProfileAddress from "../IncomeTax/Data/usePostTaxProfileAddress";
import usePatchTaxProfileAddress from "../IncomeTax/Data/usePatchTaxProfileAddress";
import usePostTaxProfilePasswordChange from "../IncomeTax/Data/usePostTaxProfilePasswordChange";
import usePostTaxProfileMobileUpdate from "../IncomeTax/Data/usePostTaxProfileMobileUpdate";
import usePostTaxProfileMobileVerify from "../IncomeTax/Data/usePostTaxProfileMobileVerify";
import usePostTaxProfileEmailUpdate from "../IncomeTax/Data/usePostTaxProfileEmailUpdate";
import usePostTaxProfileEmailVerify from "../IncomeTax/Data/usePostTaxProfileEmailVerify";
import usePatchTaxProfilePicAddOrUpdate from "../IncomeTax/Data/usePatchTaxProfilePicAddOrUpdate";
import { ProfileContext } from "../Profile/Layout";
import useDeleteTaxProfilePic from "../IncomeTax/Data/useDeleteTaxProfilePic";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import BackToBusinessDashboard from "./BackToBusinessDashboard";
import { HiOutlineUser } from "react-icons/hi2";

const BusinessProfile = () => {
  const navigate = useNavigate();
  const [basicDetails, setBasicDetails] = useState(false);
  const [kycDetails, setKycDetails] = useState(false);
  const [addressDetails, setAddressDetails] = useState(false);
  const [mobileAddressExpand, setMobileAddressExpand] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState(false);
  const [emailDetails, setEmailDetails] = useState(false);
  const [mobileBasicExpand, setMobileBasicExpand] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(0);
  const [mobileKycExpand, setMobileKycExpand] = useState(false);
  const [mobileAccountExpand, setMobileAccountExpand] = useState(false);
  const [showPicView, setShowPicView] = useState(null);
  const [basicsDetailData, setBasicsDetailData]= useState({})
  const [kycData, setKycData]=useState(null)
  const [addressData, setAddressData]=useState([])
  const { updateProfilePic } = useContext(ProfileContext);
  const [tempPicUrl, setTempPicUrl]= useState(null);
  const [businessProfileData, businessProfileError, businessProfileIsLoading, getBusinessProfile] = useGetBusinessProfile();
  const [picPatchData, picPatchError, picPatchIsLoading, patchTaxProfilePic] =
  usePatchTaxProfilePicAddOrUpdate();
  const [
    deletePicData,
    deletePicError,
    deletePicIsLoading,
    deleteTaxProfilePic,
  ] = useDeleteTaxProfilePic();


     useEffect(() => {
       if (picPatchData) {
         console.log("picPatchData", picPatchData)
         // getTaxProfileDetails();
         setShowPicView(tempPicUrl);
         updateProfilePic(tempPicUrl)
         showSuccessToast("Profile Pic Added.");
       }
     }, [picPatchData]);
   
     useEffect(() => {
       if (picPatchError) {
         showErrorToast("Error in Uploading Pic.");
       }
     }, [picPatchError]);

       useEffect(() => {
         if (deletePicData) {
           setShowPicView(null);
           updateProfilePic(null)
           showSuccessToast("Profile Pic Deleted.");
         }
       }, [deletePicData]);
     
       useEffect(() => {
         if (deletePicError) {
           showErrorToast("Error in Deleting Pic.");
         }
       }, [deletePicError]);

  useEffect(()=>{
   getBusinessProfile()
  },[])

  const handleCallAgain=()=>{
    getBusinessProfile()
  }

  useEffect(()=>{
   if(businessProfileData){
    if(businessProfileData?.data?.business_profile){
      setBasicsDetailData(businessProfileData?.data?.business_profile)
      setShowPicView(businessProfileData?.data?.business_profile?.profile_picture)
      updateProfilePic(businessProfileData?.data?.business_profile?.profile_picture)
    }
    if(businessProfileData?.data?.kyc_details){
      setKycData(businessProfileData?.data?.kyc_details)
    }
    if(businessProfileData?.data?.address_data.length>0){
      setAddressData(businessProfileData?.data?.address_data)
    }
   }
  },[businessProfileData])
  const openingDrawer = (val) => {
    setOpenDrawer(val);
  };

  const changingToViewDrawer = () => {
    setAddressDetails(false);
    setBasicDetails(false);
    setEmailDetails(false);
    setPhoneDetails(false);
    setPasswordDetails(false);
    setKycDetails(false);
  };

  const changingToEdit = () => {
    setBasicDetails(!basicDetails);
  };

  const changingToEditKyc = () => {
    setKycDetails(!kycDetails);
  };

  const changingToEditAddress = () => {
    setAddressDetails(!addressDetails);
  };

  const changeToEditPass = () => {
    setPasswordDetails(!passwordDetails);
    setPhoneDetails(false)
    setEmailDetails(false)
  };

  const changeToEditPhone = () => {
    setPhoneDetails(!phoneDetails);
    setPasswordDetails(false)
    setEmailDetails(false)
  };

  const changeToEditEmail = () => {
    setEmailDetails(!emailDetails);
    setPasswordDetails(false)
    setPhoneDetails(false)
  };

  const expandingBasicDetailsView = () => {
    setMobileBasicExpand(true);
  };

  const expandingKycDetailsView = () => {
    setMobileKycExpand(true);
  };
  const expandingAdressDetailsView = () => {
    setMobileAddressExpand(true);
  };

  const expandingAccountDetailsView = () => {
    setMobileAccountExpand(true);
  };

  const handlePrevious = () => {
    navigate(DASHBOARD_PAGE_URL);
  };

  const formatDate=(val)=>{
    const arr= val.split("-").reverse().join("/");
    return arr
  }

    // profile pic api
    const profilePicUpdation = (e) => {
      const uploadPic = e.target.files[0];
      // if (!uploadPic) return;
      // setProfilePicError("");
  
      // if (!uploadPic) {
      //   setProfilePicError("No file selected. Please choose a file.");
      //   return;
      // }
  
      // // Validate file type
      // const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      // if (!validTypes.includes(uploadPic.type)) {
      //   setProfilePicError("Invalid file type. Please upload a JPEG or PNG image.");
      //   return;
      // }
      const previewUrl = URL.createObjectURL(uploadPic);
      setTempPicUrl(previewUrl)
  
      const formData = new FormData();
      formData.append("profile_picture", uploadPic);
      patchTaxProfilePic(formData);
    };
  
    const deleteProfilePic = () => {
      deleteTaxProfilePic();
    };

  return (
    <div className="grid grid-cols-12 relative">
      <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3 pb-[2rem]">
        
        <div className="py-[2.2rem] gap-[1rem] items-center md:flex hidden">
          <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w- h-" />
          </button>
          <h4 className="text-[#334155] text-[1.4rem] font-[600] m-0">
            Business Profile
          </h4>
        </div>
        {basicDetails ? (
          <>
            <div className="hidden md:block">
              <EditBasicDetails changingToEdit={changingToEdit} basicsDetailData={basicsDetailData} handleCallAgain={handleCallAgain}/>
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Basic Details Update"}
                height={400}
                content={<EditBasicDetails changingToEdit={changingToEdit} basicsDetailData={basicsDetailData} handleCallAgain={handleCallAgain}/>}
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 1 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            {showPicView ? (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2 mt-[70px]">
                  <img
                    src={showPicView}
                    className="border-2 border-[#9065B4] rounded-[50%]"
                    height="30%"
                    width="30%"
                  />
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Update
                    </button>
                    <button
                        onClick={deleteProfilePic}
                      className="border border-[#E2E8F0] text-[#DC2626] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2 mt-[70px]">
                  <div className="h-[100px] w-[30%] border-2 border-[#9065B4] rounded-[50%]"></div>
                  <div className="">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Add Photo
                    </button>
                  </div>
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              id="viewPicId"
              onChange={profilePicUpdation}
            />
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-0 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEdit}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileBasicExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <div
                  className={`${mobileBasicExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEdit();
                    openingDrawer(1);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingBasicDetailsView}
                  className={`${mobileBasicExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`w-full md:flex justify-center items-center p-2 ${
                  mobileBasicExpand ? "block" : "hidden"
                }`}
              >
                <div className="w-[20%] md:flex justify-center items-start hidden">
                  {
                    basicsDetailData.profile_picture ?                           
                    <img
                      src={basicsDetailData.profile_picture }
                      className="w-[160px] h-[160px] overflow-hidden rounded-[50%]"
                  /> : 
                  <div className="w-44 h-44 border-2 rounded-[50%] flex justify-center items-center">
                  <HiOutlineUser color="#64748B" className="text-8xl " />
                </div>  
                  }  
                </div>
                <div className="md:w-[80%] w-[100%] md:py-12">
                  <div className="grid grid-cols-12 md:gap-x-4 gap-y-3 md:gap-y-0">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Name
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 ${basicsDetailData?.business_name ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}>
                          {basicsDetailData?.business_name ? basicsDetailData?.business_name : "Business Name"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Owner name
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5  ${basicsDetailData?.business_owner_name ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}>
                        {basicsDetailData?.business_owner_name ? basicsDetailData?.business_owner_name : "Owner Name"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          GST No: [If Available]
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5  ${basicsDetailData?.gst_number ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}>
                        {basicsDetailData?.gst_number ? basicsDetailData?.gst_number : "GST No"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:gap-x-4 gap-y-3 md:gap-y-0 md:mt-8 mt-3">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Mobile Number
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 ${basicsDetailData?.mobile_number ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}>
                        {basicsDetailData?.mobile_number ? basicsDetailData?.mobile_number : "Mobile No"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Email
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5  ${basicsDetailData?.email ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}>
                        {basicsDetailData?.email ? basicsDetailData?.email : "Email"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Website Link
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5  ${basicsDetailData?.company_website ? "text-[#334155]": "text-gray-400"} md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            
                          }}
                        >
                        {basicsDetailData?.company_website ? basicsDetailData?.company_website :"Website Url"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {kycDetails ? (
          <>
            <div className="hidden md:block">
              <EditKycDetails changingToEditKyc={changingToEditKyc} kycData={kycData} handleCallAgain={handleCallAgain}/>
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Business KYC"}
                height={400}
                content={
                  <EditKycDetails changingToEditKyc={changingToEditKyc} kycData={kycData} handleCallAgain={handleCallAgain} />
                }
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 2 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Business KYC
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEditKyc}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileKycExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Business KYC
                </h4>
                <div
                  className={`${mobileKycExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEditKyc();
                    openingDrawer(2);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingKycDetailsView}
                  className={`${mobileKycExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`w-full md:block md:p-6 p-2 ${
                  mobileKycExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:w-[90%] w-[100%]">
                  <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Company Legal Name
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 ${kycData?.legal_name ? "text-[#334155]": "text-gray-400"} border-[1px] border-[#D9D9D9]  md:border-none md:w-[92%]`}>
                          {kycData?.legal_name ? kycData?.legal_name : "Company Legal Name"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Entity
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none  ${kycData?.entity_type ? "text-[#334155]": "text-gray-400"} md:w-[92%]`}>
                        {kycData?.entity_type ? kycData?.entity_type : "Entity Type"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Industry Type
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none  ${kycData?.industry_type ? "text-[#334155]": "text-gray-400"} md:w-[92%]`}>
                        {kycData?.industry_type ? kycData?.industry_type : "Industry Type"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:gap-x-12 md:mt-8 gap-y-3 md:gap-y-0 mt-3">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Company PAN
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none ${kycData?.pan_number ? "text-[#334155]": "text-gray-400"} md:w-[92%]`}>
                        {kycData?.pan_number ? kycData?.pan_number : "PAN No"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          No of Employees [Working Currently]
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none ${kycData?.num_of_employees ? "text-[#334155]": "text-gray-400"} md:w-[92%]`}>
                        {kycData?.num_of_employees ?`Upto ${kycData?.num_of_employees}` : "No of Employees"}
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Date of Foundation
                        </h4>
                        <div className={`bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none ${kycData?.date_of_foundation ? "text-[#334155]": "text-gray-400"} md:w-[92%]`}>
                        {kycData?.date_of_foundation ? formatDate(kycData?.date_of_foundation) : "Date of Foundation"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {addressDetails ? (
          <>
            <div className="hidden md:block">
              <EditAddressDetails
                changingToEditAddress={changingToEditAddress}
                addressData={addressData}
                handleCallAgain={handleCallAgain}
              />
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Address"}
                height={400}
                content={
                  <EditAddressDetails
                  changingToEditAddress={changingToEditAddress}
                  addressData={addressData}
                  handleCallAgain={handleCallAgain}
                />
                }
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 3 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEditAddress}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileAddressExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <div
                  className={`${mobileAddressExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEditAddress();
                    openingDrawer(3);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingAdressDetailsView}
                  className={`${mobileAddressExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`md:p-6 p-3 md:block ${
                  mobileAddressExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:flex justify-between items-center hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address
                  </p>
                  <div className="flex gap-[1rem] w-[28%] items-center justify-end">
                    <p className="m-0 text-[#334155] text-[0.9rem] font-[500] rounded-md bg-[#F1F5F9] py-1.5 px-2">
                    {addressData?.length === 0 || 
                      !addressData[0]?.rent_status
                        ? "Owned" // Default value if no address or rent status
                        : addressData[0]?.rent_status === 1
                        ? "Owned"
                        : "Rented"}
                    </p>
                    <p className="m-0 text-[#64748B] text-[0.9rem] font-[500]">
                      {addressData?.length > 0 && 
                      addressData[0]?.rent_status === 2 && (
                        <a
                          href={
                            addressData[0]?.rental_agreement || "#"
                          }
                          target={
                            addressData[0]?.rental_agreement
                              ? "_blank"
                              : ""
                          }
                          className={`no-underline text-inherit ${
                            !addressData[0]?.rental_agreement &&
                            "pointer-events-none text-gray-400"
                          }`}
                        >
                          View Rental Agreement 
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 mb-4 flex-col md:hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address <span>[Rented]</span>
                  </p>
                  <p className="m-0 text-[#334155] text-[0.8rem] font-[500] rounded-lg bg-[#F1F5F9] py-2 px-2.5 w-[50%]">
                    View Address Proof
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div 
                  className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                    addressData?.length > 0 &&
                    (addressData[0]?.door_no ||
                      addressData[0]?.permise_name)
                      ? ""
                      : "text-gray-400"
                  }`}
                  >
                    {addressData?.length > 0
                      ? (addressData[0]?.door_no
                          ? addressData[0]?.door_no + ", "
                          : "") +
                        (addressData[0]?.permise_name
                          ? addressData[0]?.permise_name
                          : "")
                      : "Door No, Premise Name (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div 
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      addressData?.length > 0 &&
                      (addressData[0]?.street ||
                        addressData[0]?.area || addressData[0]?.pincode  )
                        ? ""
                        : "text-gray-400"
                    }`}
                  >
                  {addressData?.length > 0
                    ? (addressData[0]?.street
                        ? addressData[0]?.street + ", "
                        : "") +
                      (addressData[0]?.area
                        ? addressData[0]?.area + ", "
                        : "") +
                      (addressData[0]?.pincode
                        ? addressData[0]?.pincode
                        : "")
                    : "Street, Area, Pincode (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div 
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      addressData?.length > 0 &&
                      (addressData[0]?.city ||
                        addressData[0]?.state || addressData[0]?.country)
                        ? ""
                        : "text-gray-400"
                    }`}
                  >
                    {addressData?.length > 0
                    ? (addressData[0]?.city
                        ? addressData[0]?.city + ", "
                        : "") +
                      (addressData[0]?.state
                        ? addressData[0]?.state + ", "
                        : "") +
                      (addressData[0]?.country
                        ? addressData[0]?.country
                        : "")
                    : "City, State, Country (Incomplete)"}
                  </div>
                </div>
                <div className="mt-[2rem] md:mb-[1.5rem] mb-[1rem] text-[#334155] text-[0.9rem] font-[500] ">
                  Permanent Address
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div 
                  className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                    addressData?.length > 0 &&
                    (addressData[1]?.door_no ||
                      addressData[1]?.permise_name)
                      ? ""
                      : "text-gray-400"
                  }`}
                  >
                    {addressData?.length > 0
                      ? (addressData[1]?.door_no
                          ? addressData[1]?.door_no + ", "
                          : "") +
                        (addressData[1]?.permise_name
                          ? addressData[1]?.permise_name
                          : "")
                      : "Door No, Premise Name (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div 
                  className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                    addressData?.length > 0 &&
                    (addressData[1]?.street ||
                      addressData[1]?.area || addressData[1]?.pincode  )
                      ? ""
                      : "text-gray-400"
                  }`}
                  >
                  {addressData?.length > 0
                    ? (addressData[1]?.street
                        ? addressData[1]?.street + ", "
                        : "") +
                      (addressData[1]?.area
                        ? addressData[1]?.area + ", "
                        : "") +
                      (addressData[1]?.pincode
                        ? addressData[1]?.pincode
                        : "")
                    : "Street, Area, Pincode (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div 
                  className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                    addressData?.length > 0 &&
                    (addressData[1]?.city ||
                      addressData[1]?.state || addressData[1]?.country)
                      ? ""
                      : "text-gray-400"
                  }`}
                  >
                  {addressData?.length > 0
                    ? (addressData[1]?.city
                        ? addressData[1]?.city + ", "
                        : "") +
                      (addressData[1]?.state
                        ? addressData[1]?.state + ", "
                        : "") +
                      (addressData[1]?.country
                        ? addressData[1]?.country
                        : "")
                    : "City, State, Country (Incomplete)"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem] md:mb-5 mb-[5rem]">
          <div
            className={`bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl flex justify-between ${
              mobileAccountExpand ? "rounded-tl-xl rounded-tr-xl" : "rounded-xl"
            }`}
          >
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Account Settings
            </h4>
            <img
              src={plus}
              alt="plusPic"
              className={`md:hidden ${
                mobileAccountExpand ? "hidden" : "block"
              }`}
              onClick={expandingAccountDetailsView}
            />
          </div>
          <div
            className={`md:grid md:grid-cols-12 p-6 md:gap-[3rem] flex flex-col gap-3 ${
              mobileAccountExpand ? "block" : "hidden"
            }`}
          >
            <div className="md:col-span-4">
              {passwordDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditPasswordDetails changeToEditPass={changeToEditPass}  handleCallAgain={handleCallAgain}/>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Password"}
                      height={400}
                      content={
                        <EditPasswordDetails changeToEditPass={changeToEditPass}  handleCallAgain={handleCallAgain}/>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 4 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Password
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0">****************</p>
                    <div className="flex items-center justify-center gap-1">
                      <img src={eye} alt="eye" />
                      <img
                        src={edit_icon}
                        alt="editPen"
                        className="md:hidden"
                        onClick={() => {
                          changeToEditPass();
                          openingDrawer(4);
                        }}
                      />
                    </div>
                  </div>
                  <h6
                    className="m-0 text-[#64748B] text-[0.8rem] mt-1 text-right w-[98%] cursor-pointer hidden md:block"
                    onClick={() => {
                      changeToEditPass();
                    }}
                  >
                    Change Password
                  </h6>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {phoneDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditPhoneDetails changeToEditPhone={changeToEditPhone} handleCallAgain={handleCallAgain} basicsDetailData={basicsDetailData}/>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mobile Number"}
                      height={400}
                      content={
                        <EditPhoneDetails changeToEditPhone={changeToEditPhone} handleCallAgain={handleCallAgain} basicsDetailData={basicsDetailData}/>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 5 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Phone Number
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">+91-{basicsDetailData?.mobile_number}</p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditPhone}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditPhone();
                        openingDrawer(5);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {emailDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditEmailDetails changeToEditEmail={changeToEditEmail} handleCallAgain={handleCallAgain} basicsDetailData={basicsDetailData}/>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mail Address"}
                      height={400}
                      content={
                        <EditEmailDetails changeToEditEmail={changeToEditEmail} handleCallAgain={handleCallAgain} />
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 6 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Email Address
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">{basicsDetailData.email}</p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditEmail}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditEmail();
                        openingDrawer(6);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <BackToBusinessDashboard/>
      </div>
      
    </div>
  );
};

export default BusinessProfile;

const EditBasicDetails = ({ changingToEdit, basicsDetailData, handleCallAgain }) => {
  const { updateProfilePic, updateBusinessName } = useContext(ProfileContext);
    const [showPicView, setShowPicView] = useState(null);
    const [tempPicUrl, setTempPicUrl]= useState(null);
    const [profilePicError, setProfilePicError]= useState("");
    const user= JSON.parse(localStorage.getItem("user"));
  const [patchBasicData, patchBasicError, patchBasicIsLoading, patchBasicDetails]=usePatchBusinessBasicDetail()
  const [picPatchData, picPatchError, picPatchIsLoading, patchTaxProfilePic] =
  usePatchTaxProfilePicAddOrUpdate();
  const [
    deletePicData,
    deletePicError,
    deletePicIsLoading,
    deleteTaxProfilePic,
  ] = useDeleteTaxProfilePic();
    useEffect(() => {
      if (picPatchData) {
        console.log("picPatchData", picPatchData)
        // getTaxProfileDetails();
        setShowPicView(tempPicUrl);
        updateProfilePic(tempPicUrl)
        showSuccessToast("Profile Pic Added.");
      }
    }, [picPatchData]);
  
    useEffect(() => {
      if (picPatchError) {
        showErrorToast("Error in Uploading Pic.");
      }
    }, [picPatchError]);

      useEffect(() => {
        if (deletePicData) {
          setShowPicView(null);
          updateProfilePic(null)
          showSuccessToast("Profile Pic Deleted.");
        }
      }, [deletePicData]);
    
      useEffect(() => {
        if (deletePicError) {
          showErrorToast("Error in Deleting Pic.");
        }
      }, [deletePicError]);
    // profile pic api
    const profilePicUpdation = (e) => {
      const uploadPic = e.target.files[0];
      // if (!uploadPic) return;
      setProfilePicError("");
  
      if (!uploadPic) {
        setProfilePicError("No file selected. Please choose a file.");
        return;
      }
  
      // Validate file type
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(uploadPic.type)) {
        setProfilePicError("Invalid file type. Please upload a JPEG or PNG image.");
        return;
      }
      const previewUrl = URL.createObjectURL(uploadPic);
      setTempPicUrl(previewUrl)
  
      const formData = new FormData();
      formData.append("profile_picture", uploadPic);
      patchTaxProfilePic(formData);
    };

    const deleteProfilePic = () => {
      deleteTaxProfilePic();
    };

  const [basicDetailsForm, setBasicDetailsForm]=useState({
    business_name:"",
    gst_number:"",
    company_website:"",
    business_owner_name:"",
    mobile_number:"",
    email:""
  })
  useEffect(()=>{
   if(patchBasicData){
    showSuccessToast("Data Updated Successfully")
    updateBusinessName(patchBasicData?.data?.business_name)
    handleCallAgain()
    changingToEdit()
   }
   if(patchBasicError){
    const error = patchBasicError?.err?.response?.data?.data;
    // console.log("error:", error);
    if (error && typeof error === 'object') {
      const keys = Object.keys(error);
      console.log("Keys:", keys[0]);
      showErrorToast(`${keys[0]} cannot be empty`)
    } else {
      showErrorToast("Error in updating data")
    }
    // showErrorToast("Error in updating data")
   }
  },[patchBasicData, patchBasicError])
  useEffect(()=>{
   if(basicsDetailData){
    const {business_name, gst_number,company_website, business_owner_name, mobile_number ,email}=basicsDetailData;
    setBasicDetailsForm({business_name, gst_number,company_website, business_owner_name, mobile_number ,email})
    setShowPicView(basicsDetailData.profile_picture)
   }
  },[basicsDetailData])
  const handleInputChange=(e)=>{
    const {name, value} = e.target
    setBasicDetailsForm({...basicDetailsForm, [name]:value})
  }

  const handleSubmit=()=>{
    patchBasicDetails(basicDetailsForm)
  }
  return (
    <form>
      <div className="bg-white-body rounded-xl md:border md:border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            Basic Details
          </h4>
          <button
            type="button"
            onClick={handleSubmit}
            className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
          >
            Done
          </button>
        </div>
        <div className="w-full flex justify-center items-center p-2">
          <div className="w-[20%] md:flex flex-col items-center justify-start gap-2 hidden">
            {/* <div className="w-44 h-44 border-2 rounded-[50%]"></div>
            <div className="flex gap-2">
              <button
                type="button"
                className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
              >
                Update
              </button>
              <button
                type="button"
                className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
              >
                Remove
              </button>
            </div> */}
                      {showPicView ? (
                        <>
                          <img
                            src={showPicView }
                            className="w-[160px] h-[160px] overflow-hidden rounded-[50%]"
                          />
                          {profilePicError && <p className="text-red-500">{profilePicError}</p>}
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={() =>
                                document.getElementById("picId").click()
                              }
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Update
                            </button>
                            <button
                              type="button"
                              onClick={deleteProfilePic}
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Remove
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* <div className="w-[160px] h-[160px]  border border-[#E2E8F0] rounded-[50%]"></div> */}
                          <div className="w-44 h-44 border-2 rounded-[50%] flex justify-center items-center">
                            <HiOutlineUser color="#64748B" className="text-8xl " />
                          </div> 
                          {profilePicError && <p className="text-red-500">{profilePicError}</p>}
                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                document.getElementById("picId").click()
                              }
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Add Photo
                            </button>
                          </div>
                        </>
                      )}
                      <InputField
                        type="file"
                        id="picId"
                        className="hidden"
                        onChange={profilePicUpdation}
                      />
          </div>
          <div className="md:w-[80%] w-full md:py-12">
            <div className="grid grid-cols-12 md:gap-x-4 gap-y-4 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Name
                  </h4>
                  {/* <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="Business Name"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.business_name}
                   name="business_name"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Owner name
                  </h4>
                  {/* <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="Owner Name"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.business_owner_name}
                   name="business_owner_name"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    GST No: [If Available]
                  </h4>
                  {/* <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="GST No"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.gst_number}
                   name="gst_number"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-x-4 md:mt-8 mt-4 md:gap-x-4 gap-y-4 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Mobile Number
                  </h4>
                  {/* <input
                    type="number"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="number"
                   placeholder="Mobile No"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none appearance-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.mobile_number}
                   name="mobile_number"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Email
                  </h4>
                  {/* <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="Email"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.email}
                   name="email"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Website Link
                  </h4>
                  <InputField 
                   type="text"
                   placeholder="Website Url"
                   className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={basicDetailsForm.company_website}
                   name="company_website"
                  />
                  <div className="md:flex hidden justify-end items-center gap-2 w-[92%]">
                    <span onClick={()=>setBasicDetailsForm((prev)=>({...prev,company_website:""}))} className="text-[#DC2626] text-[0.8rem] cursor-pointer">Delete</span>
                    <Link className="text-[#9065B4] text-[0.8rem] no-underline cursor-pointer" to={basicDetailsForm.company_website} target="_blank">
                      Visit Website
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[1rem] mb-[1rem] w-[100%] md:hidden flex items-center justify-between">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Update
              </button>
              <button
                type="button"
                onClick={changingToEdit}
                className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const EditKycDetails = ({ changingToEditKyc, kycData , handleCallAgain}) => {
  const [postKycData, postKycError, postKycIsLoading, postKycDetails] = usePostKycData();
  const [patchKycData, patchKycError, patchKycIsLoading, patchKycDetails] = usePatchKycData();
  const [doPatch, setDoPatch]=useState(false);
  const [kycForm, setKycForm]=useState({
    legal_name:"",
    entity_type:"",
    industry_type:"",
    date_of_foundation:"",
    pan_number:"",
    num_of_employees:""
  })
  useEffect(()=>{
   if(kycData){
    setDoPatch(true);
    const {legal_name, entity_type, industry_type, date_of_foundation, pan_number, num_of_employees}=kycData;
    setKycForm({legal_name, entity_type, industry_type, date_of_foundation, pan_number, num_of_employees})
   }
  },[kycData])
  useEffect(()=>{
   if(postKycData){
    showSuccessToast("KYC Details Posted Successfully");
    changingToEditKyc();
    handleCallAgain()
   }
   if(postKycError){
     showErrorToast("Error in posting KYC Details")
   }
  },[postKycData, postKycError])
  useEffect(()=>{
    if(patchKycData){
     showSuccessToast("KYC Details Updated Successfully");
     changingToEditKyc();
     handleCallAgain()
    }
    if(patchKycError){
      showErrorToast("Error in Updating KYC Details")
    }
   },[patchKycData, patchKycError])
  const handleInputChange=(e)=>{
    const {name, value} = e.target;
      // Check if the field being updated is "pan_number"
  const updatedValue = name === "pan_number" ? value.replace(/[a-z]/g, (char) => char.toUpperCase()) : value;
    setKycForm({...kycForm, [name]:updatedValue})
  }

  const handleSubmit=()=>{
  //  changingToEditKyc()
    console.log(kycForm)
    if(doPatch){
      patchKycDetails(kycForm)
    }else{
      postKycDetails(kycForm)
      // console.log("kycForm", kycForm)
    }
  }
  return (
    <form>
      <div className="bg-white-body rounded-xl md:border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            Business KYC
          </h4>
          <button
            type="button"
            className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
            onClick={handleSubmit}
          >
            DONE
          </button>
        </div>
        <div className="w-full md:p-6 p-3">
          <div className="md:w-[90%]">
            <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Company Legal Name
                  </h4>
                  {/* <input
                    type="text"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="Legal Name"
                   className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={kycForm.legal_name}
                   name="legal_name"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Entity
                  </h4>
                  {/* <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select> */}
                  <InputField 
                    type="select"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                    value={kycForm.entity_type}
                    onChange={handleInputChange}
                    name="entity_type"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "Sole Proprietorship", label: "Sole Proprietorship" },
                      { value: "Partnership", label: "Partnership" },
                      { value: "Private Limited Company", label: "Private Limited Company" },
                      { value: "Limited Liability Partnership (LLP)", label: "Limited Liability Partnership (LLP)" },
                    ]}
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Industry Type
                  </h4>
                  {/* <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select> */}
                  <InputField 
                    type="select"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                    value={kycForm.industry_type}
                    onChange={handleInputChange}
                    name="industry_type"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "Information Technology", label: "Information Technology" },
                      { value: "Healthcare", label: "Healthcare" },
                      { value: "Manufacturing", label: "Manufacturing" },
                      { value: "Education", label: "Education" },
                      { value: "Retail", label: "Retail" },
                      { value: "Finance", label: "Finance" },
                      { value: "Construction", label: "Construction" },
                      { value: "Hospitality", label: "Hospitality" },
                      { value: "Transportation", label: "Transportation" },
                      { value: "Real Estate", label: "Real Estate" },
                      { value: "Telecommunications", label: "Telecommunications" },
                      { value: "Energy", label: "Energy" },
                      { value: "Food and Beverage", label: "Food and Beverage" },
                      { value: "Media and Entertainment", label: "Media and Entertainment" },
                      { value: "Agriculture", label: "Agriculture" },
                      { value: "Automotive", label: "Automotive" },
                      { value: "Aerospace", label: "Aerospace" },
                      { value: "Government", label: "Government" },
                      { value: "Non-Profit", label: "Non-Profit" },
                    ]
                    }
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0 mt-3 md:mt-8">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Company PAN
                  </h4>
                  {/* <input
                    type="text"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  /> */}
                  <InputField 
                   type="text"
                   placeholder="PAN No"
                   className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={kycForm.pan_number}
                   name="pan_number"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    No of Employees [Working Currently]
                  </h4>
                  {/* <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select> */}
                  <InputField 
                    type="select"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                    value={kycForm.num_of_employees}
                    onChange={handleInputChange}
                    name="num_of_employees"
                    options={[
                      { value: "", label: "Select Number of Employees" },
                      { value: "1-10 Employees", label: "1-10 Employees" },
                      { value: "11-50 Employees", label: "11-50 Employees" },
                      { value: "51-100 Employees", label: "51-100 Employees" },
                      { value: "101-500 Employees", label: "101-500 Employees" },
                      { value: "500+ Employees", label: "500+ Employees" },
                    ]}
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Date of Foundation
                  </h4>
                  {/* <input
                    type="date"
                    className=" !border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-3 focus:outline-none pr-10"
                  /> */}
                  <InputField 
                   type="date"
                   placeholder="Date OF Foundation"
                   className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                   onChange={handleInputChange}
                   value={kycForm.date_of_foundation}
                   name="date_of_foundation"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[1rem] mb-[1rem] w-[100%] md:hidden flex items-center justify-between">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Update
              </button>
              <button
                type="button"
                onClick={changingToEditKyc}
                className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Cancel
              </button>
            </div>
      </div>
    </form>
  );
};

const EditAddressDetails = ({ changingToEditAddress, handleCallAgain, addressData }) => {
  const [doPatch, setDoPatch] = useState(false)
  const [addressPostData, addressPostError, addressPostIsLoading, postTaxProfileAddress] =
  usePostTaxProfileAddress();
  const [
    addressPatchData,
    addressPatchError,
    addressPatchIsLoading,
    patchTaxProfileAddress,
  ] = usePatchTaxProfileAddress();
  const [formData, setFormData] = useState({
    addresses: [
      {
        address_type: 1, // Communication address
        rent_status: '1',
        door_no: '',
        permise_name: '',
        street: '',
        area: '',
        city: '',
        state: '',
        pincode: '',
        country: '',
        rental_agreement: null,
      },
      {
        address_type: 2, // Permanent address
        rent_status: '1',
        door_no: '',
        permise_name: '',
        street: '',
        area: '',
        city: '',
        state: '',
        pincode: '',
        country: '',
        rental_agreement: null,
      },
    ],
    isPermanentAddress: false, // To copy communication address to permanent address
  });
  useEffect(()=>{
   if(addressData.length > 0){
      setDoPatch(true)
      const updatedAddress1={...addressData[0],rent_status:String(addressData[0].rent_status)}
      const updatedAddress2={...addressData[1],rent_status:String(addressData[1].rent_status)}
      setFormData((prevData)=>{
        return {
          ...prevData,
          addresses:[updatedAddress1,updatedAddress2]
        }
      })
   }else{
    setDoPatch(false)
   }
  },[addressData])

  useEffect(()=>{
    if(addressPostData){
      showSuccessToast("Address Posted Successfully")
      changingToEditAddress()
      handleCallAgain()
    }
    if(addressPostError){
      showErrorToast("Error in posting")
    }
  },[addressPostError,addressPostData])

  useEffect(()=>{
    if(addressPatchData){
      showSuccessToast("Address Updated Successfully")
      changingToEditAddress()
      handleCallAgain()
    }
    if(addressPatchError){
      showErrorToast("Error in Updating")
    }
  },[addressPatchData,addressPatchError])
    // Handle input changes for addresses
    const handleInputChange = (e, index, field) => {
      const { value, files } = e.target;
      console.log("value", value, typeof(value))
      let updatedValue = value;
    
      // Check if the input is a file input (e.g., rental_agreement)
      if (files && files[0]) {
        // If it's a file input, get the first file
        updatedValue = files[0];
      }
    
      // Update the specific address field with the new value (or file)
      const updatedAddresses = [...formData.addresses];
      updatedAddresses[index][field] = updatedValue;
    
      // Update the form data
      setFormData({ ...formData, addresses: updatedAddresses });
    };
      // Handle checkbox change to copy communication address to permanent address
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        isPermanentAddress: checked,
      };

      if (checked) {
        // Copy communication address to permanent address
        if(doPatch){
          updatedData.addresses[1] = { ...updatedData.addresses[0],address_type: 2 , id:updatedData.addresses[1].id};
        }else{
          updatedData.addresses[1] = { ...updatedData.addresses[0],address_type: 2 };
        }
      } else {
        // Clear permanent address fields
        updatedData.addresses[1] = {
          address_type: 2,
          rent_status: '1',
          door_no: '',
          permise_name: '',
          street: '',
          area: '',
          city: '',
          state: '',
          pincode: '',
          country: '',
          rental_agreement: null,
        };
      }

      return updatedData;
    });
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log('Form Data:', formData);
    const sendData=new FormData;
    formData.addresses.forEach((address, index)=>{
      if (address.address_type) {
        sendData.append(
          `addresses[${index}].address_type`,
          address.address_type
        );
      }
      if (address.door_no) {
        sendData.append(`addresses[${index}].door_no`, address.door_no);
      }
      if (address.permise_name) {
        sendData.append(
          `addresses[${index}].permise_name`,
          address.permise_name
        );
      }
      if (address.street) {
        sendData.append(`addresses[${index}].street`, address.street);
      }
      if (address.area) {
        sendData.append(`addresses[${index}].area`, address.area);
      }
      if (address.city) {
        sendData.append(`addresses[${index}].city`, address.city);
      }
      if (address.state) {
        sendData.append(`addresses[${index}].state`, address.state);
      }
      if (address.pincode) {
        sendData.append(`addresses[${index}].pincode`, address.pincode);
      }
      if (address.country) {
        sendData.append(`addresses[${index}].country`, address.country);
      }
      if(address.rent_status){
        sendData.append(`addresses[${index}].rent_status`, address.rent_status);
      }

      // Append file if exists
      if (
        address.rental_agreement &&
        typeof address.rental_agreement === "object"
      ) {
        sendData.append(
          `addresses[${index}].rental_agreement`,
          address.rental_agreement
        );
      }
      if (doPatch) {
        sendData.append(
          `addresses[${index}].id`,
          `${address.id}`
        );
      }
    })
    if(doPatch){
      patchTaxProfileAddress(sendData)
    }else{
      postTaxProfileAddress(sendData)
    }
    // Here you can send formData to an API or handle it accordingly
  };
  return (
    <div className="bg-white-body rounded-xl md:border border-[#E2E8F0] md:mt-5 ">
      <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
        <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">Address</h4>
        <button
          type="button"
          className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
          // onClick={changingToEditAddress}
          onClick={handleSubmit}
        >
          DONE
        </button>
      </div>
      <div className="md:p-6 p-3">
        <div className="flex md:flex-row flex-col md:justify-between md:items-center">
          <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
            Communication Address
          </p>
          <div className="flex gap-[1rem] md:w-[36%] justify-between items-center md:justify-end mt-2 md:mt-0">
            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
              {/* <input
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
              /> */}
              <InputField 
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                value="1"
                checked={formData.addresses[0].rent_status === "1"}
                onChange={(e)=>handleInputChange(e, 0, "rent_status")}
                name="rent_status"
              />
              Owned
            </label>
            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
              {/* <input
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
              /> */}
              <InputField 
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                value="2"
                checked={formData.addresses[0].rent_status === "2"}
                onChange={(e)=>handleInputChange(e, 0, "rent_status")}
                name="rent_status"
              />
              Rented
            </label>
            {formData.addresses[0].rent_status === "2" && (
                <div className="">
                  <input
                    type="file"
                    accept=".pdf"
                    id="rental_agreement"
                    className="hidden"
                    name="rental_agreement"
                    onChange={(e)=>handleInputChange(e,0,"rental_agreement")}
                  />
                  <label
                    htmlFor="rental_agreement"
                    className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md cursor-pointer inline-block"
                  >
                    Upload Rental Agreement
                  </label>
                </div>
              )}
            {/* <p className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md hidden md:block">
              Upload Rental Agreement
            </p>
            <p className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md md:hidden block">
              Upload Proof
            </p> */}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Flat / Door No.
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].door_no}
               name="door_no"
               placeholder="Flat / Door No."
               onChange={(e) => handleInputChange(e, 0, 'door_no')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Premise Name
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].permise_name}
               name="permise_name"
               placeholder="Premise Name"
               onChange={(e) => handleInputChange(e, 0, 'permise_name')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Road / Street
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].street}
               name="street"
               placeholder="Road / Street"
               onChange={(e) => handleInputChange(e, 0, 'street')}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Area Locality*
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].area}
               name="area"
               placeholder="Area Locality"
               onChange={(e) => handleInputChange(e, 0, 'area')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Pincode / Zipcode
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="number"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="number"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none appearance-none"
               value={formData.addresses[0].pincode}
               name="pincode"
               placeholder="Pincode/Zipcode"
               onChange={(e) => handleInputChange(e, 0, 'pincode')}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Town / City
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].city}
               name="city"
               placeholder="Town/City"
               onChange={(e) => handleInputChange(e, 0, 'city')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              State
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none">
                <option value="">Select an option</option>
                <option>State 1</option>
                <option>State 2</option>
                <option>State 3</option>
              </select> */}
              <InputField 
               type="select"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].state}
               name="state"
               placeholder="State"
               onChange={(e) => handleInputChange(e, 0, 'state')}
               options={[
                { value: "", label: "Select an Option", disabled:true },
                { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                { value: "Assam", label: "Assam" },
                { value: "Bihar", label: "Bihar" },
                { value: "Chhattisgarh", label: "Chhattisgarh" },
                { value: "Goa", label: "Goa" },
                { value: "Gujarat", label: "Gujarat" },
                { value: "Haryana", label: "Haryana" },
                { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                { value: "Jharkhand", label: "Jharkhand" },
                { value: "Karnataka", label: "Karnataka" },
                { value: "Kerala", label: "Kerala" },
                { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                { value: "Maharashtra", label: "Maharashtra" },
                { value: "Manipur", label: "Manipur" },
                { value: "Meghalaya", label: "Meghalaya" },
                { value: "Mizoram", label: "Mizoram" },
                { value: "Nagaland", label: "Nagaland" },
                { value: "Odisha", label: "Odisha" },
                { value: "Punjab", label: "Punjab" },
                { value: "Rajasthan", label: "Rajasthan" },
                { value: "Sikkim", label: "Sikkim" },
                { value: "Tamil Nadu", label: "Tamil Nadu" },
                { value: "Telangana", label: "Telangana" },
                { value: "Tripura", label: "Tripura" },
                { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                { value: "Uttarakhand", label: "Uttarakhand" },
                { value: "West Bengal", label: "West Bengal" },
                {
                  value: "Andaman and Nicobar Islands",
                  label: "Andaman and Nicobar Islands",
                },
                { value: "Chandigarh", label: "Chandigarh" },
                {
                  value: "Dadra and Nagar Haveli and Daman and Diu",
                  label: "Dadra and Nagar Haveli and Daman and Diu",
                },
                { value: "Delhi", label: "Delhi" },
                { value: "Lakshadweep", label: "Lakshadweep" },
                { value: "Puducherry", label: "Puducherry" },
              ]}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Country
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none">
                <option value="">Select an option</option>
                <option>India</option>
              </select> */}
              <InputField 
               type="select"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].country}
               name="country"
               placeholder="Country"
               onChange={(e) => handleInputChange(e, 0, 'country')}
               options={[
                { value: "", label: "Select an option", disabled: true},
                {label: "India" }
               ]}
              />
            </div>
          </div>
        </div>
        <div className="mt-[2rem]  text-[#334155] text-[0.9rem] font-[500]">
          Permanent address
          <div className="flex items-center mt-1">
            {/* <input
              type="checkbox"
              // onChange={handleCheckboxChange}
              className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
              id="customCheckbox"
            /> */}
            <InputField 
              type="checkbox"
              className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
              id="customCheckbox"
              name="isPermanentAddress"
              checked={formData.isPermanentAddress}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="customCheckbox"
              className="ml-2 text-[#334155] text-[0.9rem] font-[500]"
            >
              Check if your communication address is your permanent address
            </label>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Flat / Door No.
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].door_no}
               name="door_no"
               placeholder="Flat / Door No."
               onChange={(e) => handleInputChange(e, 1, 'door_no')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Premise Name
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].permise_name}
               name="permise_name"
               placeholder="Premise Name"
               onChange={(e) => handleInputChange(e, 1, 'permise_name')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Road / Street
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].street}
               name="street"
               placeholder="Road / Street"
               onChange={(e) => handleInputChange(e, 1, 'street')}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Area Locality*
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].area}
               name="area"
               placeholder="Area Locality"
               onChange={(e) => handleInputChange(e, 1, 'area')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Pincode / Zipcode
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="number"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="number"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none appearance-none"
               value={formData.addresses[1].pincode}
               name="pincode"
               placeholder="Pincode/Zipcode"
               onChange={(e) => handleInputChange(e, 1, 'pincode')}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Town / City
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              /> */}
              <InputField 
               type="text"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].city}
               name="city"
               placeholder="Town/City"
               onChange={(e) => handleInputChange(e, 1, 'city')}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              State
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none">
                <option value="">Select an option</option>
                <option>State 1</option>
                <option>State 2</option>
                <option>State 3</option>
              </select> */}
              <InputField 
               type="select"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[1].state}
               name="state"
               placeholder="State"
               onChange={(e) => handleInputChange(e, 1, 'state')}
               options={[
                { value: "", label: "Select an Option", disabled:true },
                { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                { value: "Assam", label: "Assam" },
                { value: "Bihar", label: "Bihar" },
                { value: "Chhattisgarh", label: "Chhattisgarh" },
                { value: "Goa", label: "Goa" },
                { value: "Gujarat", label: "Gujarat" },
                { value: "Haryana", label: "Haryana" },
                { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                { value: "Jharkhand", label: "Jharkhand" },
                { value: "Karnataka", label: "Karnataka" },
                { value: "Kerala", label: "Kerala" },
                { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                { value: "Maharashtra", label: "Maharashtra" },
                { value: "Manipur", label: "Manipur" },
                { value: "Meghalaya", label: "Meghalaya" },
                { value: "Mizoram", label: "Mizoram" },
                { value: "Nagaland", label: "Nagaland" },
                { value: "Odisha", label: "Odisha" },
                { value: "Punjab", label: "Punjab" },
                { value: "Rajasthan", label: "Rajasthan" },
                { value: "Sikkim", label: "Sikkim" },
                { value: "Tamil Nadu", label: "Tamil Nadu" },
                { value: "Telangana", label: "Telangana" },
                { value: "Tripura", label: "Tripura" },
                { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                { value: "Uttarakhand", label: "Uttarakhand" },
                { value: "West Bengal", label: "West Bengal" },
                {
                  value: "Andaman and Nicobar Islands",
                  label: "Andaman and Nicobar Islands",
                },
                { value: "Chandigarh", label: "Chandigarh" },
                {
                  value: "Dadra and Nagar Haveli and Daman and Diu",
                  label: "Dadra and Nagar Haveli and Daman and Diu",
                },
                { value: "Delhi", label: "Delhi" },
                { value: "Lakshadweep", label: "Lakshadweep" },
                { value: "Puducherry", label: "Puducherry" },
              ]}
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Country
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              {/* <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none">
                <option value="">Select an option</option>
                <option>India</option>
              </select> */}
              <InputField 
               type="select"
               className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
               value={formData.addresses[0].country}
               name="country"
               placeholder="Country"
               onChange={(e) => handleInputChange(e, 0, 'country')}
               options={[
                { value: "", label: "Select an option", disabled: true},
                {label: "India" }
               ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[1rem] mb-[1rem] w-[100%] md:hidden flex items-center justify-between">
        <button
          type="button"
          onClick={handleSubmit}
          className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
        >
          Update
        </button>
        <button
          type="button"
          onClick={changingToEditAddress}
          className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const EditPasswordDetails = ({ changeToEditPass, handleCallAgain }) => {
  const [passData, passError, passIsLoading, postTaxProfilePasswordChange] =
  usePostTaxProfilePasswordChange();
  const [showPassword, setShowPassword]=useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  useEffect(()=>{
    if(passData){
      showSuccessToast("Password changed successfully")
      handleCallAgain()
      changeToEditPass()
    }
    if(passError){
      showErrorToast("Error in changing password")
    }
  },[passData, passError])
  const [passwordForm, setPasswordForm]=useState({
    old_password:"",
    new_password:"",
    confirm_new_password:""
  })
  const handleInputChange=(e)=>{
    const {name, value}=e.target
    setPasswordForm((prevData)=>{
      return {
        ...prevData,[name]:value
      }
    })
  }

 const handleSubmit=()=>{
  console.log(passwordForm)
  postTaxProfilePasswordChange(passwordForm)
 }

 const handleShowPass1=()=>{
    setShowPassword(!showPassword)
 }

 const handleShowPassNew=()=>{
  setShowPasswordNew(!showPasswordNew)
 }

 const handleShowPasswordConfirm=()=>{
   setShowPasswordConfirm(!showPasswordConfirm)
 }
  return (
    <form>
      <div className="p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Current Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none text-[#334155]"
            /> */}
            <InputField 
              type={showPassword ? "text" : "password"}
              placeholder="********"
              className="w-full bg-transparent border-none outline-none text-[#334155]"
              value={passwordForm.old_password}
              name="old_password"
              onChange={handleInputChange}
            />
            {/* <img src={eye} alt="eye" /> */}
            <span onClick={handleShowPass1} className="cursor-pointer">
              {showPassword ? (
                <FaEyeSlash size={18} />
              ) : (
                <FaEye size={18} />
              )}
            </span>
          </div>
        </div>
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            New Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none"
            /> */}
            <InputField 
              type={showPasswordNew ? "text" : "password"}
              placeholder="********"
              className="w-full bg-transparent border-none outline-none text-[#334155]"
              value={passwordForm.new_password}
              name="new_password"
              onChange={handleInputChange}
            />
            {/* <img src={eye} alt="eye" /> */}
            <span onClick={handleShowPassNew} className="cursor-pointer">
              {showPasswordNew ? (
                <FaEyeSlash size={18} />
              ) : (
                <FaEye size={18} />
              )}
            </span>
          </div>
        </div>
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Confirm New Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none"
            /> */}
            <InputField 
              type={showPasswordConfirm ? "text" : "password"}
              placeholder="********"
              className="w-full bg-transparent border-none outline-none text-[#334155]"
              value={passwordForm.confirm_new_password}
              name="confirm_new_password"
              onChange={handleInputChange}
            />
            {/* <img src={eye} alt="eye" /> */}
            <span onClick={handleShowPasswordConfirm} className="cursor-pointer">
              {showPasswordConfirm ? (
                <FaEyeSlash size={18} />
              ) : (
                <FaEye size={18} />
              )}
            </span>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
          >
            Update Password
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditPass}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

const EditPhoneDetails = ({ changeToEditPhone, handleCallAgain, basicsDetailData }) => {
  const [mobileData, mobileError, mobileIsLoading, postTaxProfileMobileChange] =
  usePostTaxProfileMobileUpdate();
  const [
    mobileOtpData,
    mobileOtpError,
    mobileOtpIsLoading,
    postTaxProfileMobileOtp,
  ] = usePostTaxProfileMobileVerify();
  const [otpSent, sentOtpSent]=useState(false)
  const [otpId, setOtpId]=useState("")
  const [mobilePurpose, setMobilePurpose]=useState("")
  const [phoneForm, setPhoneForm]=useState({
    mobile_number:"",
    otp:""
  })

  useEffect(()=>{
    if(basicsDetailData){
      setPhoneForm({...phoneForm, mobile_number: basicsDetailData?.mobile_number })
    }
  },[basicsDetailData])

  useEffect(()=>{
    if(mobileData){
      console.log()
      showSuccessToast("OTP Sent to your Phone")
      sentOtpSent(true)
      setOtpId(mobileData.data.otp_id);
      setMobilePurpose(mobileData.data.purpose)
    }
    if(mobileError){
      showErrorToast("Error in updating Phone Number")
    }
  },[mobileData, mobileError])

  useEffect(()=>{
    if(mobileOtpData){
      showSuccessToast("Phone number Successfully changed")
      changeToEditPhone()
      handleCallAgain()
    }
    if(mobileOtpError){
      showErrorToast("Error in changing Phone Number")
    }
  },[mobileOtpData,mobileOtpError])

  const handleInputChange=(e)=>{
    const {name, value}= e.target
    setPhoneForm((prevData)=>{
      return {
        ...prevData,
        [name]:value
      }
    })
  }

  const handleSubmit=()=>{
    if(otpSent){
      postTaxProfileMobileOtp({ otp_id: otpId, otp: phoneForm.otp, purpose:mobilePurpose });
    }else{
      postTaxProfileMobileChange({mobile_number:phoneForm.mobile_number})
    }
  }
  return (
    <>
      <div className="md:block p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Phone Number
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter Phone No."
            /> */}
            <InputField 
             type="number"
             name="mobile_number"
             onChange={handleInputChange}
             value={phoneForm.mobile_number}
              className="w-full text-[0.9rem] bg-transparent outline-none appearance-none"
              placeholder="Enter Phone No."
            />
          </div>
        </div>
        {otpSent && (
          <div className="mt-3">
            <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">OTP</h6>
            <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">              
              {/* <input
                {...registerMobile("otp", {
                  required: "OTP is required",
                })}
                className="w-full text-[0.9rem] bg-transparent outline-none"
                placeholder="Enter OTP."
              /> */}
              <InputField 
                className="w-full text-[0.9rem] bg-transparent outline-none"
                placeholder="Enter OTP."
                value={phoneForm.otp}
                onChange={handleInputChange}
                name="otp"
              />
            </div>
          </div>
        )}
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
          >
            Update Phone
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditPhone}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

const EditEmailDetails = ({ changeToEditEmail, handleCallAgain ,basicsDetailData}) => {
  const [emailData, emailError, emailIsLoading, postTaxProfileEmailChange] =
  usePostTaxProfileEmailUpdate();
  const [
    emailOtpData,
    emailOtpError,
    emailOtpIsLoading,
    postTaxProfileEmailOtp,
  ] = usePostTaxProfileEmailVerify();
  const [otpSentEmail, setOtpSentEmail]= useState(false);
  const [otp_id, setOtp_id]=useState("")
  const [emailForm, setEmailForm]=useState({
    email:"",
    otp:""
  })

  useEffect(()=>{
   if(basicsDetailData){
    setEmailForm({...emailForm, email:basicsDetailData.email})
   }
  },[basicsDetailData])

  useEffect(()=>{
   if(emailOtpData){
    showSuccessToast("Email Changed Successfully")
    changeToEditEmail()
    handleCallAgain()
   }
   if(emailOtpError){
    showErrorToast("Error in changing Email")
   }
  },[emailOtpData, emailOtpError])

  useEffect(()=>{
   if(emailData){
      showSuccessToast("OTP Sent to Email")
      setOtpSentEmail(true)
      setOtp_id(emailData.data.otp_id);
   }
   if(emailError){
     showErrorToast("Error in updating Email")
   }
  },[emailData, emailError])

  const handleInputChange=(e)=>{
    const {name, value} = e.target;
    setEmailForm({...emailForm, [name]:value})
  }

  const handleSubmit=()=>{
    if(otpSentEmail){
      postTaxProfileEmailOtp({otp_id:otp_id, otp:emailForm.otp})
    }else{
      postTaxProfileEmailChange({"email":emailForm.email})
    }
  }
  return (
    <form>
      <div className="md:block p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Email Address
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter Email."
            /> */}
            <InputField
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter Email."
              type="text"
              onChange={handleInputChange}
              value={emailForm.email}
              name="email"
            />
          </div>
        </div>
        {otpSentEmail && (
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            OTP
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            {/* <input
              {...registerEmail("otp", {
                required: "OTP is required",
              })}
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter OTP."
            /> */}
            <InputField 
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter OTP."
              type="text"
              value={emailForm.otp}
              onChange={handleInputChange}
              name="otp"
            />
          </div>
        </div>
      )}
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            className="bg-[#9065B4] cursor-pointer border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              onClick={handleSubmit}
          >
            Update Email
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] cursor-pointer border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditEmail}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};
