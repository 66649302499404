import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { myContext } from "App";
import WelcomeModal from "containers/BusinessLoginSignUp.js/WelcomeModal";

function LoginPrivateRoute() {
  const navigate = useNavigate();
  const { isLoggedIn, userType } = useContext(myContext);
  const [isOpen, setIsOpen] = useState(true); // Assuming you want it to be open initially

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleGoToNextPage = () => {
    setIsOpen(false);
    if(userType === "personal"){
      navigate("/dashboard"); 
    }else{
      navigate("/business/dashboard")
    }
  };


  // If the user is logged in, show the WelcomeModal
  if (isLoggedIn) {
    return (
      <WelcomeModal
        isOpen={isOpen}
        handleGoToNextPage={handleGoToNextPage}
        closeModal={closeModal}
      />
    );
  }

  // If the user is not logged in, render the outlet (children components)
  return <Outlet />;
}

export default LoginPrivateRoute;

