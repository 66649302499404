import React, { useEffect, useState } from "react";
import useGetStakeholderUserList from "../IncomeTax/Data/useGetStakeholderUserList";
import usePostAccessUserPermission from "../IncomeTax/Data/usePostAccessUserPermission";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const StakeHolderList = () => {
const [userListData, getError, getIsLoading, getUserListData] =
    useGetStakeholderUserList();
const [accessData, accessError, accessIsLoading, postAccessUserPermission] =
    usePostAccessUserPermission();
    const [userList, setUserList]=useState([])
    useEffect(()=>{
        getUserListData()
    },[])
    useEffect(()=>{
      if(userListData?.data?.tabs){
        setUserList(userListData?.data?.tabs)
      }
    },[userListData])
    useEffect(()=>{
      if(accessData){
        showSuccessToast("Access granted successfully")
        if(accessData?.data){    
            let tabRoute="";
            if(accessData?.data?.tab_name === "Liabilities"){
              tabRoute="liabilities"
            }else if(accessData?.data?.tab_name === "Incometax"){
              tabRoute="income-tax"
            }else if(accessData?.data?.tab_name === "Assets"){
               tabRoute="assets"
            }
            const newTabUrl = `${window.location.origin}/${tabRoute}?accessToken=${accessData.data.access_token}&permissionType=${accessData.data.permission_type}&tabId=${accessData.data.tab_id}`;
            window.open(newTabUrl, "_blank"); // Open the new URL in a new tab
        }
      }
    },[accessData])

    useEffect(()=>{
      if(accessError){
        showErrorToast("Failed in granting Access")
      }
    },[accessError])

    const handleAccessAccount=(user)=>{
      const body={
         "encoded_user_id": user.access_given_by,
         "encoded_tab_id": user.tab_id
      }
      postAccessUserPermission(body)
    }
  return (
    <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%] h-screen  md:px-4 px-3">
      <h4 className="font-[500] text-[1.4rem] text-[#334155] leading-[2.7rem] pt-2  md:block">
        StakeHolder
      </h4>
      <div className="bg-white-body rounded-xl border border-[#E2E8F0] mt-5 ">
        <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-xl">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            You can access these Users
          </h4>
          {
                userList?.map((user)=>{
                    return(
                        <div className="flex justify-between mt-4 gap-4">
                        <div>
                            <h6 className="m-0">Name</h6>
                            <p className="m-0 p-2 bg-[#557fa8] text-black rounded-lg">{user.access_given_by_user_name}</p>
                        </div>
                        <div>
                            <h6 className="m-0">Tab Name</h6>
                            <p className="m-0 p-2 bg-[#557fa8] text-black rounded-lg">{user.tab_name}</p>
                        </div>
                        <div>
                            <h6 className="m-0">Permission Type</h6>
                            <p className="m-0 p-2 bg-[#557fa8] text-black rounded-lg">{user.permission_type}</p>
                        </div>
                        <div>
                            <button onClick={()=>handleAccessAccount(user)} className="p-2 bg-slate-900 text-white rounded-md">Access Account</button>
                        </div>
                      </div>
                    )
                })
            }
        </div>
      </div>
    </div>
  );
};

export default StakeHolderList;
