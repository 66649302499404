import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import InputField from "components/input/InputField";
import usePostGoal from "./data/usePostGoal";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePostInitialAccount from "./data/usePostInitialAccount";
import { Troubleshoot } from "@mui/icons-material";
import usePatchAccount from "./data/usePatchAccount";

const AddAccountUpdate = ({
  triggerElement,
  setIsUpdateAccountOpen,
  isUpdateAccountOpen,
  resetState,
  updateData,
  isReload,
  setIsReload,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [showBankOrCredit, setShowBankOrCredit] = useState("");
    const [patchData, patchError, patchIsLoading, patchAccounts ] = usePatchAccount();

    useEffect(()=>{
      if(patchData){
        showSuccessToast("Account Updated Successfully")
        setIsClose(true)
        setIsReload(true)
      }
      if(patchError){
        showErrorToast("Error in Updating account")
      }
    },[patchData,patchError])

  const [submitForm, setSubmitForm] = useState({
    account_type: "",
    account_name: "",
    account_number: "",
    current_balance: "",
  });

  useEffect(() => {
    console.log(updateData)
    if (updateData) {
      setSubmitForm(updateData);
      if(updateData.account_type === "Bank"){
        setShowBankOrCredit("bank")
      }else if(updateData.account_type === "Credit Card"){
        setShowBankOrCredit("credit")
      }else{
        setShowBankOrCredit("cash")
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubmitForm({ ...submitForm, [name]: value });
  };

  const handleSubmitForm = () => {
    let accountTypeName = "";
    if (showBankOrCredit === "bank") {
      accountTypeName = "Bank";
    } else if((showBankOrCredit === "credit")) {
      accountTypeName = "Credit Card";
    } else{
      accountTypeName="Cash"
    }

    console.log({ ...submitForm, account_type: accountTypeName });
    patchAccounts({ ...submitForm, account_type: accountTypeName })
  };
  const getContent = () => {
    return (
      <>
        <div>
          <>
            {showBankOrCredit === "bank" ? (
              <>
                <div className="mt-2.5">
                  <p className="text-[#334155] font-[600] m-0 ">Bank Details</p>
                  <div className="grid grid-cols-12 mt-2 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Bank Name
                      </label>
                      <InputField
                        type="select"
                        className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                        options={[
                          { value: "", label: "Select Bank" },
                          { value: "Axis", label: "Axis" },
                          { value: "Sbi", label: "SBI" },
                          { value: "Hdfc", label: "HDFC" },
                        ]}
                        name="account_name"
                        value={submitForm.account_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>

                    {/* Upfront investment */}
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Account Number
                      </label>
                      <InputField
                        type="number"
                        className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                        placeholder="Account Number"
                        value={submitForm.account_number}
                        onChange={(e) => handleInputChange(e)}
                        name="account_number"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-12 mt-2 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#334155] text-[0.9rem]">
                        Current Balance
                      </label>
                      <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                        <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                          Rs.
                        </span>
                        <InputField
                          type="number"
                          name="current_balance"
                          value={submitForm.current_balance}
                          onChange={(e) => handleInputChange(e)}
                          className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) :  (
              showBankOrCredit === "credit" ? (
                <>
                <div className="mt-2.5">
                  <p className="text-[#334155] font-[600] m-0 ">Card Details</p>
                  <div className="grid grid-cols-12 mt-2 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Bank Name
                      </label>
                      <InputField
                        type="select"
                        className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                        options={[
                          { value: "", label: "Select Bank" },
                          { value: "Axis", label: "Axis" },
                          { value: "Sbi", label: "SBI" },
                          { value: "Hdfc", label: "HDFC" },
                        ]}
                        name="account_name"
                        value={submitForm.account_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>

                    {/* Upfront investment */}
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Card Number
                      </label>
                      <InputField
                        type="number"
                        className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                        placeholder="Account Number"
                        value={submitForm.account_number}
                        onChange={(e) => handleInputChange(e)}
                        name="account_number"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-12 mt-2 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#334155] text-[0.9rem]">
                        Monthly Available Limit
                      </label>
                      <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                        <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                          Rs.
                        </span>
                        <InputField
                          type="number"
                          name="current_balance"
                          value={submitForm.current_balance}
                          onChange={(e) => handleInputChange(e)}
                          className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
              ):(
               <>
                <div className="mt-2.5 mb-2">
                  <p className="text-[#334155] font-[600] m-0 ">Cash Details</p>
                  <div className="grid grid-cols-12 mt-2 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                         Name
                      </label>
                      <InputField
                        type="select"
                        className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                        options={[
                          { value: "", label: "Select" },
                          { value: "Cash", label: "Cash" }
                        ]}
                        name="account_name"
                        value={submitForm.account_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>

                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#334155] text-[0.9rem]">
                        Current Balance
                      </label>
                      <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
                        <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                          Rs.
                        </span>
                        <InputField
                          type="number"
                          name="current_balance"
                          value={submitForm.current_balance}
                          onChange={(e) => handleInputChange(e)}
                          className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
               </>
              )
            )}
          </>
        </div>
      </>
    );
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        resetClose={setIsUpdateAccountOpen}
        isNotDefault={isUpdateAccountOpen}
        triggerElement={triggerElement}
        heading={
          <p className="font-[500] m-0 text-[#64748B]">Update Account</p>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div>
              <div className="flex justify-end gap-[1rem] ">
                <button
                  onClick={handleSubmitForm}
                  className="px-[0.6rem] py-[0.4rem] text-[0.9rem] font-[600] text-[#FFFFFF] bg-[#94A3B8] rounded"
                >
                  Update
                </button>
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddAccountUpdate;
