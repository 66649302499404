import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import upload from "assets/images/liabilities/upload.svg";
import "../../Liabilities/liabilities.css";
import usePostSukanyaDetails from "../data/usePostSukanyaDetails";
import { showSuccessToast } from "components/toaster/toastHelper";
import { ASSETS_TYPE_URL } from "constants/routes";
import { useNavigate } from "react-router-dom";
import datePicker from "assets/images/incomeTax/datePicker.png";

const AddNewDepositType = ({
  depositType,
  triggerElement,
  assetId,
  setIsReload,
  defaultState,
  setDefaultState
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [accHolderName, setAccHolderName] = useState("");
  const [accNo, setaccNo] = useState("");
  const [uploadStatement, setuploadStatement] = useState("");
  const [uploadProofBirth, setuploadProofBirth] = useState(null);
  const [depositAmt, setdepositAmt] = useState("");
  const [depositDate, setdepositDate] = useState("");
  const [uploadDepositProof, setuploadDepositProof] = useState("");
  const [balanceToDate, setbalanceToDate] = useState("");
  const [totalBalance, settotalBalance] = useState("");
  const [interestRate, setinterestRate] = useState("");
  const [redemptionDate, setredemptionDate] = useState("");
  const [maturityAmt, setmaturityAmt] = useState("");
  const [errors, setErrors] = useState({});
  const [isClose, setIsClose] = useState(false);
  const [postData, postError, postIsLoading, postSukanyaDetails] =
    usePostSukanyaDetails();

  const progressSteps = [
    {
      label: "Account",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Investment",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Returns",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const resetState = () => {
    setAccHolderName("");
    setaccNo("");
    setuploadStatement("");
    setuploadProofBirth("");
    setdepositAmt("");
    setdepositDate("");
    setuploadDepositProof("");
    settotalBalance("");
    setbalanceToDate("");
    setinterestRate("");
    setredemptionDate("");
    setmaturityAmt("");
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
    setDefaultState({...defaultState,deposits:false})
  }; 

  const validateInputs = () => {
    const errors = {};

    if (!/^[A-Za-z\s]+$/.test(accHolderName.trim())) {
      errors.accHolderName = "Acc. Holder Name can only contain letters.";
    }
    // if (!uploadProofBirth) errors.uploadProofBirth = "Birth Proof is required.";
    if (!accNo) {
      errors.accNo = "Account number is required.";
    } else if (typeof accNo !== "string" || accNo.trim() === "") {
      errors.accNo = "Account number cannot be empty.";
    } else if (!/^\d+$/.test(accNo)) {
      errors.accNo = "Account number must contain only numeric characters.";
    } else if (accNo.length < 8 || accNo.length > 18) {
      errors.accNo = "Account number must be between 8 and 18 digits.";
    }
    // if (!uploadStatement) errors.uploadStatement = "Statement is required.";

    // if (currentStep == 1) {
    //   if (!depositAmt || isNaN(depositAmt) || depositAmt % 1 !== 0)
    //     errors.depositAmt = "Amt required";
    //   if (!depositDate) errors.depositDate = "Date required";
    //   if (!uploadDepositProof)
    //     errors.uploadDepositProof = "Statement is required.";
    //   if (!balanceToDate || isNaN(balanceToDate) || balanceToDate % 1 !== 0)
    //     errors.balanceToDate = "Amt required";
    //   if (!totalBalance || isNaN(totalBalance) || totalBalance % 1 !== 0)
    //     errors.totalBalance = "Balance required";
    // }

    // if (currentStep == 2) {
    //   if (
    //     !interestRate ||
    //     isNaN(interestRate) ||
    //     interestRate < 0 ||
    //     interestRate > 100
    //   ) {
    //     errors.interestRate = "Interest Rate must be between 0 and 100.";
    //   }
    //   if (!redemptionDate) errors.redemptionDate = "Date required";

    //   if (!maturityAmt || isNaN(maturityAmt) || maturityAmt % 1 !== 0)
    //     errors.maturityAmt = "Amt required";
    // }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    if (postData) {
      showSuccessToast("Details posted");
      setIsClose(true);
      // setIsReload(true);
      navigate(ASSETS_TYPE_URL.replace(":type", "deposits-sukanya-samridhi"), {
        state: { sukanyaId: assetId },
      });
    }
  }, [postData]);

  const createSukanyaData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(accHolderName)) formData.append("account_holder_name", accHolderName);
    if (isNotEmpty(accNo)) formData.append("account_number", accNo);
    if (isNotEmpty(depositAmt)) formData.append("deposit_amount", depositAmt);
    if (isNotEmpty(depositDate)) formData.append("deposit_date", depositDate);
    if (isNotEmpty(totalBalance)) formData.append("total_balance", totalBalance);
    if (isNotEmpty(interestRate)) formData.append("interest_rate", interestRate);
    if (isNotEmpty(redemptionDate)) formData.append("redemption_date", redemptionDate);
    if (isNotEmpty(maturityAmt)) formData.append("expected_maturity_amount", maturityAmt);
    if (isNotEmpty(balanceToDate)) formData.append("balance_to_date", balanceToDate);
  
    // Append the files if they are valid File instances
    if (uploadDepositProof instanceof File) {
      formData.append("deposit_proof", uploadDepositProof);
    }
    if (uploadStatement instanceof File) {
      formData.append("statement_certificate", uploadStatement);
    }
    if (uploadProofBirth instanceof File) {
      formData.append("birth_proof_document", uploadProofBirth);
    }
  
    // Submit the form data via the API call
    postSukanyaDetails(formData, assetId);
  };
  

  const handleNext = () => {
    const isValid = validateInputs();

    if (isValid) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        createSukanyaData();
      }
    } else {
      console.log("Validation failed.");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onEditAccount = () => {
    setCurrentStep(0);
  };

  const onEditInvestment = () => {
    setCurrentStep(1);
  };
  const onEditReturns = () => {
    setCurrentStep(2);
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Account Information
            </h4>

            {/* form */}
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem]">
                <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                  <div className="input-container">
                    <label className="input-label">
                      Account Holder Name(Girl Child)
                    </label>
                    <InputField
                      value={accHolderName}
                      onChange={(e) => setAccHolderName(e.target.value)}
                      type="text"
                      placeholder="Enter Name"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.accHolderName && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.accHolderName}
                      </span>
                    )}
                  </div>

                  <div className="input-container">
                    <label className="input-label">Account Number</label>
                    <InputField
                      value={accNo}
                      onChange={(e) => setaccNo(e.target.value)}
                      placeholder="Enter Acc. No."
                      type="number"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.accNo && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.accNo}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-[1rem]">
                <h4 className="text-[#334155] font-[500] text-[0.9rem]">
                  Add Statement or Certificate
                </h4>
                <div className=" mt-[0.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-statement`}
                    className="hidden"
                    onChange={(e) => setuploadStatement(e.target.files[0])}
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-statement`).click()
                    }
                    className="text-darkGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.3rem] px-[1.3rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Document
                  </button>
                  {errors.uploadStatement && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.uploadStatement}
                    </span>
                  )}
                </div>
              </div>

              <div className="my-[1rem]">
                <h4 className="text-[#334155] font-[500] text-[0.9rem] m-0">
                  Add Proof of Birth Certificate
                </h4>
                <label className="input-label">
                  SSY Deposits can only be withdrawn after the girl becomes 18
                  years old
                </label>
                <div className="mt-[0.5rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-birth`}
                    className="hidden"
                    onChange={(e) => setuploadProofBirth(e.target.files[0])}
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-birth`).click()
                    }
                    className="text-darkGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.3rem] px-[1.3rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Document
                  </button>
                  {errors.uploadProofBirth && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.uploadProofBirth}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Investment Details
            </h4>

            {/* form */}
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Deposit Amount</label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                      Rs.
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                      value={depositAmt}
                      onChange={(e) => setdepositAmt(e.target.value)}
                      type="number"
                      placeholder="Enter Deposit Amount"
                    />
                  </div>
                  <br />
                  {errors.depositAmt && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.depositAmt}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Deposit Date</label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="p-[0.5rem] rounded-l-md">
                      <img src={datePicker} className="w-5 h-5" />
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                      value={depositDate}
                      onChange={(e) => setdepositDate(e.target.value)}
                      type="date"
                    />
                  </div>
                  {errors.depositDate && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.depositDate}
                    </span>
                  )}
                </div>

                <div className="w-[80%] mt-[0.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input`}
                    className="hidden"
                    onChange={(e) => setuploadDepositProof(e.target.files[0])}
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input`).click()
                    }
                    className="text-darkGray bg-white font-[600] text-[0.9rem] border-1 border-lightGray py-[0.3rem] px-[0.8rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Proof
                  </button>
                  {errors.uploadDepositProof && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.uploadDepositProof}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem]  py-[0.7rem]">
              <h4 className="text-[#334155] font-[500] text-[0.9rem]">
                Current Amount
              </h4>
              <div className="grid grid-cols-2 ">
                <div className="input-container">
                  <label className="input-label">Balance to Date</label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                      Rs.
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                      value={balanceToDate}
                      onChange={(e) => setbalanceToDate(e.target.value)}
                      type="number"
                      placeholder="Enter Balance to Date"
                    />
                  </div>
                  <br />
                  {errors.balanceToDate && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.balanceToDate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Total Balance</label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                      Rs.
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                      value={totalBalance}
                      onChange={(e) => settotalBalance(e.target.value)}
                      type="number"
                      placeholer="Enter Total Balance"
                    />
                  </div>
                  {errors.totalBalance && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.totalBalance}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">Returns</h4>

            {/* form */}
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[14rem]">
              <div className="grid grid-cols-3 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Interest Rate</label>
                  <InputField
                    value={interestRate}
                    onChange={(e) => setinterestRate(e.target.value)}
                    type="number"
                    placeholder="Enter Interest Rate"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.interestRate && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.interestRate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Redemption Date</label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="p-[0.5rem] rounded-l-md">
                      <img src={datePicker} className="w-3 h-3" />
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.5rem] w-[80%] rounded-r"
                      value={redemptionDate}
                      onChange={(e) => setredemptionDate(e.target.value)}
                      type="date"
                    />
                  </div>
                  {errors.redemptionDate && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.redemptionDate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">
                    Expected Maturity Amount
                  </label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                    <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                      Rs.
                    </span>
                    <InputField
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[80%] rounded-r"
                      value={maturityAmt}
                      onChange={(e) => setmaturityAmt(e.target.value)}
                      type="number"
                      placeholder="Enter Expected Maturity Amt."
                    />
                  </div>
                  {errors.maturityAmt && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.maturityAmt}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review Details
            </h4>

            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {/* account details */}
              <div className="mt-[1rem] flex justify-between items-center">
                <h4 className="text-[#334155] font-[500] text-[1rem]">
                  Account Details
                </h4>
                <div className="flex gap-2 ">
                  <WhiteButton
                    onClick={() => {
                      if (uploadStatement) {
                        const fileURL = URL.createObjectURL(uploadStatement);
                        window.open(fileURL, "_blank");
                      }
                    }}
                    name={"View Statement"}
                    px={"px-[0.5rem]"}
                    py={"py-[0.2rem]"}
                  />
                  <WhiteButton
                    onClick={() => {
                      if (uploadProofBirth) {
                        const fileURL = URL.createObjectURL(uploadProofBirth);
                        window.open(fileURL, "_blank");
                      }
                    }}
                    name={"View Proof of Birth"}
                    px={"px-[0.5rem]"}
                    py={"py-[0.2rem]"}
                  />

                  <WhiteButton
                    onClick={onEditAccount}
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    name="Edit"
                  />
                </div>
              </div>

              <div className="border bg-white rounded-xl p-[1rem] mt-2">
                {/* form */}
                <div className="flex justify-between ">
                  <div className="flex flex-col gap-[1rem]">
                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Account Holder Name(Girl Child)
                      </label>
                      <InputField
                        className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={accHolderName}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-[1rem]">
                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Account Number
                      </label>
                      <InputField
                        className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={accNo}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* investment details */}
              <div className="mt-[1rem] flex justify-between items-center">
                <h4 className="text-[#334155] font-[500] text-[1rem]">
                  Investment Details
                </h4>
                <div className="flex gap-2 ">
                  <WhiteButton
                    onClick={onEditInvestment}
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    name="Edit"
                  />
                </div>
              </div>

              <div className="border bg-white rounded-xl p-[1rem] mt-2">
                {/* form */}
                <div className="flex justify-between ">
                  <div className="flex flex-col gap-[1rem]">
                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Deposit Amount
                      </label>
                      <InputField
                        className="w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={`${depositAmt} Rs.`}
                        readOnly
                      />
                    </div>

                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Balance to Date
                      </label>
                      <InputField
                        className="w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={`${balanceToDate} Rs.`}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-[1rem]">
                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Deposit Date
                      </label>
                      <InputField
                        className="w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={depositDate}
                        readOnly
                      />
                    </div>

                    <div className="input-container group flex flex-col">
                      <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                        Total Balance
                      </label>
                      <InputField
                        className="w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                        value={`${totalBalance} Rs.`}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* returns details */}
              <div className="mt-[1rem] flex justify-between items-center">
                <h4 className="text-[#334155] font-[500] text-[1rem]">
                  Returns Details
                </h4>
                <div className="flex gap-2 ">
                  <WhiteButton
                    onClick={onEditReturns}
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    name="Edit"
                  />
                </div>
              </div>

              <div className="border bg-white rounded-xl p-[1rem] mt-2">
                <div className="grid grid-cols-3 ">
                  <div className="input-container group flex flex-col">
                    <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                      Interest Rate
                    </label>
                    <InputField
                      readOnly
                      value={`${interestRate} %`}
                      className=" text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    />
                  </div>

                  <div className="input-container group flex flex-col">
                    <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                      Redemption Date
                    </label>
                    <InputField
                      value={redemptionDate}
                      readOnly
                      className=" text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    />
                  </div>

                  <div className="input-container group flex flex-col">
                    <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                      Expected Maturity Amount
                    </label>
                    <InputField
                      value={`${maturityAmt} Rs.`}
                      readOnly
                      className=" text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-[1.2rem] text-[#334155]">
            Add <span className="text-[#9065B4]">{depositType}</span> to your
            Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] mt-[4rem]">
              <WhiteButton
                onClick={currentStep === 0 ? handleClose : handleBack}
                name="Back"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              {currentStep < 3 ? (
                <Button
                  onClick={handleNext}
                  name={currentStep === 2 ? "Preview & Save" : "Next"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : (
                <Button
                  onClick={handleNext}
                  name={"Add to Assets"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              )}
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewDepositType;
