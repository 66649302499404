import React, { useContext, useEffect, useState } from "react";
import logo from "assets/images/dashboard/logo.svg";
import loginMobileView from "assets/images/login/LoginMobileFrontView.svg";
import { useLocation, useNavigate } from "react-router-dom";
import OtpModal from "components/OtpDialog/OtpModal";
import InputField from "components/input/InputField";
import usePostGoogleSignupAccount from "./data/usePostGoogleSignupAcc";
import { showSuccessToast } from "components/toaster/toastHelper";
import usePostVerifyOtp from "./data/usePostVerifyOtp";
import usePostResendOtp from "./data/usePostResendOtp";
import WelcomeModal from "./WelcomeModal";
import { myContext } from "App";
import { BUSINESS_DASHBOARD_URL } from "constants/routes";

const SignupMobileVerification = () => {
  const {setIsLoggedIn}=useContext(myContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [userType, setUserType] = useState(false);
  const [otpSessionId, setOtpSessionId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isOpenWelcome, setIsOpenWelcome]= useState(false)
  const [purposeId, setPurposeId] = useState(null)
  const user = JSON.parse(localStorage.getItem("user"))
  const {userType:businessOrPersonal, setUserType:setBusinessOrPersonal}=useContext(myContext)
  const [
    postGoogleData,
    postGoogleError,
    postGoogleIsLoading,
    postGoogleSignup,
  ] = usePostGoogleSignupAccount();

  const [postOtpData, postOtpError, postOtpIsLoading, postOtp] =
    usePostVerifyOtp();
  const [
    postResendOtpData,
    postResendOtpError,
    postResendOtpIsLoading,
    postResendOtp,
  ] = usePostResendOtp();

  const handleUserType = () => {
    setUserType(!userType);
  };


  const validateFields = () => {
    let newErrors = {};
    if (!mobileNo.trim()) {
      newErrors.mobileNo = "Mobile number is required";
    } else if (!/^[1-9]\d{9}$/.test(mobileNo)) {
      newErrors.mobileNo =
        "Mobile number must be exactly 10 digits and cannot start with 0";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (postGoogleData) {
      showSuccessToast("Sign up with google successful");
      setIsModalOpen(true); // Open the OTP modal
      setMobileNo(mobileNo);
      setOtpSessionId(postGoogleData.data.otp_session_id);
      setPurposeId(postGoogleData.data.purpose)
    }
  }, [postGoogleData]);

  useEffect(() => {
    if (postOtpData) {
      console.log("postOtpData", postOtpData)
      if(postOtpData?.data){
        console.log({...user,is_business_user:postOtpData?.data?.is_business_user})
        if(postOtpData?.data?.account_type === 2 || postOtpData?.data?.account_type === 3){
          setBusinessOrPersonal("business")
        }else{
          setBusinessOrPersonal("personal")
        }
        localStorage.setItem("user",JSON.stringify({...user,account_type:postOtpData?.data?.account_type}))
      }
      showSuccessToast("Otp Submitted Successfully.");
      setIsModalOpen(false); // Close the modal after submitting OTP
      if (userType) {
        navigate("/businessDetails", {
          state: { user_id: postGoogleData.data.user_id },
        });
      } else {
        // navigate("/newLogin");
        setIsOpenWelcome(true)
      }
    }
  }, [postOtpData]);

  useEffect(() => {
    if (postResendOtpData) {
      showSuccessToast("Otp resent Successfully");
      setOtpSessionId(postResendOtpData.data.otp_session_id);
    }
  }, [postResendOtpData]);

  const handleSendOtp = () => {
    // Send OTP logic here
    if (validateFields()) {
      console.log("mobileNumber:", {
        account_type: userType == true ? 2 : 1,
        mobile_number: mobileNo,
      });
      postGoogleSignup({
        account_type: userType == true ? 2 : 1,
        mobile_number: mobileNo,
        user_id:user?.id
      });
    }
  };

  const handleOtpSubmit = (otp) => {
    console.log("Submitted OTP:", otp);
    postOtp({ otp_session_id: otpSessionId, otp: otp, purpose:purposeId });
  };

  const handleResend = () => {
    postResendOtp({ mobile_number: mobileNo, purpose:purposeId })
  };

  const closeModal = () => {
    setIsOpenWelcome(false);
  };

  const handleGoToNextPage = () => {
    setIsOpenWelcome(false);
    setIsLoggedIn(true)
    if(businessOrPersonal === "business"){
      navigate(BUSINESS_DASHBOARD_URL)
    }else{
      navigate("/dashboard");
    }
  };
  return (
    <div className="bg-[#FFFFFF] w-full min-h-screen flex">
      <div className="md:w-[50%] w-[100%] min-h-screen flex items-center justify-center">
        <div className="md:w-[60%] w-[80%]">
          <div className="flex flex-col items-center w-[90%]">
            <img src={logo} alt="logo" width="60%" />
            <p className="m-0 text-[#334155] text-[0.9rem]">
              All-in-one financial care
            </p>
          </div>
          <div className="flex flex-col items-center mt-4 mb-3 md:w-[90%] md:px-10 gap-1.5">
            <p className="m-0 text-[#334155] text-[1rem] font-[500]">
              Hello, {user?.full_name || "User"}
            </p>
            <p className="m-0 text-[#334155] text-[1rem] font-[500] text-center">
              Add few details to get you started
            </p>
          </div>
          <div className="md:w-60% flex flex-col items-center mt-4 gap-1">
            <p className="m-0 text-[#334155] text-[0.95rem] md:text-[1rem] font-[600]">
              Account Type
            </p>
            <div className="bg-[#F3EFF8] rounded-xl px-3 py-2.5 md:w-[52%] flex justify-between">
              <span
                onClick={handleUserType}
                className={` font-[600] py-2 px-2.5  md:text-[0.9rem] text-[0.83rem] cursor-pointer ${
                  userType ? "" : "bg-[#FFFFFF] shadow-sm rounded-xl"
                }`}
              >
                Personal
              </span>
              <span
                onClick={handleUserType}
                className={`font-[600] py-2 px-2.5 md:text-[0.9rem] text-[0.83rem] cursor-pointer ${
                  userType ? "bg-[#FFFFFF] shadow-sm rounded-xl" : ""
                }`}
              >
                Business
              </span>
            </div>
          </div>
          <div className="md:w-[90%] p-2 mt-2 flex flex-col justify-center gap-3">
            <div className="flex flex-col gap-1 ">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                Mobile
              </p>
              <InputField
                type="tel" 
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
                name="mobileNo"
                validation={{ required: true }}
              />
              {errors.mobileNo && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.mobileNo}
                </p>
              )}
            </div>
            <div className="">
              <button
                className="text-[#FFFFFF] bg-[#94A3B8] rounded-md px-2 py-1.5 w-[100%]"
                onClick={() => {
                  handleSendOtp();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <OtpModal
        otp_session_id={otpSessionId}
        mobileNo={mobileNo}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        handleOtpSubmit={handleOtpSubmit}
        handleResend={handleResend}
      />
      <div className="w-[50%] min-h-screen border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <div className="flex items-center justify-center">
            <img
              src={loginMobileView}
              alt="loginMobileView"
              height="80%"
              width="80%"
            />
          </div>
          <div className="flex flex-col items-center w-[40%]">
            <p className="m-0 text-[#000000] text-[1rem] font-[600]">
              Welcome to BeyondTax
            </p>
            <p className="m-0 text-[#000000] text-[0.8rem] text-center mt-2">
              Let's confirm your mobile number to get you started on your
              financial journey.
            </p>
          </div>
        </div>
      </div>
     <>
       {isOpenWelcome && (
        <WelcomeModal
          isOpen={isOpenWelcome}
          handleGoToNextPage={handleGoToNextPage}
          closeModal={closeModal}
        />
      )}
    </>
    </div>
  );
};

export default SignupMobileVerification;
