import React, { useEffect, useRef, useState } from 'react'
import extraArrow from "assets/images/moneyManager/Extra Options.svg"
import AddNewGoal from './AddNewGoal';
import useGetBudget from './data/useGetBudget';
import upDownArrow from "assets/images/moneyManager/chevrons-up-down.svg";
import trash from "assets/images/moneyManager/trashMoneyManager.svg";
import InputField from 'components/input/InputField';
import usePostAddExpenseCategory from './data/usePostAddExpenseCategory';
import { showErrorToast, showSuccessToast } from 'components/toaster/toastHelper';
import usePatchCategoryExpense from './data/usePatchCategoryExpense';
import usePostSetCategory from './data/usePostSetCategory';
import useDeleteCategory from './data/useDeleteCategory';
import editPen from "assets/images/incomeTax/editPen.png"
import useDeleteGoals from './data/useDeleteGoals';

const BudgetAndPlans = () => {
    const [showDetailView, setShowDetailView] = useState(false);
    const [budgetData, setBudgetData]=useState({})
    const [isGoal, setIsGoal] = useState(false);
    const [isReload, setIsReload]=useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [showGoalEdit, setShowGoalEdit]= useState(null);
    const [editableGoal, setEditableGoal]=useState(null);
    const [categorySort,setCategorySort]=useState(false);
    const [spentSort, setSpentSort]=useState(false);
    const containerRef = useRef(null);
    const addGoalBtnRef= useRef(null);
    const [getBudgetData, getBudgetError, getBudgetIsloading, getBudget ] = useGetBudget();
    const [postCategoryData, postCategoryError, postCategoryIsLoading, postCategory ] = usePostAddExpenseCategory();
    const [patchCategoryData, patchCategoryError, patchCategoryIsLoading, patchCategory ] = usePatchCategoryExpense();
    const [postSetData, postSetError, postSetIsLoading, postSet ] = usePostSetCategory();
    const [deleteData, deleteError, deleteIsLoading, deleteCategory ] = useDeleteCategory();
    const [deleteGoalData, deleteGoalError, deleteGoalIsLoading, deleteGoal ] = useDeleteGoals();
    const [showInput, setShowInput] = useState(false); // Toggle input field visibility
    const [editingIndex, setEditingIndex]=useState("")
    const [newExpenseForm, setNewExpenseForm] = useState({
      category: "",
      budget: ""
    }); // Temporary state for the new expense

    const [setForm,setSetForm] = useState(
       {
        category_id:"",
        budget_amount:"",
        budget_frequency:"monthly"
       }
    )
  
    const handleAddNewExpense = () => {
      setShowInput(true); // Show input fields
      setEditingIndex("");
      setNewExpenseForm({
        category: "",
        budget: ""
      })
    };

    useEffect(()=>{
     if(postCategoryData){
       showSuccessToast("Added Category Successfully")
       getBudget()
     }
     if(postCategoryError){
       showErrorToast("Error in adding category")
     }
    },[postCategoryData,postCategoryError])

    useEffect(()=>{
      if(deleteData){
        showSuccessToast("Category Deleted Successfully")
        getBudget()
      }
      if(deleteError){
        showErrorToast("Error in deleting category")
      }
     },[deleteData,deleteError])

     useEffect(()=>{
      if(deleteGoalData){
        showSuccessToast("Goal Deleted Successfully")
        getBudget()
      }
      if(deleteGoalError){
        showErrorToast("Error in deleting goal")
      }
     },[deleteGoalData,deleteGoalError])

    useEffect(()=>{
      if(postSetData){
        showSuccessToast("Updated Budget Successfully")
        getBudget()
      }
      if(postSetError){
        showErrorToast("Error in updating budget")
      }
     },[postSetData,postSetError])

    useEffect(()=>{
      if(patchCategoryData){
        showSuccessToast("Updated Category Successfully")
        getBudget()
      }
      if(patchCategoryError){
        showErrorToast("Error in updating category")
      }
     },[patchCategoryData,patchCategoryError])

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewExpenseForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleAddExpenseToList = (e) => {
      if (e.key === "Enter") {
         if(newExpenseForm?.id){
           patchCategory({category:newExpenseForm.category, budget:newExpenseForm.budget}, {id:newExpenseForm.id})
           setEditingIndex("")
         }else{
          postCategory(newExpenseForm)
         }
          setNewExpenseForm({ category: "", budget: "" });
          setShowInput(false);
      }
    };

    const handleKeyDown=(e, id)=>{
       if(e.key === "Enter"){
          postSet({...setForm, category_id:id})
          setSetForm({category_id:"", budget_amount:"", budget_frequency:"monthly"})
          setHoveredIndex(null)
       }
    }


    const handleToggleChange = () => {
      setShowDetailView(!showDetailView);
    };
    useEffect(()=>{
     getBudget()
    },[])

    useEffect(()=>{
      if(isReload){
        getBudget()
        setIsReload(false)
      }
    },[isReload])

    useEffect(()=>{
     if(getBudgetData){
      setBudgetData(getBudgetData?.data)
      if(getBudgetData?.data?.budget_data.length > 0 || getBudgetData?.data?.summary.length > 0 || getBudgetData?.data?.goals.length > 0){
        setShowDetailView(true)
      }
     }
    },[getBudgetData])


    // const incomeBudget = 20000; // Total budget for income
    // const expenseUsed = 11897; // Amount used for expense
    // const totalExpenseBudget = 20000; // Assuming the expense budget is the same as income
  
    // const incomeProgress = (incomeBudget / incomeBudget) * 100; // Always 100%
    // const expenseProgress = (expenseUsed / totalExpenseBudget) * 100;

    const handleOpenDilog=()=>{
        setIsGoal(true)
    }

    useEffect(()=>{
    if(isGoal){
        addGoalBtnRef.current.click()
    }
    },[isGoal])

    const resetState=()=>{
        setIsGoal(false)
        setEditableGoal(null)
    }

    const handleEdit=(id)=>{
      const editingObj=budgetData?.budget_data?.filter((el)=>{
        return el.id===id
      })
      const {actual_spent, average_spent, ...rest}=editingObj[0];
      setEditingIndex(rest.id)
      setNewExpenseForm(rest)
    }

    const handleSetChange=(e)=>{
      const {name, value} = e.target;
      setSetForm({...setForm, [name]:value})
    }

    const handleDelete=(id)=>{
       deleteCategory({"id":id})
    }

    const handleGoalDelete=(id)=>{
      deleteGoal({"id":id})
    }

    const handleEditableGoal=(id)=>{
      const editableArray=budgetData?.goals?.filter((el)=>{
          return el.id===id
      })
      setEditableGoal(editableArray[0]);
      setIsGoal(true);
    }

    const handleSortingCategory=()=>{
       if(categorySort){
        //  desc
        getBudget({sort_by:"category",sort_order:"desc"})
       }else{
        // asc
        getBudget({sort_by:"category",sort_order:"asc"})
       }
       setCategorySort(!categorySort)
    }
    const handleSortingSpent=()=>{
       if(spentSort){
        // desc
        getBudget({sort_by:"actual_spent",sort_order:"desc"})
       }else{
        // asc
        getBudget({sort_by:"actual_spent",sort_order:"asc"})
       }
       setSpentSort(!spentSort)
    }
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowInput(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  return (
    <>
      {getBudgetIsloading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
    <div className="bg-gray-100 h-full md:px-[1rem] px-6 pt-[2rem] py-4">
    <h4 className="text-darkGray text-[1.4rem] m-0">Budget</h4>
    {showDetailView ? (
      <>
        <div className="mt-6 py-4 px-2.5 flex gap-2">
           <div className='w-[68%]'>
           <div className='flex justify-between items-center px-1'>
            <p className='m-0 text-[#334155] font-[500] text-[1.1rem]'>Expense</p>
            {/* <p className='m-0 text-[#334155] font-[500] text-[1.1rem]'></p> */}
            <InputField
              type="select"
              className="border-[1px] rounded-md bg-white text-[0.9rem] p-[0.48rem] focus:outline-none"
              options={[
                { value: "", label: "Select Type" },
                { value: "monthly", label: "Monthly" },
                { value: "weekly", label: "Weekly" },
                { value: "yearly", label: "Yearly" },
              ]}
              name="budget_frequency"
              value={setForm.budget_frequency}
              onChange={(e)=>handleSetChange(e)}
            />
          </div>
               <div className='border-[1px] border-[#D9D9D9] bg-[#FFFFFF] w-[100%] rounded-xl p-3 mt-2'>
                 <div className='px-2.5 py-2 flex items-center justify-between'>
                     <div onClick={()=>handleSortingCategory()} className='flex gap-1 p-1 border-[1px] border-[#D9D9D9] rounded-md cursor-pointer'>
                      <p className='m-0 text-[#334155] font-[500]'>Category</p>
                      <img src={upDownArrow} alt='upDownArrow'/>
                     </div>
                     <div onClick={()=>handleSortingSpent()} className='flex gap-1 p-1 border-[1px] border-[#D9D9D9] rounded-md cursor-pointer'>
                      <p className='m-0 text-[#334155] font-[500]'>Actual Spent</p>
                      <img src={upDownArrow} alt='upDownArrow'/>
                     </div>
                     <p className='m-0 text-[#334155] font-[500]'>Budget</p>
                 </div>
                  <div className='mt-3.5'>
                    {budgetData?.budget_data?.map((item, index) => {
                      return editingIndex === item.id ? (
                        <>
                          <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-lg py-2 px-2.5 mt-2 flex justify-between items-center">
                            <div className="w-[33%]">
                              <p className="m-0 text-[0.75rem] text-[#334155] font-[500]">Name</p>
                              <InputField
                                type="text"
                                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                                placeholder="Enter Name"
                                onKeyDown={handleAddExpenseToList}
                                value={newExpenseForm.category}
                                onChange={(e) => handleInputChange(e)}
                                name="category"
                              />
                            </div>
                            <div className="w-[23%] flex gap-1">
                              <div className="w-[98%]">
                                <p className="m-0 text-[0.75rem] text-[#334155] font-[500]">Budget</p>
                                <InputField
                                  type="number"
                                  className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5 appearance-none"
                                  placeholder="Enter Amount"
                                  onKeyDown={handleAddExpenseToList}
                                  value={newExpenseForm.budget}
                                  onChange={(e) => handleInputChange(e)}
                                  name="budget"
                                />
                              </div>
                              <div className="flex items-center">
                                <img src={trash} alt="trashPic" className="mt-3.5 cursor-pointer" onClick={()=>handleDelete(item.id)} />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div  
                            key={index}
                            className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-lg py-2 px-2.5 flex items-center justify-between mt-2"
                          >
                            <p onClick={()=>handleEdit(item.id)} className="m-0 text-[#334155] font-[500] w-[33%] cursor-pointer">{item.category}</p>
                            <p className="m-0 text-[#334155] font-[500] w-[33%] flex justify-center">
                              ₹{item.actual_spent}
                            </p>
                            {item.budget ? (
                              <p
                                onClick={() => handleEdit(item.id)}
                                className="m-0 text-[#334155] font-[500] w-[33%] flex justify-end cursor-pointer"
                              >
                                ₹{item.budget}
                              </p>
                            ) : hoveredIndex === item.id ? (
                              <div className='w-[33%] flex justify-end'>
                              <InputField
                                type="number"
                                value={setForm.budget_amount}
                                name="budget_amount"
                                onChange={(e) =>handleSetChange(e)}
                                onKeyDown={(e) => handleKeyDown(e, item.id)}
                                className="border-[1px] border-[#D9D9D9] rounded-md w-[60%] px-2 py-1 text-[#334155] font-[500] focus:outline-none appearance-none"
                                autoFocus
                                placeholder="Set Budget"
                                onMouseLeave={() => setHoveredIndex(null)}
                              />
                              </div>
                            ) : (
                              <p
                                className="m-0 text-[#334155] font-[500] w-[33%] flex justify-end cursor-pointer hover:underline"
                                onMouseEnter={() => setHoveredIndex(item.id)}
                              >
                                + Set
                              </p>
                            )}
                          </div>
                        </>
                      );
                    })}

                    {
                      showInput && <div ref={containerRef} className='border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-lg py-2 px-2.5 mt-2 flex justify-between items-center'>
                      <div className='w-[33%]'>
                         <p className='m-0 text-[0.75rem] text-[#334155] font-[500]'>Name</p>
                         <InputField
                           type="text"
                           className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                           placeholder="Enter Name"
                           onKeyDown={handleAddExpenseToList}
                           value={newExpenseForm.category}
                           onChange={(e) => handleInputChange(e)}
                           name="category"
                         />
                      </div>
                      <div className='w-[23%] flex gap-1'>
                       <div className='w-[98%]'>
                       <p className='m-0 text-[0.75rem] text-[#334155] font-[500]'>Budget</p>
                         <InputField
                           type="number"
                           className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5 appearance-none"
                           placeholder="Enter Amount"
                           onKeyDown={handleAddExpenseToList}
                           value={newExpenseForm.budget}
                           onChange={(e) => handleInputChange(e)}
                           name="budget"
                         />
                       </div>
                       {/* <div className="flex items-center">
                         <img src={trash} alt='trashPic' className='mt-3.5'/>
                       </div> */}
                      </div>
                   </div>
                    }
                  </div>
                  <div className='mt-3.5'>
                    <p onClick={handleAddNewExpense} className='m-0 font-[500] text-[#9065B4] text-[0.95rem] cursor-pointer'>+ Add New Expense</p>
                  </div>
               </div>
               <div className='border-[1px] border-[#D9D9D9] bg-[#F3EFF8] w-[100%] rounded-xl p-2 flex justify-between mt-2'>
                    <p className='m-0 text-[#334155] font-[500]'>Total Expense</p>
                    <p className='m-0 text-[#9065B4] font-[500]'>₹{budgetData?.summary?.total_expense || 0}</p>
               </div>
           </div>
           <div className='w-[28%]'>
                <div className='border-[1px] border-[#D9D9D9] bg-[#EAE1F3] w-[100%] rounded-xl px-3 py-4 flex justify-center items-center'>
                    <div className='flex flex-col items-center gap-2'>
                       <p className='m-0 text-[#334155] font-[600] text-[1.3rem]'>₹{budgetData?.summary?.expected_savings}</p>
                       <p className='m-0 text-[#334155] font-[500]'>Expected Savings</p>
                    </div>
                </div>
                <div className='mt-3.5 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-lg p-2.5'>
                    <p className="font-[500] text-[#334155]">Summary</p>
                    <div className="mt-2">
                        {/* Income Section */}
                        <div className="mb-2">
                        <div className="flex justify-between">
                            <p className="font-[600] text-[#334155] m-0 text-[0.9rem]">Expenses</p>
                            <p className="font-[600] text-[#334155] m-0 text-[0.9rem]">₹{budgetData?.summary?.total_expense}</p>
                        </div>
                        </div>

                        {/* Expense Section */}
                        <div className="mb-2">
                        <div className="flex justify-between">
                            <p className="font-[600] text-[#334155] m-0 text-[0.9rem]">Budget</p>
                            <p className="font-[600] text-[#334155] m-0 text-[0.9rem]">₹{budgetData?.summary?.total_budget}</p>
                        </div>
                        </div>
                        <hr className='mb-2'/>
                        <div className="flex justify-between">
                            <p className="font-[500] text-[#64748B] m-0 text-[0.8rem]">Total Amount to Set Goal</p>
                            <p className="font-[500] text-[#64748B] m-0 text-[0.8rem]">₹0</p>
                        </div>
                    </div>

                </div> 
                <div className='mt-3.5 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-lg px-2.5 py-3'>
                    <div className='flex items-center justify-between'>
                      <p className="font-[500] text-[#334155]">Goals</p>
                      <p onClick={handleOpenDilog} className="font-[500] text-[#9065B4] text-[0.85rem] cursor-pointer">+ Add New Goal</p>
                    </div>
                    {
                      budgetData?.goals?.map((item,i)=>(
                        <div key={item.id} className='bg-[#F8FAFC] rounded-lg p-2 mt-1'>
                        {/* Expense Section */}
                        <div className="">
                        <div className="flex items-center justify-between">
                            <div className='flex gap-1 items-center'>
                              <p className="font-[600] text-[#334155] m-0 text-[1.1rem]">{item.goal_name}</p>
                              {
                                <>
                                 <div onMouseLeave={()=>setShowGoalEdit(null)} className={`flex flex-col gap-1 shadow-lg p-1 bg-inherit cursor-pointer ${showGoalEdit?.id===item.id ? "":"hidden"}`}>
                                     <div className='flex gap-[2px]' onClick={()=>handleGoalDelete(item.id)}>
                                        <img src={trash} alt='delete'/>
                                        <p className='m-0 text-[#334155] font-[500] text-[0.7rem]'>Delete</p>
                                     </div>
                                     <div className='flex gap-[2px]' onClick={()=>handleEditableGoal(item.id)}>
                                        <img src={editPen} alt='edit' height={"1px"}/>
                                        <p className='m-0 text-[#334155] font-[500] text-[0.7rem]'>Edit</p>
                                     </div>
                                </div>
                                <img src={extraArrow} onMouseEnter={()=>setShowGoalEdit({id:item.id})} className={`${showGoalEdit?.id===item.id ? "hidden":""}`}/>
                                </>
                              }
                            </div>
                            <p className="font-[600] text-[#334155] m-0 text-[1.1rem]">₹{item.present_value_of_goal}</p>
                        </div>
                        <div className="w-full h-3 mt-1 bg-gray-200 rounded-full">
                            <div
                            className="h-3 bg-purple-500 rounded-full"
                            style={{ width: `${(item?.months_completed * 100)/(item?.total_months)}%` }}
                            ></div>
                        </div>
                        <div className="flex justify-between mt-1">
                            <p className="text-[#334155] m-0 text-[0.8rem]">{item?.months_completed}/{item?.total_months} Months Completed</p>
                            <p className="text-[#334155] m-0 text-[0.8rem]">₹{item?.monthly_savings.toFixed(2)}/Month</p>
                        </div>
                        </div>
                    </div>
                      ))
                    }
                </div>
           </div>
           {
             isGoal && <AddNewGoal 
             setIsGoal={setIsGoal}
             isGoal={isGoal}
             editableGoal={editableGoal}
             setEditableGoal={setEditableGoal}
             setIsReload={setIsReload}
             isReload={isReload}
             resetState={resetState}
             triggerElement={
              <button ref={addGoalBtnRef} className="hidden">
                Add Goal
              </button>
            }
             />
           }
        </div>
      </>
    ) : (
      <>
        <div className="flex items-center justify-center mt-8 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl py-4">
          <div className="flex flex-col gap-[0.35rem]">
            <h6 className="m-0 text-[#334155] text-center font-[500] text-[1.25rem]">
            Plan Your Financial Goals
            </h6>
            <p className="m-0 text-[#334155] text-center text-[0.95rem] font-[500]">
            Budgeting & Planning for a Secure Future
            </p>
            <ul className="!list-disc list-inside text-[#334155] text-[0.95rem] font-[500]">
              <li>•Define Your Budget - <span className='text-[#64748B] font-[400]'>Set spending limits for various categories to manage finances effectively.</span></li>
              <li>•Track Your Goals - <span className='text-[#64748B] font-[400]'>Stay updated on your progress towards financial milestones.</span></li>
              <li>• Organize Monthly Expenses - <span className='text-[#64748B] font-[400]'>Manage bills, rent, and other recurring costs with ease.</span></li>
              <li>• Plan Investments - <span className='text-[#64748B] font-[400]'>Strategically set aside funds to grow wealth over time.</span></li>
            </ul>
            <div className="w-[150px] self-center">
              <button
                onClick={handleToggleChange}
                className="text-[#FFFFFF] bg-[#9065B4] px-2.5 py-1.5 rounded-md text-[0.95rem] font-[500]"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </>
    )}
  </div>
    </>
  )
}

export default BudgetAndPlans