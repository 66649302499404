import { DELETE_BUSINESS_MEMBERS_URL } from "constants/apis";
import useAPICall from "hooks/useAPICall";

const useDeleteBusinessMember = () => {
  const [
    data,
    error,
    isLoading,
    callDeleteBusinessMember,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };
  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
  const deleteBusinessMember = (member_id) => {
    const url = DELETE_BUSINESS_MEMBERS_URL.replace(
      ":member_id",
      member_id
    );
    callDeleteBusinessMember({
      url,
      method: "DELETE",
      statusObj,
      defaultFallback,
    });
  };

  return [
    data,
    error,
    isLoading,
    deleteBusinessMember,
    setSuccessData,
    setError,
  ];
};

export default useDeleteBusinessMember;
