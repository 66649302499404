import React, { useEffect, useState } from "react";
import usePostLetterHeadData from "./data/usePostLetterHeadData";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

function LetterHead() {
    const [postData, postError, postIsLoading, postLetterHeadData] =
    usePostLetterHeadData();

    useEffect(()=>{
       if(postData){
        showSuccessToast("Successfully posted the data")
       }
       if(postError){
        showErrorToast("Error in posting the data")
       }
    },[postData, postError])
  const [formData, setFormData] = useState({
    company_name: "Aiyug Beyondtax Private Limited",
    date: "2024-12-04",
    address: "3rd Floor, T-Hub, Madhapur, Hyderabad, Telangana - 500081",
    email: "admin@beyondtax.co",
    website: "https://www.beyondtax.co",
    phone_number: "+91 818683268",
    letter_text: `Dear John Carter,\nWe hope this message finds you well! At BRIX Agency, we are a team of designers and developers specialized in creating modern and attractive websites that stand out on the web.`,
    name: "Dheeraj Rao Chikyala",
    role: "Director",
    logotype: null,
    signature: null,
    isotype: null,
    template: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append all fields from formData to the FormData object
    Object.keys(formData).forEach((key) => {
      if (formData[key] instanceof File) {
        formDataToSend.append(key, formData[key]);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    // For demonstration: Log the FormData values
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0], pair[1]);
    }

    postLetterHeadData(formDataToSend)
    // Replace the following line with your API call
    // fetch('/api/endpoint', {
    //   method: 'POST',
    //   body: formDataToSend,
    // });
  };

  return (
    <div className="min-h-screen bg-gray-100 p-2 relative">
      <div className="max-w-6xl mx-auto rounded-md p-8 grid grid-cols-1 lg:grid-cols-12 gap-8 mt-8">
        {/* Left Column */}
        <div className="bg-white rounded-md p-4 shadow-sm lg:col-span-3">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Inputs</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm text-[#19213D]">Company Name</label>
              <input
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Date</label>
              <input
                type="text"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Web</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
          </form>
        </div>

        {/* Center Column */}
        <div className="bg-white rounded-md p-4 shadow-sm lg:col-span-6">
          <h2 className="text-xl font-semibold text-[#19213D] mb-4">Letter Text</h2>
          <textarea
            name="letter_text"
            value={formData.letter_text}
            onChange={handleInputChange}
            className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2 resize-none overflow-hidden box-border"
            rows="20"
          />
        </div>

        {/* Right Column */}
        <div className="bg-white rounded-md p-4 shadow-sm lg:col-span-3">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Inputs</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm text-[#19213D]">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Role</label>
              <input
                type="text"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Signature</label>
              <input
                type="file"
                name="signature"
                onChange={handleFileChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Isotype</label>
              <input
                type="file"
                name="isotype"
                onChange={handleFileChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Logotype</label>
              <input
                type="file"
                name="logotype"
                onChange={handleFileChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm text-[#19213D]">Template</label>
              <select
                name="template"
                value={formData.template}
                onChange={handleInputChange}
                className="w-full border-[#A8B7D4] border-[1px] rounded-md shadow-sm p-2"
              >
                <option>Select an option</option>
                <option value="Template-1">Template-1</option>
                <option value="Template-2">Template-2</option>
                <option value="Template-3">Template-3</option>
                <option value="Template-4">Template-4</option>
                <option value="Template-5">Template-5</option>
              </select>
            </div>
          </form>
                {/* Submit Button */}
            <button
                onClick={handleSubmit}
                className="mt-3 bg-blue-600 text-white px-6 py-2 rounded-md shadow-lg hover:bg-blue-700"
            >
                Submit
            </button>
        </div>
      </div>

    </div>
  );
}

export default LetterHead;
