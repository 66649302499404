import React, { useEffect, useRef, useState } from "react";
import light from "assets/images/customers/light.svg";
import shield_check from "assets/images/customers/shield-check.svg";
import star from "assets/images/customers/star.svg";
import OrangeButton from "components/Button/OrangeButton";
import { BUSINESS_NEW_CUSTOMERS_URL } from "constants/routes";
import { useNavigate } from "react-router-dom";
import useGetCustomers from "./data/useGetCustomers";
import CustomersList from "./CustomersList";

const Customers = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const isFirstCall = useRef(true);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [type, setType] = useState("");
  const [getData, getError, getIsLoading, getCustomersOverview] =
    useGetCustomers();

  useEffect(() => {
    if (isFirstCall.current) {
      isFirstCall.current = false; // Prevents the first call
      return;
    }

    // Check if no filters are applied
    if (!order && !searchQuery && !type) {
      getCustomersOverview(user?.business_id, { page: page });
    }
  }, [page, order, searchQuery, type]);

  return (
    <>
      {getData?.data?.total_count === 0 ? (
        <div className="bg-gray-100 pt-[5.3rem] h-screen px-[1.2rem] ">
          <div className="bg-white px-[1rem] rounded-md py-[9.5rem]">
            <h4 className="text-[1.2rem] text-darkGray text-center">
              Customer Management
            </h4>
            <p className="text-[0.8rem] text-lightGray text-center">
              Everything you need to build stronger customer relationships in
              one place
            </p>
            <div className="grid grid-cols-3 gap-[0.8rem]">
              {/* stay connected */}
              <div className="border rounded-md flex gap-[0.5rem] p-[0.8rem] bg-[#FFF7ED]">
                <img src={light} alt="" className="w-[1.3rem] h-[1.3rem]" />
                <div className="text-left ">
                  <h4 className="text-[0.9rem] text-darkGray ">
                    Stay Connected
                  </h4>
                  <p className="text-[0.8rem] text-lightGray m-0">
                    Access customer information instantly
                    <br /> including contact details and <br /> purchase
                    history.
                  </p>
                </div>
              </div>

              {/* share with ease */}
              <div className="border rounded-md flex gap-[0.5rem] p-[0.8rem] bg-[#FFF7ED]">
                <img
                  src={shield_check}
                  alt=""
                  className="w-[1.3rem] h-[1.3rem]"
                />
                <div className="text-left ">
                  <h4 className="text-[0.9rem] text-darkGray ">
                    Share with Ease
                  </h4>
                  <p className="text-[0.8rem] text-lightGray m-0">
                    Create and send quotations and
                    <br /> invoices in seconds. Organized and
                    <br /> accessible.
                  </p>
                </div>
              </div>

              {/* insights a glance */}
              <div className="border rounded-md flex gap-[0.5rem] p-[0.8rem] bg-[#FFF7ED]">
                <img src={star} alt="" className="w-[1.3rem] h-[1.3rem]" />
                <div className="text-left ">
                  <h4 className="text-[0.9rem] text-darkGray ">
                    Insights at a Glance
                  </h4>
                  <p className="text-[0.8rem] text-lightGray m-0">
                    Download comprehensive reports to understand business
                    performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-[1rem]">
              <OrangeButton
                name="Get Started"
                px={"px-[0.5rem]"}
                py={"py-[0.3rem]"}
                onClick={() =>
                  navigate(
                    BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "select")
                  )
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <CustomersList
          getData={getData?.data}
          getError={getError}
          setPage={setPage}
          getCustomersOverview={getCustomersOverview}
          initialPage={page}
          order={order}
          setOrder={setOrder}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          type={type}
          setType={setType}
        />
      )}
    </>
  );
};

export default Customers;
