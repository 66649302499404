import useAPICall from "hooks/useAPICall";
import { GET_BUSINESS_IMPORT_GST_DETAILS_URL } from "constants/apis";

const useGetImportGstDetails = () => {
  const [data, error, isLoading, callGetImportGstDetails, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
          setError(undefined);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getImportGstDetails = (business_id,gstin) => {
    const url = GET_BUSINESS_IMPORT_GST_DETAILS_URL.replace(":business_id", business_id);
    const params={gstin : gstin}
    callGetImportGstDetails({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      params
    });
  };

  return [data, error, isLoading, getImportGstDetails, setSuccessData, setError];
};

export default useGetImportGstDetails;
