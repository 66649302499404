import useAPICall from "hooks/useAPICall";
import {POST_KYC_DATA_URL} from "constants/apis";

const usePatchKycData = () => {
  const [data, error, isLoading, callPatchKycData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
          setError(undefined)
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const patchKycData = (body) => {
    const url = POST_KYC_DATA_URL;
    callPatchKycData({
      url,
      method: "PATCH",
      statusObj,
      defaultFallback,
      body,
    //   params
    });
  };

  return [data, error, isLoading, patchKycData, setSuccessData, setError];
};

export default usePatchKycData;
