import Button from "components/Button/BtnOrange";
import React, { useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import InputField from "components/input/InputField";
import { useLocation } from "react-router-dom";
import { MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL } from "constants/routes";

const CreateNewCategory = ({
  isOpen,
  closeModal,
  handleGoToCategories,
  handleCreateNewCategory,
  name,
  setName,
  isCreateMoreToggledCategory,
  setIsCreateMoreToggledCategory,
}) => {
  const location = useLocation();
  const handleCreateMoreToggle = () => {
    setIsCreateMoreToggledCategory(!isCreateMoreToggledCategory);
  };

  

  return (
    <>
      <Button
        onClick={handleCreateNewCategory}
        name="Create New category"
        px={"px-[0.9rem]"}
        py={"py-[0.3rem]"}
      />
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900  bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-[85%] sm:w-[70%] md:w-[480px] bg-[#FFFFFF] rounded-2xl px-6 py-4 relative">
            <div>
              <button
                className="group absolute top-[1rem] right-6 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
                onClick={closeModal}
              >
                <img
                  alt=""
                  src={closeIcon}
                  className="w-3 h-3 group-hover:hidden"
                />
                <img
                  alt=""
                  src={closeIconRed}
                  className="w-4 h-4 hidden group-hover:block"
                />
              </button>
              <h4 className="text-[1rem] font-[600] text-lightGray">
                New Category
              </h4>
            </div>

            {/* input fields */}
            <div className="mt-[2rem] mb-[1rem]">
              <h6 className="text-lightGray text-[0.7rem]">Name</h6>
              <div>
                <InputField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Enter Category Name"
                  className="p-[0.4rem] text-[0.8rem] focus:outline-none font-[500] text-lightGray border rounded-md"
                />
              </div>
            </div>

            {/* Add button */}
            <div className="flex justify-end gap-2 items-center">
              {location.pathname ===
              MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL ? (
                <div className="flex items-center gap-2">
                  {/* create more toggle */}
                  <div
                    onClick={handleCreateMoreToggle}
                    className={`w-6 h-3 flex items-center rounded-full p-[0.2rem] cursor-pointer ${
                      isCreateMoreToggledCategory
                        ? "border-1 border-customPurple"
                        : "border-1 border-lightGray"
                    }`}
                  >
                    <div
                      className={`h-2 w-2 rounded-full  shadow-md transform ${
                        isCreateMoreToggledCategory
                          ? "translate-x-2 bg-customPurple"
                          : "translate-x-0 bg-lightGray"
                      }`}
                    ></div>
                  </div>
                  <button className="text-lightGray text-[0.8rem] rounded-md font-[500]">
                    Create More
                  </button>
                </div>
              ) : (
                ""
              )}
              <Button
                onClick={handleGoToCategories}
                name="Add"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNewCategory;
