import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the required modules
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalaryGraph = ({graphData}) => {
  const [baseData, setBaseData]=useState([])
  const [allowanceData, setAllowanceData]=useState([])
  useEffect(()=>{
  if(graphData?.data){
    let graphBase=graphData?.data?.map((el)=>{
      return (el.total_base_pay_this_month/100000)
    })
    let graphAllowance=graphData?.data?.map((el)=>{
      return (el.total_allowances_this_month/100000)
    })
    setBaseData(graphBase)
    setAllowanceData(graphAllowance)
  }
  },[graphData])
  
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Base Pay',
        // data: [10, 10, 10, 10, 10, 12.5, 12.5, 12, 12, 13, 14, 15],
        data:baseData,
        backgroundColor: '#EA580C',
      },
      {
        label: 'Allowances',
        // data: [2, 2.5, 3, 3, 3, 3.5, 4, 4, 4.5, 5, 5, 5.5],
        data:allowanceData,
        backgroundColor: '#FDBA74',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow custom height
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}L`,
        },
      },
    },
    layout: {
        padding: { top: 10, bottom: 10},
      },
    scales: {
      x: {
        grid: { display: false, drawBorder: false }, // Remove X-axis grid and border
        border: {
            display: false, // Remove X-axis border
        },
        ticks: { display: true }, // Optionally remove X-axis tick marks
      },
      y: {
        grid: { display: false, drawBorder: false }, // Remove Y-axis grid and border
        border: {
            display: false, // Remove X-axis border
          },
        // title: {
        //   display: true,
        //   text: 'Amount (in Lacs)',
        // },
        ticks: {
          callback: (value) => `${value}L`,
        },
      }, 
    },
    elements: {
      bar: {
        borderRadius: 5, // Add border radius to bar lines
      },
    },
  };

  return (
    <div style={{ height: '260px', width: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default SalaryGraph;
