import { POST_TAX_PROFILE_INFORMATION_URL, POST_TAX_SUMMARY_OTHERS_URL } from "constants/apis";
import useAPICall from "hooks/useAPICall";

const usePatchTaxProfileInformation = () => {
  const [
    data,
    error,
    isLoading,
    callPostTaxProfileInformation,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const PostTaxSummaryProfileInformation = (body) => {
    const url = POST_TAX_PROFILE_INFORMATION_URL;
    var config = {
      headers: { Authorization: `token ${localStorage.getItem("token")}` },
    };
    console.log("create profile details API: ", data);
    console.log("body", body);
    callPostTaxProfileInformation({
      url,
      method: "PATCH",
      statusObj,
      config,
      defaultFallback,
      body,
    });
  };

  return [
    data,
    error,
    isLoading,
    PostTaxSummaryProfileInformation,
    setSuccessData,
    setError,
  ];
};

export default usePatchTaxProfileInformation;
