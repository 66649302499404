import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/BusinessDashboard/SideNav/businessLogo.svg";
import customer from "assets/images/BusinessDashboard/SideNav/customer.svg";
import dashboardHome from "assets/images/BusinessDashboard/SideNav/dashboardHome.svg";
import employees from "assets/images/BusinessDashboard/SideNav/employees.svg";
import expenses from "assets/images/BusinessDashboard/SideNav/expenses.svg";
import invoiceSvg from "assets/images/BusinessDashboard/SideNav/invoiceSvg.svg";
import payroll from "assets/images/BusinessDashboard/SideNav/payroll.svg";
import sales from "assets/images/BusinessDashboard/SideNav/sales.svg";
import transaction from "assets/images/BusinessDashboard/SideNav/transaction.svg";
import useGetProfileNavTabs from "../Dashboard/data/userGetProfileNavTabs";
import logout from "assets/images/dashboard/log-out.svg";
import {
  BUSINESS_DASHBOARD_URL,
  BUSINESS_EMPLOYEE_URL,
  BUSINESS_PROFILE_URL,
  BUSINESS_INVITES_URL,
  BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
  BUSINESS_BANK_ACCOUNTS_URL,
  BUSINESS_DOCUMENTS_URL,
  BUSINESS_INVOICES_URL,
  BUSINESS_SALES_URL,
  BUSINESS_TRANSACTIONS_URL,
  BUSINESS_EXPENSES_URL, 
  BUSINESS_PAYROLL_URL, 
  BUSINESS_CUSTOMERS_URL
} from "constants/routes";
import LogoutDialog from "components/loginProfileNavbar/LogoutDialog";
import { ProfileContext } from "../Profile/Layout";
import loop from "assets/images/BusinessDashboard/TopProfileNav/loop-right-line.svg";
import rightArrow from "assets/images/dashboardNav/chevron-right.svg";

const BusinessProfileNav = ({ showSideNav }) => {
  const [tabsWithChildrens, setTabsWithChildrens] = useState([]);
  const [activeTab, setActiveTab] = useState(null); // Manage active tab
  const [getData, getError, getIsLoading, getProfileNavTabs] =
    useGetProfileNavTabs();
  const location = useLocation();
  const { setBusinessDashboardHeading } = useContext(ProfileContext);
  useEffect(() => {
    getProfileNavTabs({ param: "business" });
  }, []);

  const tabPath = {
    Dashboard: BUSINESS_DASHBOARD_URL,
    Invoices: BUSINESS_INVOICES_URL,
    Sales: BUSINESS_SALES_URL, 
    Transaction: BUSINESS_TRANSACTIONS_URL,
    Expenses: BUSINESS_EXPENSES_URL,
    Employees: BUSINESS_EMPLOYEE_URL,
    Payroll: BUSINESS_PAYROLL_URL,
    Customers: BUSINESS_CUSTOMERS_URL,
    "Business Profile": BUSINESS_PROFILE_URL,
    "Members & Invites": BUSINESS_INVITES_URL,
    "Business Tax Information": BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
    "Business Bank Accounts": BUSINESS_BANK_ACCOUNTS_URL,
    Documents: BUSINESS_DOCUMENTS_URL,
  };

  const tabIcons = {
    Dashboard: dashboardHome,
    Invoices: invoiceSvg,
    Sales: sales,
    Transaction: transaction,
    Expenses: expenses,
    Employees: employees,
    Payroll: payroll,
    Customers: customer,
  };
  const [isProfileActive, setIsProfileActive] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(BUSINESS_PROFILE_URL)) {
      setIsProfileActive(true);
    } else {
      setIsProfileActive(false);
    }
    const activeTab = Object.keys(tabPath).find((key) => {
      const path = tabPath[key];
      return location.pathname === path;
    });
    if (activeTab) {
      setActiveTab(activeTab);
      setBusinessDashboardHeading(activeTab);
    }
  }, [location]);

  useEffect(() => {
    const activeTab = Object.keys(tabPath).find((key) => {
      const path = tabPath[key];
      return location.pathname === path;
    });
    if (activeTab) {
      setActiveTab(activeTab);
      setBusinessDashboardHeading(activeTab);
    }
  }, []);

  useEffect(() => {
    if (getData) {
      const tabs = getData?.data?.results;

      const parents = tabs.filter((tab) => !tab.parent_tab);
      const children = tabs.filter((tab) => tab.parent_tab);

      const result = parents.map((parent) => ({
        ...parent,
        children: children.filter((child) => child.parent_tab === parent.id),
      }));
      const desiredOrder = [
        "Dashboard",
        "Invoices",
        "Sales",
        "Transaction",
        "Expenses",
        "Employees",
        "Payroll",
        "Customers",
        "Business Profile",
        "Documents",
        "Business Bank Accounts",
        "Members & Invites",
        "Business Tax Information",
      ];

      const sortedResult = result.sort(
        (a, b) =>
          desiredOrder.indexOf(a.tab_name) - desiredOrder.indexOf(b.tab_name)
      );

      setTabsWithChildrens(sortedResult);
    }
  }, [getData]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setBusinessDashboardHeading(tabName);
  };

  const profileTabs = [
    "Business Profile",
    "Documents",
    "Business Bank Accounts",
    "Members & Invites",
    "Business Tax Information",
  ];
  // Group tabs under headings (Operations and Customers)
  const groupedTabs = {
    Operations: tabsWithChildrens.filter(
      (tab) =>
        tab.tab_name !== "Dashboard" &&
        tab.tab_name !== "Customers" &&
        !profileTabs.includes(tab.tab_name)
    ),
    Customers: tabsWithChildrens.filter((tab) => tab.tab_name === "Customers"),
    Profile: tabsWithChildrens.filter((tab) =>
      profileTabs.includes(tab.tab_name)
    ),
  };

  const [switchClicked, setSwitchClicked] = useState(false);
  const navigate = useNavigate();
  const [showPP, setShopPP] = useState(false);
  const { profilePic } = useContext(ProfileContext);

  const handleNavigate = () => {
    setSwitchClicked(true);
    setTimeout(() => {
      navigate("/dashboard");
    }, 200);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const firstNameLetter = user?.full_name
    ? user?.full_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.business_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  useEffect(() => {
    if (profilePic) {
      setShopPP(true);
    }
  }, [profilePic]);

  return (
    <div
      className={`bg-white flex flex-col h-screen justify-between fixed top-0 left-0 md:w-[15%] w-[60%]`}
    >
      <div className="p-[8%] ml-2">
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
        {!isProfileActive ? (
          <ul className="md:mt-[15%] mt-[10%] p-0">
            {/* Dashboard */}
            {tabsWithChildrens &&
              tabsWithChildrens.find(
                (item) => item.tab_name === "Dashboard"
              ) && (
                <li key="Dashboard" className="mb-3 p-0">
                  <Link
                    to={tabPath["Dashboard"] || "#"}
                    className="flex items-center no-underline"
                    onClick={() => handleTabClick("Dashboard")}
                  >
                    <img
                      src={tabIcons["Dashboard"]}
                      alt="Dashboard icon"
                      className={`w-6 h-6 mr-3 transition-colors`}
                      style={{
                        filter:
                          activeTab === "Dashboard"
                            ? "invert(57%) sepia(91%) saturate(1400%) hue-rotate(345deg) brightness(95%) contrast(94%)"
                            : "none",
                      }}
                    />
                    <span
                      className={`transition-colors font-[500] text-[0.9rem] ${
                        activeTab === "Dashboard"
                          ? "text-[#EA580C]"
                          : "text-[#1F2937]"
                      }`}
                    >
                      Dashboard
                    </span>
                  </Link>
                </li>
              )}

            {/* Operations Heading */}
            <li className="mb-3 font-[500] text-[#6B7280] text-[0.9rem]">
              OPERATIONS
            </li>
            {groupedTabs.Operations.map((item) => {
              const isActive = activeTab === item.tab_name;
              return (
                <li key={item.tab_name} className="mb-3 p-0">
                  <Link
                    to={tabPath[item.tab_name] || "#"}
                    className="flex items-center no-underline"
                    onClick={() => handleTabClick(item.tab_name)}
                  >
                    <img
                      src={tabIcons[item.tab_name]}
                      alt={`${item.tab_name} icon`}
                      // className={`w-6 h-6 mr-3 transition-colors ${
                      //   isActive ? "text-[#EA580C]" : "text-[#1F2937]"
                      // }`}
                      // style={{ color: isActive ? "#EA580C" : "#1F2937" }}
                      className={`w-6 h-6 mr-3 transition-colors`}
                      style={{
                        filter: isActive
                          ? "invert(57%) sepia(91%) saturate(1400%) hue-rotate(345deg) brightness(95%) contrast(94%)"
                          : "none",
                      }}
                    />

                    <span
                      className={`transition-colors font-[500] text-[0.9rem] ${
                        isActive ? "text-[#EA580C]" : "text-[#1F2937]"
                      }`}
                    >
                      {item.tab_name}
                    </span>
                  </Link>
                </li>
              );
            })}

            {/* Payroll and Customers Heading */}
            <li className="mb-3 font-[500] text-[#6B7280] text-[0.9rem]">
              PEOPLE
            </li>
            {groupedTabs.Customers.map((item) => {
              const isActive = activeTab === item.tab_name;
              return (
                <li key={item.tab_name} className="mb-3 p-0">
                  <Link
                    to={tabPath[item.tab_name] || "#"}
                    className="flex items-center no-underline"
                    onClick={() => handleTabClick(item.tab_name)}
                  >
                    <img
                      src={tabIcons[item.tab_name]}
                      alt={`${item.tab_name} icon`}
                      // className={`w-6 h-6 mr-3 transition-colors ${
                      //   isActive ? "text-[#EA580C]" : "text-[#1F2937]"
                      // }`}
                      // style={{ fill: isActive ? "#EA580C" : "#1F2937" }}
                      className={`w-6 h-6 mr-3 transition-colors`}
                      style={{
                        filter: isActive
                          ? "invert(57%) sepia(91%) saturate(1400%) hue-rotate(345deg) brightness(95%) contrast(94%)"
                          : "none",
                      }}
                    />
                    <span
                      className={`transition-colors font-[500] text-[0.9rem] ${
                        isActive ? "text-[#EA580C]" : "text-[#1F2937]"
                      }`}
                    >
                      {item.tab_name}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <ul className="md:mt-[15%] mt-[10%] p-0">
            {groupedTabs.Profile.map((item) => {
              const isActive = activeTab === item.tab_name;
              return (
                <li key={item.tab_name} className="mb-3 p-0">
                  <Link
                    to={tabPath[item.tab_name] || "#"}
                    className="flex justify-between items-center no-underline"
                    onClick={() => handleTabClick(item.tab_name)}
                  >
                    <span
                      className={`transition-colors font-[500] text-[0.9rem] ${
                        isActive ? "text-[#EA580C]" : "text-[#1F2937]"
                      }`}
                    >
                      {item.tab_name}
                    </span>
                    {isActive ? (
                      <img
                        src={rightArrow}
                        alt=""
                        className={`mr-3 transition-colors`}
                        style={{
                          filter: isActive
                            ? "invert(57%) sepia(91%) saturate(1400%) hue-rotate(345deg) brightness(95%) contrast(94%)"
                            : "none",
                        }}
                      />
                    ) : null}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div>
        <Link
          className="md:hidden text-black no-underline right-0"
          to={BUSINESS_PROFILE_URL}  
        >
          <div className="ml-[1.8rem] mb-[0.4rem] flex items-center gap-[0.5rem]">
            {showPP === false && (
              <div className=" md:w-[2.5rem] md:h-[2.5rem] w-[2.2rem] h-[2.2rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                {" "}
                <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                  {finalLetter}
                </p>
              </div>
            )}
            {showPP === true && (
              <div className="md:w-[2.5rem] md:h-[2.5rem] w-[2.2rem] h-[2.2rem] rounded-full overflow-hidden">
                <img
                  className="w-full h-full object-cover "
                  src={profilePic}
                  alt="profile"
                />
              </div>
            )}
            <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
              {user?.business_name}
            </p>
          </div>
        </Link>
        <button
          onClick={handleNavigate}
          className="md:hidden ml-[2rem] font-[500] bg-[#FFF7ED] text-[#C2410C] text-[0.9rem] flex gap-2 items-center rounded-md py-[0.3rem] px-[0.9rem]"
        >
          <img
            src={loop}
            alt=""
            className={`w-[1.1rem] h-[1.1rem] ${
              switchClicked ? "rotate-90" : "rotate-0"
            }`}
          />{" "}
          Switch
        </button>
        <hr className="w-[80%] mx-auto " />
        <a
          data-ignore-click-outside
          className="dialog-box flex no-underline font-[500] text-[1rem] leading-[1.5rem] text-black justify-around px-[10%] pb-[10%]"
        >
          <LogoutDialog />
          <img src={logout} alt="logout" />
        </a>
      </div>
    </div>
  );
};

export default BusinessProfileNav;
