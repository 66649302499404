import useAPICall from "hooks/useAPICall";
import { POST_BUSINESS_UNREGISTERED_CUSTOMERS_URL } from "constants/apis";

const usePostCustomers = () => {
  const [data, error, isLoading, callPostCustomers, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
          setError(undefined);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postCustomers = (body, business_id) => {
    const url = POST_BUSINESS_UNREGISTERED_CUSTOMERS_URL.replace(":business_id", business_id);
    callPostCustomers({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, postCustomers, setSuccessData, setError];
};

export default usePostCustomers;
