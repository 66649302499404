import useAPICall from "hooks/useAPICall";
import { GET_MANAGER_CASH_FLOW, GET_MANAGER_HOME_URL } from "constants/apis";

const useGetDashboardCashFlow = () => {
  const [data, error, isLoading, callGetDashboardCashFlow, setSuccessData, setError] =
    useAPICall(undefined, "");
  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getDashboardCashFlow = (params) => {
    const url = GET_MANAGER_CASH_FLOW;
    callGetDashboardCashFlow({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      params
    //   body
    });
  };

  return [data, error, isLoading, getDashboardCashFlow, setSuccessData, setError];
};

export default useGetDashboardCashFlow;
