import React, { useState } from "react";
import increase from "assets/images/dashboardNav/Increase.svg";
import arrowLeft from "assets/images/dashboardNav/arrow-left.svg";
import arrowRight from "assets/images/dashboardNav/arrow-right.svg";
import download from "assets/images/dashboardNav/download.svg";
import user from "assets/images/dashboardNav/user.svg";
import userDark from "assets/images/dashboardNav/userDark.svg";
import calenderLight from "assets/images/dashboardNav/calendarLight.svg";
import calenderDark from "assets/images/dashboardNav/calendar.svg";
import RevenueBusinessChart from "./RevenueBusinessChart";
import CustomizableDrawer from "../Reports/Drawer";
import { showErrorToast } from "components/toaster/toastHelper";

const DashboardBusinessContent = ({ data, getisLoading }) => {
  const [activeType, setActiveType] = useState("today");
  const handleToggleActive = (type) => {
    setActiveType(type);
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prev) =>
      prev > 0 ? prev - 1 : data?.client_progress.length - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev < data?.client_progress.length - 1 ? prev + 1 : 0
    );
  };

  const formatDate = (dateString) => {
    // Split the input string by the '-' separator
    const [year, month, day] = dateString.split("-");
    // Return the date in the desired format
    return `${day}/${month}/${year}`;
  };

  // console.log("getIsLoading:", getisLoading);

  return (
    <>
      {getisLoading ? (
        <>
          <div className="w-full h-32 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-48 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-80 bg-gray-300 rounded animate-pulse"></div>
          <div className="flex gap-[1rem]">
            <div className="h-48 w-1/2 bg-gray-300 rounded animate-pulse"></div>
            <div className="h-48 w-1/2 bg-gray-300 rounded animate-pulse"></div>
          </div>
        </>
      ) : (
        <>
          {/* business performance */}
          <div className="bg-white rounded-xl p-[0.8rem]">
            {/* for mobile */}
            <div className="md:hidden">
              <h4 className="m-0 text-lightGray text-[0.9rem] font-[500] text-left">
                BUSINESS PERFORMANCE
              </h4>
            </div>
            <div className="flex md:flex-row flex-col md:justify-between items-center ">
              {/* for desktop */}
              <h4 className="m-0 hidden md:block text-lightGray text-[0.9rem] font-[500]">
                BUSINESS PERFORMANCE
              </h4>
              <div className="bg-[#F8FAFC] p-1 rounded-md font-[500] md:mt-0 mt-[0.5rem] ">
                <button
                  onClick={() => handleToggleActive("today")}
                  className={`px-2 py-1 text-[0.8rem] ${
                    activeType === "today"
                      ? "bg-darkGray text-white"
                      : "text-lightGray"
                  } rounded-md`}
                >
                  Today
                </button>

                <button
                  onClick={() => handleToggleActive("week")}
                  className={`px-2 py-1 text-[0.8rem] ${
                    activeType === "week"
                      ? "bg-darkGray text-white"
                      : "text-lightGray"
                  } rounded-md`}
                >
                  Week
                </button>
                <button
                  onClick={() => handleToggleActive("month")}
                  className={`px-2 py-1 text-[0.8rem] ${
                    activeType === "month"
                      ? "bg-darkGray text-white"
                      : "text-lightGray"
                  } rounded-md`}
                >
                  Month
                </button>
              </div>
            </div>
            <div className="flex mt-[0.4rem] border bg-[#F8FAFC] rounded-xl  grid grid-cols-12 divide-x divide-y ">
              <div className=" p-[1rem] md:col-span-3 col-span-6">
                <h4 className="text-lightGray font-[500] text-[0.8rem]">
                  Income
                </h4>
                <h4 className="text-darkGray font-[500] text-[1.3rem]">
                  {data?.business_performance?.income?.value || 0}
                </h4>
                <h4 className="text-lightGray text-[0.7rem] flex m-0">
                  <span className="text-[#1A932E] flex gap-1 items-center">
                    {data?.business_performance?.income?.change_type ===
                    "increase" ? (
                      <img src={increase} alt="" />
                    ) : (
                      ""
                    )}
                    {data?.business_performance?.income?.change || 0} &nbsp;
                  </span>
                  {data?.business_performance?.income?.period || 0}
                </h4>
              </div>

              <div className=" p-[1rem] md:col-span-3 col-span-6">
                <h4 className="text-lightGray font-[500] text-[0.8rem]">
                  Sales
                </h4>
                <h4 className="text-darkGray font-[500] text-[1.3rem]">
                  {data?.business_performance?.sales?.value || 0}
                </h4>
                <h4 className="text-lightGray text-[0.7rem] flex m-0">
                  <span className="text-[#1A932E] flex gap-1 items-center">
                    {data?.business_performance?.sales?.change_type ===
                    "increase" ? (
                      <img src={increase} alt="" />
                    ) : (
                      ""
                    )}
                    {data?.business_performance?.sales?.change || 0} &nbsp;
                  </span>
                  {data?.business_performance?.sales?.period || 0}
                </h4>
              </div>

              <div className=" p-[1rem] md:col-span-3 col-span-6 ">
                <h4 className="text-lightGray font-[500] text-[0.8rem]">
                  Clients
                </h4>
                <h4 className="text-darkGray font-[500] text-[1.3rem]">
                  {data?.business_performance?.clients?.value || 0}
                </h4>
                <h4 className="text-lightGray text-[0.7rem] flex m-0">
                  <span className="text-[#1A932E] flex gap-1 items-center">
                    {data?.business_performance?.clients?.change_type ===
                    "increase" ? (
                      <img src={increase} alt="" />
                    ) : (
                      ""
                    )}
                    {data?.business_performance?.clients?.change || 0}&nbsp;
                  </span>
                  {data?.business_performance?.clients?.period || 0}
                </h4>
              </div>

              <div className=" p-[1rem] md:col-span-3 col-span-6">
                <h4 className="text-lightGray font-[500] text-[0.8rem]">
                  Employee Count
                </h4>
                <h4 className="text-darkGray font-[500] text-[1.3rem]">
                  {data?.business_performance?.employee_count?.value || 0}
                </h4>
                <h4 className="text-lightGray text-[0.7rem] flex m-0">
                  <span className="text-[#1A932E] flex gap-1 items-center">
                    {data?.business_performance?.employee_count?.change_type ===
                    "increase" ? (
                      <img src={increase} alt="" />
                    ) : (
                      " "
                    )}
                    {data?.business_performance?.employee_count?.change || 0}{" "}
                    &nbsp;
                  </span>
                  {data?.business_performance?.employee_count?.period || 0}
                </h4>
              </div>
            </div>
          </div>

          {/* client progress*/}
          <div className="bg-white rounded-xl p-[0.8rem] mt-[1rem]">
            <div className="flex justify-between items-center">
              <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
                Client Progress
              </h4>
              <div className="flex items-center gap-[0.8rem]">
                <button
                  className="border rounded-full p-[0.3rem]"
                  onClick={handlePrev}
                >
                  <img src={arrowLeft} alt="" />
                </button>
                <button
                  className="border rounded-full p-[0.3rem]"
                  onClick={handleNext}
                >
                  <img src={arrowRight} alt="" />
                </button>
              </div>
            </div>

            {/* Single Slide */}
            {data?.client_progress?.map((item, index) =>
              index === currentSlide ? (
                <div
                  key={index}
                  className="border rounded-xl pt-[1rem] px-[0.7rem] flex md:flex-row flex-col md:justify-between mt-[0.5rem] "
                >
                  <div className="md:block flex md:flex-col justify-between items-center">
                    <h4 className="text-darkGray text-[0.9rem] font-[500]">
                      {item?.client_name}
                    </h4>
                    <h4 className="text-center m-0 text-lightGray text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#FEF2F2]">
                      {item?.company}
                    </h4>
                  </div>

                  {/* Progress Bar */}
                  <div className="flex items-center gap-[1rem] relative md:justify-end justify-center md:mt-0 mt-[1rem]">
                    <div className="absolute top-2 md:left-2 md:right-8 left-10 right-14 h-[2px] bg-gray-300 z-0"></div>

                    {item?.progress?.map((step, index) => {
                      const isAllCompleted = item?.progress?.every(
                        (step) => step?.status === "completed"
                      );

                      return (
                        <div
                          key={index}
                          className="relative z-10 flex flex-col items-center"
                        >
                          <div
                            className={`w-5 h-5 rounded-full flex items-center justify-center ${
                              step?.status === "completed"
                                ? isAllCompleted &&
                                  index === item?.progress?.length - 1
                                  ? "bg-[#198038]"
                                  : "bg-[#EA580C]"
                                : "bg-gray-300"
                            } text-white items-center flex p-[0.1rem]`}
                          >
                            {step?.status === "completed" ? "✓" : ""}
                          </div>
                          <p
                            className={`text-[0.8rem] font-[500] ${
                              step?.status === "completed"
                                ? isAllCompleted &&
                                  index === item?.progress?.length - 1
                                  ? "text-[#1A932E]"
                                  : "text-[#6F6F6F]"
                                : "text-gray-400"
                            }`}
                          >
                            {index === 0 || index === 1 ? ( // Handle the first two steps
                              <>
                                <span className="block">
                                  {step?.stage.split(" ")[0]}
                                </span>
                                <span className="block">&nbsp;</span>{" "}
                              </>
                            ) : step?.stage.includes(" ") ? ( // Handle multi-word steps for other indexes
                              step?.stage.split(" ").map((word, i) => (
                                <span key={i} className="block">
                                  {word}
                                </span>
                              ))
                            ) : (
                              step?.stage
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null
            )}

            {/* Pagination Dots */}
            <div className="flex justify-center items-center gap-[0.5rem] mt-[1rem]">
              {data?.client_progress?.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === currentSlide ? "bg-[#EA580C]" : "bg-gray-300"
                  }`}
                ></div>
              ))}
            </div>
          </div>

          {/* revenue */}
          <div className="bg-white rounded-xl p-[0.8rem] mt-[1rem]">
            <div className="flex justify-between items-center">
              <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
                Revenue
              </h4>
              <div className="md:flex items-center gap-[0.8rem] hidden">
                <button
                  onClick={() => {
                    if (data?.revenue?.download_links?.sales_report) {
                      window.open(
                        data?.revenue?.download_links?.sales_report,
                        "_blank"
                      );
                    } else {
                      showErrorToast("Revenue Sales Report not present");
                    }
                  }}
                  className="flex items-center gap-1 rounded-md bg-[#F1F5F9] py-[0.3rem] px-[0.5rem] text-lightGray text-[0.8rem] font-[500]"
                >
                  <img src={download} alt="" className="w-[1rem] h-[1rem]" />{" "}
                  Sales Report
                </button>
                <button
                  onClick={() => {
                    if (data?.revenue?.download_links?.spends_report) {
                      window.open(
                        data?.revenue?.download_links?.spends_report,
                        "_blank"
                      );
                    } else {
                      showErrorToast("Revenue Spends Report not present");
                    }
                  }}
                  className="flex items-center gap-1 rounded-md bg-[#F1F5F9] py-[0.3rem] px-[0.5rem] text-lightGray text-[0.8rem] font-[500]"
                >
                  <img src={download} alt="" className="w-[1rem] h-[1rem]" />{" "}
                  Spends Report
                </button>
              </div>
              <div className="md:hidden">
                <CustomizableDrawer
                  triggerElement={
                    <button className="flex items-center gap-2 py-[0.3rem] px-[0.5rem] text-lightGray text-[0.8rem] font-[500]">
                      <img
                        src={download}
                        alt=""
                        className="w-[1rem] h-[1rem]"
                      />
                      Download Report
                    </button>
                  }
                  heading={"Download Report"}
                  placement="bottom"
                  height="200px"
                  content={
                    <div className="flex items-center justify-center gap-[0.8rem] pt-[1rem]">
                      <button
                        onClick={() => {
                          if (data?.revenue?.download_links?.sales_report) {
                            window.open(
                              data?.revenue?.download_links?.sales_report,
                              "_blank"
                            );
                          } else {
                            showErrorToast("Revenue Sales Report not present");
                          }
                        }}
                        className="flex items-center gap-2 rounded-md bg-[#F1F5F9] py-[0.4rem] px-[0.8rem] text-lightGray text-[0.9rem] font-[500]"
                      >
                        <img
                          src={download}
                          alt=""
                          className="w-[1.1rem] h-[1.1rem]"
                        />{" "}
                        Sales Report
                      </button>
                      <button
                        onClick={() => {
                          if (data?.revenue?.download_links?.spends_report) {
                            window.open(
                              data?.revenue?.download_links?.spends_report,
                              "_blank"
                            );
                          } else {
                            showErrorToast("Revenue Spends Report not present");
                          }
                        }}
                        className="flex items-center gap-2 rounded-md bg-[#F1F5F9] py-[0.4rem] px-[0.8rem] text-lightGray text-[0.9rem] font-[500]"
                      >
                        <img
                          src={download}
                          alt=""
                          className="w-[1.1rem] h-[1.1rem]"
                        />{" "}
                        Spends Report
                      </button>
                    </div>
                  }
                />
              </div>
            </div>
            {/* sales and spends legends */}
            <div className="flex items-center gap-4 ml-[1rem] mb-4 mt-[0.5rem]">
              <div className="border bg-[#F8FAFC] rounded-md p-[0.2rem] flex items-center gap-[0.5rem]">
                <div className="bg-[#1A932E] rounded-sm p-[0.4rem]"></div>
                <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
                  <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                    SALES
                  </span>
                  {data?.revenue?.sales}
                </h4>
              </div>

              <div className="border bg-[#F8FAFC] rounded-md p-[0.2rem] flex items-center gap-[0.5rem]">
                <div className="bg-[#FD656C] rounded-sm p-[0.4rem]"></div>
                <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
                  <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                    SPENDS
                  </span>
                  {data?.revenue?.spends}
                </h4>
              </div>
            </div>
            <RevenueBusinessChart data={data?.revenue} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 mt-[1rem] gap-[1rem] md:mb-0 mb-[1rem]">
            {/* upcoming expenses */}
            <div className="bg-white rounded-xl p-[0.8rem]">
              <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
                Upcoming Expenses
              </h4>
              {/* salary */}
              {data?.upcoming_expenses?.map((item, index) => (
                <div
                  className="rounded-md p-[0.9rem] border mt-[0.5rem]"
                  key={index}
                >
                  <div className="flex justify-between items-center">
                    <h4 className=" text-darkGray text-[0.9rem] font-[600] m-0">
                      {item?.amount}
                    </h4>
                    <h4 className="text-center m-0 text-lightGray text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#FEF2F2]">
                      {item?.description}
                    </h4>
                  </div>

                  <div className=" flex items-center gap-[0.6rem] mt-[0.6rem]">
                    <h4 className="flex items-center gap-[0.2rem] font-[500] text-lightGray text-[0.8rem] m-0">
                      <img src={calenderLight} alt="" />{" "}
                      {formatDate(item?.date)}
                    </h4>
                    <h4 className="flex items-center gap-[0.2rem] font-[500] text-lightGray text-[0.8rem] m-0">
                      <img src={user} alt="" />
                      {item?.employee_name}
                    </h4>
                  </div>
                </div>
              ))}
            </div>

            {/* employees on Leave */}
            <div className="bg-white rounded-xl p-[0.8rem]">
              <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
                Employees on Leave
              </h4>
              {/* earned leave */}
              {data?.employees_on_leave?.map((item, index) => (
                <div
                  className="rounded-md p-[0.9rem] border mt-[0.5rem]"
                  key={index}
                >
                  <div className="flex justify-between items-center mb-[0.6rem]">
                    <h4 className="flex items-center gap-[0.2rem] text-darkGray text-[0.9rem] font-[600] m-0">
                      <img src={userDark} alt="" />
                      {item?.employee_name}
                    </h4>
                    <h4 className="text-center m-0 text-lightGray text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#FEF2F2]">
                      {item?.leave_type}
                    </h4>
                  </div>

                  <h4 className=" flex items-center gap-[0.2rem] font-[500] text-lightGray text-[0.8rem] m-0">
                    <img src={calenderDark} alt="" />{" "}
                    {formatDate(item?.start_date)}{" "}
                    {item?.end_date ? `- ${formatDate(item?.end_date)}` : ""} (
                    {item?.duration})
                  </h4>
                </div>
              ))}

              {/* paid leave */}
              {/* <div className="mt-[0.3rem] rounded-md p-[0.9rem] border">
            <div className="flex justify-between items-center mb-[0.6rem]">
              <h4 className="flex items-center gap-[0.2rem] text-darkGray text-[0.9rem] font-[600] m-0">
                <img src={userDark} alt="" />
                Jenna
              </h4>
              <h4 className="text-center m-0 text-lightGray text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#FEF2F2]">
                Paid Leave
              </h4>
            </div>

            <h4 className=" flex items-center gap-[0.2rem] font-[500] text-lightGray text-[0.8rem] m-0">
              <img src={calenderDark} alt="" /> 12 Dec - 14 Dec (3 day)
            </h4>
          </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardBusinessContent;

const BusinessPerformanceSkeleton = () => {
  return (
    <>
      <div className="bg-white rounded-xl p-[0.8rem]">
        <div className="w-full h-32 bg-gray-300 rounded animate-pulse"></div>
      </div>
    </>
  );
};
