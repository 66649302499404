import Button from "components/Button/BtnOrange";
import React, { useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import InputField from "components/input/InputField";

const EditCategory = ({
  isOpen,
  closeModal,
  handlePatchCategory,
  categoryId,
  name,
  setName,
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900  bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-[85%] sm:w-[70%] md:w-[480px] bg-[#FFFFFF] rounded-2xl px-6 py-4 relative">
            <div>
              <button
                className="group absolute top-[1rem] right-6 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
                onClick={closeModal}
              >
                <img
                  alt=""
                  src={closeIcon}
                  className="w-3 h-3 group-hover:hidden"
                />
                <img
                  alt=""
                  src={closeIconRed}
                  className="w-4 h-4 hidden group-hover:block"
                />
              </button>
              <h4 className="text-[1rem] font-[600] text-lightGray">
                Edit Category
              </h4>
            </div>

            {/* input fields */}
            <div className="mt-[2rem] mb-[1rem] flex gap-[1rem] grid grid-cols-2">
              <div>
                <h6 className="text-lightGray text-[0.7rem]">Name</h6>
                <div>
                  <InputField
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Category Name"
                    className="p-[0.4rem] text-[0.8rem] focus:outline-none font-[500] text-lightGray border rounded-md"
                  />
                </div>
              </div>
            </div>

            {/* Add button */}
            <div className="flex justify-end gap-2 items-center">
              <Button
                onClick={handlePatchCategory}
                name="Edit"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCategory;
