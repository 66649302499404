import useAPICall from "hooks/useAPICall";
import { POST_INITIAL_ACCOUNT_URL } from "constants/apis";

const usePostInitialAccount = () => {
  const [data, error, isLoading, callPostInitialAccount, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postInitialAccount = (body) => {
    const url = POST_INITIAL_ACCOUNT_URL;
    callPostInitialAccount({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, postInitialAccount, setSuccessData, setError];
};

export default usePostInitialAccount;
