import useAPICall from "hooks/useAPICall";
import {GET_DETAIL_EMPLOYEE } from "constants/apis";

const useGetDetailEmployee = () => {
  const [data, error, isLoading, callGetDetailEmployee, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getDetailEmployee = (obj) => {
    const url = GET_DETAIL_EMPLOYEE.replace(":employeeId",(obj?.id ? obj?.id : ""))
    const params={month:obj?.month, year:obj?.year}
    callGetDetailEmployee({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      params
      // body,
    });
  };

  return [data, error, isLoading, getDetailEmployee, setSuccessData, setError];
};

export default useGetDetailEmployee;
