import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button/BtnOrange";
import { BUSINESS_DASHBOARD_URL, GET_SERVICE_URL } from "constants/routes";
import logo from "assets/images/dashboard/logo.svg";
import GetStartedDialog from "./GetStartedDialog";
import { useContext } from "react";
import { myContext } from "App";
import useGetUser from "containers/services/Data/useGetUserDetails";
import LogoutNavDialog from "./LogoutNavDialog";
import useGetUserBasic from "containers/privateRoutes/Dashboard/data/getUserbasicDetails";
import slantedArrow from "assets/images/blogs/arrow-up-right.svg";
import arrow from "assets/images/navbar/chevron-down.svg";

import usePostLogout from "containers/privateRoutes/Orders/data/usePostLogout";
import NormalLoginSheet from "components/BottomSheet/NormalLoginSheet";
import MobileSheetRegistration from "components/BottomSheet/MobileSheetRegistration";
import FooterNav from "./FooterNav";

function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [logOutClicked, setLogOutClicked] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const user = JSON.parse(localStorage.getItem("user"));

  const [logoutData, LogoutError, LogoutIsLoading, postLogout] =
    usePostLogout();

  useEffect(() => {
    if (logOutClicked) {
      postLogout();
      localStorage.clear();
      setIsLoggedIn(false);
    }
  }, [logOutClicked]);

  const [showRegMob, setShowRegMob] = useState(false);

  const [showPP, setShowPP] = useState(false);
  const [profilePic, setProfilePic] = useState("");

  const [showService, setShowService] = useState(false); //
  const [showMenu, setShowMenu] = useState(false);

  const firstNameLetter = user?.full_name
    ? user?.full_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.business_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  useEffect(() => {
    if(user?.profile_picture){
    setShowPP(true);
    setProfilePic(user?.profile_picture);
    }
  }, [user]);

  showMenu
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const serviceRef = useRef();
  const menuRef = useRef();

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== serviceRef.current) {
      setShowService(false);
    }
  });

  return (
    <div className="lg:w-screen md:w-screen w-[95%] mx-auto ">
      <div className=" flex lg:justify-around md:justify-around justify-between  py-3 fixed z-[4] lg:w-screen md:w-screen w-[95%] lg:rounded-none md:rounded-none rounded-xl mx-auto forBlur">
        <div>
          <Link to={"/"}>
            <img src={logo} className=" lg:pl-0 md:pl-o pl-3" alt="logo" />
          </Link>
        </div>
        <div className="lg:flex md:flex hidden items-center gap-4">
          <p
            className={`hover:cursor-pointer m-0 flex gap-2 font-[400] text-[1rem] leading-[1.3rem] ${
              location.pathname.includes(GET_SERVICE_URL)
                ? "text-[#9065B4]"
                : "slightDarkBlack"
            }`}
            id="serviceActive"
            ref={serviceRef}
            onClick={() => {
              setShowService(!showService);
            }}
          >
            Registrations
            <img
              src={arrow}
              className={`${
                showService ? "transform -rotate-180 duration-200" : ""
              }`}
              alt=""
            />
          </p>
          <Link
            className={` no-underline  ${
              location.pathname === "/Incometax"
                ? "text-[#9065B4]"
                : "slightDarkBlack"
            }`}
            to={"/Incometax"}
          >
            <p
              onClick={() => {}}
              className="hover:cursor-pointer m-0  font-[400] text-[1rem] leading-[1.3rem] "
            >
              Income Tax
            </p>
          </Link>
          <Link
            className={` no-underline  ${
              location.pathname === "/blogs"
                ? "text-[#9065B4]"
                : "slightDarkBlack"
            }`}
            to={"/blogs"}
          >
            <p
              id="blogsActive"
              onClick={() => {
                // forBlogActive();
              }}
              className="hover:cursor-pointer m-0  font-[400] text-[1rem] leading-[1.3rem] "
            >
              Blogs
            </p>
          </Link>
        </div>
        <div className="lg:flex md:flex hidden items-center gap-4">
          {isLoggedIn ? (
            <>
              <LogoutNavDialog />
              <Link  
              to={
              user?.account_type === 2 || user?.account_type === 3
                ? BUSINESS_DASHBOARD_URL
                : "/dashboard"
            }
            >
              {showPP === false && (
                <div className=" w-[2.8rem] h-[2.8rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                  {" "}
                  <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                    {finalLetter}
                  </p>
                </div>
              )}
              {showPP === true && (
                <div className=" w-[3rem] h-[3rem] rounded-full overflow-hidden">
                  <img
                    className="w-full h-full object-cover "
                    src={profilePic}
                    alt="profile"
                  />
                </div>
              )}
              </Link>
            </>
          ) : (
            <>
              {" "}
              <Link to={"/newLogin"} className=" no-underline text-purple-800">
                {" "}
                <p className="m-0 font-[400] text-[1rem] leading-[1.3rem]">
                  Login
                </p>
              </Link>
              {/* <GetStartedDialog
                  name={ */}
              <Button
                onClick={() => navigate("/newSignup")}
                px={"px-[1rem]"}
                py={"py-[0.5rem]"}
                name="Get Started"
              />
              {/* }
                /> */}
            </>
          )}
        </div>
        <div className="lg:hidden md:hidden flex justify-center items-center">
          {showMenu ? (
            <i
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              className="fa-solid fa-x pr-3"
            ></i>
          ) : (
            <i
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              className="fa-solid fa-bars fa-lg lg:hidden pr-3"
            ></i>
          )}
        </div>
        {showService && (
          <>
            <div className=" px-2 py-4 rounded-xl h-[60vh] top-[120%] absolute grid grid-cols-12 bg-white-body backdrop-blur-3xl ">
              <div className=" col-span-3">
                <ul className=" flex flex-col justify-center h-full">
                  <li className="py-2 pr-4 font-medium">Registrations</li>
                  <li className="py-2 pr-4 font-medium">Income Tax</li>
                </ul>
              </div>
              <div className=" bg-gray-50 col-span-9 p-2 rounded-lg grid grid-cols-12 overflow-y-scroll">
                <h6 className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                  BUSINESS ESSENTIALS
                </h6>
                <Link
                  className="col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                  to={`${GET_SERVICE_URL}/udyam-msme-registration`}
                >
                  <a
                    ref={menuRef}
                    onClick={() => {
                      setShowService(false);
                    }}
                  >
                    Udyam - MSME Registration
                  </a>
                </Link>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Trade License
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Shop & Establishment Registration
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Labour License
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Food License
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Import Export Code
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Startup India{" "}
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  {" "}
                  Digital Signatures
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Agreements
                </a>
                <h6 className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                  TAX RELATED
                </h6>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  PAN Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  TAN Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  GST Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Professional Tax Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  PF Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  ESI Registration
                </a>
                <h6 className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                  ENTITY FORMATION
                </h6>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  HUF Registration
                </a>{" "}
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Firm Registration
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  LLP Registration
                </a>
                <a
                  ref={menuRef}
                  onClick={() => {
                    setShowService(false);
                  }}
                  className=" col-span-6 bg-gray-200 p-2 m-2 rounded-lg no-underline text-black font-medium text-center"
                >
                  Company Incorporation
                </a>
              </div>
            </div>
          </>
        )}
        {showMenu && (
          <div className="absolute max-h-screen min-h-screen w-screen flex flex-col -left-[.7rem] justify-between z-[-1] bg-white">
            <div className="pt-[20%] px-[13%] flex flex-col">
              <Link to={"/"} className=" no-underline slightDarkBlack">
                {" "}
                <div
                  className="flex justify-between"
                  ref={menuRef}
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  <h6 className="font-[500] test-[1.2rem] leading-[1.9rem]">
                    Home
                  </h6>
                  <img src={slantedArrow} alt="" />
                </div>
              </Link>

              <div>
                <div
                  onClick={() => {
                    setShowRegMob(!showRegMob);
                  }}
                  className="flex justify-between"
                >
                  <h6 className=" font-[500] test-[1.2rem] leading-[1.9rem]">
                    Registrations
                  </h6>
                  <img className="" src={slantedArrow} alt="" />
                </div>

                {showRegMob && (
                  <div className=" bg-gray-50 h-40 col-span-9 p-2 rounded-lg grid grid-cols-12 overflow-y-scroll">
                    <p className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                      BUSINESS ESSENTIALS
                    </p>
                    <Link
                      className="col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                      to={`${GET_SERVICE_URL}/udyam-msme-registration`}
                    >
                      <a
                        ref={menuRef}
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        Udyam - MSME Registration
                      </a>
                    </Link>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Trade License
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Shop & Establishment Registration
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Labour License
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Food License
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Import Export Code
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Startup India{" "}
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      {" "}
                      Digital Signatures
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Agreements
                    </a>
                    <h6 className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                      TAX RELATED
                    </h6>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      PAN Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      TAN Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      GST Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Professional Tax Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      PF Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      ESI Registration
                    </a>
                    <h6 className="col-span-12 font-[700] text-[1rem] leading-[1.3rem] text-gray-400 p-2 m-2">
                      ENTITY FORMATION
                    </h6>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      HUF Registration
                    </a>{" "}
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Firm Registration
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      LLP Registration
                    </a>
                    <a
                      ref={menuRef}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      className=" col-span-12 bg-gray-200 p-2 m-2 rounded-lg no-underline text-[#4D4D4D] font-[700] text-[.7rem] leading-[1.1rem] text-center"
                    >
                      Company Incorporation
                    </a>
                  </div>
                )}

                <Link
                  className=" no-underline slightDarkBlack"
                  to={"/Incometax"}
                >
                  <div
                    ref={menuRef}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                    className="flex justify-between"
                  >
                    <h6 className="font-[500] test-[1.2rem] leading-[1.9rem]">
                      Income Tax
                    </h6>
                    <img src={slantedArrow} alt="" />
                  </div>
                </Link>
              </div>
              {/*  )} */}

              <Link className=" no-underline slightDarkBlack" to={"/blogs"}>
                {" "}
                <div
                  ref={menuRef}
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  className="flex justify-between"
                >
                  <h6 className="font-[500] test-[1.2rem] leading-[1.9rem]">
                    Blog
                  </h6>
                  <img src={slantedArrow} alt="" />
                </div>
              </Link>
            </div>
            <div className="w-full flex-col flex items-center gap-2 justify-center py-11 absolute bottom-12">
              {isLoggedIn ? (
                <button
                  onClick={() => {
                    setLogOutClicked(true);
                  }}
                  className=" border-1 bg-[#EF343F] px-[8rem] py-1 rounded mb-2"
                >
                  <span className="text-white font-[500] text-[1rem] leading-[1.5rem]">
                    {" "}
                    Logout
                  </span>
                </button>
              ) : (
                <div className="w-full flex-col flex items-center gap-2 justify-center py-3 absolute bottom-3">
                  <MobileSheetRegistration
                    element={
                      <button
                        type="primary"
                        className=" border-1 border-[#9065B4] px-[7.6rem] py-1 rounded"
                      >
                        <span className="text-[#9065B4] font-[500] text-[1rem] leading-[1.5rem]">
                          {" "}
                          Signup
                        </span>
                      </button>
                    }
                  />{" "}
                  <NormalLoginSheet
                    element={
                      <button
                        type="primary"
                        className={`border-1 mx-auto rounded bg-[#9065B4]   min-w-[100%] px-[8rem] py-1 `}
                      >
                        <span className="text-white font-[500] whitespace-nowrap  text-[1rem] leading-[1.5rem]">
                          {" "}
                          Login
                        </span>  
                      </button>
                    }
                  />{" "}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
