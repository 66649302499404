import React, { useState, createContext, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from "containers/blogs/Blogs.jsx";
import NavBar from "components/navBar/NavBar.js";
import Footer from "components/Footer/Footer.js";
import ServicesPage from "containers/services/ServicesPage.jsx";
import Home from "containers/home/Home.jsx";
import {
  ASSETS_DEPOSITS_DETAILED_URL,
  ASSETS_OTHER_ASSET_DETAILED_URL,
  ASSETS_PERSONAL_ASSET_DETAILED_URL,
  ASSETS_REAL_ESTATE_DETAILED_URL,
  ASSETS_TYPE_URL,
  ASSETS_URL,
  BANK_ACCOUNTS_URL,
  BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
  BUSINESS_INVITES_URL,
  BUSINESS_PROFILE_URL,
  FAMILY_LOAN_DETAILED_VIEW,
  GET_SERVICE_URL,
  GOOGLE_SIGNIN_SUCCESS_PAGE_URL,
  GOVT_IDS_URL,
  INCOME_TAX_PROFILE_INFORMATION_URL,
  INCOME_TAX_PROFILE_URL,
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_DETAILS,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  INVITES_URL,
  LETTER_HEAD_DOCS,
  LIABILITIES_FAMILY_LOAN,
  LIABILITIES_PERSONAL_LOAN,
  LIABILITIES_PERSONAL_LOAN_DETAILS,
  LIABILITIES_REVOLVING_LOAN,
  LIABILITIES_REVOLVING_LOAN_DETAILED_URL,
  LIABILITIES_URL,
  MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL,
  MONEY_MANAGER,
  MONEY_MANAGER_BUDGET_PLANS,
  MONEY_MANAGER_CASH_FLOW,
  MONEY_MANAGER_ADD_INITIAL_ACCOUNT_URL,
  MONEY_MANAGER_TRANSACTIONS_DASHBOARD_URL,
  MONEY_MANAGER_TRANSACTIONS_URL,
  MONEY_MANAGER_URL,
  REPORTS_URL,
  STAKEHOLDER_LIST,
  USER_INCOME_SUMMARY_PAGE_URL,
  MONEY_MANAGER_ACCOUNTS_URL,
  ADD_BUSINESS_HOME_URL,
  ADD_BUSINESS_ONGOING_URL,
  ADD_BUSINESS_NEW_URL,
  BUSINESS_DASHBOARD_URL,
  BUSINESS_EMPLOYEE_URL,
  BUSINESS_EMPLOYEE_DETAIL_URL,
  BUSINESS_BANK_ACCOUNTS_URL,
  BUSINESS_DOCUMENTS_URL,
  BUSINESS_INVOICES_URL,
  BUSINESS_SALES_URL,
  BUSINESS_TRANSACTIONS_URL,
  BUSINESS_EXPENSES_URL,
  BUSINESS_PAYROLL_URL,
  BUSINESS_CUSTOMERS_URL,
  BUSINESS_NEW_CUSTOMERS_URL,
  BUSINESS_CUSTOMER_DETAILVIEW,
} from "constants/routes";
import ToShowNavAndFooter from "components/showNavFooter/ToShowNavAndFooter";
import Dashboard from "containers/privateRoutes/Dashboard/Dashboard";
import OrdersList from "containers/privateRoutes/Orders/OrdersList";
import Profile from "containers/privateRoutes/Profile/Profile";
import Registration from "containers/Registration/Registration";
import BlogDetails from "containers/blogs/BlogDetails";
import GoogleLoginLoader from "containers/LoginAndSignUp/GoogleLoginLoader";

import SetPrivateRoute from "components/settingPrivateRoute/SetPrivateRoute";
import GoogleSuccessLoginRedirect from "containers/LoginAndSignUp/GoogleSuccessLogin";
import LoginPrivateRoute from "components/settingPrivateRoute/LoginPrivateRoute";
import IncomeTax from "containers/privateRoutes/IncomeTax/IncomeTax";
import SignupSuccessModal from "components/mobileModals/SignupSuccessModal";
import OrderPlacedModal from "components/mobileModals/OrderPlacedModal";
import { BLOG_DETAILS_URL } from "constants/routes";
import ForgotPassword from "containers/LoginAndSignUp/ForgotPassword";
import Otp from "containers/LoginAndSignUp/Otp";
import SetNewPswd from "containers/LoginAndSignUp/SetNewPswd";
import IncomeTaxProfile from "containers/privateRoutes/IncomeTax/IncomeTaxProfile";
import useWindowSize from "components/navBar/useWindowResize";
import IncomeTaxServices from "containers/IncomeTaxServices/IncomeTaxServices";
import IncomeTaxSummary from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/IncomeTaxSummary";
import UserIncomeSummaryPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/UserIncomeSummaryPage";
import UserDetailsSummaryPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/UserDetailsSummaryPage";
import Layout from "containers/privateRoutes/Profile/Layout";
import SummaryUserPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/SummaryUserPage";
import Reports from "containers/privateRoutes/Reports/Reports";
import GovtIds from "containers/privateRoutes/Tax Profile/GovtIds";
import Accounts from "containers/privateRoutes/Tax Profile/Accounts";
import Invites from "containers/privateRoutes/Tax Profile/Invites";
import IncomeTaxProfileInformation from "containers/privateRoutes/IncomeTax/IncomeTaxProfileInformation";
import BusinessProfile from "containers/privateRoutes/BusinessProfile/BusinessProfile";
import BusinessMembersAndInvites from "containers/privateRoutes/BusinessProfile/BusinessMembersAndInvites";
import LoginSignUpBusiness from "containers/BusinessLoginSignUp.js/LoginSignUpBusiness";
import SignupBusiness from "containers/BusinessLoginSignUp.js/SignupBusiness";
import SignupMobileVerification from "containers/BusinessLoginSignUp.js/SignupMobileVerification";
import BusinessDetails from "containers/BusinessLoginSignUp.js/BusinessDetails";
import StakeHolderList from "containers/privateRoutes/Dashboard/StakeHolderList";
import SampleStakeholder from "containers/privateRoutes/Tax Profile/SampleStakeholder";
import InitialLiabilitiesPage from "containers/privateRoutes/Liabilities/InitialLiabilitiesPage";
import PersonalLoan from "containers/privateRoutes/Liabilities/UnsecuredLoans/PersonalLoan";
import PersonalLoanDetailed from "containers/privateRoutes/Liabilities/UnsecuredLoans/PersonalLoanDetailed";
import FamilyLoanDetailedView from "containers/privateRoutes/Liabilities/FamilyAndInformalLoan/FamilyLoanDetailedView";
import FamilyLoan from "containers/privateRoutes/Liabilities/FamilyAndInformalLoan/FamilyLoan";
import RevolvingLoan from "containers/privateRoutes/Liabilities/RevolvingCredit/RevolvingLoan";
import RevolvingLoanDetailed from "containers/privateRoutes/Liabilities/RevolvingCredit/RevolvingLoanDetailed";
import Assets from "containers/privateRoutes/Assets/AssetsLandingPage";
import RealEstateDetailedView from "containers/privateRoutes/Assets/RealEstate/RealEstateDetailedView";
import AssetsComponets from "containers/privateRoutes/Assets/AssetsComponents";
import PersonalAssetDetailedView from "containers/privateRoutes/Assets/Personal Assets/PersonalAssetDetailedView";
import DepositsDetailedView from "containers/privateRoutes/Assets/Deposits/DepositsDetailedView";
import AssetsLandingPage from "containers/privateRoutes/Assets/AssetsLandingPage";
import OtherAssetDetailedView from "containers/privateRoutes/Assets/Other Assets/OtherAssetDetailedView";
import MoneyManagerHome from "containers/privateRoutes/MoneyManager/MoneyManagerHome";
import CashFlow from "containers/privateRoutes/MoneyManager/CashFlow";
import BudgetAndPlans from "containers/privateRoutes/MoneyManager/BudgetAndPlans";
import LetterHead from "containers/privateRoutes/Docs/LetterHead";
import MoneyManager from "containers/privateRoutes/MoneyManager/MoneyManager";
import AddInitialAccount from "containers/privateRoutes/MoneyManager/AddInitialAccount";
import Transactions from "containers/privateRoutes/MoneyManager/Transactions/Transactions";
import TransactionsDashboard from "containers/privateRoutes/MoneyManager/Transactions/TransactionsDashboard";
import ManageCategories from "containers/privateRoutes/MoneyManager/Transactions/ManageCategories";
import MoneyManagerAccounts from "containers/privateRoutes/MoneyManager/MoneyManagerAccounts";
import AddBusiness from "containers/privateRoutes/AddingBusiness/AddBusiness";
import AddOngoingBusiness from "containers/privateRoutes/AddingBusiness/AddOngoingBusiness";
import AddNewBusiness from "containers/privateRoutes/AddingBusiness/AddNewBusiness";
import ComingSoon from "./containers/maintanance/ComingSoon";
import BusinessDashboard from "containers/privateRoutes/BusinessDashboard/Dashboard/BusinessDashboard";
import BusinessEmployee from "containers/privateRoutes/BusinessDashboard/Employee/BusinessEmployee";
import BusinessEmployeeDetailView from "containers/privateRoutes/BusinessDashboard/Employee/BusinessEmployeeDetailView";
import RoutesWrapper from "components/RoutesWrapper/RoutesWrapper";
import DashboardPersonal from "containers/privateRoutes/Dashboard/DashboardPersonal";
import DashboardBusiness from "containers/privateRoutes/Dashboard/DashboardBusiness";
import SampleDashboard from "containers/privateRoutes/Dashboard/SampleDashboard";
import BusinessBankAccounts from "containers/privateRoutes/BusinessProfile/BusinessBankAccounts";
import BusinessDocuments from "containers/privateRoutes/BusinessProfile/BusinessDocuments";
import {
  USER_TYPE_BUSINESS,
  USER_TYPE_PERSONAL,
  USER_TYPE_PERSONAL_AND_BUSINESS,
} from "constants/userType";
import Invoices from "containers/privateRoutes/BusinessDashboard/Invoices/Invoices";
import Customers from "containers/privateRoutes/BusinessDashboard/Customers/Customers";
import CustomersComponent from "containers/privateRoutes/BusinessDashboard/Customers/CustomersComponent";
import CustomersList from "containers/privateRoutes/BusinessDashboard/Customers/CustomersList";
import CustomerDetailView from "containers/privateRoutes/BusinessDashboard/Customers/CustomerDetailView";

export let myContext = createContext();
export let otpContext = createContext();
export let loaderContext = createContext();
export let registrationSuccessModalContext = createContext();
export let orderPlacedSuccessModalContext = createContext();

function App() {
  const [isAccessToken, setIsAccessToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(null);
  const [comingSoon, setComingSoon] = useState(false);
  const [registrationSuccessModal, setRegistrationSuccessModal] =
    useState(false);
  const [orderPlacedSuccessModal, setOrderPlacedSuccessModal] = useState(false);
  var expiry = localStorage.getItem("expiry");

  var currentDate = new Date();
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {}, []);

  useEffect(() => {
    if (localStorage.getItem("token") && expiry) {
      var dateParts = expiry.split(" ");
      var dateComponents = dateParts[0]?.split("-");
      var timeComponents = dateParts[1]?.split(":");

      var year = parseInt(dateComponents[2]);
      var month = parseInt(dateComponents[1]) - 1;
      var day = parseInt(dateComponents[0]);
      var hour = parseInt(timeComponents[0]);
      var minute = parseInt(timeComponents[1]);

      if (dateParts[2].toLowerCase() === "pm" && hour !== 12) {
        hour += 12;
      } else if (dateParts[2].toLowerCase() === "am" && hour === 12) {
        hour = 0;
      }

      var givenDate = new Date(year, month, day, hour, minute);

      if (givenDate > currentDate) {
        setIsLoggedIn(true);
      } else if (givenDate < currentDate) {
        setIsLoggedIn(false);
      } else {
        console.log("The given date is the same as the current date and time.");
      }
    }
    if (localStorage.getItem("user")) {
      const accountType = JSON.parse(localStorage.getItem("user")).account_type;
      if (accountType === 3) {
        setUserType(USER_TYPE_PERSONAL_AND_BUSINESS);
      } else if (accountType === 2) {
        setUserType(USER_TYPE_BUSINESS);
      } else if (accountType === 1) {
        setUserType(USER_TYPE_PERSONAL);
      }
    }
  }, [isLoggedIn]);

  return (
    <>
      {loading && (
        <div className="h-screen w-screen flex justify-center items-center">
          {" "}
          <div className="loader"></div>
        </div>
      )}
      {loading && (
        <div className="h-screen w-screen flex justify-center items-center">
          {" "}
          <div className="loader"></div>
        </div>
      )}

      <myContext.Provider
        value={{ isLoggedIn, setIsLoggedIn, setUserType, userType }}
      >
        <loaderContext.Provider value={{ loading, setLoading }}>
          <registrationSuccessModalContext.Provider
            value={{ registrationSuccessModal, setRegistrationSuccessModal }}
          >
            <orderPlacedSuccessModalContext.Provider
              value={{ orderPlacedSuccessModal, setOrderPlacedSuccessModal }}
            >
              <BrowserRouter>
                <RoutesWrapper>
                  <ToShowNavAndFooter>
                    <NavBar />
                  </ToShowNavAndFooter>
                  <SignupSuccessModal />
                  <OrderPlacedModal />

                  <Routes>
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route
                      path={`${GET_SERVICE_URL}/:servicesSlug`}
                      element={<ServicesPage />}
                    />
                    <Route path={BLOG_DETAILS_URL} element={<BlogDetails />} />
                    <Route path="/Incometax" element={<IncomeTaxServices />} />

                    <Route element={<SetPrivateRoute WIN_WIDTH={WIN_WIDTH} />}>
                      <Route element={<Layout />}>
                        {userType === USER_TYPE_PERSONAL && getPersonalRoutes()}
                        {userType === USER_TYPE_BUSINESS && getBusinessRoutes()}
                        {userType === USER_TYPE_PERSONAL_AND_BUSINESS && (
                          <>
                            {getPersonalRoutes()}
                            {getBusinessRoutes()}
                          </>
                        )}
                      </Route>
                    </Route>
                    <Route
                      path="/googleLoginLoading"
                      element={<GoogleLoginLoader />}
                    />
                    <Route
                      path={GOOGLE_SIGNIN_SUCCESS_PAGE_URL}
                      element={<GoogleSuccessLoginRedirect />}
                    />
                    <Route path="/" element={<Home />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route
                      path="/registrationPage"
                      element={<Registration />}
                    />

                    <Route element={<LoginPrivateRoute />}>
                      <Route
                        path="/newLogin"
                        element={<LoginSignUpBusiness />}
                      />
                      <Route path="/newSignup" element={<SignupBusiness />} />
                      <Route
                        path="/businessDetails"
                        element={<BusinessDetails />}
                      />
                      <Route
                        path="/signUpMobileVerification"
                        element={<SignupMobileVerification />}
                      />

                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route path="/otp" element={<Otp />} />
                      <Route path="/set-password" element={<SetNewPswd />} />
                    </Route>
                  </Routes>
                  {/* </Layout> */}
                  {/* {WIN_WIDTH < 768 && <FooterNav />} */}

                  <ToShowNavAndFooter>
                    {loading === false && <Footer />}
                  </ToShowNavAndFooter>
                </RoutesWrapper>
              </BrowserRouter>
            </orderPlacedSuccessModalContext.Provider>
          </registrationSuccessModalContext.Provider>
        </loaderContext.Provider>
        {/* </otpContext.Provider> */}
      </myContext.Provider>
    </>
  );
}

export default App;

const getPersonalRoutes = () => {
  return (
    <>
      <Route element={<Dashboard />} path="/dashboard" />
      <Route element={<OrdersList />} path="/orders" />
      <Route element={<Profile />} path="/profile" />
      <Route element={<IncomeTax />} path="/income-tax" />

      <Route
        element={<UserDetailsSummaryPage />}
        path={INCOME_TAX_SUMMARY_USER_DETAILS}
      />

      <Route element={<IncomeTaxProfile />} path={INCOME_TAX_PROFILE_URL} />
      <Route element={<IncomeTaxSummary />} path={INCOME_TAX_SUMMARY_URL} />
      <Route
        element={<SummaryUserPage />}
        path={INCOME_TAX_SUMMARY_USER_PAGE_URL}
      />
      <Route
        element={<UserIncomeSummaryPage />}
        path={USER_INCOME_SUMMARY_PAGE_URL}
      />
      <Route element={<Reports />} path={REPORTS_URL} />
      <Route element={<GovtIds />} path={GOVT_IDS_URL} />
      <Route element={<Accounts />} path={BANK_ACCOUNTS_URL} />
      <Route element={<Invites />} path={INVITES_URL} />
      <Route
        element={<IncomeTaxProfileInformation />}
        path={INCOME_TAX_PROFILE_INFORMATION_URL}
      />
      <Route
        element={<BusinessMembersAndInvites />}
        path={BUSINESS_INVITES_URL}
      />
      <Route element={<SampleStakeholder />} path={"/sample-stakeholder"} />

      <Route element={<StakeHolderList />} path={STAKEHOLDER_LIST} />

      <Route element={<PersonalLoan />} path={LIABILITIES_PERSONAL_LOAN} />

      <Route
        element={<PersonalLoanDetailed />}
        path={LIABILITIES_PERSONAL_LOAN_DETAILS}
      />
      <Route element={<RevolvingLoan />} path={LIABILITIES_REVOLVING_LOAN} />
      <Route
        element={<RevolvingLoanDetailed />}
        path={LIABILITIES_REVOLVING_LOAN_DETAILED_URL}
      />
      <Route element={<InitialLiabilitiesPage />} path={LIABILITIES_URL} />

      <Route
        element={<FamilyLoanDetailedView />}
        path={FAMILY_LOAN_DETAILED_VIEW}
      />

      <Route element={<FamilyLoan />} path={LIABILITIES_FAMILY_LOAN} />
      <Route element={<AssetsLandingPage />} path={ASSETS_URL} />

      <Route element={<AssetsComponets />} path={ASSETS_TYPE_URL} />
      <Route
        element={<RealEstateDetailedView />}
        path={ASSETS_REAL_ESTATE_DETAILED_URL}
      />
      <Route
        element={<PersonalAssetDetailedView />}
        path={ASSETS_PERSONAL_ASSET_DETAILED_URL}
      />
      <Route
        element={<DepositsDetailedView />}
        path={ASSETS_DEPOSITS_DETAILED_URL}
      />
      <Route
        element={<OtherAssetDetailedView />}
        path={ASSETS_OTHER_ASSET_DETAILED_URL}
      />
      <Route element={<MoneyManagerHome />} path={MONEY_MANAGER} />
      <Route element={<CashFlow />} path={MONEY_MANAGER_CASH_FLOW} />
      <Route element={<BudgetAndPlans />} path={MONEY_MANAGER_BUDGET_PLANS} />
      <Route element={<LetterHead />} path={LETTER_HEAD_DOCS} />
      <Route element={<MoneyManager />} path={MONEY_MANAGER_URL} />
      <Route
        element={<AddInitialAccount />}
        path={MONEY_MANAGER_ADD_INITIAL_ACCOUNT_URL}
      />
      <Route element={<Transactions />} path={MONEY_MANAGER_TRANSACTIONS_URL} />

      <Route
        element={<ManageCategories />}
        path={MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL}
      />
      <Route
        element={<MoneyManagerAccounts />}
        path={MONEY_MANAGER_ACCOUNTS_URL}
      />

      <Route element={<AddBusiness />} path={ADD_BUSINESS_HOME_URL} />

      <Route element={<AddOngoingBusiness />} path={ADD_BUSINESS_ONGOING_URL} />

      <Route element={<AddNewBusiness />} path={ADD_BUSINESS_NEW_URL} />
      <Route element={<SampleDashboard />} path="/dashboard-individual" />
    </>
  );
};

const getBusinessRoutes = () => {
  return (
    <>
      <Route element={<BusinessProfile />} path={BUSINESS_PROFILE_URL} />

      <Route
        element={<BusinessBankAccounts />}
        path={BUSINESS_BANK_ACCOUNTS_URL}
      />
      <Route element={<BusinessDocuments />} path={BUSINESS_DOCUMENTS_URL} />

      <Route
        element={<BusinessMembersAndInvites />}
        path={BUSINESS_INVITES_URL}
      />
      <Route element={<BusinessDashboard />} path={BUSINESS_DASHBOARD_URL} />
      <Route element={<BusinessEmployee />} path={BUSINESS_EMPLOYEE_URL} />
      <Route
        element={<BusinessEmployeeDetailView />}
        path={BUSINESS_EMPLOYEE_DETAIL_URL}
      />
      <Route element={<BusinessProfile />} path={BUSINESS_PROFILE_URL} />
      <Route
        element={<IncomeTaxProfileInformation />}
        path={BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL}
      />
      <Route element={<Invoices />} path={BUSINESS_INVOICES_URL} />
      {/* temp */}
      <Route element={<SampleDashboard />} path="/dashboard-individual" />
      {/* TEMP CHANGE COMPONENT NAME HERE, URL IS FINE */}
      <Route element={<Invoices />} path={BUSINESS_SALES_URL} />
      <Route element={<Invoices />} path={BUSINESS_TRANSACTIONS_URL} />
      <Route element={<Invoices />} path={BUSINESS_EXPENSES_URL} />
      <Route element={<Invoices />} path={BUSINESS_PAYROLL_URL} />
      <Route element={<Customers />} path={BUSINESS_CUSTOMERS_URL} />  
      <Route element={<CustomersComponent />} path={BUSINESS_NEW_CUSTOMERS_URL} />  
      {/* <Route element={<CustomersList />} path={BUSINESS_CUSTOMERS_URL} />  */}
      <Route element={<CustomerDetailView />} path={BUSINESS_CUSTOMER_DETAILVIEW} /> 
    </>
  );
};
