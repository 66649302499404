import React, { useEffect, useRef, useState } from "react";
import incomeArrow from "assets/images/moneyManager/arrow-down-income.svg";
import expenseArrow from "assets/images/moneyManager/arrow-up-expense.svg";
import spendArrow from "assets/images/moneyManager/arrow-down-spend.svg";
import arrowRight from "assets/images/moneyManager/arrow-right.svg";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, Tooltip } from "chart.js";
import useGetCashFlow from "./data/useGetCashFlow";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "components/toaster/toastHelper";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

const CashFlow = () => {
  const [showDetailView, setShowDetailView] = useState(false);
  const [cashFlowData, setCashFlowData]=useState({})
  const [getCashData, getCashError, getCashIsLoading, getCashFlow ] = useGetCashFlow();
  const [currentDate, setCurrentDate] = useState(new Date()); // Initialize with the current date
  const navigate=useNavigate()
  const handleToggleChange = () => {
    showErrorToast("Add a cash transaction.");
  };

  useEffect(()=>{
    getCashFlow("")
  },[])

  useEffect(()=>{
    if(getCashData){
      setCashFlowData(getCashData?.data)
      if(getCashData?.data?.expense_transactions.length > 0 || getCashData?.data?.transactions_by_date.length > 0 || getCashData?.data?.income_transactions.length > 0){
        setShowDetailView(true)
      }
    }
  },[getCashData])

const generateChartData = (cashFlowData) => {
  // Helper to add ordinal suffixes to dates
  const addOrdinalSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = day % 100;
    return (
      day +
      (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
    );
  };

  // Get all days of the month
  const daysInMonth = 31; // Assuming 31 days for the current month
  const labels = Array.from({ length: daysInMonth }, (_, i) =>
    addOrdinalSuffix(i + 1)
  );

  // Create a lookup for backend data by date
  const dataByDate = {};
  cashFlowData?.transactions_by_date?.forEach((item) => {
    const day = new Date(item.date_of_transaction).getDate(); // Get day of the month
    dataByDate[day] = {
      income: item.total_income || 0,
      expenses: item.total_expenses || 0,
    };
  });

  // Populate income and expenses data for all days
  const incomeData = [];
  const expensesData = [];

  for (let day = 1; day <= daysInMonth; day++) {
    if (dataByDate[day]) {
      // Use values from backend data
      incomeData.push(dataByDate[day].income);
      expensesData.push(dataByDate[day].expenses);
    } else {
      // Default to 0 if no data for the day
      incomeData.push(0);
      expensesData.push(0);
    }
  }

  // Construct chart data
  return {
    labels,
    datasets: [
      {
        label: "Expense",
        data: expensesData,
        backgroundColor: "rgba(193, 167, 216, 1)", // Lighter Purple for Expense
      },
      {
        label: "Income",
        data: incomeData,
        backgroundColor: "rgba(144, 101, 180, 1)", // Purple for Income
      },
    ],
  };
};

// Example usage with your backend data
const chartData = generateChartData(cashFlowData);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  height: 200,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        font: { size: 12 },
        boxWidth: 40,
        borderRadius: 10,
        padding: 10,
      },
    },
    tooltip: {
      callbacks: {
        label: (context) =>
          `${context.dataset.label}: ${context.raw.toLocaleString()}`,
      },
    },
  },
  layout: {
    padding: { top: 10, bottom: 10, left: 10, right: 10 },
  },
  scales: {
    x: {
      stacked: true,
      grid: { display: false },
      ticks: { font: { size: 10 } },
    },
    y: {
      stacked: true,
      grid: { display: false, borderWidth: 0.2 },
      ticks: {
        font: { size: 10 },
        callback: (value) => `${value}`, // Format Y-axis values
        stepSize: 2000,
        max: 12000,
        min: 0,
      },
    },
  }, 
  elements: {
    bar: {
      borderRadius: 5,
    },
  },
  backgroundColor: "#FFFFFF",
};

    // Function to format month and year
    const formatMonthYear = (date) => {
      const options = { year: "numeric", month: "long" };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
  
    // Function to change the month
    const changeMonth = (increment) => {
      const updatedDate = new Date(currentDate.setMonth(currentDate.getMonth() + increment));
      setCurrentDate(updatedDate);
  
      // API call with formatted date (e.g., "November 2024")
      const formattedDate = formatMonthYear(updatedDate);
      console.log("API Call with date:", formattedDate);
      getCashFlow(`?month_year=${formattedDate}`)
      // Make your API call here
    };


  const cashFlowCards = [
    {
      name: "Income",
      arrowSymbol: incomeArrow,
      money: cashFlowData?.total_income,
      desc: "Your Income this month",
      bg: "#EAE1F3",
    },
    {
      name: "Expenses",
      arrowSymbol: expenseArrow,
      money: cashFlowData?.total_expenses,
      desc: `${
        cashFlowData?.total_expenses === 0
          ? 0
          : (((cashFlowData?.total_expenses || 0) * 100) / (cashFlowData?.total_income || 1)).toFixed(2)
      }% of your Income`,
      
      bg: "#F3EFF8",
    },
    {
      name: "Available to Spend",
      arrowSymbol: spendArrow,
      money: cashFlowData?.availability_to_spend,
      desc: `${
        cashFlowData?.availability_to_spend === 0
          ? 0
          : (((cashFlowData?.availability_to_spend || 0) * 100) / (cashFlowData?.total_income || 1)).toFixed(2)
      }% of your Income`,      
      bg: "#F3EFF8",
    },
  ];

  const handleNavigate=(val)=>{
    const date = new Date(currentDate); // Convert string to Date object

    // Get the year and month
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // `getMonth` is 0-based

    // Helper function to format numbers to always have two digits
    const formatTwoDigits = (number) => (number < 10 ? `0${number}` : number);

    // Get the first and last dates of the month
    const firstDate = `${year}-${formatTwoDigits(month)}-01`;
    const lastDate = `${year}-${formatTwoDigits(month)}-${new Date(year, month, 0).getDate()}`;


   if(val==="Income"){
      const data={
        amount_type:"Income",
        account_name:"Cash",
        start_date:firstDate,
        end_date:lastDate
      }
      navigate("/money-manager/transactions", { state: data });
   }
   if(val==="Expense"){
    const data={
      amount_type:"Expense",
      account_name:"Cash",
      start_date:firstDate,
      end_date:lastDate
    }
    navigate("/money-manager/transactions", { state: data });
   }
  }
  return (
    <>
      {getCashIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="bg-gray-100 h-screen md:px-[1rem] px-6 pt-[2rem] py-4">
      <h4 className="text-darkGray text-[1.4rem] m-0">Cash Flow</h4>
      {showDetailView ? (
        <>
          <div className="mt-8 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl py-4 px-2.5">
            {/* Month Navigation */}
            <div className="flex items-center gap-2 mb-6">
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={() => changeMonth(-1)} // Move to the previous month
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="px-2 py-1 bg-white rounded-md border-[1px] border-[#D9D9D9]">
                <p className=" font-[500] m-0 text-[#334155]">
                  {formatMonthYear(currentDate)}
                </p>
              </div>
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={() => changeMonth(1)} // Move to the next month
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
            <div className="flex items-center gap-2 mt-2.5">
              <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[73%] p-4 flex gap-4 h-[180px]">
                {cashFlowCards.map((item, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-xl bg-[${item.bg}] w-[31%]`}
                  >
                    <div className="flex flex-col gap-3 justify-between">
                      <div className="flex gap-1 items-center">
                        <img src={item.arrowSymbol} />
                        <p className="m-0 text-[#334155] font-[600] text-[1.1rem]">
                          {item.name}
                        </p>
                      </div>
                      <div className="flex flex-col items-end gap-1">
                        <p className="m-0 text-[#334155] font-[600] text-[1.5rem]">
                          ₹{item.money}
                        </p>
                        <p className="m-0 text-[#334155] font-[500] text-[0.8rem]">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[26%] p-4 h-[180px]">
                <p className="m-0 text-[#334155] font-[600] text-[1.1rem]">
                  Summary
                </p>
                <div className="flex justify-between">
                  <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                    Total Transactions
                  </p>
                  <p className="m-0 text-[#334155] font-[600]">{cashFlowData?.total_transactions}</p>
                </div>
                <div className="flex justify-between">
                  <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                    Largest Transaction
                  </p>
                  <p className="m-0 text-[#027A48] font-[600]">₹{cashFlowData?.largest_transaction}</p>
                </div>
                <div className="flex justify-between">
                  <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                    Average Transaction
                  </p>
                  <p className="m-0 text-[#027A48] font-[600]">₹{(cashFlowData?.average_transaction).toFixed(2)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                    Total Income
                  </p>
                  <p className="m-0 text-[#027A48] font-[600]">₹{cashFlowData?.total_income}</p>
                </div>
              </div>
            </div>
            <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-2">
              <div className="border-b-[1px] border-[#D9D9D9] p-2.5">
                <p className="m-0 text-[#64748B]">Income vs Expense</p>
              </div>
              <div className="p-2.5 h-[250px] w-full">
               <Bar data={chartData} options={options} />
              </div>
            </div>
            <div className="flex items-start gap-2 mt-2.5">
              <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[49.7%] p-2">
                <div className="flex justify-between items-center border-b-[1px] border-[#D9D9D9]">
                  <p className="m-0 text-[#64748B]">Income</p>
                  <div onClick={()=>handleNavigate("Income")} className="flex gap-1 bg-[#F1F5F9] rounded text-center mb-2 cursor-pointer">
                    <p className="text-[#334155] font-[500] m-0 p-1">
                      View All
                    </p>
                    <img src={arrowRight} alt="right" />
                  </div>
                </div>
                {
                  cashFlowData?.income_transactions?.map((item,index)=>(
                    <div className="flex items-center gap-1 mt-2">
                    <div className="w-[24%] text-[0.9rem] font-[600] text-[#334155]">{item.categeory__name}</div>
                    <div className="w-[76%] flex items-center gap-1.5">
                      <div className="w-[80%]">
                        <div className="w-full h-3 mt-1 bg-gray-200 rounded-full">
                          <div
                            className="h-3 bg-purple-500 rounded-full"
                            style={{ width: `${(item.total_amount * 100)/(cashFlowData?.total_income)}%` }}
                          ></div>
                        </div>
                      </div>
                      <div>
                        <p className="m-0 text-[0.9rem]">₹{item.total_amount}</p>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
              <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[49.7%] p-2">
                <div className="flex justify-between items-center border-b-[1px] border-[#D9D9D9]">
                  <p className="m-0 text-[#64748B]">Expense</p>
                  <div onClick={()=>handleNavigate("Expense")} className="flex gap-1 bg-[#F1F5F9] rounded text-center mb-2 cursor-pointer">
                    <p className="text-[#334155] font-[500] m-0 p-1">
                      View All
                    </p>
                    <img src={arrowRight} alt="right" />
                  </div>
                </div>
                {
                  cashFlowData?.expense_transactions?.map((item,index)=>(
                    <div className="flex items-center gap-1 mt-2">
                    <div className="w-[24%] text-[0.9rem] font-[600] text-[#334155]">{item.categeory__name}</div>
                    <div className="w-[76%] flex items-center gap-1.5">
                      <div className="w-[80%]">
                        <div className="w-full h-3 mt-1 bg-gray-200 rounded-full">
                          <div
                            className="h-3 bg-purple-500 rounded-full"
                            style={{
                              width: `${Math.min(
                                (item.total_amount * 100) / cashFlowData?.total_income,
                                100
                              )}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div>
                        <p className="m-0 text-[0.9rem]">₹{item.total_amount}</p>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center mt-8 border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl py-4">
            <div className="flex flex-col gap-[0.35rem]">
              <h6 className="m-0 text-[#334155] text-center font-[500] text-[1.25rem]">
                Let's Plan Your Cash Flow
              </h6>
              <p className="m-0 text-[#334155] text-center text-[0.95rem] font-[500]">
                Set Income - Track Expense
              </p>
              <ul className="!list-disc list-inside text-[#334155] text-[0.95rem] font-[500]">
                <li>• Manage Your Money Movement (Income, Expenses, Bills)</li>
                <li>• Track Your Spending Patterns</li>
                <li>• Quick tips for better cash management</li>
              </ul>
              <div className="w-[150px] self-center">
                <button
                  onClick={handleToggleChange}
                  className="text-[#FFFFFF] bg-[#9065B4] px-2.5 py-1.5 rounded-md text-[0.95rem] font-[500]"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    </>
  );
};

export default CashFlow;
