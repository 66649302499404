import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const TaxAndWealthStatic = () => {
  const data = {
    labels: [
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
    ],
    datasets: [
      {
        label: "IncomeTax",
        data: [20, 40, 30, 70, 50, 80, 60, 90, 100, 80, 70, 60],
        borderColor: "#FBBF24", // Tailwind yellow-400
        backgroundColor: "rgba(250, 204, 21, 0.2)", // Yellow with transparency
        pointBackgroundColor: "#FBBF24",
        fill: true,
        tension: 0, // Slight curvature of the line
        pointRadius: 6, // Size of the points
        pointHoverRadius: 8, // Point size on hover
        borderWidth: 1.5,
        pointBorderWidth: 6, // Border width for the shadow effect
        pointBorderColor: "#FDE68A", // Shadow color for normal state
        pointHoverBorderWidth: 6, // Larger shadow effect on hover
        pointHoverBorderColor: "#FDE68A",
      },
      {
        label: "Net Worth",
        data: [30, 20, 60, 40, 80, 70, 50, 100, 90, 70, 80, 60],
        borderColor: "#06B6D4", // Tailwind cyan-400
        backgroundColor: "rgba(34, 211, 238, 0.2)", // Cyan with transparency
        pointBackgroundColor: "#06B6D4",
        fill: true,
        tension: 0,
        pointRadius: 6, // Size of the points
        pointHoverRadius: 8,
        borderWidth: 1.5,
        pointBorderWidth: 6, // Border width for the shadow effect
        pointBorderColor: "#BAE6FD", // Shadow color for normal state
        pointHoverBorderWidth: 6, // Larger shadow effect on hover
        pointHoverBorderColor: "#BAE6FD",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom", // Position of the legend
        labels: {
          usePointStyle: true, // Circle markers in the legend
        },
      },
      tooltip: {
        backgroundColor: "#1E293B", // Tailwind slate-800
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          // borderDash: [4, 4], 
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 20,
        },
        grid: {
          color: "#E2E8F0", // Tailwind gray-300
          // borderDash: [4, 4],  
        },
      },
    },
  };

  return (
    <div className="w-full h-80">
      <Line data={data} options={options} />
    </div>
  );
};

export default TaxAndWealthStatic;
