import useAPICall from "hooks/useAPICall";
import {GET_BUSINESS_CUSTOMERS_DETAIL_URL} from "constants/apis";

const useGetCustomerDetailView = () => {
  const [data, error, isLoading, callGetCustomerDetailView, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getCustomerDetailView = (business_id, user_id) => {
    const url = GET_BUSINESS_CUSTOMERS_DETAIL_URL.replace(":business_id", business_id).replace(":user_id", user_id)
    callGetCustomerDetailView({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
    //   params
      // body,
    });
  };

  return [data, error, isLoading, getCustomerDetailView, setSuccessData, setError];
};

export default useGetCustomerDetailView;
