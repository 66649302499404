import {DELETE_TAX_PROFILE_PIC_URL, PATCH_TAX_PROFILE_PIC_ADD_UPDATE_URL} from "constants/apis";
import useAPICall from "hooks/useAPICall";

const useDeleteTaxProfilePic = () => {
    const [
        data,
        error,
        isLoading,
        callDeleteTaxProfilePic,
        setSuccessData,
        setError,
      ] = useAPICall(undefined, "");
    
      const defaultFallback = () => {
        // setError(en.something_went_wrong);
        setSuccessData(undefined);
      };
      const statusObj = [
        {
          status_code: 200,
          status_text: "OK",
          callBack: (res) => {
            const data = res;
            if (data && typeof data === "object") {
              setSuccessData(data);
              setError(undefined)
            } else {
              defaultFallback();
            }
          },
        },
        {
          status_text: "Bad Request",
          status_code: 400,
          callBack: defaultFallback,
        },
        {
          status_text: "Internal Server Error",
          status_code: 500,
          callBack: defaultFallback,
        },
      ];
      const deleteTaxProfilePic = ( body) => {
        const url = DELETE_TAX_PROFILE_PIC_URL
        var config = {
          headers: { Authorization: `token ${localStorage.getItem("token")}` },
        };
    
        callDeleteTaxProfilePic({
          url,
          method: "DELETE",
          statusObj,
          config,
          defaultFallback,
          body,

        });
      };
    
      return [
        data,
        error,
        isLoading,
        deleteTaxProfilePic,
        setSuccessData,
        setError,
      ];
}

export default useDeleteTaxProfilePic