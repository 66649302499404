import React from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import discardIcon from "assets/images/customers/discardIcon.svg";
import OrangeButton from "components/Button/OrangeButton";

const DiscardChangesDialog = ({ isOpen, handleCloseDialog, DiscardChanges }) => {
  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-900  backdrop-blur-sm bg-opacity-75 flex justify-center items-center z-50">
        <div className="w-[90%] sm:w-[70%] md:w-[440px] bg-[#FFFFFF] rounded-xl px-8 py-6 relative">
          <div>
            <button
              className="group absolute top-[1rem] right-6 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
              onClick={handleCloseDialog}
            >
              <img
                alt=""
                src={closeIcon}
                className="w-3 h-3 group-hover:hidden"
              />
              <img
                alt=""
                src={closeIconRed}
                className="w-4 h-4 hidden group-hover:block"
              />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            <img
              src={discardIcon}
              alt="discardIcon"
              className="w-[3rem] h-[3rem] mb-[0.5rem]"
            />
            <h4 className="text-[1.1rem] font-[600]">Discard Changes?</h4>
            <p className="text-[0.8rem] font-[500] text-[#EF4444]">
              Are you sure you want to discard changes?
            </p>
            <div className="flex gap-[0.5rem]">
              <button onClick={DiscardChanges} className="py-[0.2rem] px-[1.8rem] text-[0.9rem] font-[500] border rounded-md">
                Discard
              </button>
              <OrangeButton
                onClick={handleCloseDialog}
                name="Cancel"
                px={"px-[1.8rem]"}
                py={"py-[0.2rem]"}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DiscardChangesDialog;
