import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ToShowNavAndFooter({ children }) {
  // const [show, setShow] = useState(true);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hidePaths = [
      "/orders",
      "/profile",
      "/dashboard",
      "/order",
      "/profileView",
      "/login",
      "/newLogin",
      "/googleLoginLoading",
      "/income-tax",
      "/income-tax-returns",
      "/income-tax-profile",
      "/forgot-password",
      "/otp",
      "/set-password",
      "/income-tax/reports",
      "/income-tax-profile/govt-ids",
      "/income-tax-profile/bank-accounts",
      "/income-tax-profile/invites/",
      "/income-tax-profile/tax-information",
      "/income-tax-business-profile",
      "/income-tax-business-profile/tax-business-information",
      '/income-tax-business-profile/business-invites/',
      '/newSignup',
      '/signUpMobileVerification',
      '/businessDetails', 
      '/sample-stakeholder',
      '/stakeholder-list/',
      '/liabilities/',
      '/liabilities-view/',
      '/liabilities/family-loan-detailed-view/',
      '/money-manager/home/',
      '/money-manager/cash-flow/',
      '/money-manager/budget-plans/',
      '/docs/',
      '/docs/letter-head/',
      '/money-manager/accounts/',
      '/add-business',
      '/add-business/ongoing-business',
      '/add-business/new-business',
      '/coming-soon', 
      '/dashboard-individual', 
      '/dashboard-business',
      '/business/employee',
      '/business/dashboard',
      '/business/employee/detail-employee',
      '/coming-soon',
      "/business/business-profile"
    ];

    // Regular expressions for matching paths
    const regexPaths = [
      /^\/income-tax-summary(\/.*)?$/, // matches /income-tax-summary and any subpaths
      /^\/liabilities(\/.*)?$/,       // matches /liabilities and any subpaths
      /^\/assets(\/.*)?$/,            // matches /assets and any subpaths
      /^\/google-signin-success(\/.*)?$/, 
      /^\/money-manager(\/.*)?$/,
      /^\/business(\/.*)?$/,
      /^\/business\/business-profile(\/.*)?$/ // matches /business/business-profile and any subpaths
    ];
    

    // Check if the path is included in hidePaths or matches any regex
    if (
      hidePaths.includes(location.pathname) ||
      regexPaths.some((regex) => regex.test(location.pathname))
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location]);

  return <div>{show && children}</div>;
}

export default ToShowNavAndFooter;
