import useAPICall from "hooks/useAPICall";
import { PATCH_BUSINESS_DOCUMENTS_URL } from "constants/apis";

const usePatchBusinessDoc = () => {
  const [
    data,
    error,
    isLoading,
    callPatchBusinessDoc,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const patchBusinessDoc = (body, business_user_id, doc_id) => {
    const url = PATCH_BUSINESS_DOCUMENTS_URL.replace(
      ":business_user_id",
      business_user_id
    ).replace(":doc_id", doc_id);
    callPatchBusinessDoc({
      url,
      method: "PATCH",
      statusObj,
      defaultFallback,
      body,
      //   params
    });
  };

  return [data, error, isLoading, patchBusinessDoc, setSuccessData, setError];
};

export default usePatchBusinessDoc;
