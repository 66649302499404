import { forwardRef } from "react";

const InputField = forwardRef(
  (
    {
      type = "text",
      name,
      register,
      readOnly = false,
      options = [],
      className = "",
      onChange,
      fileName,
      id,
      value,
      placeholder, // Add placeholder prop
      validation = {}, // Add validation prop for custom rules like "required"
      checked,
      downloadHandler,
      fileNotToDownload=false,
      fileDownload = false,
      fileDownloadForLink = false,
      imgSrc, // Add imgSrc prop for dynamic image source
      imgAlt = "Download Icon", // Optional alt text for the image
      ...rest
    },
    ref
  ) => {
    // Ref as the second argument here
    const permissionType = sessionStorage.getItem("permissionType");
    if (permissionType === "Read") {
      readOnly = true;
      fileDownload = false;
      fileNotToDownload=true;
    } else if (permissionType === "Edit") {
      readOnly = false;
      fileNotToDownload=true;
    } else if (permissionType === "Download") {
      readOnly = true;
      fileDownload = true;
      fileNotToDownload=false;
    } else if (permissionType === "Read & Download") {
      readOnly = false;
      fileDownload = true;
      fileNotToDownload=false;
    }

    const handleFileDownload = () => {
      if (fileDownloadForLink && fileDownload) {
        downloadHandler();
      }
    };
    return (
      <>
        {/* For Select Input */}
        {type === "select" ? (
          <select
            {...(register ? register(name, validation) : {})} // Include validation rules
            disabled={readOnly}
            className={`${className} ${!value ? "text-gray-400" : "text-[#334155]"}`}
            onChange={onChange}
            value={value}
            name={name}
            id={id || undefined}
            {...rest}
          >
            {options.map((option, index) => (
              <option key={option.value} value={option.value} disabled={option.disabled} className={option.className}>
                {option.label}
              </option>
            ))}
          </select>
        ) : type === "checkbox" ? (
          <input
            type={type}
            {...(register ? register(name, validation) : {})}
            className={className}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={onChange}
            id={id || undefined}
            {...rest}
          />
        ) : type === "radio" ? (
          <input
            type={type}
            name={name}
            value={value}
            {...(register ? register(name, validation) : {})}
            checked={checked}
            className={className}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={onChange}
            id={id || undefined}
            {...rest}
          />
        ) : type === "file" ? (
          <>
            {fileDownloadForLink ? (
              <div
                className={`text-[#64748B] flex items-center gap-1 cursor-pointer 
                ${!fileDownload ? "cursor-not-allowed opacity-50" : ""}`} // Apply visual feedback
                onClick={fileDownload ? handleFileDownload : null} // Disable click if not allowed
              >
                <img src={imgSrc} alt={imgAlt} />{" "}
                {/* Use dynamic image source */}
                <h4 className="text-[0.9rem] font-[500] m-0">
                  {fileName ? fileName : "Download Computation"}
                </h4>
              </div>
            ) : (
              <input
                type={type}
                name={name}
                ref={ref}  /* Use forwarded ref here */
                {...(register ? register(name, validation) : {})}
                className={className}
                readOnly={fileNotToDownload} // Still disable input if fileDownload is true
                disabled={fileNotToDownload}
                onChange={onChange}
                id={id || undefined}
                {...rest}
              />
            )}
          </>
        ) : (
          <input
            type={type}
            name={name}
            value={value}
            step="any"
            ref={ref} /* Use forwarded ref here */
            {...(register ? register(name, validation) : {})}
            className={className}
            placeholder={placeholder} // Apply placeholder
            readOnly={readOnly}
            disabled={readOnly && type === "date"}
            onChange={onChange}
            id={id || undefined}
            onWheel={type === "number" ? (e) => e.target.blur() : undefined} // Add onWheel here
            {...rest}
          />
        )}
      </>
    );
  }
);

export default InputField;
