import InputField from "components/input/InputField";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logout from "assets/images/dashboard/log-out.svg";
import WhiteButton from "components/Button/WhiteButton";
import Button from "components/Button/BtnOrange";
import upload from "assets/images/liabilities/upload.svg";
import usePostNewBusiness from "./data/usePostNewBusiness";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import { myContext } from "App";
import { BUSINESS_DASHBOARD_URL } from "constants/routes";
import { USER_TYPE_PERSONAL_AND_BUSINESS } from "constants/userType";

const AddNewBusiness = () => {
  const navigate= useNavigate()
  const {setUserType}=useContext(myContext)
  const user=JSON.parse(localStorage.getItem("user"))
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [newBusinessForm, setNewBusinessForm] = useState({
    knowledge_level: "",
    business_goal: "",
    progress_status: "",
    business_name: "",
    business_type: "",
    business_location: "",
    employees: "",
    how_to_earn: "",
    business_status: "",
    budget: "",
    alternate_phone: "",
    alternate_email: "",
  });
  const [postData, postError, postIsLoading, postNewBusiness] =
  usePostNewBusiness();
  useEffect(()=>{
    if(postData){
      console.log("postData", postData)
      showSuccessToast("Data Uploaded Successfully");
      const {business_id,business_type,business_name}=postData?.data
      localStorage.setItem("user", JSON.stringify({...user,account_type:3,business_name,business_type,business_id}))
      setUserType(USER_TYPE_PERSONAL_AND_BUSINESS)
      navigate(BUSINESS_DASHBOARD_URL)
    }
    if(postError){
      showErrorToast("Error in uploading data")
    }
  },[postData, postError])
  const progressSteps = [
    {
      label: "Basics",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "About Business",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Planning",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  const handleNext = () => {
    if(currentStep === 2){
      console.log('newBusinessForm', newBusinessForm)
      postNewBusiness(newBusinessForm)
      return
    }
    setVisitedSteps((prev) => [...prev, currentStep + 1]);
    setCurrentStep((prev) => prev + 1);
  };

  const handleInputChange=(e)=>{
    const {name, value}=e.target
    setNewBusinessForm({...newBusinessForm,[name]:value})
  }
  return (
    <div className="relative min-h-screen bg-[#F9F8FC] overflow-hidden scrollbar-hide w-full p-[2%]">
      {/* Left Triangle */}
      <div
        className="absolute left-0 w-full h-[80%] bottom-[-70px] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(0 100%, 50% 23%, 0% 0%)",
          //   transform: "translate(0, 20%)", // Align along bottom triangle's left side with slight gap
        }}
      ></div>

      {/* Right Triangle */}
      <div
        className="absolute bottom-[-70px] right-0 w-full h-[80%] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(100% 100%, 50% 23%, 100% 0%)",
          //   transform: "translate(0, -40%)", // Align along bottom triangle's right side with slight gap
        }}
      ></div>

      {/* Bottom Triangle */}
      <div
        className="absolute bottom-0 left-0 w-full h-[50%] bg-[#E5D9F3]"
        style={{
          clipPath: "polygon(10% 100%, 50% 0, 90% 100%)",
        }}
      ></div>
      <div className="absolute top-6 left-6 z-50">
        <Link
          className="no-underline text-[#64748B] flex gap-2 text-[0.9rem] font-[500] cursor-pointer"
          to={`/dashboard`}
        >
          <img src={logout} alt="logout" />
          Back to Dashboard
        </Link>
      </div>

      <div className="flex justify-center items-center z-20 mt-8 relative w-[100%]">
        <div className="w-[60%]">
          <div className="flex flex-col items-center">
            <h6 className="m-0 text-[#334155] text-[1.2rem] font-[600]">
              New Business Idea
            </h6>
            <p className="m-0 text-[#64748B] text-[0.9rem]">
              Bring Your Vision to Life and Make an Impact!
            </p>
          </div>
          <div className="p-3 rounded-lg shadow-md mt-2 bg-[#FFFFFF]">
            <div className="flex flex-col items-center gap-3">
              <p className="m-0 text-[#334155] text-[1.1rem] font-[500]">
                {currentStep === 0
                  ? "Tell us about Yourself!"
                  : currentStep === 1
                  ? "Tell us about Your Business"
                  : currentStep === 2
                  ? "Final Step"
                  : ""}
              </p>
              {progressSteps.length > 0 && (
                <div className="flex items-center justify-between mb-4 gap-1">
                  {progressSteps.map((step, index) => (
                    <React.Fragment key={index}>
                      <div className="">
                        <div
                          className={`h-[3px] w-[200px]
                         
                      mb-1 rounded-md ${
                        step.visited
                          ? "bg-[#C1A7D8]"
                          : step.active
                          ? "bg-customPurple"
                          : "bg-[#C1C9D2]"
                      }`}
                        ></div>

                        <div className="flex items-center gap-1">
                          {step.visited ? (
                            <input
                              type="checkbox"
                              checked={true}
                              readOnly
                              className={`form-checkbox w-3 h-3 rounded-full border-2 ${
                                step.visited
                                  ? "border-[#C1A7D8] bg-[#C1A7D8]"
                                  : "border-lightGray"
                              } appearance-none  checked:border-[#C1A7D8] custom-checkbox2`}
                              style={{ position: "relative" }} // To position the custom checkmark
                            />
                          ) : (
                            <input
                              type="radio"
                              checked={step.active}
                              readOnly
                              className={`form-radio w-3 h-3 rounded-full ${
                                step.active
                                  ? "custom-radio text-customPurple"
                                  : ""
                              } ${
                                step.visited
                                  ? "accent-[#C1A7D8] text-[#C1A7D8] checked"
                                  : ""
                              }`}
                            />
                          )}

                          <span
                            className={`font-[500] text-[0.75rem] ${
                              step.active ? "text-darkGray" : ""
                            } ${step.visited ? "text-[#C1A7D8]" : ""}`}
                          >
                            {step.label}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            {currentStep === 0 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        How much do you know about running a business?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="knowledge_level"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.knowledge_level === "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          None – I’m completely new to this
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="knowledge_level"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.knowledge_level === "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Some – I’ve dabbled before
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="knowledge_level"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.knowledge_level === "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          A lot – I know my stuff
                        </label>
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        How far along are you?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="progress_status"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.progress_status == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          I’m already making money
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="progress_status"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.progress_status == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Launching soon (in the next 1–2 months)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="progress_status"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.progress_status == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Still in the planning phase
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What’s the goal for your business?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_goal"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.business_goal == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          A small side hustle
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_goal"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.business_goal == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          My main source of income
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_goal"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.business_goal == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Building something BIG – aiming to scale!
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-1 w-[90%]">
                        <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                          What’s the Proposed name of your business?
                        </p>
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[88%] focus:outline-none px-2 py-1 bg-[#FFFFFF]"
                          placeholder="Enter Business Name"
                          value={newBusinessForm.business_name}
                          onChange={handleInputChange}
                          name="business_name"
                        />
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What kind of business are you starting?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.business_type == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          I’m working alone (Sole Proprietorship)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.business_type == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Teaming up with others (Partnership)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.business_type == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Something bigger like an LLP or Private Limited
                          Company
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_type"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.business_type == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Not sure yet – I need help deciding!
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        Where will your business run from?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_location"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.business_location === "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          My home
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_location"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.business_location === "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          A separate office or store (Owned)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_location"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.business_location === "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Rented Premises
                        </label>
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        Will your business have employees in the first year?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.employees == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          No, it’s just me.
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.employees == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Upto 10 employees
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="employees"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.employees == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Above 10 employees.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="mt-2 flex gap-4">
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        How do you plan to make money?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="how_to_earn"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.how_to_earn == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Selling products (like clothes, gadgets, etc.)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="how_to_earn"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.how_to_earn == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Offering services (consulting, freelancing, etc.)
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="how_to_earn"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.how_to_earn == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Manufacturing
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="how_to_earn"  // Same name for both options
                            value="4"
                            checked={newBusinessForm.how_to_earn == "4"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Others
                        </label>
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What’s your Business Set-Up Budget?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="budget"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.budget == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Upto 1 Lakhs
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="budget"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.budget == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Rs. 1 - 15 Lakhs
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="budget"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.budget == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          Above Rs. 15 Lakhs
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-[48%] flex flex-col gap-4">
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <p className="m-0 text-[#64748B] text-[0.83rem] font-[500]">
                        What’s your current business status?
                      </p>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_status"  // Same name for both options
                            value="1"
                            checked={newBusinessForm.business_status == "1"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          I’m already registered
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_status"  // Same name for both options
                            value="2"
                            checked={newBusinessForm.business_status == "2"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          I need help registering
                        </label>
                      </div>
                      <div className="mt-2.5">
                        <label className="text-[#334155] text-[0.83rem] leading-[1rem] hover:cursor-pointer flex items-center gap-1  peer-checked:text-customPurple">
                          <InputField
                            type="radio"
                            name="business_status"  // Same name for both options
                            value="3"
                            checked={newBusinessForm.business_status == "3"}
                            onChange={handleInputChange}
                            className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                          />
                          I’m in the process of getting registered
                        </label>
                      </div>
                    </div>
                    <div className="w-[100%] bg-[#F8FAFC] p-[0.93rem] rounded-lg">
                      <div className="flex flex-col gap-2 w-[100%]">
                        <p className="m-0 text-[#64748B] text-[0.73rem] font-[500]">
                          Do you have different communication details for
                          business?
                        </p>
                        <InputField
                          type="number"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF] appearance-none"
                          placeholder="Phone Number"
                          value={newBusinessForm.alternate_phone}
                          onChange={handleInputChange}
                          name="alternate_phone"
                        />
                        <InputField
                          type="text"
                          className="border-[1px] border-[#D9D9D9] rounded-md w-[78%] focus:outline-none px-2 py-1 bg-[#FFFFFF]"
                          placeholder="Mail ID"
                          value={newBusinessForm.alternate_email}
                          onChange={handleInputChange}
                          name="alternate_email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex gap-2 justify-end items-center mt-2">
              <WhiteButton px="px-2.5" py="py-1" name="Cancel" onClick={()=>navigate("/add-business")} />
              <Button px="px-2.5" py="py-1" name="Next" onClick={handleNext} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBusiness;
