import React, { useState, useContext, useEffect } from "react";
import { myContext } from "App";
import DocSlider from "./DocSlider";
import useGetDocsListDownload from "./data/useGetDownloadList";
import useGetDueDate from "./data/useGetDueDate";
import useGetUserBasic from "./data/getUserbasicDetails";
import SampleDocsSlider from "./SampleDocsSlider";

function ProfileAndCertificate() {
  const { isLoggedIn } = useContext(myContext);
  const [docImages, setDocImages] = useState([]);
  const [dueDateArr, setDueDateArr] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const [
    docsListDownloadData,
    docsListDownloadError,
    docsListDownloadIsLoading,
    getDocsListDownload,
  ] = useGetDocsListDownload();

  const [dueDateData, dueDateError, dueDateIsLoading, getDueDate] =
    useGetDueDate();

  const [dataFetched, setDataFetched] = useState(false);
  const [date, setDate] = useState(new Date());
  const [filteredDates, setFilteredDates] = useState([]);

  useEffect(() => {
    if (isLoggedIn && !dataFetched) {
      getDocsListDownload();
      getDueDate();
      setDataFetched(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (docsListDownloadData) {
      setDocImages(docsListDownloadData.data.results);
    }
    if (dueDateData) {
      const flattenedData = [];
      Object.keys(dueDateData.data).forEach((month) => {
        dueDateData.data[month].forEach((item) => {
          flattenedData.push(item);
        });
      });
      setDueDateArr(flattenedData);
    }
  }, [docsListDownloadData, dueDateData]);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const filtered = dueDateArr.filter((data) => {
      const eventDate = new Date(data.date);
      return (
        eventDate.getMonth() === currentMonth &&
        eventDate.getFullYear() === currentYear
      );
    });
    setFilteredDates(filtered);
  }, [date, dueDateArr]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const content = [
    {
      title: "Maximize Tax Deductions",
      description:
        "Save taxes by using deductions under Section 80C for investments like Section 80D for health insurance.",
    },
    {
      title: "Invest in Retirement Plans",
      description:
        "Contribute to retirement plans such as NPS to reduce taxable income and ensure financial security after retirement.",
    },
    {
      title: "Claim Home Loan Interest",
      description:
        "Claim deductions for home loan interest under Section 24 to reduce your taxable income.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
      setScrollPosition((prevPosition) => {
        const maxScroll =
          document.getElementById("scrollable-content")?.scrollHeight || 0;
        return (prevPosition + 100) % maxScroll; // Scroll by 100px each time, looping back to top
      });
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="flex flex-col gap-[1rem]">
      {/* finance tips */}
      <div className="bg-white rounded-xl shadow-md p-[1rem]">
        <h4 className="font-[500] text-[1rem] text-lightGray">Finance Tips</h4>

        <div className="bg-[#FAF8FC] px-[1rem] py-[1.5rem] rounded-md mt-[1rem] relative overflow-hidden">
          <div
            id="scrollable-content"
            className="max-h-[200px] overflow-y-auto pr-4" // Adjust max height and enable scrolling
          >
            <h4 className="font-[500] text-[0.9rem] text-darkGray">
              {content[currentIndex].title}
            </h4>
            <p className="text-[0.8rem] text-lightGray">
              {content[currentIndex].description}
            </p>
          </div>
        </div>

        {/* purple dots */}
        <div className="mt-[0.7rem] flex space-x-2 items-center justify-center">
          {content.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                index === currentIndex ? "bg-customPurple" : "bg-gray-200"
              }`}
            ></div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-md p-[1rem] min-h-[36rem]">
        <h4 className="font-[500] text-[1rem] text-lightGray mb-[1.5rem] ">
          Upcoming due dates
        </h4>
        <div className="overflow-y-scroll scrollbar-hide max-h-[30rem]">
          {filteredDates.length > 0 ? (
            filteredDates.map((data, index) => (
              <div key={index} className="">
                <span className="mb-2 bg-[#D3C1E1] px-2 py-1 rounded-md text-[#344054] font-[500] text-[.8rem] leading-[.9rem]">
                  {data.date}
                </span>
                <ul>
                  <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                    {data.compliance_activity}
                  </li>
                  <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                    {data.penalty_fine_interest}
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <p>No important dates for this month.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileAndCertificate;
