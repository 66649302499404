import React, { useEffect } from "react";
import moneyManagerIcon from "assets/images/moneyManager/moneyManagerIcon.svg";
import direction from "assets/images/moneyManager/direction.svg";
import future_proof from "assets/images/moneyManager/future_proof.svg";
import reality_check from "assets/images/moneyManager/reality_check.svg";
import Button from "components/Button/BtnOrange";
import { useNavigate } from "react-router-dom";
import { MONEY_MANAGER_ADD_INITIAL_ACCOUNT_URL } from "constants/routes";
import useGetManagerHome from "./data/usegetManagerHome";
import MoneyManagerHome from "./MoneyManagerHome";

const MoneyManager = () => {
  const navigate = useNavigate();
  const [getData, isError, isLoading, getManagerHomeDetails] =
    useGetManagerHome();

  useEffect(() => {
    getManagerHomeDetails("");
  }, []);

  // const data =
  //   getData?.data?.latest_transactions.length>0 ||
  //   getData?.data?.recurring_transactions.length>0 ||
  //   getData?.data?.top_goals.length>0

  const handleBeginyourJournery = () => {
    navigate(MONEY_MANAGER_ADD_INITIAL_ACCOUNT_URL);
  };
  return (
    <>
      {isLoading && (
            <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
              <div className="loader mt-48"></div>
            </div>
          )}
      {getData?.data?.is_account_existing ? (
        <>
          <MoneyManagerHome />
        </>
      ) : (
        <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 h-screen">
          <h4 className="  text-darkGray text-[1.4rem] m-0">Money Manger</h4>
          <div className="bg-white rounded-lg px-[1rem] py-[2rem] mt-[1.5rem]">
            <div className=" text-center ">
              <h4 className="text-darkGray text-[1.3rem] font-[500]">
                Know Your Money Story
              </h4>
              <h6 className="text-lightGray text-[0.9rem] font-[400]">
                See where you stand today, shape where you'll go tomorrow
              </h6>
              <div className="flex justify-center">
                <img
                  src={moneyManagerIcon}
                  alt=""
                  className="w-[12rem] h-[12rem]"
                />
              </div>

              {/* cards */}
              <div className="grid grid-cols-3 gap-[1rem] mt-2">
                <div className="flex gap-[1rem] p-[1rem] border-1 border-[#D9D9D9] bg-[#FAF8FC] rounded-lg">
                  <img
                    src={reality_check}
                    alt=""
                    className="w-[1.8rem] h-[1.8rem]"
                  />
                  <div className="text-left">
                    <h4 className="text-darkGray text-[0.9rem] font-[600]">
                      Reality Check
                    </h4>
                    <p className="text-lightGray text-[0.8rem] m-0">
                      Get a clear picture of your <br /> accounts, spending, and
                      monthly
                      <br />
                      cash flow
                    </p>
                  </div>
                </div>

                <div className="flex gap-[1rem] p-[1rem] border-1 border-[#D9D9D9] bg-[#FAF8FC] rounded-lg">
                  <img
                    src={future_proof}
                    alt=""
                    className="w-[1.8rem] h-[1.8rem]"
                  />
                  <div className="text-left">
                    <h4 className="text-darkGray text-[0.9rem] font-[600]">
                      Future-Proof
                    </h4>
                    <p className="text-lightGray text-[0.8rem] m-0">
                      Stay on top of your <br /> commitments and plan your
                      <br /> monthly budgets ahead
                    </p>
                  </div>
                </div>

                <div className="flex gap-[1rem] p-[1rem] border-1 border-[#D9D9D9] bg-[#FAF8FC] rounded-lg">
                  <img
                    src={direction}
                    alt=""
                    className="w-[1.8rem] h-[1.8rem]"
                  />
                  <div className="text-left">
                    <h4 className="text-darkGray text-[0.9rem] font-[600]">
                      Reality Check
                    </h4>
                    <p className="text-lightGray text-[0.8rem] m-0">
                      Transform your financial <br /> goals form dreams to{" "}
                      <br />
                      achievable milestones
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-[1rem]">
                <Button
                  onClick={handleBeginyourJournery}
                  name="Begin Your Journey"
                  px={"px-[1rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MoneyManager;
