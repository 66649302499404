import useAPICall from "hooks/useAPICall";
import {POST_GOAL, POST_BUSINESS_DOCUMENTS_URL } from "constants/apis";

const usePostBusinessDoc = () => {
  const [data, error, isLoading, callPostBusinessDoc, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postBusinessDoc = (business_user_id, body) => {
    const url = POST_BUSINESS_DOCUMENTS_URL.replace(":business_user_id", business_user_id);
    callPostBusinessDoc({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, postBusinessDoc, setSuccessData, setError];
};

export default usePostBusinessDoc;
