import Button from "components/Button/BtnOrange";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddTransaction from "./AddTransaction";
import { MONEY_MANAGER_TRANSACTIONS_URL } from "constants/routes";
import usePostTransactions from "../data/usePostTransactions";
import useGetTransactionsList from "../data/useGetTransactionsList";
import useGetAccountsList from "../data/useGetListAccounts";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import useGetCategoriesList from "../data/useGetCategoriesList";
import TransactionsDashboard from "./TransactionsDashboard";

const Transactions = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Assuming you want it to be open initially
  const [account, setaccount] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [dateOfTran, setDateOfTran] = useState("");
  const [amountType, setAmountType] = useState("Expense");
  const [category, setcategory] = useState("");
  const [isToggled, setIsToggled] = useState(false); //for recurring
  const [isCreateMoreToggled, setIsCreateMoreToggled] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [dateOfRecc, setDateOfRecc] = useState("");

  const [postData, postIsError, postIsLoading, postTransactions] =
    usePostTransactions();
  const [getData, getIsError, getIsLoading, getTransactionsList] =
    useGetTransactionsList();
  const [
    getCategoryData,
    getCategoryIsError,
    getCategoryIsLoading,
    getCategoriesList,
  ] = useGetCategoriesList();
  const [
    getAccountData,
    getAccountIsError,
    getAccountIsLoading,
    getAccountsList,
  ] = useGetAccountsList();

  useEffect(() => {
    getCategoriesList();
    getAccountsList();
    getTransactionsList(`?filter_type=${"all_time"}`);
  }, []);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Transaction Created Successfully");
      if (!isCreateMoreToggled) {
        setIsOpen(false);
      }
      navigate(MONEY_MANAGER_TRANSACTIONS_URL);
      // getTransactionsList();
      setaccount("");
      setAmount("");
      setDesc("");
      setDateOfTran("");
      setAmountType("Expense");
      setcategory("");
      setIsToggled(false);
      setIsCreateMoreToggled(false);
      getTransactionsList(`?filter_type=${"all_time"}`);
    }
    if(postIsError){
      if(getData?.data?.total_transactions === 0){
        console.log('postIsError', postIsError)
        showErrorToast("Account required to create a transaction.")
      }else{
        showErrorToast("Error in adding transaction")
      }
    }
  }, [postData,postIsError]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleAddTransaction = () => {
    setIsOpen(true);
  };

  const handleGoToDashboard = () => {
    const formData = new FormData();
    formData.append("account", account);
    formData.append("amount", amount);
    formData.append("description", desc);
    formData.append("date_of_transaction", dateOfTran);
    formData.append("amount_type", amountType);
    formData.append("categeory", category);
    formData.append("reccuring_transaction", isToggled);
    if (isToggled === true) {
      formData.append("frequency", frequency);
      formData.append("date_of_reccuring", dateOfRecc);
    }
    postTransactions(formData);
  };

  return (
    <>
      {getData?.data?.total_transactions === 0 ? (
        <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 h-screen">
          <h4 className="  text-darkGray text-[1.4rem] m-0">Transactions</h4>
          <div className="flex flex-col gap-[1rem] justify-center items-center bg-white rounded-lg px-[1rem] py-[2rem] mt-[1.5rem] border">
            <h6 className="text-center text-darkGray text-[0.9rem] font-[500] m-0">
              No Transactions added yet,
              <br /> Get started by adding your first Transaction
            </h6>

            <AddTransaction
              frequency={frequency}
              setFrequency={setFrequency}
              dateOfRecc={dateOfRecc}
              setDateOfRecc={setDateOfRecc}
              isCreateMoreToggled={isCreateMoreToggled}
              setIsCreateMoreToggled={setIsCreateMoreToggled}
              account={account}
              setaccount={setaccount}
              amount={amount}
              setAmount={setAmount}
              desc={desc}
              setDesc={setDesc}
              dateOfTran={dateOfTran}
              setDateOfTran={setDateOfTran}
              category={category}
              setcategory={setcategory}
              getCategoryData={getCategoryData}
              getAccountData={getAccountData}
              amountType={amountType}
              setAmountType={setAmountType}
              isToggled={isToggled}
              setIsToggled={setIsToggled}
              isOpen={isOpen}
              getCategoriesList={getCategoriesList}
              handleAddTransaction={handleAddTransaction}
              handleGoToDashboard={handleGoToDashboard}
              closeModal={closeModal}
            />
          </div>
        </div>
      ) : (
        <TransactionsDashboard />
      )}
    </>
  );
};

export default Transactions;
