import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import fileDownload from "assets/images/BusinessDashboard/BusinessEmployee/file-down.svg";
import AddNewEmployee from "./AddNewEmployee";

const AddEmployeeType = ({
  triggerElement,
  openEmployeType,
  setOpenEmployeType,
  setReloadGetEmployee
}) => {
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const addNewEmployeeBtnRef = useRef(null);
  const handleReset = () => {
    setOpenEmployeType(false);
  };
  const handleOpenDilog = () => {
    setOpenAddEmployee(true);
  };
  useEffect(() => {
    if (openAddEmployee) {
      addNewEmployeeBtnRef.current.click();
    }
  }, [openAddEmployee]);

  const resetState=()=>{
    setOpenAddEmployee(false)
  }
  
  const handleCloseTypeAndAddEmployee=()=>{
    setOpenAddEmployee(false)
    setOpenEmployeType(false)
  }

  const handleReloadGetApi=()=>{
    setReloadGetEmployee(true)
  }
  return ( 
    <DialogBox
      triggerElement={triggerElement}
      goRight={true}
      onClose={handleReset}
      heading={
        <p className="font-[600] text-[1rem] text-[#334155]">
          Add New Employee
        </p>
      }
      content={(handleClose) => (
        <>
          <div className="flex flex-col h-[520px] items-center gap-4 justify-center">
            <div className="flex flex-col items-center">
              <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                Add a new employee to your organization.
              </p>
              <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                You can either add the details manually or import from an Excel
                file.
              </p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <div>
                <button
                  className="px-3 py-1.5 bg-[#EA580C] text-[#FFFFFF] rounded-md"
                  onClick={handleOpenDilog}
                >
                  Add Manually
                </button>
              </div>
              <div className="text-[#64748B]">OR</div>
              <div className="flex flex-col gap-1">
                <button className="px-3 py-1.5 bg-[#FFFFFF] text-[#64748B] rounded-md border-[1px] border-[#D9D9D9]">
                  Import from excel
                </button>
                <div className="flex gap-1 items-center">
                  <p className="text-[#64748B] m-0">Download Template</p>
                  <img src={fileDownload} alt="fileDownload" />
                </div>
              </div>
            </div>
          </div>
          {openAddEmployee && (
            <AddNewEmployee
              triggerElement={
                <button className="hidden" ref={addNewEmployeeBtnRef}>
                  Add New Employee
                </button>
              }
              resetState={resetState}
              handleReloadGetApi={handleReloadGetApi}
              handleCloseTypeAndAddEmployee={handleCloseTypeAndAddEmployee}
            />
          )}
        </>
      )}
    />
  );
};

export default AddEmployeeType;
