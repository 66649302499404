import React, { useEffect, useRef, useState } from "react";
import zap from "assets/images/BusinessDashboard/BusinessEmployee/zap.svg";
import shield from "assets/images/BusinessDashboard/BusinessEmployee/shield-check.svg";
import fileEdit from "assets/images/BusinessDashboard/BusinessEmployee/file-edit.png";
import star from "assets/images/BusinessDashboard/BusinessEmployee/star.svg";
import search from "assets/images/BusinessDashboard/BusinessEmployee/search.svg";
import userPlus from "assets/images/BusinessDashboard/BusinessEmployee/user-plus.svg";
import AddEmployeeType from "./AddEmployeeType";
import Calendar from "react-calendar";
import InputField from "components/input/InputField";
import { useNavigate } from "react-router-dom";
import { BUSINESS_EMPLOYEE_DETAIL_URL } from "constants/routes";
import CustomCalendar from "./CustomCalender";
import RecordLeave from "./RecordLeave";
import SalaryGraph from "./SalaryGraph";
import Payslip from "./Payslip";
import useGetEmployeesOverview from "./data/useGetEmployeesOverview";
import useGetEmployeeRoles from "./data/useGetEmployeeRoles";
import useGetSearchEmployee from "./data/useGetSearchEmployee";
import usePostEmployeeFilter from "./data/usePostEmployeeFilter";

const BusinessEmployee = () => {
    const employeeTypeBtnRef= useRef(null);
    const generatePaySlipBtnRef=useRef(null)
    const openRecordLeaveBtnRef = useRef(null);
    const [openEmployeType, setOpenEmployeType]=useState(false);
    const [showDetailPage, setShowDetailPage]=useState(false);
    const [openRecordLeave, setOpenRecordLeave]=useState(false);
    const [openGeneratePaySlip,setOpenGeneratePaySlip]=useState(false);
    const [employeeOverviewData, setEmployeeOverviewData]=useState({});
    const [idForPayslip, setIdForPayslip]=useState("");
    const [dateToGet, setDateToGet] = useState("");
    const [reloadGetEmployee, setReloadGetEmployee]=useState(false);
    const navigate= useNavigate()
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [employeeData, employeeError, employeeIsLoading, getEmployeeOverview]= useGetEmployeesOverview()
    const [employeeFilterData, employeeFilterError, employeeFilterIsLoading, postEmployeeFilter]= usePostEmployeeFilter()

    useEffect(()=>{
      if(reloadGetEmployee){
        getEmployeeOverview()
        setReloadGetEmployee(false)
      }
    },[reloadGetEmployee])

  const user = JSON.parse(localStorage.getItem("user"));
  const [
    employeeRoleData,
    employeeRoleError,
    employeeRoleIsLoading,
    getEmployeeRoles,
  ] = useGetEmployeeRoles();
  const [
    searchEmployeeData,
    searchEmployeeError,
    searchEmployeeIsLoading,
    getSearchEmployee,
  ] = useGetSearchEmployee();

  useEffect(() => {
    getEmployeeOverview();
    getEmployeeRoles(user?.business_id);
  }, []);

  useEffect(()=>{
    if(employeeFilterData){
      setEmployeeOverviewData((prev)=>({...prev,employees:employeeFilterData?.data}))
    }
  },[employeeFilterData,employeeFilterError])

  useEffect(() => {
    if (employeeData) {
      if (employeeData?.data) {
        setEmployeeOverviewData(employeeData?.data);
      }
      if (employeeData?.data?.employees?.employee_details.length > 0) {
        setShowDetailPage(true);
      }
    }
  }, [employeeData]);

  const handleOpenDilog = () => {
    setOpenEmployeType(true);
  };
  const handleNavigate = (id) => {
    navigate(BUSINESS_EMPLOYEE_DETAIL_URL, { state: { id } });
  };
  useEffect(() => {
    if (openEmployeType) {
      employeeTypeBtnRef.current.click();
    }
  }, [openEmployeType]);

  useEffect(() => {
    if (openRecordLeave) {
      openRecordLeaveBtnRef.current.click();
    }
  }, [openRecordLeave]);

  const handleOpenRecordLeave = () => {
    setOpenRecordLeave(true);
  };

  const handleGeneratePaySlip = (id) => {
    setOpenGeneratePaySlip(true);
    setIdForPayslip(id);
  };

  useEffect(() => {
    if (openGeneratePaySlip) {
      generatePaySlipBtnRef.current.click();
    }
  }, [openGeneratePaySlip]);

  useEffect(() => {
    if (openGeneratePaySlip) {
      generatePaySlipBtnRef.current.click();
    }
  }, [openGeneratePaySlip]);

  const [role, setRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNameSuggestion, setSearchNameSuggestion]=useState([])

  useEffect(() => {
    if (searchEmployeeData) {
      setSearchNameSuggestion(searchEmployeeData?.data?.employee_names)
    }
  }, [searchEmployeeData]);

  const handleInputChange = (e) => {
    postEmployeeFilter({role: e.target.value})
    setRole(e.target.value);
  };

  const handleSearchEmployee = (e) => {
    setSearchQuery(e.target.value);
    if(e.target.value === ""){
      setSearchNameSuggestion([]);
      postEmployeeFilter({employee_name: ""})
      return
    }
    if(debounceTimeout){
      clearTimeout(debounceTimeout)
    }

    const newTimeout=setTimeout(()=>{
     if(e.target.value.trim()){
      getSearchEmployee(user?.business_id,e.target.value)
     }
    },300)

    setDebounceTimeout(newTimeout)
  };


  // useEffect(() => {
  //   getEmployeeOverview({
  //     formattedDate: dateToGet,
  //     role: role,
  //     employee_name: searchQuery,
  //   });
  // }, [role, searchQuery]);

  const [dropdownWidth, setDropdownWidth] = useState("20px");

  const calculateWidth = (text) => {
    // Create a temporary element to measure text width
    const tempElement = document.createElement("span");
    tempElement.style.visibility = "hidden";
    tempElement.style.whiteSpace = "nowrap";
    tempElement.style.fontWeight = 500; // Match font styles
    tempElement.style.fontSize = "0.9rem"; // Match text size
    tempElement.style.paddingRight = "0.5rem";
    tempElement.textContent = text;

    document.body.appendChild(tempElement);
    const width = tempElement.offsetWidth + 40; // Add padding for safe space
    document.body.removeChild(tempElement);

    return `${width}px`;
  };

  useEffect(() => {
    // Set initial width based on the default value
    const defaultOption = role || "All Roles";
    setDropdownWidth(calculateWidth(defaultOption));
  }, [role]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setDropdownWidth(calculateWidth(selectedValue)); // Update width
    handleInputChange(e); // Trigger parent handler
  };

  const handleSelectName=(val)=>{
    postEmployeeFilter({employee_name: val})
    setSearchNameSuggestion([])
  }

  return (
    <div>
      {employeeIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="mt-6 p-3 bg-gray-100">
        <h6 className="m-0 text-[#334155] text-[1.2rem]">Employees</h6>
        {showDetailPage ? (
          <div className="mt-4">
            <div className="grid grid-cols-2 gap-[0.8rem] ">
              <div className=" rounded bg-[#FFFFFF] p-[1rem]">
                <div className="flex justify-between">
                  <p className="m-0 text-[#334155] text-[1rem] font-[600]">
                    Leaves
                  </p>
                  <button
                    onClick={handleOpenRecordLeave}
                    className="bg-[#EA580C] text-[0.9rem] text-[#FFFFFF] rounded px-2.5 py-1.5 flex gap-1 items-center"
                  >
                    <img
                      src={fileEdit}
                      alt="fileEdit"
                      className="w-[1.2rem] h-[1.2rem]"
                    />
                    Record Leave
                  </button>
                </div>
                <div>
                  <CustomCalendar
                    leaveData={employeeOverviewData?.leaves}
                    getEmployeeOverview={getEmployeeOverview}
                    setDateToGet={setDateToGet}
                  />
                </div>
              </div>
              <div className=" rounded bg-[#FFFFFF] p-[1rem]">
                <div className="flex justify-between items-center">
                  <p className="m-0 text-[#334155] text-[1rem] font-[600]">
                    Report
                  </p>
                  <button className="text-[0.9rem] text-[#334155] rounded px-2.5 py-1.5 flex gap-1 items-center border border-[#D9D9D9] font-[600]">
                    {employeeOverviewData?.report?.year}
                  </button>
                </div>
                <div className="mt-[1rem] mb-[0.5rem]">
                  <p className="text-[#64748B] m-0 font-[600] text-[0.9rem]">
                    Expenditure in {employeeOverviewData?.report?.year}
                  </p>
                </div>
                <div>
                  <SalaryGraph graphData={employeeOverviewData?.report} />
                </div>
              </div>
            </div>
            <div className="mt-[0.8rem] p-[1rem] rounded bg-[#FFFFFF] w-[100%] flex flex-col gap-2.5">
              <div className="flex justify-between items-center w-[100%]">
                <div
                  style={{ width: dropdownWidth }}
                  className="transition-all text-center"
                >
                  <InputField
                    type="select"
                    options={[
                      { label: "All Roles", value: "" },
                      ...(Array.isArray(employeeRoleData?.data?.roles)
                        ? employeeRoleData.data.roles.map((role) => ({
                            value: role,
                            label: role,
                          }))
                        : []),
                    ]}
                    value={role}
                    onChange={handleChange}
                    className="text-darkGray font-[500] w-full pl-[.5rem] py-[.3rem] text-[0.9rem] rounded border border-[#D9D9D9] focus:outline-none"
                  />
                </div>

                <div className="w-[90%] flex items-center justify-end gap-1.5">
                  <div className="relative">
                  <div className="flex gap-1 items-center rounded border border-[#D9D9D9] px-2">
                    <img
                      src={search}
                      alt="search"
                      className="w-[1.1rem] h-[1.1rem]"
                    />
                    <InputField
                      placeholder="Search Employees"
                      type="search"
                      value={searchQuery}
                      onChange={(e) => handleSearchEmployee(e)}
                      className="w-[100%] focus:outline-none px-[0.7rem] py-1.5 rounded text-[0.9rem]"
                    />
                  </div>
                  {searchNameSuggestion.length > 0 && (
                  <div className="absolute top-full left-0 w-full bg-white border border-[#D9D9D9] shadow-md rounded mt-1 z-10">
                    {searchNameSuggestion.map((name, index) => (
                      <div
                        key={index}
                        onClick={() => handleSelectName(name)}
                        className="px-3 py-2 hover:bg-[#F1F5F9] cursor-pointer text-[0.9rem] text-[#64748B]"
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                )}
                  </div>
                  <div
                    onClick={handleOpenDilog}
                    className="text-[0.9rem] bg-[#F1F5F9] px-2.5 py-1.5 text-[#64748B] flex gap-1 cursor-pointer items-center rounded"
                  >
                    <img
                      src={userPlus}
                      alt="addFriend"
                      className="w-[1.2rem] h-[1.2rem] mr-1"
                    />
                    Add New Employee
                  </div>
                </div>
                <div></div>
              </div>
              <div className="w-[100%] rounded-md border border-[#D9D9D9]">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr className="bg-[#F8FAFC] border-b border-[#D9D9D9] ">
                      <td className="px-4 py-2 text-[#64748B] text-[0.8rem] rounded-tl-md font-[500]">
                        Name
                      </td>
                      <td className="px-4 py-2 text-[#64748B] text-[0.8rem] font-[500]">
                        Salary
                      </td>
                      <td className="px-4 py-2 text-[#64748B] text-[0.8rem] font-[500]">
                        Team
                      </td>
                      <td className="px-4 py-2 text-[#64748B] text-[0.8rem] font-[500]">
                        Role
                      </td>
                      <td className="px-4 py-2 text-[#64748B] rounded-tr-md"></td>
                    </tr>
                    {employeeOverviewData?.employees?.employee_details?.map(
                      (el, i) => {
                        return (
                          <tr
                            key={el.id}
                            className="border-b border-[#D9D9D9] "
                          >
                            <td className="px-4 py-2 text-darkGray font-[500]">
                              {el.name}
                            </td>
                            <td className="px-4 py-2 text-[#64748B] font-[500]">
                              ₹{el.salary}
                            </td>
                            <td className="px-4 py-2 text-[#64748B] font-[500]">
                              {el.team}
                            </td>
                            <td className="px-4 py-2 text-[#64748B] font-[500]">
                              {el.role}
                            </td>
                            <td className="px-4 py-2 text-[#64748B]">
                              <div className="flex items-center gap-1">
                                <span
                                  onClick={() => handleNavigate(el.id)}
                                  className="bg-[#F8FAFC] p-1 rounded cursor-pointer text-[0.8rem]"
                                >
                                  View
                                </span>
                                <span
                                  onClick={() => handleGeneratePaySlip(el.id)}
                                  className="bg-[#F8FAFC] p-1 rounded cursor-pointer text-[0.8rem]"
                                >
                                  Generate Payslip
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}

                    <tr className="bg-[#F8FAFC]">
                      <td className="font-[500] px-4 py-2 text-[#64748B] text-[0.8rem] rounded-bl-md">
                        Total
                      </td>
                      <td className="px-4 py-2 text-[#64748B] text-[0.8rem]">
                        ₹{employeeOverviewData?.employees?.total || 0}
                      </td>
                      <td className="font-[500] px-4 py-2 text-[#64748B] text-[0.8rem]">
                        {employeeOverviewData?.employees?.total_teams || 0}
                      </td>
                      <td className="font-[500] px-4 py-2 text-[#64748B] text-[0.8rem]">
                        {employeeOverviewData?.employees?.total_roles || 0}
                      </td>
                      <td className="px-4 py-2 text-[#64748B] rounded-br-md"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[100%] mt-6 bg-[#FFFFFF] rounded-md px-3 py-4 flex flex-col gap-3.5">
            <div className="flex flex-col gap-1 items-center">
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                Manage Your Workforce with Ease
              </p>
              <p className="m-0 text-[#64748B] text-[0.9rem] font-[500]">
                Unlock the power of comprehensive employee insights
              </p>
            </div>
            <div className="flex gap-2.5">
              <div className="w-[32%] rounded-[16px] border-[1px] border-[#D9D9D9] bg-[#FFF7ED] py-3.5 px-4 flex gap-2.5">
                <div className="w-[10%]">
                  <img src={zap} alt="zap" />
                </div>
                <div className="w-[88%]">
                  <p className="m-0 text-[#000000] text-[0.85rem]">
                    Real-Time Visibility
                  </p>
                  <p className="m-0 text-[#64748B] text-[0.85rem]">
                    Clear, up-to-date picture of their team's performance,
                    attendance, and compensation details.
                  </p>
                </div>
              </div>
              <div className="w-[32%] rounded-[16px] border-[1px] border-[#D9D9D9] bg-[#FFF7ED] py-3.5 px-4 flex gap-2.5">
                <div className="w-[10%]">
                  <img src={shield} alt="shield" />
                </div>
                <div className="w-[88%]">
                  <p className="m-0 text-[#000000] text-[0.85rem]">
                    Never Miss a Milestone
                  </p>
                  <p className="m-0 text-[#64748B] text-[0.85rem]">
                    Important employee events like birthdays, work
                    anniversaries, and performance reviews.
                  </p>
                </div>
              </div>
              <div className="w-[32%] rounded-[16px] border-[1px] border-[#D9D9D9] bg-[#FFF7ED] py-3.5 px-4 flex gap-2.5">
                <div className="w-[10%]">
                  <img src={zap} alt="zap" />
                </div>
                <div className="w-[88%]">
                  <p className="m-0 text-[#000000] text-[0.85rem]">
                    Set Your Direction
                  </p>
                  <p className="m-0 text-[#64748B] text-[0.85rem]">
                    Identify trends, spot issues, and make data-driven
                    decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={handleOpenDilog}
                className="bg-[#EA580C] text-[#FFFFFF] rounded-sm text-[0.85rem] px-2.5 py-1.5"
              >
                Add Employee
              </button>
            </div>
          </div>
        )}
        {openEmployeType && (
          <AddEmployeeType
            openEmployeType={openEmployeType}
            setOpenEmployeType={setOpenEmployeType}
            setReloadGetEmployee={setReloadGetEmployee}
            triggerElement={
              <button ref={employeeTypeBtnRef} className="hidden">
                openDilog
              </button>
            }
          />
        )}
        {openRecordLeave && (
          <RecordLeave
            openRecordLeave={openRecordLeave}
            setOpenRecordLeave={setOpenRecordLeave}
            getEmployeeOverview={getEmployeeOverview}
            triggerElement={
              <button ref={openRecordLeaveBtnRef} className="hidden">
                openDilog
              </button>
            }
          />
        )}
        {openGeneratePaySlip && (
          <Payslip
            openGeneratePaySlip={openGeneratePaySlip}
            setOpenGeneratePaySlip={setOpenGeneratePaySlip}
            idForPayslip={idForPayslip}
            triggerElement={
              <button ref={generatePaySlipBtnRef} className="hidden">
                openDilog
              </button>
            }
          />
        )}
      </div>
    </div>
  );
};

export default BusinessEmployee;
