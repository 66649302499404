import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import InputField from "components/input/InputField";
import usePostGoal from "./data/usePostGoal";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import usePatchGoalData from "./data/usePatchGoalData";

const AddNewGoal = ({ triggerElement, setIsGoal, isGoal, resetState, isReload, setIsReload, setEditableGoal, editableGoal}) => {
  const [postGoalData, postGoalError, postGoalIsLoading, postGoal] = usePostGoal();
  const [patchGoalData, patchGoalError, patchGoalIsLoading, patchGoal] = usePatchGoalData();
  const [isClose, setIsClose] = useState(false);
  const [monthlySavings, setMonthlySavings] = useState(null);
   const [yearsToPay, setYearsToPay] = useState(null);
  const [goalForm, setGoalForm] = useState({
    goal_name: "",
    present_value_of_goal: "",
    invest_upfront: "",
    target_date: "",
  });

  useEffect(()=>{
   if(postGoalData){
    showSuccessToast("Successfully posted")
    setIsClose(true)
    setIsReload(true)
   }
   if(postGoalError){
    showErrorToast("Error in Uploading")
   }
  },[postGoalData, postGoalError])

  useEffect(()=>{
    if(patchGoalData){
     showSuccessToast("Successfully Updated")
     setIsClose(true)
     setIsReload(true)
     setEditableGoal(null)
    }
    if(patchGoalError){
     showErrorToast("Error in Updating")
    }
   },[patchGoalData, patchGoalError])

  useEffect(()=>{
    if(editableGoal){
      const {goal_name, id, present_value_of_goal, upfront_investment, target_date}=editableGoal
      setGoalForm({goal_name:goal_name,id:id,present_value_of_goal:present_value_of_goal,invest_upfront:upfront_investment,target_date:target_date})
    }
  },[editableGoal])



  // Handle input changes in form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGoalForm({ ...goalForm, [name]: value });
  };

  // Function to calculate the savings required per month
  const calculateSavings = () => {
    const { present_value_of_goal, invest_upfront, target_date } = goalForm;
  
    // Parse input values to ensure they are numbers
    const goalValue = parseFloat(present_value_of_goal);
    const upfrontInvestment = parseFloat(invest_upfront);
  
    // Check if all required fields are filled and valid numbers
    if (!goalValue || !upfrontInvestment || !target_date || isNaN(goalValue) || isNaN(upfrontInvestment)) {
      setMonthlySavings(null);
      setYearsToPay(null); // Reset yearsToPay if inputs are invalid
      return;
    }
  
    // Convert target_date to a Date object and calculate months left
    const targetDate = new Date(target_date);
    const currentDate = new Date();
  
    // Ensure targetDate is in the future
    if (targetDate <= currentDate) {
      setMonthlySavings(0);
      setYearsToPay(0); // No years if target date is in the past
      return;
    }
  
    // Calculate the exact months and years left between two dates
    const yearsDiff = targetDate.getFullYear() - currentDate.getFullYear();
    const monthsDiff = targetDate.getMonth() - currentDate.getMonth();
    const totalMonths = yearsDiff * 12 + monthsDiff;
  
    if (totalMonths <= 0) {
      setMonthlySavings(0);
      setYearsToPay(0);
      return;
    }
  
    const remainingYears = (totalMonths / 12).toFixed(1); // Convert months to years (e.g., 1.5 years)
    setYearsToPay(remainingYears); // Update the state for years to pay
  
    // Calculate the remaining amount to save (goal - upfront investment)
    const remainingAmount = goalValue - upfrontInvestment;
  
    // If the upfront investment exceeds or equals the goal value
    if (remainingAmount <= 0) {
      setMonthlySavings(0);
      setYearsToPay(0);
      return;
    }
  
    // Calculate the monthly savings required
    const monthlyAmount = remainingAmount / totalMonths;
  
    // Round to two decimal places for better readability
    setMonthlySavings(parseFloat(monthlyAmount.toFixed(2)));
  };

  useEffect(() => {
    // Calculate savings whenever any form field changes
    calculateSavings();
  }, [goalForm]);

  const handlePostGoal=()=>{
    if(goalForm?.id){
      patchGoal(goalForm)
    }else{
      postGoal(goalForm)
    }
  }

  const getContent = () => {
    return (
      <>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-6 w-[100%] group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B]">
              Goal Name
            </label>
            <InputField
              type="text"
              className="border-[1px] border-[#D9D9D9] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] mt-2"
              value={goalForm.goal_name}
              onChange={handleInputChange}
              name="goal_name"
              placeholder="Goal Name"
            />
          </div>
        </div>

        {/* Present value of goal */}
        <div className="grid grid-cols-12 mt-2 gap-3">
          <div className="col-span-6 w-[100%] group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              What is the present value of your goal?
            </label>
            <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
              <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                Rs.
              </span>
              <InputField
                type="number"
                name="present_value_of_goal"
                value={goalForm.present_value_of_goal}
                onChange={handleInputChange}
                className="rounded-r-md  bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
              />
            </div>
          </div>

          {/* Upfront investment */}
          <div className="col-span-6 w-[100%] group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              How much can you invest upfront?
            </label>
            <div className="w-[100%] rounded-md border-[1px] border-[#D9D9D9] flex justify-center items-center focus-within:border-customPurple">
              <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                Rs.
              </span>
              <InputField
                type="number"
                name="invest_upfront"
                value={goalForm.invest_upfront}
                onChange={handleInputChange}
                className="rounded-r-md bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
              />
            </div>
          </div>
        </div>

        {/* Start date */}
        <div className="grid grid-cols-12 mt-2 gap-3">
          <div className="input-container col-span-6 w-[100%] group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              Target Date
            </label>
            <InputField
              type="date"
              className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
              value={goalForm.target_date}
              name="target_date"
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Display calculated monthly savings */}
        {monthlySavings !== null && (
          <div className="mt-2 mb-2 rounded-lg justify-center items-center p-2 flex h-32 bg-[#F3EFF8]">
            <p className="text-center m-0">
              YOU'LL NEED TO SAVE <br />
              <span className="text-[#000000] font-[600]">
                Rs. {monthlySavings.toFixed(2)} monthly, for{" "}
               {yearsToPay}
                years
                </span>

              <br />
              To achieve your goal.
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        resetClose={setIsGoal}
        isNotDefault={isGoal}
        triggerElement={triggerElement}
        heading={<p className="font-[500] m-0 text-[#64748B]">New Goal</p>}
        content={(handleClose) => (
          <>
            {getContent()}
            <div>
              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={handlePostGoal}
                  name={`${goalForm?.id ? "Update" : "Add"}`}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewGoal;
