export const GET_HOME_URL = `/home`;
export const GET_SERVICE_URL = `/services`;
export const GET_BLOG_URL = `/blog`;
export const DASHBOARD_PAGE_URL = `/dashboard`;
export const BLOG_DETAILS_URL = "/blogDetails/:blogId";
export const INCOME_TAX_RETURNS_URL = "/income-tax-returns";
export const INCOME_TAX_PROFILE_URL = "/income-tax-profile";
export const INCOME_TAX_PROFILE_INFORMATION_URL = "/income-tax-profile/tax-information";
export const INCOME_TAX_SUMMARY_URL = "/income-tax-summary/:income_tax_return_id";
export const USER_INCOME_SUMMARY_PAGE_URL = '/income-tax-summary/:type/:income_tax_return_id/';
export const INCOME_TAX_SUMMARY_USER_DETAILS = '/income-tax-summary/user/:detailType/:income_tax_return_id/';
export const INCOME_TAX_SUMMARY_USER_PAGE_URL = "/income-tax-summary/:income_tax_return_id/tax-info";
export const REPORTS_URL = "/income-tax/reports";
export const GOVT_IDS_URL = '/income-tax-profile/govt-ids'
export const BANK_ACCOUNTS_URL = '/income-tax-profile/bank-accounts'
export const INVITES_URL = '/income-tax-profile/invites/'
export const BUSINESS_TAX_INFORMATION_URL = "/income-tax-business-profile";
export const BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL = "/business/business-profile/tax-business-information";
export const BUSINESS_INVITES_URL = '/business/business-profile/business-invites/'
export const BUSINESS_BANK_ACCOUNTS_URL = '/business/business-profile/bank-accounts/'
export const BUSINESS_DOCUMENTS_URL = '/business/business-profile/documents/'
export const STAKEHOLDER_LIST = '/stakeholder-list/'
export const FAMILY_LOAN_DETAILED_VIEW = '/liabilities/family-loan-detailed-view/'
export const LIABILITIES_FAMILY_LOAN = '/liabilities/family-loan/'
export const LIABILITIES_PERSONAL_LOAN = '/liabilities/personal-loan/';
export const LIABILITIES_PERSONAL_LOAN_DETAILS = '/liabilities/personal-loan-detailed-view/';
export const LIABILITIES_URL = '/liabilities/';
export const LIABILITIES_VIEW_URL = '/liabilities-view/';
export const LIABILITIES_REVOLVING_LOAN = '/liabilities/revolving-loan/';
export const LIABILITIES_REVOLVING_LOAN_DETAILED_URL = '/liabilities/revolving-loan-detailed-view/';
export const ASSETS_URL = '/assets/';
export const ASSETS_TYPE_URL = '/assets/:type'
export const ASSETS_REAL_ESTATE_DETAILED_URL = '/assets/real-estate-detailed-view/:estate_type';
export const ASSETS_PERSONAL_ASSET_DETAILED_URL = '/assets/personal-asset-detailed-view/:personal_asset_type';
export const ASSETS_DEPOSITS_DETAILED_URL = '/assets/deposits-detailed-view/:deposit_type';
export const MONEY_MANAGER = '/money-manager/home/'
export const MONEY_MANAGER_CASH_FLOW = '/money-manager/cash-flow/'
export const MONEY_MANAGER_BUDGET_PLANS = '/money-manager/budget-plans/'
export const LETTER_HEAD_DOCS = '/docs/letter-head/'
export const MONEY_MANAGER_URL = '/money-manager';
export const MONEY_MANAGER_ADD_INITIAL_ACCOUNT_URL = '/money-manager/add';
export const MONEY_MANAGER_TRANSACTIONS_URL = '/money-manager/transactions';
export const MONEY_MANAGER_ACCOUNTS_URL = '/money-manager/accounts';
export const MONEY_MANAGER_TRANSACTIONS_CATEGORIES_URL = '/money-manager/transactions/categories';
  
export const ASSETS_OTHER_ASSET_DETAILED_URL = '/assets/other-asset-detailed-view/:other_asset_type';

export const ADD_BUSINESS_HOME_URL="/add-business"
export const ADD_BUSINESS_ONGOING_URL="/add-business/ongoing-business"
export const ADD_BUSINESS_NEW_URL="/add-business/new-business" 

// Business dashboard
export const BUSINESS_DASHBOARD_URL="/business/dashboard"
export const BUSINESS_EMPLOYEE_URL="/business/employee"
export const BUSINESS_EMPLOYEE_DETAIL_URL="/business/employee/detail-employee"
export const BUSINESS_PROFILE_URL = "/business/business-profile";
export const BUSINESS_INVOICES_URL = "/business/invoices";
export const BUSINESS_SALES_URL = "/business/sales";
export const BUSINESS_TRANSACTIONS_URL = "/business/transactions";
export const BUSINESS_EXPENSES_URL = "/business/expenses";
export const BUSINESS_PAYROLL_URL = "/business/payroll";
export const BUSINESS_CUSTOMERS_URL = "/business/customers";
export const BUSINESS_NEW_CUSTOMERS_URL = "/business/customers/:type";
export const BUSINESS_CUSTOMER_DETAILVIEW = "/business/customers/detail-view";

export const GOOGLE_SIGNIN_SUCCESS_PAGE_URL = "/google-signin-success/";
