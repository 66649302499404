import {  DASHBOARD_PAGE_URL } from "constants/routes";
import React from "react";
import { Link} from "react-router-dom";
import logout from "assets/images/dashboard/log-out.svg";

const BackToPersonalDashboard = () => {
  return (
      <Link
        className="ml-[1rem] mt-[1rem] no-underline text-[#64748B] flex gap-2 text-[0.9rem] font-[500] cursor-pointer"
        to={DASHBOARD_PAGE_URL}
      >
        <img src={logout} alt=""/>
        Back to Dashboard
      </Link>
  );
};

export default BackToPersonalDashboard;
