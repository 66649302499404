import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { myContext } from "App";
import incomeTaxTopImg from "assets/images/incomeTax/IncometaxTop.png";
import incomeTaxBottomImg from "assets/images/incomeTax/IncometaxBottom.png";
import FormDialog from "containers/services/Dialog";
import NormalLoginSheet from "components/BottomSheet/NormalLoginSheet";
import { INCOME_TAX_SUMMARY_URL } from "constants/routes";

const IncomeTaxTitle = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setUserType } = useContext(myContext);
  const [fileNowClicked, setFileNowClicked] = useState(false);
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (fileNowClicked && isLoggedIn) {
      navigate("/income-tax");
    }
  }, [fileNowClicked, isLoggedIn, navigate]);

  const handleFileNow = () => {
    if (user) {
      // navigate(
      //   INCOME_TAX_SUMMARY_URL.replace(
      //     ":income_tax_return_id",
      //     user.current_income_tax_return_year_id
      //   )
      // );
      if(user.account_type === 1 || user.account_type === 3){
        navigate("/income-tax");
      }else{
        navigate("#")
      }
    }
  };

  return (
    <div className=" bg-[url('assets/images/home/background.png')] py-[22%] md:py-[8%] max-h-screen h-fit grid grid-cols-12">
      <div className="md:pl-[12%] md:pr-[20%] md:py-[6%] col-span-12 md:col-span-8 px-[8%]">
        <h2 className="lg:font-[700] lg:text-[3rem] lg:leading-[3.8rem] md:font-[700] md:text-[3rem] md:leading-[3.8rem] font-[700] text-[2.5rem] leading-[2.8rem]">
          File Taxes, &nbsp;
          <span className="bg-gradient-to-r from-[#7E0CDE] from-5% via-[#D14588] via-30% via-[#E65757] via-40% to-[#7E0CDE] to-60% text-transparent bg-clip-text">
            Track Net Worth &nbsp;
          </span>
          Stay Financially Organized
        </h2>
        <p className=" text-gray-500 font-[500] text-[1rem] leading-[1.5rem] mt-4 md:hidden">
          Start your tax filing process with our easy-to-use platform. Enjoy
          accurate calculations, secure data handling, and a streamlined filing
          experience
        </p>
        <div className="mt-4 md:flex md:space-x-2 grid grid-cols-12 gap-4">
          {user ? (
            <div className="col-span-12">
              <button
                type="button"
                // className="purpleBtn py-2 px-4 rounded font-[500] text-white"
                className="w-[100%] border-1 bg-[#9065B4] text-white py-2 px-4 rounded font-[500]"
                onClick={handleFileNow}
              >
                File Now
              </button>
            </div>
          ) : (
            <>
              {isMobile && !user ? (
                <NormalLoginSheet
                  fileNowPathMobile={location.pathname}
                  element={
                    <div className="col-span-12">
                      <button
                        type="button"
                        className=" border-1 w-[98%] rounded bg-[#9065B4]  px-[8rem] py-2"
                      >
                        <span className="text-white font-[500] whitespace-nowrap text-[1rem] leading-[1.5rem]">
                          File Now
                        </span>
                      </button>
                    </div>
                  }
                />
              ) : (
                // <div className="col-span-12 md:mt-2 ">
                <FormDialog 
                  btnName={"File Now"}
                  fileNowPath={location.pathname}
                />
                //  </div>
              )}
            </>
          )}

          <button className="col-span-12 border-1 border-[#9065B4] text-[#9065B4] py-2 px-4 rounded font-[500]">
            Tax Planning
          </button>
        </div>
        <p className=" text-gray-500 font-[500] text-[1rem] leading-[1.5rem] mt-4 md:block hidden">
          Start your tax filing process with our easy-to-use platform. Enjoy
          accurate calculations, secure data handling, and a streamlined filing
          experience
        </p>{" "}
      </div>
      <div className="relative  col-span-4 hidden md:block  ">
        <img src={incomeTaxBottomImg} alt="bottom img" className="w-full " />
        <img
          src={incomeTaxTopImg}
          alt="top img"
          className="absolute top-[-12px] left-0 w-full h-full"
        />
      </div>
    </div>
  );
};

export default IncomeTaxTitle;
