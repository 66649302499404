import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import useGetDetailEmployee from "./data/useGetDetailEmployee";
import useGetGeneratePayslip from "./data/useGetGeneratePayslip";
import useGetSentEmail from "./data/useGetSentEmail";
import { showSuccessToast } from "components/toaster/toastHelper";

const Payslip = ({
  openGeneratePaySlip,
  setOpenGeneratePaySlip,
  triggerElement,
  idForPayslip
}) => {
  const [payslipData, setPayslipData]=useState([])
  const [employeeData, employeeError, employeeIsLoading, getEmployeeOverview]= useGetDetailEmployee();
  const [generatePayslipData, generatePayslipError, generatePayslipIsloading, generatePayslip]= useGetGeneratePayslip();
  const [getEmailData, getEmailError, getEmailIsLoading, getSentEmail]= useGetSentEmail();
  useEffect(()=>{
    if(idForPayslip){
      getEmployeeOverview({id:idForPayslip})
    }
  },[idForPayslip])

  useEffect(()=>{
    if(employeeData){
      setPayslipData(employeeData?.data)
    }
  },[employeeData])

  useEffect(()=>{
   if(generatePayslipData?.data?.download_url){
    window.open(generatePayslipData?.data?.download_url)
   }
  },[generatePayslipData])

  useEffect(()=>{
  if(getEmailData){
    showSuccessToast(getEmailData?.data?.message)
  }
  },[getEmailData])

  const getContent = () => {
    return (
      <>
        <div className="grid grid-cols-12 py-1 gap-3">
          <div className="input-container col-span-6 w-[100%] group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
              Employee Name
            </label>
            <input 
              type="text"
              className="border-[1px] border-[#D9D9D9] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
              value={payslipData?.personal_details?.name}
              disabled={true}
            />
          </div>
        </div>
        <div className="border border-[#D9D9D9] rounded mt-2">
          <table className="border-collapse w-full">
            <tr className="border-b border-[#D9D9D9] h-[50px]">
              <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC] rounded-tl">
                Base Pay
              </td>
              <td className="text-[#64748B] px-4 py-2">₹{payslipData?.job_details?.pay_structure?.base_pay}</td>
            </tr>
            <tr className="border-b border-[#D9D9D9] h-[50px]">
              <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC]">
                Allowances
              </td>
              <td className="text-[#EA580C] px-4 py-2">
                <div className="flex justify-between">
                  <span>₹{payslipData?.job_details?.pay_structure?.allowances?.reduce((acc,el)=> {
                       return acc+el.amount
                      },0)}</span>
                  <div className="bg-[#F1F5F9] w-6 h-6 flex justify-center items-center rounded-[50%]">
                    <MdKeyboardArrowUp className="text-[#334155] text-xl" />
                  </div>
                </div>
              </td>
            </tr>
            {payslipData?.job_details?.pay_structure?.allowances?.map((el, i) => (
              <>
                <tr className="border-b border-[#D9D9D9] h-[50px]">
                  <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] ">
                    {el.name}
                  </td>
                  <td className="text-[#64748B] px-4 py-2">
                    <div className="flex justify-between">
                      <span>₹{el.amount}</span>
                    </div>
                  </td>
                </tr>
              </>
            ))}
            <tr className="border-b border-[#D9D9D9] h-[50px]">
              <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC]">
                Deductions
              </td>
              <td className="text-[#EA580C] px-4 py-2">
                <div className="flex justify-between">
                  <span>₹{payslipData?.job_details?.pay_structure?.deductions?.reduce((acc,el)=> {
                       return acc+el.amount
                      },0)}</span>
                  <div className="bg-[#F1F5F9] w-6 h-6 flex justify-center items-center rounded-[50%]">
                    <MdKeyboardArrowDown className="text-[#334155] text-xl" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="h-[50px]">
              <td className="text-[#334155] font-[500] px-4 py-2 border-r border-[#D9D9D9] bg-[#F8FAFC] rounded-bl">
                Net Pay
              </td>
              <td className="text-[#EA580C] px-4 py-2">₹{payslipData?.job_details?.pay_structure?.net_pay}</td>
            </tr>
          </table>
        </div>
      </>
    );
  };
  const resetState = () => {
    setOpenGeneratePaySlip(false);
  };
  const handleGeneratePayslip=()=>{
    generatePayslip(idForPayslip)
  }
  const handleSentEmail=()=>{
    getSentEmail(idForPayslip)
  }
  return (
    <>
      <DialogBox
        triggerElement={triggerElement}
        onClose={resetState}
        heading={
          <p className="font-[600] text-[1rem] text-[#334155]">Payslip</p>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end items-end gap-[1rem] mt-[2rem] h-[60%]">
              <button onClick={handleSentEmail} className="px-[0.6rem] py-[0.4rem] rounded border border-[#D9D9D9] text-[#64748B] text-[0.9rem]">
                Send Mail
              </button>
              <button onClick={handleGeneratePayslip} className="px-[0.6rem] py-[0.4rem] rounded bg-[#94A3B8] text-[#FFFFFF]">
                Download
              </button>
            </div>
          </>
        )}
      />
    </>
  );
};

export default Payslip;
