import React, { useState } from "react";
import IncomeTaxInsightsChartStatic from "./IncomeTaxInsightsChartStatic";
import CreditScoreChart from "./CreditScoreChart";
import RevenueBusinessChart from "./RevenueBusinessChart";
import arrowLeft from "assets/images/dashboardNav/arrow-left.svg";
import arrowRight from "assets/images/dashboardNav/arrow-right.svg";
import increase from "assets/images/dashboardNav/Increase.svg";
import info_red from "assets/images/reports/info-red.png";
import calenderDark from "assets/images/dashboardNav/calendar.svg";
import TaxAndWealthStatic from "./TaxAndWealthStatic";
import ExpensesChart from "./ExpensesChart";
import InputField from "components/input/InputField";

const DashboardPersonalNew = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideMobile, setCurrentSlideMobile] = useState(0);
  const slides = [
    {
      name: "FY 2023-2024",
      company: "Pre-Filing Stage",
      progress: [
        "PAN Linking ",
        "Record Details ",
        "Tax Computation & Regime Selection",
        "Professional Review",
        "Ready to File",
      ],
      progressMobile: [
        "PAN Linking ",
        "Record Details ",
        "Tax& Regime Selection",
        "Ready to File",
      ],
      completedSteps: 5, // Highlight all steps
      completedStepsMobile: 4, // Highlight all steps
    },
    {
      name: "FY 2022-2023",
      company: "Pre-Filing Stage",
      progress: [
        "PAN Linking ",
        "Record Details ",
        "Tax Computation & Regime Selection",
        "Professional Review",
        "Ready to File",
      ],
      progressMobile: [
        "ITS Filed ",
        "E-Verfiy",
        "Under Process",
        "Refund Credited to Bank",
      ],
      completedSteps: 2, // Highlight first 2 steps
      completedStepsMobile: 4,
    },
    {
      name: "FY 2024-2025",
      company: "Pre-Filing Stage",
      progress: [
        "PAN Linking ",
        "Record Details ",
        "Tax Computation & Regime Selection",
        "Professional Review",
        "Ready to File",
      ],
      progressMobile: [
        "ITS Filed ",
        "E-Verfiy",
        "Under Process",
        "Refund Credited to Bank",
      ],
      completedSteps: 3, // Highlight only the first step
      completedStepsMobile: 4,
    },
  ];

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const { name, company, progress, completedSteps } = slides[currentSlide];

  const slidesMobile = [
    {
      nameMobile: "FY 2023-2024",
      companyMobile: "Pre-Filing Stage",

      progressMobile: [
        "PAN Linking ",
        "Record Details ",
        "Tax& Regime Selection",
        "Ready to File",
      ],
      completedStepsMobile: 4, // Highlight all steps
    },
    {
      nameMobile: "FY 2022-2023",
      companyMobile: "Pre-Filing Stage",

      progressMobile: [
        "PAN Linking ",
        "Record Details ",
        "Tax& Regime Selection",
        "Ready to File",
      ],
      completedStepsMobile: 4,
    },
    {
      nameMobile: "FY 2024-2025",
      companyMobile: "Pre-Filing Stage",

      progressMobile: [
        "PAN Linking ",
        "Record Details ",
        "Tax& Regime Selection",
        "Ready to File",
      ],
      completedStepsMobile: 4,
    },
  ];

  const { nameMobile, companyMobile, progressMobile, completedStepsMobile } =
    slidesMobile;

  return (
    <div className="grid grid-cols-12 gap-[1rem]">
      {/* header */}
      <div className="bg-white rounded-xl p-[0.8rem] col-span-12">
        <div className="flex mt-[0.4rem] border rounded-xl  grid grid-cols-12 divide-x divide-y ">
          <div className=" p-[1rem] md:col-span-3 col-span-6">
            <h4 className="text-lightGray font-[500] text-[0.8rem]">Assets</h4>
            <h4 className="text-darkGray font-[500] text-[1.3rem]">
              ₹10 <span className="text-[1.1rem]">Lakhs</span>
            </h4>
            <h4 className="text-lightGray text-[0.7rem] flex m-0">
              <span className="text-[#1A932E] flex gap-1 items-center">
                <img src={increase} alt="" />
                ₹3.8K &nbsp;
              </span>
              v/s Last Week
            </h4>
          </div>

          <div className=" p-[1rem] md:col-span-3 col-span-6">
            <h4 className="text-lightGray font-[500] text-[0.8rem]">
              Liabilities
            </h4>
            <h4 className="text-darkGray font-[500] text-[1.3rem]">
              ₹6 <span className="text-[1.1rem]">Lakhs</span>
            </h4>
            <h4 className="text-lightGray text-[0.7rem] flex m-0">
              <span className="text-[#1A932E] flex gap-1 items-center">
                <img src={increase} alt="" />8 &nbsp;
              </span>
              v/s Last Week
            </h4>
          </div>

          <div className=" p-[1rem] md:col-span-3 col-span-6 ">
            <h4 className="text-lightGray font-[500] text-[0.8rem]">
              Net Worth
            </h4>
            <h4 className="text-darkGray font-[500] text-[1.3rem]">
              ₹4 <span className="text-[1.1rem]">Lakhs</span>
            </h4>
            <h4 className="text-lightGray text-[0.7rem] flex m-0">
              <span className="text-[#1A932E] flex gap-1 items-center">
                <img src={increase} alt="" />
                20 &nbsp;
              </span>
              v/s Last Week
            </h4>
          </div>

          <div className=" p-[1rem] md:col-span-3 col-span-6">
            <h4 className="text-lightGray font-[500] text-[0.8rem]">Income</h4>
            <h4 className="text-darkGray font-[500] text-[1.3rem]">
              ₹10 <span className="text-[1.1rem]">Lakhs</span>
            </h4>
            <h4 className="text-lightGray text-[0.7rem] flex m-0">
              <span className="text-[#1A932E] flex gap-1 items-center">
                <img src={increase} alt="" />
                100 &nbsp;
              </span>
              v/s Last Week
            </h4>
          </div>
        </div>
        <div className="flex md:flex-row flex-col md:justify-between justify-center items-center bg-[#FEF2F2] rounded-md p-[0.4rem] mt-[0.8rem] md:border-none border-1 border-red-300">
          <h6 className="md:mb-0 mb-2 md:flex items-center gap-[0.5rem] text-center  text-[0.8rem] font-[500] text-[#DC2626] m-0">
            <img
              src={info_red}
              alt=""
              className="w-4 h-4 ml-1 md:block hidden"
            />
            Your Assets as Excess compared to Your Total Income reported as
            Income Tax Dept.
          </h6>
          <button className="text-[0.8rem] bg-customPurple text-white rounded-md py-[0.3rem] px-[0.5rem]">
            Start Tax Planning
          </button>
        </div>
      </div>

      {/* ITR filing desktop*/}
      <div className="md:block col-span-12 hidden bg-white rounded-xl p-[0.8rem]">
        <div className="flex justify-between items-center">
          <h4 className="m-0 text-lightGray text-[0.9rem] font-[600]">
            ITR Filing Progress
          </h4>
          <div className="flex items-center gap-[0.8rem]">
            <button
              className="border rounded-full p-[0.3rem]"
              onClick={handlePrev}
            >
              <img src={arrowLeft} alt="" />
            </button>
            <button
              className="border rounded-full p-[0.3rem]"
              onClick={handleNext}
            >
              <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>

        {/* items */}
        <div className="border rounded-xl pt-[1rem] px-[0.7rem] flex md:flex-row flex-col md:justify-between mt-[0.5rem] ">
          <div className="flex flex-col items-center">
            <h4 className="mb-[1rem] text-darkGray text-[0.9rem] font-[500]">
              {name}
            </h4>
            <h4 className="text-center m-0  text-customPurple text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#EAE1F3]">
              {company}
            </h4>
          </div>
          

          {/* Progress Bar desktop*/}
          <div className="flex items-center gap-[1rem] relative md:justify-end justify-center md:mt-0 mt-[1rem]">
            <div className="absolute top-2  md:left-8 md:right-8 left-10 right-14 h-[2px] bg-gray-300 z-0"></div>

            {progress.map((step, index) => (
              <div
                key={index}
                className="relative z-10 flex flex-col items-center"
              >
                <div
                  className={`w-5 h-5 rounded-full flex items-center justify-center  ${
                    index < completedSteps
                      ? index === progress.length - 1
                        ? "bg-[#198038]"
                        : "bg-customPurple"
                      : "bg-gray-300"
                  } text-white items-center flex p-[0.1rem]`}
                >
                  {index < completedSteps ? "✓" : ""}
                </div>
                <p
                  className={`text-[0.8rem] font-[500] text-center ${
                    index < completedSteps
                      ? index === progress.length - 1
                        ? "text-[#1A932E]"
                        : "text-[#6F6F6F]"
                      : "text-gray-400"
                  }`}
                >
                  {step.includes(" ")
                    ? (() => {
                        const words = step.split(" ");
                        const totalWords = words.length;

                        if (totalWords === 2) {
                          // Case: Exactly 2 words
                          return (
                            <>
                              <span className="block w-full text-center">
                                {words[0]}
                              </span>
                              <span className="block w-full text-center">
                                {words[1]}
                              </span>
                              <span className="block w-full">&nbsp;</span>{" "}
                              {/* Extra space */}
                            </>
                          );
                        } else if (totalWords > 2) {
                          // Case: More than 2 words
                          const line1 = words.slice(0, 2).join(" "); // First 2 words
                          const line2 = words.slice(2, 3).join(" "); // Middle word
                          const line3 = words.slice(3).join(" "); // Last 2 words

                          return (
                            <>
                              <span className="block">{line1}</span>
                              <span className="block">
                                {line2 || "\u00A0"}
                              </span>{" "}
                              {/* Add space if no middle word */}
                              <span className="block">
                                {line3 || "\u00A0"}
                              </span>{" "}
                              {/* Add space if no last words */}
                            </>
                          );
                        }

                        // Default case: Single word or fallback
                        return step;
                      })()
                    : step}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Pagination Dots */}
        <div className="flex justify-center items-center gap-[0.5rem] mt-[1rem]">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                index === currentSlide ? "bg-customPurple" : "bg-gray-300"
              }`}
            ></div>
          ))}
        </div>
      </div>

      {/* ITR filing mobile */}
      <div className="md:hidden col-span-12 bg-white rounded-xl p-[0.8rem]">
        <div className="flex justify-between items-center">
          <h4 className="m-0 text-lightGray text-[0.9rem] font-[600]">
            ITR Filing Progress
          </h4>
        </div>
        {slidesMobile.map((item, index) => (
          <div
            key={index}
            className="border rounded-xl pt-[1rem] px-[0.7rem] flex gap-[0.5rem] md:flex-row flex-col md:justify-between mt-[0.5rem] "
          >
            <div className="flex justify-between items-center">
              <h4 className="mb-[1rem] text-darkGray text-[0.9rem] font-[500] m-0">
                {item.nameMobile}
              </h4>
              <h4 className="text-center m-0  text-customPurple text-[0.8rem] font-[500] rounded-md p-[0.2rem] bg-[#EAE1F3]">
                {item.companyMobile}
              </h4>
            </div>

            {/* progress bar mobile */}
            <div className="flex md:hidden items-center gap-[0.5rem] relative md:justify-end justify-center md:mt-0 mt-[1rem]">
              <div className="absolute top-2 left-10 right-8 h-[2px] bg-gray-300 z-0"></div>

              {item.progressMobile.map((step, index) => (
                <div
                  key={index}
                  className="relative z-10 flex flex-col items-center"
                >
                  <div
                    className={`w-5 h-5 rounded-full flex items-center justify-center  ${
                      index < item.completedStepsMobile
                        ? index === item.progressMobile.length - 1
                          ? "bg-[#198038]"
                          : "bg-customPurple"
                        : "bg-gray-300"
                    } text-white items-center flex p-[0.1rem]`}
                  >
                    {index < item.completedStepsMobile ? "✓" : ""}
                  </div>
                  <p
                    className={`text-[0.8rem] font-[500] text-center ${
                      index < item.completedStepsMobile
                        ? index === item.progressMobile.length - 1
                          ? "text-[#1A932E]"
                          : "text-[#6F6F6F]"
                        : "text-gray-400"
                    }`}
                  >
                    {step.includes(" ")
                      ? (() => {
                          const words = step.split(" ");
                          const totalWords = words.length;

                          if (totalWords === 2) {
                            // Case: Exactly 2 words
                            return (
                              <>
                                <span className="block w-full text-center">
                                  {words[0]}
                                </span>
                                <span className="block w-full text-center">
                                  {words[1]}
                                </span>
                                <span className="block w-full">&nbsp;</span>{" "}
                                {/* Extra space */}
                              </>
                            );
                          } else if (totalWords > 2) {
                            // Case: More than 2 words
                            const line1 = words.slice(0, 2).join(" "); // First 2 words
                            const line2 = words.slice(2, 3).join(" "); // Middle word
                            const line3 = words.slice(3).join(" "); // Last 2 words

                            return (
                              <>
                                <span className="block">{line1}</span>
                                <span className="block">
                                  {line2 || "\u00A0"}
                                </span>{" "}
                                {/* Add space if no middle word */}
                                <span className="block">
                                  {line3 || "\u00A0"}
                                </span>{" "}
                                {/* Add space if no last words */}
                              </>
                            );
                          }

                          // Default case: Single word or fallback
                          return step;
                        })()
                      : step}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Income and Expense */}
      <div className="col-span-12 bg-white rounded-xl p-[0.8rem]">
        <div className="flex justify-between items-center md:mb-[1rem]">
          <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
            Income & Expense
          </h4>
          {/* for mobile view */}
          <div className="border rounded-md p-[0.4rem] md:hidden">
            <img src={calenderDark} alt="" />
          </div>
          {/* income and expense legends  */}
          <div className="md:flex items-center gap-[0.8rem] hidden">
            <div className="border rounded-md p-[0.4rem] flex items-center gap-[0.3rem]">
              <div className="bg-[#1A932E] rounded-full p-[0.3rem]"></div>
              <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
                <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                  Income
                </span>
                ₹64,231
              </h4>
            </div>

            <div className="border rounded-md p-[0.4rem] flex items-center gap-[0.3rem]">
              <div className="bg-[#FD656C] rounded-full p-[0.3rem]"></div>
              <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
                <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                  Expense
                </span>
                ₹64,231
              </h4>
            </div>
            <div className="border rounded-md p-[0.4rem]">
              <img src={calenderDark} alt="" />
            </div>
          </div>
        </div>
        {/* mobile view legends */}
        <div className="flex items-center gap-[0.8rem] md:hidden my-[0.5rem]">
          <div className="border rounded-md p-[0.4rem] flex items-center gap-[0.3rem]">
            <div className="bg-[#1A932E] rounded-full p-[0.3rem]"></div>
            <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
              <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                Income
              </span>
              ₹64,231
            </h4>
          </div>

          <div className="border rounded-md p-[0.4rem] flex items-center gap-[0.3rem]">
            <div className="bg-[#FD656C] rounded-full p-[0.3rem]"></div>
            <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
              <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                Expense
              </span>
              ₹64,231
            </h4>
          </div>
        </div>
        <RevenueBusinessChart />
      </div>

      {/* Tax and wealth tracker */}
      <div className="col-span-12 bg-white rounded-xl p-[0.8rem]">
        <h4 className="mb-[1rem] text-lightGray text-[0.9rem] font-[500]">
          Tax and wealth tracker
        </h4>
        <TaxAndWealthStatic />
      </div>

      {/* Expenses */}
      <div className="col-span-12 bg-white rounded-xl p-[0.8rem]">
        <div className="flex justify-between items-center">
          <h4 className="m-0 text-lightGray text-[0.9rem] font-[500]">
            Expenses
          </h4>
          {/* <div className="border rounded-md p-[0.4rem]"> */}
          <InputField
            type="select"
            className="border rounded px-2 py-1 text-[0.9rem] font-[500] focus:outline-none text-lightGray"
            options={[
              { value: 1, label: "Monthly" },
              { value: 2, label: "Weekly" },
              { value: 3, label: "Yearly" },
            ]}
            //   onChange={(e) => handleInputChange(e)}
            //  value={dropDownValue}
          />
          {/* </div> */}
        </div>
        <hr />
        <ExpensesChart />
      </div>

      {/* income tax insights */}
      <div className="md:col-span-8 col-span-12 bg-white rounded-xl shadow-md p-[1rem]">
        <h4 className="font-[500] text-[0.9rem] text-lightGray">
          Income tax insights
        </h4>
        <IncomeTaxInsightsChartStatic />
      </div>

      {/* Credit Score */}
      <div className="md:col-span-4 col-span-12 md:mb-0 mb-[1rem] bg-white rounded-xl shadow-md p-[1rem]">
        <h4 className="font-[500] text-[0.9rem] text-lightGray">
          Credit Score
        </h4>
        <CreditScoreChart />

        <div className="mt-[1rem] grid grid-cols-2 text-lightGray ">
          <h6 className="text-[0.8rem] text-[10px]">
            <span className="mr-1">•</span>Excellent: 80 - 100
          </h6>
          <h6 className="text-[0.8rem] text-[10px]">
            <span className="mr-1">•</span>Good Score: 60 - 79
          </h6>
          <h6 className="text-[0.8rem] text-[10px]">
            <span className="mr-1">•</span>Fair Score: 40 - 59
          </h6>
          <h6 className="text-[0.8rem] text-[10px]">
            <span className="mr-1">•</span>Poor Score: Below 40
          </h6>
        </div>
      </div>
    </div>
  );
};

export default DashboardPersonalNew;
