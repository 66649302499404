import React, { useEffect, useState } from 'react'
import useGetSettings from './data/useGetSettings';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const RoutesWrapper = ({children}) => {
    const [weAreComingSoon, setWeAreComingSoon] = useState(false);
    const [settingData, settingError, settingIsLoading, getSetting]=useGetSettings();
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(()=>{
       getSetting()
    },[location.pathname])
    useEffect(()=>{
     if(settingData){
        setWeAreComingSoon(settingData?.data?.we_are_comming_soon)
     }
    },[settingData])
    useEffect(()=>{
        if(!weAreComingSoon && location.pathname==="/coming-soon"){
            navigate("/")
        }
    },[location.pathname, navigate])
    if(weAreComingSoon && location.pathname !== "/coming-soon"){
       return <Navigate to="/coming-soon" replace={true}/>
    }


  return children
}

export default RoutesWrapper