import useAPICall from "hooks/useAPICall";
import {GET_BUDGET} from "constants/apis";

const useGetBudget = () => {
  const [data, error, isLoading, callGetCashFlow, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getCashFlow = (params={}) => {
    const url = GET_BUDGET;
    callGetCashFlow({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      // body,
      params
    });
  };

  return [data, error, isLoading, getCashFlow, setSuccessData, setError];
};

export default useGetBudget;
