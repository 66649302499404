import React from "react";

function OrangeButton(props) {
  return (
    <div>
      <button
        className={`flex w-[100%] items-center whitespace-nowrap bg-[#EA580C] rounded border-1 border-[#FEE2E2] ${props.px} ${props.py}`}
        onClick={props.onClick}
      >
        <p className="text-center w-[100%] text-white text-[0.9rem] font-[500] m-0">{props.name}</p>
      </button>
    </div>
  );
}

export default OrangeButton;
