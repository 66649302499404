import React, { useEffect, useState } from "react";
import useGetBankAccounts from "../Tax Profile/data/useGetBankAccounts";
import usePostBankAccount from "../Tax Profile/data/usePostBankAccount";
import usePatchBankAccount from "../Tax Profile/data/usePatchBankAccount";
import useDeleteBankAccount from "../Tax Profile/data/useDeleteAccount";
import usePutAccountBatchUpdate from "../Tax Profile/data/usePutAccountBatchUpdate";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";
import bankIcon from "assets/images/taxProfile/bank.svg";
import BackToBusinessDashboard from "./BackToBusinessDashboard";

const BusinessBankAccounts = () => {
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsccode, setIfscCode] = useState("");
  const [accountType, setAccountType] = useState("");
  const [isPrimary, setIsPrimary] = useState("");
  const [change, setChange] = useState(false);
  const [errors, setErrors] = useState({});
  const [getData, getError, getIsLoading, getBankAccounts] =
    useGetBankAccounts();
  const [postData, postError, postIsLoading, postBankAccounts] =
    usePostBankAccount();
  const [patchData, patchError, patchIsLoading, patchBankAccounts] =
    usePatchBankAccount();
  const [deleteData, deleteError, deleteIsLoading, deleteBankAccounts] =
    useDeleteBankAccount();
  const [putData, putError, putIsLoading, putBankAccounts] =
    usePutAccountBatchUpdate();

  useEffect(() => {
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Bank Details Added");
      getBankAccounts();
    }
  }, [postData]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Bank Details Updated");
      getBankAccounts();
    }
  }, [patchData]);

  useEffect(() => {
    if (putData) {
      showSuccessToast("Primary Account Updated");
      getBankAccounts();
      setChange(false);
    }
  }, [putData]);

  useEffect(() => {
    if (postError) {
      const error = postError;
      showErrorToast("error");
      getBankAccounts();
    }
  }, [postError]);

  useEffect(() => {
    if (deleteData) {
      showSuccessToast("Bank Details Deleted");
      getBankAccounts();
    }
  }, [deleteData]);

  useEffect(() => {
    if (patchError) {
      const error = patchError?.err?.response?.data?.error?.errors[0]?.detail;
      showErrorToast(error || "Bank Details not Updated.");
    }
  }, [patchError]);

  useEffect(() => {
    if (patchData) {
      setChange(false);
    }
  }, [patchData]);

  useEffect(() => {
    if (getData?.data) {
      const bankDetails = getData.data.results;
      if (bankDetails?.length > 0) {
        const primaryBankDetail = bankDetails.find(
          (detail) => detail.is_primary === true
        );
        if (primaryBankDetail) {
          setBankName(primaryBankDetail.bank_name);
          setAccountNumber(primaryBankDetail.account_no);
          setIfscCode(primaryBankDetail.ifsc_code);
          setAccountType(primaryBankDetail.type);
          setIsPrimary(primaryBankDetail.is_primary);
        }else {
          // Reset states to empty if no primary account is found
          setBankName("");
          setAccountNumber("");
          setIfscCode("");
          setAccountType("");
          setIsPrimary(false);
        }
      }
    }
  }, [getData]);


  const AllBankAccounts = () => {
    const [addAccountFields, setAddAccountFIelds] = useState([
      {
        bankName: "",
        accountNumber: "",
        IFSCcode: "",
        accountType: "",
        isNewAccount: true,
        isReadOnly: false,
        hasChanges: false, // Track if changes were made
        hasValidationError: false,
      },
    ]);

    const [isAddAccountEnabled, setIsAddAccountEnabled] = useState(false);
    const handleAddAccount = () => {
      // If no accounts exist, simply allow editing the initial field
      if (getData?.data?.total_count === 0 && addAccountFields.length === 1) {
        setAddAccountFIelds([
          {
            bankName: "",
            accountNumber: "",
            IFSCcode: "",
            accountType: "",
            isNewAccount: true,
            isReadOnly: false,
            hasChanges: false, // Track if changes were made
          },
        ]);
      } else {
        // Add a new field only if an account is being explicitly added
        setAddAccountFIelds([
          ...addAccountFields,
          {
            bankName: "",
            accountNumber: "",
            IFSCcode: "",
            accountType: "",
            isNewAccount: true,
            isReadOnly: false,
            hasChanges: false,
          },
        ]);
      }
      setIsAddAccountEnabled(false); // Reset button state for the new account
    };

    const handleChange = (index, field, value) => {
      const newAccounts = [...addAccountFields];
      newAccounts[index][field] = value;
      newAccounts[index].hasChanges = true; // Mark as changed
      setAddAccountFIelds(newAccounts);
      checkAllFieldsFilled(newAccounts[index]); // check if fields are filled
    };

    const checkAllFieldsFilled = (account) => {
      const allFieldsFilled =
        account.bankName &&
        account.accountNumber &&
        account.IFSCcode &&
        account.accountType;
      setIsAddAccountEnabled(allFieldsFilled);
    };

    useEffect(() => {
      if (getData?.data) {
        const bankDetails = getData.data.results;
        if (bankDetails?.length > 0) {
          const formattedBankDetails = bankDetails.map((detail) => ({
            id: detail.id,
            accountNumber: detail.account_no,
            IFSCcode: detail.ifsc_code,
            bankName: detail.bank_name,
            accountType: detail.type,
            isPrimary: detail.is_primary,
            isNewAccount: false,
            isReadOnly: true,
          }));
          setAddAccountFIelds(formattedBankDetails);
        }
      }
    }, [getData]);

    const [errors, setErrors] = useState({});
    const validateInputs = (account, index) => {
      let isValid = true;
      const accountErrors = {};

      if (!account.bankName) {
        accountErrors.bankName = "Bank Name is required";
        isValid = false;
      }
      if (!account.accountNumber) {
        accountErrors.accountNumber = "Account number is required";
        isValid = false;
      } else if (!/^\d{9,18}$/.test(account.accountNumber)) {
        accountErrors.accountNumber = "Account number must be 9-18 digits long";
        isValid = false;
      }

      if (!account.IFSCcode) {
        accountErrors.IFSCcode = "IFSC Code is required";
        isValid = false;
      }else if (account.IFSCcode !== account.IFSCcode.toUpperCase()) {
        accountErrors.IFSCcode = "IFSC Code must contain only uppercase letters";
        isValid = false;
      } 
      else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(account.IFSCcode)) {
        accountErrors.IFSCcode = "Invalid IFSC code format. Should be 11 characters. 1st 4 alphabetic characters, 0 as 5th character, last 6 as alphanumeric.";
        isValid = false;
      } 

      if (!account.accountType) {
        accountErrors.accountType = "Account Type is required";
        isValid = false;
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: accountErrors,
      }));

      return isValid;
    };

    const createBankDetails = (account) => {
      let createData = {
        account_no: account.accountNumber,
        ifsc_code: account.IFSCcode,
        bank_name: account.bankName,
        type: parseInt(account.accountType),
        is_primary: false,
      };
      postBankAccounts(createData);
      const updatedAccounts = [...addAccountFields];
      updatedAccounts[addAccountFields.indexOf(account)].isReadOnly = true;
      setAddAccountFIelds(updatedAccounts);
    };

    const handleAddBankDetail = (index) => {
      const currentAccount = addAccountFields[index];

      // Check if there is any input to validate
      if (
        currentAccount.bankName ||
        currentAccount.accountNumber ||
        currentAccount.IFSCcode ||
        currentAccount.accountType
      ) {
        const isValid = validateInputs(currentAccount, index); // Pass the specific account being validated
        if (isValid) {
          createBankDetails(currentAccount); // Only proceed if valid
        } else {
          console.log("Validation error");
          const updatedAccounts = [...addAccountFields];
          updatedAccounts[index].hasChanges = true;
          setAddAccountFIelds(updatedAccounts);
        }
      } else {
        console.log("No input to validate, skipping validation");
      }
    };

    const handleEditDetails = (index) => {
      const updatedAccounts = [...addAccountFields];
      updatedAccounts[index].isReadOnly = false;
      setAddAccountFIelds(updatedAccounts);
    };

    const updateBankDetails = (account) => {
      let id = account.id;
      let updateData = {
        account_no: account.accountNumber,
        ifsc_code: account.IFSCcode,
        bank_name: account.bankName,
        type: parseInt(account.accountType),
        // is_primary: false,
      };
      patchBankAccounts(id, updateData);
      const updatedAccounts = [...addAccountFields];
      updatedAccounts[addAccountFields.indexOf(account)].isReadOnly = true;
      setAddAccountFIelds(updatedAccounts);
    };

    const handleApplyChanges = (index) => {
      const currentAccount = addAccountFields[index];
      const isValid = validateInputs(currentAccount, index);
      if (isValid) {
        updateBankDetails(addAccountFields[index]);
        const updatedAccounts = [...addAccountFields];
        updatedAccounts[index].hasChanges = false; // Reset after applying changes
        setAddAccountFIelds(updatedAccounts);
      } else {
        console.log("Validation error");
        const updatedAccounts = [...addAccountFields];
        updatedAccounts[index].hasChanges = true;
        setAddAccountFIelds(updatedAccounts);
      }
    };

    const remove = (account) => {
      let id = account.id;
      deleteBankAccounts(id);
    };
    const handleRemoveAccount = (index) => {
      remove(addAccountFields[index]);
    };

    const handleCancel = (index) => {
      const updatedAccounts = [...addAccountFields];
      updatedAccounts[index].isReadOnly = true;
      setAddAccountFIelds(updatedAccounts);
    };

    const handleCancelAccount = (index) => {
      setAddAccountFIelds((prevAccount) => {
        const updatedAccounts = prevAccount.filter((_, i) => i !== index);

        // If total_count is 0 and no account fields remain, reset the state
        if (getData?.data?.total_count === 0 && updatedAccounts.length === 0) {
          setIsAddAccountEnabled(false); // Reset isAddingAccount
          setIsAddingAccount(false);
        }

        return updatedAccounts;
      });
    };

    const [isAddingAccount, setIsAddingAccount] = useState(false);

    return (
      <div className="md:border border-[#E2E8F0] rounded mt-[2rem]">
        <div className="flex justify-between items-center p-[1rem]">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            All Bank Accounts
          </h4>
          <button
            // onClick={handleAddAccount}
            onClick={() => {
              setIsAddingAccount(true);
              handleAddAccount();
            }}
            className=" border-1 border-[#9065B4] text-[#9065B4] rounded py-[0.4rem] px-[1rem] font-[500] text-[0.9rem]"
          >
            Add Bank Account
          </button>
        </div>
        <div className="md:bg-white md:px-3 py-3 rounded ">
          {getData?.data?.total_count === 0 && !isAddingAccount ? (
            <div className="text-center py-[2rem]">
              <h4 className="font-[400] text-[1rem] text-darkGray">
                No Bank Accounts Added!
              </h4>
              <h6 className="font-[400] text-[0.9rem] text-lightGray">
                Please add an Account to see the details here
              </h6>
            </div>
          ) : (
            addAccountFields.map((account, index) => (
              <div className="flex items-center mb-[2rem]" key={index}>
                <div className="">
                  <img src={bankIcon} alt="bank icon" className="mr-[1rem]" />
                </div>
                <div className="grid grid-cols-5 gap-1">
                  <div className="input-container ">
                    <h6 className="input-label ">Bank Name</h6>
                    <InputField
                      type="select"
                      className={`w-[95%] rounded-md px-[0.3rem] py-[0.5rem] bg-[#F1F5F9] text-[0.9rem] ${
                        account.isReadOnly
                          ? "border-none"
                          : "border-1 border-[#94A3B8] bg-white"
                      }`}
                      value={account.bankName}
                      readOnly={account.isReadOnly}
                      onChange={(e) =>
                        handleChange(index, "bankName", e.target.value)
                      }
                      options={[
                        { value: "", label: "Select Bank" },
                        { value: "Axis Bank", label: "Axis Bank" },
                        { value: "Sbi", label: "SBI" },
                        { value: "Canara Bank", label: "Canara Bank" },
                        { value: "Other", label: "Other" },
                      ]}
                    />
                    <br />
                    {errors[index]?.bankName && (
                      <span className="error text-[0.7rem] text-red-500">
                        {errors[index].bankName}
                      </span>
                    )}
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">Account Number</h6>
                    <InputField
                      className={`w-[95%] rounded-md px-[0.3rem] py-[0.5rem] bg-[#F1F5F9] text-[0.9rem] ${
                        account.isReadOnly
                          ? "border-none"
                          : "border-1 border-[#94A3B8] bg-white"
                      }`}
                      placeholder="Add Your Account No."
                      readOnly={account.isReadOnly}
                      value={account.accountNumber}
                      onChange={(e) =>
                        handleChange(index, "accountNumber", e.target.value)
                      }
                    />
                    <br />
                    {errors[index]?.accountNumber && (
                      <span className="error text-[0.7rem] text-red-500">
                        {errors[index].accountNumber}
                      </span>
                    )}
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">IFSC Code</h6>
                    <InputField
                      className={`w-[95%] rounded-md px-[0.3rem] py-[0.5rem] bg-[#F1F5F9] text-[0.9rem] ${
                        account.isReadOnly
                          ? "border-none"
                          : "border-1 border-[#94A3B8] bg-white"
                      }`}
                      readOnly={account.isReadOnly}
                      value={account.IFSCcode}
                      onChange={(e) =>
                        handleChange(index, "IFSCcode", e.target.value)
                      }
                    />
                    <br />

                    {errors[index]?.IFSCcode && (
                      <span className="error text-[0.7rem] text-red-500">
                        {errors[index].IFSCcode}
                      </span>
                    )}
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">Account Type</h6>

                    <InputField
                      type="select"
                      className={`w-[95%] rounded-md px-[0.3rem] py-[0.5rem] bg-[#F1F5F9] text-[0.9rem] ${
                        account.isReadOnly
                          ? "border-none"
                          : "border-1 border-[#94A3B8] bg-white"
                      }`}
                      readOnly={account.isReadOnly}
                      value={account.accountType}
                      onChange={(e) =>
                        handleChange(index, "accountType", e.target.value)
                      }
                      options={[
                        { value: "", label: "Select Account" },
                        { value: "1", label: "Savings Account" },
                        { value: "2", label: "Current Account" },
                      ]}
                    />
                    <br />
                    {errors[index]?.accountType && (
                      <span className="error text-[0.7rem] text-red-500">
                        {errors[index].accountType}
                      </span>
                    )}
                  </div>

                  <div className="">
                    {account.isNewAccount || getData?.data?.total_count == 0 ? (
                      <>
                        <button
                          disabled={!isAddAccountEnabled}
                          className={`${
                            isAddAccountEnabled ? "bg-[#9065B4]" : "bg-gray-400"
                          } w-[60%] text-white rounded py-2 px-3 font-[600] text-[0.8rem]`}
                          onClick={() => handleAddBankDetail(index)}
                        >
                          Add Account
                        </button>
                        <button
                          onClick={() => handleCancelAccount(index)}
                          className=" bg-[#F1F5F9] w-[60%] text-[#334155] rounded py-2 px-6 font-[600] text-[0.8rem]"
                        >
                          Cancel
                        </button>
                      </>
                    ) : account.isReadOnly ? (
                      <>
                        <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>
                        <button
                          onClick={() => handleEditDetails(index)}
                          className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                        >
                          Edit Details
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          disabled={!account.hasChanges} // Disable if no changes
                          className={`${
                            account.hasChanges ? "bg-[#9065B4]" : "bg-gray-400"
                          } mb-1 w-[60%] text-white rounded py-2 px-2 font-[600] text-[0.8rem]`}
                          onClick={() => handleApplyChanges(index)}
                        >
                          Apply Changes
                        </button>
                        <button
                          onClick={() => handleRemoveAccount(index)}
                          className="mb-1 w-[60%] bg-[#FEF2F2] text-[#DC2626] rounded p-2  font-[600] text-[0.8rem]"
                        >
                          Delete Account
                        </button>
                        <button
                          onClick={() => handleCancel(index)}
                          className=" bg-[#F1F5F9]  w-[60%] p-2 text-[#334155] rounded font-[600] text-[0.8rem]"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  const ChangePrimary = () => {
    const [addAccountFields, setAddAccountFIelds] = useState([
      {
        bankName: "",
        accountNumber: "",
        IFSCcode: "",
        accountType: "",
        is_Primary: "",
      },
    ]);

    useEffect(() => {
      if (getData?.data) {
        const bankDetails = getData.data.results;
        if (bankDetails?.length > 0) {
          const formattedBankDetails = bankDetails.map((detail) => ({
            id: detail.id,
            accountNumber: detail.account_no,
            IFSCcode: detail.ifsc_code,
            bankName: detail.bank_name,
            accountType: detail.type,
            is_Primary: detail.is_primary,
            isNewAccount: false,
            isReadOnly: true,
          }));
          setAddAccountFIelds(formattedBankDetails);
        }
      }
    }, [getData]);

    const handleChange = (index) => {
      // Set the clicked account as primary and others as non-primary
      const newAccounts = addAccountFields.map((account, i) => ({
        ...account,
        is_Primary: i === index, // Only the clicked account will be true
      }));
      setAddAccountFIelds(newAccounts);
    };

    const putAccount = () => {
      let put = addAccountFields.map((detail) => ({
        id: detail.id,
        account_no: detail.accountNumber,
        ifsc_code: detail.IFSCcode,
        bank_name: detail.bankName,
        type: parseInt(detail.accountType),
        is_primary: detail.is_Primary,
      }));

      const primaryAccount = put.find((account) => account.is_primary === true);
      // console.log("put account:", put);
      // console.log("primary account:", primaryAccount);
      // patchBankAccounts(primaryAccount.id, put);
      putBankAccounts(put);
    };

    const saveAsPrimary = (index) => {
      putAccount();
    };

    return (
      <>
        <div className="md:border border-[#E2E8F0] rounded ">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] p-[1rem] m-0">
            Select Primary Bank Account to receive funds
          </h4>

          <div className="md:bg-white md:px-3 py-2 rounded ">
            {addAccountFields?.map((account, index) => (
              <>
                <div
                  className="flex items-center  mb-[1.5rem] mt-[0.5rem]"
                  key={index}
                >
                  <div className="">
                    <input
                      className="peer appearance-none h-6 w-6 border-2  rounded-md checked:bg-purple-100 checked:border-[#9065B4] checked:before:content-['✔'] checked:before:text-[#9065B4] before:block before:text-center"
                      value={account.is_Primary}
                      type="checkbox"
                      onChange={() => handleChange(index)}
                      checked={account.is_Primary == true}
                    />
                  </div>
                  <div className="">
                    <img src={bankIcon} alt="bank icon" className="mx-[2rem]" />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Bank Name</h6>
                    <input
                      className="input-box text-[0.9rem] bg-[#F8FAFC] border-none"
                      value={account.bankName}
                      readOnly
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">Account Number</h6>
                    <input
                      className="input-box text-[0.9rem] bg-[#F8FAFC] border-none"
                      readOnly
                      value={account.accountNumber}
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">IFSC Code</h6>
                    <input
                      className="input-box text-[0.9rem]  bg-[#F8FAFC] border-none"
                      readOnly
                      value={account.IFSCcode}
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">Account Type</h6>
                    <input
                      className="input-box bg-[#F8FAFC] border-none text-[0.9rem]"
                      value={
                        account.accountType === 1
                          ? "Savings Account"
                          : account.accountType === 2
                          ? "Current Account"
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
                <hr className="" />
              </>
            ))}
          </div>
        </div>
        <div className="flex justify-end gap-3 mt-[2rem]">
          <button
            onClick={() => setChange(false)}
            className=" bg-[#F1F5F9] border text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[0.9rem]"
          >
            Discard Changes
          </button>
          <button
            onClick={() => saveAsPrimary()}
            className=" mb-1 bg-[#9065B4]  text-white rounded py-[0.5rem] px-[1rem] font-[500] text-[0.9rem]"
          >
            Save as Primary
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="md:bg-gray-100 md:mb-0 mb-[12rem] pb-[2rem] pt-[5.2rem]">
        <div className="px-3">
          {/* <HeadingProfile /> */}

          {change ? (
            <ChangePrimary />
          ) : (
            <>
              {/* Primary Bank Account */}
              <div className="md:border border-[#E2E8F0] rounded ">
                <div className="flex justify-between bg-[#FAF8FC] p-[1rem] m-0 rounded items-center">
                  <div className="">
                    <h4 className="text-[#334155] text-[1.1rem] font-[500]">
                      Primary Bank Account
                    </h4>
                    <h6 className="text-[#64748B] text-[0.8rem] font-[400] m-0">
                      Bank account you'll receive your funds in
                    </h6>
                  </div>
                  <button
                    onClick={() => setChange(true)}
                    className=" border-1 border-[#9065B4] text-[#9065B4] rounded py-[0.4rem] px-[1rem] font-[500] text-[0.9rem]"
                  >
                    Change
                  </button>
                </div>

                <div className="md:bg-white md:px-3 py-3 rounded flex items-center">
                  <div className="">
                    <img src={bankIcon} alt="bank icon" className="mr-3" />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Bank Name</h6>
                    <input
                      className="input-box bg-[#F8FAFC] text-[0.9rem] border-none"
                      value={bankName ? bankName : ""}
                      readOnly
                    />
                  </div>

                  <div className="input-container ">
                    <h6 className="input-label">Account Number</h6>
                    <input
                      className="input-box bg-[#F8FAFC] border-none text-[0.9rem]"
                      value={accountNumber}
                      readOnly
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">IFSC Code</h6>
                    <input
                      className="input-box text-[0.9rem] bg-[#F8FAFC] border-none"
                      value={ifsccode}
                      readOnly
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label">Account Type</h6>
                    <input
                      className="input-box text-[0.9rem] bg-[#F8FAFC] border-none"
                      value={
                        accountType == 1
                          ? "Savings Account"
                          : accountType === 2
                          ? "Current Account"
                          : ""
                      }
                      readOnly
                    />
                  </div>
                  <div className="">
                    <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>{" "}
                    {/* empty button to set edit details according to UI will fix later */}
                    <button
                      onClick={() => setChange(true)}
                      className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                    >
                      Edit Details
                    </button>
                  </div>
                </div>
              </div>

              <AllBankAccounts />
            </>
          )}
        </div>
        <BackToBusinessDashboard />
      </div>
    </>
  );
};

export default BusinessBankAccounts;
