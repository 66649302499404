import React from "react";
import welcomeModalIcon from "assets/images/moneyManager/welcomeModalIcon.svg";

const WelcomeMoneyManagerDialog = ({
  isOpen,
  closeModal,
  handleGoToNextPage,
}) => {
  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-900  backdrop-blur-sm bg-opacity-75 flex justify-center items-center z-50">
        <div className="w-[85%] sm:w-[70%] md:w-[300px] bg-[#FFFFFF] rounded-2xl px-8 py-6 relative">
          <div className="px-3 py-3.5 flex items-center justify-center">
            <img src={welcomeModalIcon} alt="" className="w-[6rem] h-[6rem]" />
          </div>
          <div className="flex flex-col justify-center p-2">
            <h6 className="m-0 text-[#334155] font-[500] text-[1rem] md:text-[1.1rem] mb-2 text-center">
              Welcome to Money Manager by BeyondTax.
            </h6>
            <p className="m-0 text-lightGray text-center mb-3 font-[500] text-[0.9rem]">
              You're ready to take control of your finances
            </p>
            <button
              className="bg-[#9065B4] rounded-lg text-[#FFFFFF] w-full px-2 py-1.5 md:text-[0.9rem] text-[0.8rem]"
              onClick={handleGoToNextPage}
            >
              Start exploring
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default WelcomeMoneyManagerDialog;
