import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RevenueBusinessChart = (data) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  

  useEffect(() => {
    if(data){
      const monthDate = data?.data?.chart_data?.map((item) => {
        const monthDate = item.date;
        return monthDate;
      });

      const salesData = data?.data?.chart_data?.map((item) => item.sales)
      const spendsData = data?.data?.chart_data?.map((item) => item.spends)
    
    setChartData({
      labels: monthDate,
      datasets: [
        {label: "Sales",
        data: salesData,
        borderColor: "#1A932E", // Green line
        backgroundColor: "rgba(26, 147, 46, 0.2)", // Green area
        fill: true,
        tension: 0.2, // Smooth curves
        pointRadius: 0,
      }, 
      {
        label: "Spends",
        data: spendsData,
        borderColor: "#FD656C", // Red line
        backgroundColor: "rgba(253, 101, 108, 0.2)", // Red area
        fill: true,
        tension: 0.2, // Smooth curves
        pointRadius: 0,
      },

   
      ]
    })
  }
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#1F2937",
        titleColor: "#F9FAFB",
        bodyColor: "#F9FAFB",
        displayColors: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#6B7280",
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(209,213,219,0.3)", // Light gray gridlines
          drawBorder: false,
        },
        ticks: {
          color: "#6B7280",
          // stepSize: 5000,
          callback: (value) => `${value / 1000}k`, // Format as 'k'
        },
      },
    },
  };

  return (
    <div className="">
      {/* <div className="flex justify-between items-center mb-4 mt-[0.5rem]"> */}
      {/* <div className="flex items-center gap-4 ml-[1rem]">
          <div className="border bg-[#F8FAFC] rounded-md p-[0.2rem] flex items-center gap-[0.5rem]">
            <div className="bg-[#1A932E] rounded-sm p-[0.4rem]"></div>
            <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
              <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                SALES
              </span>
              ₹64,231
            </h4>
          </div>

          <div className="border bg-[#F8FAFC] rounded-md p-[0.2rem] flex items-center gap-[0.5rem]">
            <div className="bg-[#FD656C] rounded-sm p-[0.4rem]"></div>
            <h4 className="text-darkGray text-[0.8rem] font-[600] m-0">
              <span className="font-[500] text-[0.7rem] text-[#8B8D98] mr-[0.8rem]">
                SPENDS
              </span>
              ₹64,231
            </h4>
          </div>
        </div> */}
      {/* </div> */}
      <div className="relative w-full h-[330px]">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default RevenueBusinessChart;
