import { myContext } from "App";
import Button from "components/Button/BtnOrange";
import { DASHBOARD_PAGE_URL } from "constants/routes";
import { USER_TYPE_PERSONAL } from "constants/userType";
import BusinessSideBarDashboard from "containers/privateRoutes/Dashboard/BusinessSideBarDashboard";
import DashboardBusinessContent from "containers/privateRoutes/Dashboard/DashboardBusinessContent";
import useGetBusinessDashboardOverview from "containers/privateRoutes/Dashboard/data/useGetBusinessDashboardOverview";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const BusinessDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getData, getisLoading, isError, getBusinessDashboardDetails] = useGetBusinessDashboardOverview();

  useEffect(() => {
    getBusinessDashboardDetails();
  }, []);

  useEffect(() => {
    // Simulate an API call or loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(DASHBOARD_PAGE_URL);
  };

  // console.log("getIsLoading dashboard:", getisLoading);

  return (
    <>
      <div className="w-[20%] md:block hidden">
        <Button
          name="Switch to Personal"
          px="px-[2rem]"
          py="py-[1rem]" 
          onClick={handleNavigate}
        />
      </div>
      {/* desktop */}
      <div className="md:block hidden">
        <div className="grid grid-cols-12 bg-gray-100 px-[1rem] pb-[4rem] pt-[2rem]">
          {isLoading ? (
            <>
              <div className="col-span-12 md:col-span-9 space-y-4">
                <div className="h-32 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-48 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-80 bg-gray-300 rounded animate-pulse"></div>
                <div className="flex gap-[1rem]">
                  <div className="h-48 w-1/2 bg-gray-300 rounded animate-pulse"></div>
                  <div className="h-48 w-1/2 bg-gray-300 rounded animate-pulse"></div>
                </div>
              </div>
              <div className="col-span-3 hidden md:block pl-[1rem] space-y-4">
                <div className="h-60 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-screen bg-gray-300 rounded animate-pulse"></div>
              </div>
            </>
          ) : (
            <>  
              {/* Actual content */}
              <div className="col-span-12 md:col-span-9">
                <DashboardBusinessContent data={getData?.data} getisLoading={getisLoading}/>
              </div>
              <div className="col-span-3 hidden md:block pl-[1rem]">
                <BusinessSideBarDashboard getisLoading={getisLoading}/>
              </div>
            </>
          )}
        </div>
      </div>
      {/* mobile */}
      <div className="md:hidden mt-[3.5rem]">
        <div className="bg-gray-100 px-[1rem] pb-[4rem] pt-[1rem] mb-[4rem]">
          <DashboardBusinessContent data={getData?.data} getisLoading={getisLoading}/>
          <BusinessSideBarDashboard getisLoading={getisLoading}/>
        </div>
      </div>
    </>
  );
};

export default BusinessDashboard;
