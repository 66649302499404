import { BUSINESS_PROFILE_URL } from "constants/routes";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import BackToBusinessDashboard from "./BackToBusinessDashboard";
import useGetStakeholder from "../Tax Profile/data/useGetStakeholder";
import useGetEmailSuggestions from "../Tax Profile/data/useGetEmailSuggestions";
import useDeleteStakeHolder from "../Tax Profile/data/useDeleteStakeholder";
import usePatchStakeHolder from "../Tax Profile/data/usePatchStakeHolder";
import usePostStakeHolder from "../Tax Profile/data/usePostStakeholder";
import useGetTabs from "../Tax Profile/data/useGetTabs";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";
import useGetBusinessMembers from "./data/useGetBusinessMembers";
import usePostBusinessMember from "./data/usePostBusinessMember";
import useDeleteBusinessMember from "./data/useDeleteBusinessMember";
import usePatchBusinessMember from "./data/usePatchBusinessMember";

const BusinessMembersAndInvites = () => {
  const [getStakeData, getError, getIsLoading, getStakeholder] =
    useGetStakeholder();
  const [
    getEmailSuggestionData,
    getEmailSuggestionError,
    getEmailSuggestionIsLoading,
    getEmailSuggestion,
  ] = useGetEmailSuggestions();
  const [getTabsData, getTabsError, getTabsIsLoading, getTabs] = useGetTabs();
  const [deleteData, deleteError, deleteIsLoading, deleteStakeHolder] =
    useDeleteStakeHolder();
  const [patchData, patchError, patchIsLoading, patchStakeHolder] =
    usePatchStakeHolder();
  const [postStakeData, postStakeError, postStakeIsLoading, postStakeHolder] =
    usePostStakeHolder();

  // members
  const [getMemberData, getMemberError, getMemberIsLoading, getMemberholder] =
    useGetBusinessMembers();
  const [
    postMemberData,
    postMemberError,
    postMemberIsLoading,
    postMemberHolder,
  ] = usePostBusinessMember();
  const [
    deleteMemberData,
    deleteMemberError,
    deleteMemberIsLoading,
    deleteMember,
  ] = useDeleteBusinessMember();
  const [patchMemberData, patchMemberError, patchMemberIsLoading, patchMember] =
    usePatchBusinessMember();

  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate(BUSINESS_PROFILE_URL);
  };

  const [emailSuggestionData, setEmailSuggestionData] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [viewDropDown, setViewDropDown] = useState(null);
  const [isAddAccountEnabled, setIsAddAccountEnabled] = useState(false);

  const [stakeholder, setStakeholder] = useState([
    {
      email: "",
      tab: "",
      type: "",
      permission: "",
      tab_name: "",
      isNewAccount: true,
      isReadOnly: false,
      hasChanges: false,
    },
  ]);

  const handleAddStakeHolder = () => {
    setStakeholder([
      ...stakeholder,
      {
        email: "",
        tab: "",
        type: "",
        permission: "",
        tab_name: "",
        isNewAccount: true,
        isReadOnly: false,
        hasChanges: false,
      },
    ]);
    setIsAddAccountEnabled(false);
  };

  useEffect(() => {
    getStakeholder();
    getMemberholder();
    getTabs();
  }, []);

  useEffect(() => {
    if (getEmailSuggestionData) {
      setEmailSuggestionData(getEmailSuggestionData?.data?.emails);
      console.log(
        "getEmailSuggestionData",
        getEmailSuggestionData?.data?.emails
      );
    }
  }, [getEmailSuggestionData]);

  useEffect(() => {
    if (postStakeData) {
      showSuccessToast("Guest Details Added");
      getStakeholder();
    }
  }, [postStakeData]);

  useEffect(() => {
    if (postMemberData) {
      showSuccessToast("Member Details Added");
      getMemberholder();
    }
  }, [postMemberData]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Guest Details Updated");
    }
  }, [patchData]);

  useEffect(() => {
    if (patchMemberData) {
      showSuccessToast("Member Details Updated");
    }
  }, [patchMemberData]);

  useEffect(() => {
    if (deleteData) {
      showSuccessToast("Guest Details Deleted");
      getStakeholder();
    }
  }, [deleteData]);

  useEffect(() => {
    if (deleteMemberData) {
      showSuccessToast("Member Details Deleted");
      getMemberholder();
    }
  }, [deleteMemberData]);

  useEffect(() => {
    if (postStakeError) {
      showErrorToast(postStakeError?.err?.response?.data?.data?.error);
    }
  }, [postStakeError]);

  useEffect(() => {
    if (getStakeData?.data) {
      const getHolder = getStakeData.data.results;
      if (getHolder?.length > 0) {
        const stakeholderDetails = getHolder.map((item) => ({
          id: item.id,
          user_id: item.user_id,
          email: item.access_given_to_email,
          tab: item.tab_name,
          type: item.stakeholder_type,
          permission: item.permission_type,
          isNewAccount: false,
          isReadOnly: true,
        }));
        setStakeholder(stakeholderDetails);
      }
    }
  }, [getStakeData]);

  const handleChange = (index, field, value) => {
    if (field === "type") {
      const alphabetOnly = /^[A-Za-z\s]*$/;
      if (!alphabetOnly.test(value)) {
        return;
      }
    }
    const newAccounts = [...stakeholder];
    newAccounts[index][field] = value;
    newAccounts[index].hasChanges = true;
    setStakeholder(newAccounts);
    checkAllFieldsFilled(newAccounts[index]); // check if fields are filled
  };
  const checkAllFieldsFilled = (account) => {
    const allFieldsFilled =
      account.email && account.tab && account.type && account.permission;
    setIsAddAccountEnabled(allFieldsFilled);
  };

  const handleEditDetails = (index) => {
    const updatedAccounts = [...stakeholder];
    updatedAccounts[index].isReadOnly = false;
    setStakeholder(updatedAccounts);
  };

  const handleCancel = (index) => {
    const updatedAccounts = [...stakeholder];
    updatedAccounts[index].isReadOnly = true;
    setStakeholder(updatedAccounts);
  };

  const handleCancelAccount = (index) => {
    setStakeholder((prevAccount) => prevAccount.filter((_, i) => i !== index));
  };

  const tabId = {
    Incometax: "_8PGAAAG",
    Assets: "TQ78AAAG",
    Liabilities: "NWX8AAAG",
  };


  const [errors, setErrors] = useState({});

  const validateInputsStake = (account, index) => {
    let isValid = true;
    const accountErrors = {};

    if (!account.email) {
      accountErrors.email = "Email is required";
      isValid = false;
    }
    if (!account.tab) {
      accountErrors.tab = "Tab is required";
      isValid = false;
    }
    if (!account.type) {
      accountErrors.type = "Stakeholder Type is required";
      isValid = false;
    }
    if (!account.permission) {
      accountErrors.permission = "Permission is required";
      isValid = false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: accountErrors,
    }));

    return isValid;
  };

  const incomeTaxTab = getTabsData?.data?.results?.find(
    (tab) => tab.tab_name === "Incometax"
  );
  const assetsTab = getTabsData?.data?.results?.find(
    (tab) => tab.tab_name === "Assets"
  );
  const liabilitiesTab = getTabsData?.data?.results?.find(
    (tab) => tab.tab_name === "Liabilities"
  );

  const getParticularTabs = {
    Incometax: incomeTaxTab?.id,
    Assets: assetsTab?.id,
    Liabilities: liabilitiesTab?.id,
  };

  const createDetails = (item) => {
    let createData = {
      email: item.email,
      // tab_id: item.tab,
      tab_id: getParticularTabs[item.tab],
      stakeholder_type: item.type,
      permission_type: parseInt(item.permission),
    };
    postStakeHolder(createData);
    const updatedAccounts = [...stakeholder];
    updatedAccounts[stakeholder.indexOf(item)].isReadOnly = true;
    setStakeholder(updatedAccounts);
    getStakeholder();
  };

  const handleAddStakeholder = (index) => {
    const currentAccount = stakeholder[index];
    if (
      currentAccount.email ||
      currentAccount.tab ||
      currentAccount.permission ||
      currentAccount.type
    ) {
      const isValid = validateInputsStake(currentAccount, index); // Pass the specific account being validated
      if (isValid) {
        createDetails(stakeholder[index]); // Only proceed if valid
      } else {
        console.log("Validation error");
        const updatedAccounts = [...member];
        updatedAccounts[index].hasChanges = true;
        setStakeholder(updatedAccounts);
      }
    } else {
      console.log("No input to validate, skipping validation");
    }
  };

  const updateStakeHolderDetails = (item) => {
    let id = item.id;
    let updatedData = {
      email: item.email,
      // tab_id: item.tab,
      tab_id: getParticularTabs[item.tab],
      stakeholder_type: item.type,
      permission_type: parseInt(item.permission),
    };
    patchStakeHolder(id, updatedData);
    const updatedAccounts = [...stakeholder];
    updatedAccounts[stakeholder.indexOf(item)].isReadOnly = true;
    setStakeholder(updatedAccounts);
  };

  const handleApplyChanges = (index) => {
    const currentAccount = stakeholder[index];
    const isValid = validateInputsStake(currentAccount, index); // Pass the specific account being validated
    if (isValid) {
      updateStakeHolderDetails(stakeholder[index]);
      const updatedAccounts = [...stakeholder];
      updatedAccounts[index].hasChanges = false; // Reset after applying changes
      setStakeholder(updatedAccounts);
    } else {
      console.log("Validation error");
      const updatedAccounts = [...stakeholder];
      updatedAccounts[index].hasChanges = true;
      setStakeholder(updatedAccounts);
    }
  };

  const remove = (item) => {
    let id = item.id;
    deleteStakeHolder(id);
  };
  const handleRemoveAccount = (index) => {
    remove(stakeholder[index]);
  };

  const handleEmailInputChange = (index, type, value) => {
    if (value === "") {
      setEmailSuggestionData([]);
    }
    setViewDropDown(index);
    const updatedStakeholder = [...stakeholder];
    updatedStakeholder[index][type] = value;
    setStakeholder(updatedStakeholder);

    // Debounce logic
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (value.trim()) {
        getEmailSuggestion(`?query=${value}`);
      }
    }, 500); // 300ms delay

    setDebounceTimeout(newTimeout);
  };

  const handleEmailSuggestionClick = (index, val) => {
    const modifiedArray = stakeholder.map((el, i) => {
      if (index === i) {
        return { ...el, email: val };
      }
      return el;
    });
    setStakeholder(modifiedArray);
    setEmailSuggestionData([]);
  };

  // members
  const [isAddAccountEnabledMember, setIsAddAccountEnabledMember] =
    useState(false);

  const [member, setMember] = useState([
    {
      email: "",
      designation: "",
      ownership_percent: "",
      permission: "",
      tab_name: "",
      isNewAccount: true,
      isReadOnly: false,
      hasChanges: false,
    },
  ]);

  const handleAddMember = () => {
    setMember([
      ...member,
      {
        email: "",
        designation: "",
        ownership_percent: "",
        permission: "",
        tab_name: "",
        isNewAccount: true,
        isReadOnly: false,
        hasChanges: false,
      },
    ]);
    setIsAddAccountEnabledMember(false);
  };

  useEffect(() => {
    if (getMemberData?.data) {
      const getHolder = getMemberData?.data;
      if (getHolder?.length > 0) {
        const stakeholderDetails = getHolder.map((item) => ({
          id: item.id,
          // user_id: item.user_id,
          name: item.name,
          designation: item.designation,
          ownership_percent: item.ownership_percentage,
          permission: parseInt(item.permission),
          isNewAccount: false,
          isReadOnly: true,
        }));
        setMember(stakeholderDetails);
      }
    }
  }, [getMemberData]);

  const handleChangeMember = (index, field, value) => {
    if (field === "type") {
      const alphabetOnly = /^[A-Za-z\s]*$/;
      if (!alphabetOnly.test(value)) {
        return;
      }
    }
    const newAccounts = [...member];
    newAccounts[index][field] = value;
    newAccounts[index].hasChanges = true;
    setMember(newAccounts);
    checkAllFieldsFilledMember(newAccounts[index]); // check if fields are filled
  };

  const checkAllFieldsFilledMember = (account) => {
    const allFieldsFilled =
      account.name &&
      account.designation &&
      account.ownership_percent &&
      account.permission;
    setIsAddAccountEnabledMember(allFieldsFilled);
  };

  const handleEditDetailsMember = (index) => {
    const updatedAccounts = [...member];
    updatedAccounts[index].isReadOnly = false;
    setMember(updatedAccounts);
  };

  const handleCancelMember = (index) => {
    const updatedAccounts = [...member];
    updatedAccounts[index].isReadOnly = true;
    setMember(updatedAccounts);
  };
  const handleCancelAccountMember = (index) => {
    setMember((prevAccount) => prevAccount.filter((_, i) => i !== index));
  };

  const [errorsMembers, setErrorsMembers] = useState({});
  const validateInputs = (account, index) => {
    let isValid = true;
    const accountErrors = {};

    if (!account.name) {
      accountErrors.name = "Name is required";
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(account.name)) {
      accountErrors.name = "Name can only contain alphabets";
      isValid = false;
    }
    if (!account.designation) {
      accountErrors.designation = "Designation is required";
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(account.designation)) {
      accountErrors.designation = "Designation can only contain alphabets";
      isValid = false;
    }

    if (!account.permission) {
      accountErrors.permission = "Permission Type is required";
      isValid = false;
    }

    if (!account.ownership_percent) {
      accountErrors.ownership_percent = "Percentage is required";
      isValid = false;
    } else if (!/^\d+$/.test(account.ownership_percent)) {
      accountErrors.ownership_percent = "Percentage can only contain numbers";
      isValid = false;
    } else if (
      Number(account.ownership_percent) < 0 ||
      Number(account.ownership_percent) > 100
    ) {
      accountErrors.ownership_percent = "Percentage must be between 0 and 100";
      isValid = false;
    } 

    setErrorsMembers((prevErrors) => ({
      ...prevErrors,
      [index]: accountErrors,
    }));

    return isValid;
  };

  const createDetailsmember = (item) => {
    let createData = {
      name: item.name,
      designation: item.designation,
      ownership_percentage: item.ownership_percent,
      permission: parseInt(item.permission),
    };
    postMemberHolder(createData);
    const updatedAccounts = [...member];
    updatedAccounts[member.indexOf(item)].isReadOnly = true;
    setMember(updatedAccounts);
    getMemberholder();
  };

  const handleAddStakeholderMember = (index) => {
    const currentAccount = member[index];
    if (
      currentAccount.name ||
      currentAccount.designation ||
      currentAccount.permission ||
      currentAccount.ownership_percent
    ) {
      const isValid = validateInputs(currentAccount, index); // Pass the specific account being validated
      if (isValid) {
        createDetailsmember(member[index]); // Only proceed if valid
      } else {
        console.log("Validation error");
        const updatedAccounts = [...member];
        updatedAccounts[index].hasChanges = true;
        setMember(updatedAccounts);
      }
    } else {
      console.log("No input to validate, skipping validation");
    }
  };

  const updateStakeHolderDetailsMember = (item) => {
    let id = item.id;
    let updatedData = {
      name: item.name,
      designation: item.designation,
      ownership_percentage: item.ownership_percent,
      permission: parseInt(item.permission),
    };
    patchMember(id, updatedData);
    const updatedAccounts = [...member];
    updatedAccounts[member.indexOf(item)].isReadOnly = true;
    setMember(updatedAccounts);
  };

  const handleApplyChangesMember = (index) => {
    const currentAccount = member[index];
    const isValid = validateInputs(currentAccount, index); // Pass the specific account being validated
    if (isValid) {
      updateStakeHolderDetailsMember(member[index]);
      const updatedAccounts = [...member];
      updatedAccounts[index].hasChanges = false; // Reset after applying changes
      setMember(updatedAccounts);
    } else {
      console.log("Validation error");
      const updatedAccounts = [...member];
      updatedAccounts[index].hasChanges = true;
      setMember(updatedAccounts);
    }
  };

  const removeMember = (item) => {
    let id = item.id;
    deleteMember(id);
  };
  const handleRemoveAccountMember = (index) => {
    removeMember(member[index]);
  };

  return (
    <>
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3 pb-[2rem]">
        <div className="py-[2.2rem] gap-[1rem] items-center md:flex hidden">
          <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w- h-" />
          </button>
          <h4 className="text-[#334155] text-[1.4rem] font-[600] m-0">
            Members & Invites
          </h4>
        </div>
        <div className="bg-white-body rounded-xl border border-[#E2E8F0]">
          <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
            <div className="flex flex-col gap-2">
              <h4 className="text-[#334155] text-[1rem] font-[500] m-0">
                BeyondTax Members
              </h4>
              <p className="text-[#334155] m-0 text-[0.9rem]">
                Key People in The Organisation
              </p>
            </div>
            <button
              onClick={handleAddMember}
              className="!border !border-[#9065B4] rounded-md text-[0.9rem] py-1 px-2 text-[#9065B4] font-[400]"
            >
              Add Member
            </button>
          </div>

          {/* input fields */}
          <div className="md:bg-white px-3 py-3 rounded">
            {member.map((stake, index) => (
              <div className="flex mb-[1rem] gap-[1rem]" key={index}>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Name
                  </h6>

                  <InputField
                    type="text"
                    className={`border-1 border-[#94A3B8] rounded-md  text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    readOnly={stake.isReadOnly}
                    value={stake.name}
                    name="name"
                    onChange={(e) =>
                      handleChangeMember(index, "name", e.target.value)
                    }
                  />
                  <br />
                  {errorsMembers[index]?.name && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errorsMembers[index].name}
                    </span>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Designation
                  </h6>
                  <InputField
                    type="text"
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly
                        ? "border-none bg-[#F8FAFC]"
                        : "bg-white "
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.designation}
                    onChange={(e) =>
                      handleChangeMember(index, "designation", e.target.value)
                    }
                  />
                  {errorsMembers[index]?.designation && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errorsMembers[index].designation}
                    </span>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Ownership Percentage{" "}
                  </h6>
                  <InputField
                    type="number"
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.ownership_percent}
                    onChange={(e) =>
                      handleChangeMember(
                        index,
                        "ownership_percent",
                        e.target.value
                      )
                    }
                  />
                  {errorsMembers[index]?.ownership_percent && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errorsMembers[index].ownership_percent}
                    </span>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Permission
                  </h6>
                  <select
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.permission}
                    onChange={(e) =>
                      handleChangeMember(index, "permission", e.target.value)
                    }
                  >
                    <option value="">Choose Permission type</option>
                    <option value={1}>Read</option>
                    <option value={3}>Edit </option>
                    <option value={2}>Download</option>
                    <option value={4}>Read & Download</option>
                  </select>
                  {errorsMembers[index]?.permission && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errorsMembers[index].permission}
                    </span>
                  )}
                </div>

                <div className="">
                  {stake.isNewAccount ? (
                    <>
                      <button
                        disabled={!isAddAccountEnabledMember}
                        onClick={() => handleAddStakeholderMember(index)}
                        className={`${
                          isAddAccountEnabledMember
                            ? "bg-[#9065B4]"
                            : "bg-gray-400 cursor-not-allowed"
                        } w-[60%] text-white rounded py-2 px-3 font-[600] text-[0.8rem]`}
                      >
                        Add Account
                      </button>
                      <button
                        onClick={() => handleCancelAccountMember(index)}
                        className=" bg-[#F1F5F9] w-[60%] text-[#334155] rounded py-2 px-6 font-[600] text-[0.8rem]"
                      >
                        Cancel
                      </button>
                    </>
                  ) : stake.isReadOnly ? (
                    <>
                      <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>
                      <button
                        onClick={() => handleEditDetailsMember(index)}
                        className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                      >
                        Edit Guest Details
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled={!stake.hasChanges}
                        onClick={() => handleApplyChangesMember(index)}
                        className={`${
                          stake.hasChanges ? "bg-[#9065B4]" : "bg-gray-400"
                        } mb-1 w-[60%] text-white rounded py-2 px-2 font-[600] text-[0.8rem]`}
                      >
                        Apply Changes
                      </button>

                      <button
                        onClick={() => handleRemoveAccountMember(index)}
                        className="mb-1 w-[60%] bg-[#FEF2F2] text-[#DC2626] rounded p-2  font-[600] text-[0.8rem]"
                      >
                        Delete Account
                      </button>
                      <button
                        onClick={() => handleCancelMember(index)}
                        className=" bg-[#F1F5F9]  w-[60%] p-2 text-[#334155] rounded font-[600] text-[0.8rem]"
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
          <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
            <div className="flex flex-col gap-2">
              <h4 className="text-[#334155] text-[1rem] font-[500] m-0">
                Guests
              </h4>
              <p className="text-[#334155] m-0 text-[0.9rem]">
                Manage who has access to this workspace
              </p>
            </div>
            <button
              onClick={handleAddStakeHolder}
              className="!border !border-[#9065B4] rounded-md text-[0.9rem] py-1 px-2 text-[#9065B4] font-[400]"
            >
              Add Guest
            </button>
          </div>

          {/* input fields */}
          <div className="md:bg-white px-3 py-3 rounded">
            {stakeholder.map((stake, index) => (
              <div className="flex mb-[1rem] gap-[1rem]" key={index}>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Email
                  </h6>

                  <InputField
                    type="email"
                    className={`border-1 border-[#94A3B8] rounded-md  text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    readOnly={stake.isReadOnly}
                    value={stake.email}
                    name="email"
                    onChange={(e) =>
                      handleEmailInputChange(index, "email", e.target.value)
                    }
                  />
                  {errors[index]?.email && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errors[index].email}
                    </span>
                  )}
                  {emailSuggestionData.length > 0 && viewDropDown === index && (
                    <ul className="absolute z-10 mt-2 pl-0 p-1  bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
                      {emailSuggestionData.map((email, idx) => (
                        <li
                          key={idx}
                          className="py-2 px-2 hover:bg-blue-100 cursor-pointer text-[0.9rem]"
                          onClick={() =>
                            handleEmailSuggestionClick(index, email)
                          }
                        >
                          {email}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Tab
                  </h6>
                  <select
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly
                        ? "border-none bg-[#F8FAFC]"
                        : "bg-white "
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.tab}
                    onChange={(e) => handleChange(index, "tab", e.target.value)}
                  >
                    {/* {console.log("tab:", stake.tab)} */}
                    <option value="">Choose Tab</option>
                    <option value="Incometax">Incometax</option>
                    <option value="Assets">Assets</option>
                    <option value="Liabilities">Liabilities</option>
                    {/* value="_8PGAAAG" */}

                    {/* {getTabsData?.data?.results?.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          className="text-[0.9rem]"
                        >
                          {item.tab_name}
                        </option>
                      ))} */}
                  </select>
                  {errors[index]?.tab && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errors[index].tab}
                    </span>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Stakeholder Type{" "}
                  </h6>

                  <select
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.type}
                    onChange={(e) =>
                      handleChange(index, "type", e.target.value)
                    }
                  >
                    <option value="">Choose Stakeholder type</option>
                    <option value="Chartered Accountant">
                      Chartered Accountant
                    </option>
                    <option value="Portfolio Manager">Portfolio Manager</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors[index]?.type && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errors[index].type}
                    </span>
                  )}
                </div>
                <div className="w-[18%] input-container group">
                  <h6 className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Permission Type
                  </h6>
                  <select
                    className={`border-1 border-[#94A3B8] rounded-md text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4] ${
                      stake.isReadOnly ? "border-none bg-[#F8FAFC]" : "bg-white"
                    }`}
                    disabled={stake.isReadOnly}
                    value={stake.permission}
                    onChange={(e) =>
                      handleChange(index, "permission", e.target.value)
                    }
                  >
                    <option value="">Choose Permission type</option>
                    <option value={1}>Read</option>
                    <option value={3}>Edit </option>
                    <option value={2}>Download</option>
                    <option value={4}>Read & Download</option>
                  </select>
                  {errors[index]?.permission && (
                    <span className="error text-[0.7rem] text-red-500">
                      {errors[index].permission}
                    </span>
                  )}
                </div>
                <div className="">
                  {stake.isNewAccount ? (
                    <>
                      <button
                        disabled={!isAddAccountEnabled}
                        onClick={() => handleAddStakeholder(index)}
                        className={`${
                          isAddAccountEnabled
                            ? "bg-[#9065B4]"
                            : "bg-gray-400 cursor-not-allowed"
                        } w-[60%] text-white rounded py-2 px-3 font-[600] text-[0.8rem]`}
                      >
                        Add Account
                      </button>
                      <button
                        onClick={() => handleCancelAccount(index)}
                        className=" bg-[#F1F5F9] w-[60%] text-[#334155] rounded py-2 px-6 font-[600] text-[0.8rem]"
                      >
                        Cancel
                      </button>
                    </>
                  ) : stake.isReadOnly ? (
                    <>
                      <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>
                      <button
                        onClick={() => handleEditDetails(index)}
                        className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                      >
                        Edit Guest Details
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled={!stake.hasChanges}
                        onClick={() => handleApplyChanges(index)}
                        // className=" mb-1 w-[60%] bg-[#9065B4] text-white rounded p-2 font-[600] text-[0.8rem]"
                        className={`${
                          stake.hasChanges ? "bg-[#9065B4]" : "bg-gray-400"
                        } mb-1 w-[60%] text-white rounded py-2 px-2 font-[600] text-[0.8rem]`}
                      >
                        Apply Changes
                      </button>

                      <button
                        onClick={() => handleRemoveAccount(index)}
                        className="mb-1 w-[60%] bg-[#FEF2F2] text-[#DC2626] rounded p-2  font-[600] text-[0.8rem]"
                      >
                        Delete Account
                      </button>
                      <button
                        onClick={() => handleCancel(index)}
                        className=" bg-[#F1F5F9]  w-[60%] p-2 text-[#334155] rounded font-[600] text-[0.8rem]"
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}

            {/* <div className="flex justify-center items-center rounded py-3 border-dashed border-2 border-[#E2E8F0]">
              <button
                onClick={handleAddStakeHolder}
                className="flex items-center gap-2 text-[#334155] font-[600]"
              >
                <img src={add} alt="add account icon" />
                Add New Stakeholder
              </button>
            </div> */}
          </div>
        </div>
        <BackToBusinessDashboard />
      </div>
    </>
  );
};

export default BusinessMembersAndInvites;
