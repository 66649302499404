import Button from "components/Button/BtnOrange";
import React, { useEffect, useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import InputField from "components/input/InputField";
import datePicker from "assets/images/incomeTax/datePicker.png";
import RedButton from "components/Button/RedButton";
import usePostCategories from "../data/usePostCategories";
import { showSuccessToast } from "components/toaster/toastHelper";
import CreateNewCategory from "./CreateNewCategory";
import check from "assets/images/moneyManager/check.svg";
import arrow from "assets/images/navbar/down-arrow.svg";

const EditTransaction = ({
  isOpen,
  closeModal,
  getAccountData,
  getCategoryData,
  amountType,
  setAmountType,
  isToggled,
  setIsToggled,
  account,
  setaccount,
  amount,
  setAmount,
  desc,
  setDesc,
  dateOfTran,
  setDateOfTran,
  category,
  setcategory,
  setDateOfRecc,
  dateOfRecc,
  frequency,
  setFrequency,
  handleEditTransactionDetails,
  getCategoriesList,
  categoryToEdit
}) => {
  const toggleAmountType = (type) => {
    setAmountType(type);
  };
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [postCategoryData, categoryIsError, categoryIsLoading, postCategory] =
    usePostCategories();

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(""); // Tracks the selected category
  const [selectedCategoryID, setSelectedCategoryID] = useState(""); // Tracks the selected category
  const [categoryName, setCategoryName] = useState("");
  const [openCategoryDialogBox, setOpenCategoryDialogBox] = useState(false);

  useEffect(() => {
    if (postCategoryData) {
      showSuccessToast("Category Added");
      setCategoryOpen(false);
      getCategoriesList();
      setOpenCategoryDialogBox(false);
    }
  }, [postCategoryData]);

  const handleOpenCategoryDialog = () => {
    setCategoryOpen(!categoryOpen);
  };

  const handleClose = () => {
    setOpenCategoryDialogBox(false);
  };

  const handleOpen = () => {
    setOpenCategoryDialogBox(true);
  };

  const handleCategorySelect = (categoryName, categoryId) => {
    if (categoryName === "Create New+") {
      setSelectedCategory("Create New+");
    } else {
      setSelectedCategory(categoryName);
      setSelectedCategoryID(categoryId);
      setcategory(categoryId);
      setCategoryOpen(false);
    }
  };
  const handlePostnewCategory = () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    postCategory(formData);
  };

  useEffect(()=>{
    if(categoryToEdit){
      setSelectedCategory(categoryToEdit)
    }
  },[categoryToEdit])
  console.log('categoryToEdit', categoryToEdit)

  return (
    <>
      {/* isOpen && ( */}
      <div className="fixed inset-0 bg-gray-900  bg-opacity-50 flex justify-center items-center z-50">
        <div className="w-[85%] sm:w-[70%] md:w-[500px] bg-[#FFFFFF] rounded-2xl px-6 py-4 relative">
          <div>
            <button
              className="group absolute top-[1rem] right-6 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
              onClick={closeModal}
            >
              <img
                alt=""
                src={closeIcon}
                className="w-3 h-3 group-hover:hidden"
              />
              <img
                alt=""
                src={closeIconRed}
                className="w-4 h-4 hidden group-hover:block"
              />
            </button>
            <h4 className="text-[1rem] font-[600] text-lightGray">
              Edit Transaction
            </h4>
          </div>

          {/* input fields */}
          <div className="flex flex-col gap-[0.5rem] items-center justify-center">
            {/* amount */}
            <div className="group focus-within:border-customPurple flex items-center border rounded-md w-[25%]">
              <span className="group-focus-within:text-customPurple p-[0.3rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                Rs.
              </span>
              <InputField
                className={`w-[100%]  text-[0.9rem] bg-white focus:outline-none p-[0.3rem] rounded-r ${
                  amountType === "Income" ? "text-[#15803D]" : "text-red-500"
                }`}
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            {/* description */}
            <div className="group focus:outline-none flex items-center border rounded-md w-[30%]">
              <InputField
                className="w-[100%] text-[#334155] text-[0.8rem] bg-white focus:outline-none p-[0.4rem] rounded-r"
                type="text"
                placeholder="Add Description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>

            {/* date category */}
            <div className="flex gap-[0.5rem]">
              <InputField
                value={dateOfTran}
                onChange={(e) => setDateOfTran(e.target.value)}
                type="date"
                className="w-[30%] border focus:outline-none rounded-md bg-white text-[0.8rem] pl-[0.2rem] py-[0.3rem]"
              />

              {/* expense / income */}
              <div
                className={`w-[41%] px-[0.2rem] py-[0.3rem] flex gap-2 items-center border bg-white rounded-md overflow-hidden 
                        `}
              >
                <button
                  onClick={() => toggleAmountType("Expense")}
                  className={`px-[0.1rem] py-[0.1rem] text-[0.7rem] font-[500] ${
                    amountType === "Expense"
                      ? "text-[#DC2626] bg-[#FEF2F2]"
                      : "bg-white text-gray-500"
                  } rounded-md`}
                >
                  Expense
                </button>
                <button
                  onClick={() => toggleAmountType("Income")}
                  className={`px-[0.1rem] py-[0.1rem] text-[0.7rem]  ${
                    amountType === "Income"
                      ? "text-[#15803D] bg-[#F0FDF4]"
                      : "bg-white text-gray-500"
                  } rounded-md`}
                >
                  Income
                </button>
              </div>

              {/* category */}
              <div className="relative border w-[45%] bg-white rounded-md flex items-center justify-center">
                <button
                  className="flex gap-1 items-center justify-center focus:outline-none text-[0.8rem] p-[0.4rem] "
                  onClick={handleOpenCategoryDialog}
                >
                  {selectedCategory || "Category"}
                  <img
                    src={arrow}
                    className={`transition-transform ${
                      categoryOpen ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                    }`}
                    alt="Arrow"
                  />
                </button>
                {categoryOpen && (
                  <div className="absolute mt-1 border rounded-md bg-white shadow-lg z-10 w-[7.5rem] max-h-[10rem] overflow-y-auto scrollbar-hide">
                    <div
                      onClick={handleOpen}
                      // onClick={() =>
                      //   handleCategorySelect("Create New+", null)
                      // }
                      className="text-[0.8rem] font-[600] px-[0.7rem] py-[0.3rem] cursor-pointer"
                    >
                      Create New +
                    </div>

                    <hr className="m-0" />
                    {getCategoryData?.data?.map((category) => (
                      <button
                        key={category.id}
                        onClick={() =>
                          handleCategorySelect(category.name, category.id)
                        }
                        className={`flex text-[0.8rem] font-[600] px-[0.9rem] py-[0.3rem] cursor-pointer
                  ${
                    selectedCategory === category.name.toLowerCase()
                      ? "text-darkGray flex gap-1"
                      : "text-lightGray"
                  }
                `}
                      >
                        {category.name}
                        {selectedCategory === category.name.toLowerCase() ? (
                          <img src={check} alt="" />
                        ) : (
                          ""
                        )}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {openCategoryDialogBox && (
                <CreateNewCategory
                  name={categoryName}
                  setName={setCategoryName}
                  isOpen={openCategoryDialogBox}
                  handleGoToCategories={handlePostnewCategory}
                  closeModal={handleClose}
                />
              )}

              {/* cash */}
              <InputField
                type="select"
                className="w-[30%] border focus:outline-none rounded-md bg-white text-[0.8rem] px-[0.2rem] py-[0.1rem]"
                options={[
                  { label: "Account Type" },
                  ...getAccountData?.data?.results?.map((option) => ({
                    value: option.id,
                    label: option.account_name,
                  })),
                ]}
                value={account}
                onChange={(e) => setaccount(e.target.value)}
              />
            </div>
          </div>

          {/* recurring */}
          <div className="flex items-center gap-2 my-[1rem]">
            <span
              className={`${
                isToggled ? "text-customPurple" : "text-lightGray"
              } text-[0.8rem] font-[500]`}
            >
              Recurring Transaction
            </span>
            <div
              onClick={handleToggle}
              className={`w-6 h-3 flex items-center rounded-full p-[0.2rem] cursor-pointer ${
                isToggled
                  ? "border-1 border-customPurple"
                  : "border-1 border-lightGray"
              }`}
            >
              <div
                className={`h-2 w-2 rounded-full  shadow-md transform ${
                  isToggled
                    ? "translate-x-2 bg-customPurple"
                    : "translate-x-0 bg-lightGray"
                }`}
              ></div>
            </div>
          </div>
          {isToggled ? (
            <div className="my-[1rem] flex gap-[1rem]">
              {/* frequency */}
              <div>
                <h6 className="text-lightGray text-[0.7rem]">Frequency</h6>
                <InputField
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  type="select"
                  className="p-[0.3rem] text-[0.8rem] font-[500] text-lightGray border rounded-md"
                  options={[
                    { value: "", label: "Repeats Every" },
                    { value: "Monthly", label: "Every Month" },
                    { value: "Yearly", label: "Every Year" },
                  ]}
                />
              </div>

              {/* date */}
              <div>
                <h6 className="text-lightGray text-[0.7rem]">Date</h6>

                <div className="w-[90%] group focus-within:border-customPurple flex items-center border rounded-md">
                  <span className="p-[0.3rem] rounded-l-md">
                    <img src={datePicker} className="w-3 h-2" alt="" />
                  </span>
                  <InputField
                    value={dateOfRecc}
                    onChange={(e) => setDateOfRecc(e.target.value)}
                    className="w-[80%] py-[0.2rem] focus:outline-none text-[0.8rem] font-[500] text-lightGray"
                    type="date"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="flex justify-end gap-2 items-center">
            {/* <RedButton
              // onClick={handleEditTransactionDetails}
              name="Remove Transaction"
              px={"px-[0.6rem]"}
              py={"py-[0.4rem]"}
            /> */}
            <Button
              onClick={handleEditTransactionDetails}
              name="Save"
              px={"px-[0.6rem]"}
              py={"py-[0.4rem]"}
            />
          </div>
        </div>
      </div>
      {/* ) */}
    </>
  );
};

export default EditTransaction;
