import React from "react";
import PropTypes from "prop-types";

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="">
      {/* Stepper Navigation */}
      <ul className="relative flex flex-row gap-x-2">
        {steps.map((step, idx) => (
          <li
            key={idx}
            className={`flex items-center gap-x-2 shrink basis-0 flex-1 group ${
              step.index === currentStep ? "hs-stepper-active" : ""
            } ${
              step.index < currentStep ? "hs-stepper-completed" : ""
            }`}
          >
            <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
              <span
                className={`size-7 flex justify-center items-center shrink-0  font-medium text-gray-800 rounded-full ${
                  step.index === currentStep
                    ? "bg-[#EA580C] text-white"
                    : step.index < currentStep
                    ? "bg-[#14B8A6] text-white"
                    : "group-focus:bg-[#EA580C] dark:bg-gray-200 dark:text-black"
                }`}
              >
                {step.index <= currentStep ? (
                  step.index < currentStep ? (
                    <svg
                      className="shrink-0 size-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  ) : (
                    step.index
                  )
                ) : (
                  step.index
                )}
              </span>
              <span className="ms-2 text-[0.9rem] font-[500] text-gray-800">
                {step.label}
              </span>
            </span>
            {idx !== steps.length  && (
              <div
                className={`w-full h-px flex-1 ${
                  step.index < currentStep
                    ? "bg-[#14B8A6]"  
                    : "bg-[#E5E7EB]"
                }`}
              ></div>
            )}
          </li>
        ))}
      </ul>

      {/* Stepper Content */}
      <div className="">
        {steps.map(
          (step, idx) =>
            step.index === currentStep && (
              <div
                key={idx}
                // className="p-4 h-48 flex justify-center items-center border border-dashed border-gray-200 rounded-xl bg-gray-50 dark:bg-neutral-700 dark:border-neutral-600"
              >
                {/* <h3 className="text-gray-500 dark:text-neutral-400"> */}
                  {step.content}  
                {/* </h3> */}
              </div>
            )
        )}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;
