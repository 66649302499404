import React, { useEffect, useState } from "react";
import ProfileAndCertificate from "./ProfileAndCertificate";
import "containers/privateRoutes/Dashboard/Order.css";
import DashboardContentNew from "./DashboardNew";
import HeaderDashboardProfile from "../Profile/HeaderDashboardProfile";
import DashboardPersonal from "./DashboardPersonal";
import { useLocation } from "react-router-dom";
import DashboardPersonalNew from "./DashboardPersonalNew";

function SampleDashboard() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* desktop */}
      <div className="border-l md:block hidden">
        <HeaderDashboardProfile />
        <div className="grid grid-cols-12 bg-gray-100 px-[1rem] pb-[4rem] pt-[1rem]">
          {isLoading ? (
            <>
              {/* Skeleton for main content */}
              <div className="col-span-12 md:col-span-9 space-y-4">
                <div className="h-48 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-48 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-80 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-80 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-80 bg-gray-300 rounded animate-pulse"></div>
                <div className="flex gap-[1rem]">
                  <div className="h-64 w-2/3 bg-gray-300 rounded animate-pulse"></div>
                  <div className="h-64 w-1/3 bg-gray-300 rounded animate-pulse"></div>
                </div>
              </div>
              {/* Skeleton for sidebar */}
              <div className="col-span-3 hidden md:block pl-[1rem] space-y-4">
                <div className="h-60 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-screen bg-gray-300 rounded animate-pulse"></div>
              </div>
            </>
          ) : (
            <>
              <div className="col-span-12 md:col-span-9 ">
                <DashboardPersonalNew />
              </div>
              <div className="col-span-3 hidden md:block pl-[1rem]">
                <ProfileAndCertificate />
              </div>
            </>
          )}
        </div>
      </div>
      {/* mobile */}
      <div className="md:hidden">
        <div className="bg-gray-100 px-[1rem] pb-[4rem] pt-[1rem] mb-[4rem]">
          <DashboardPersonalNew />
          <ProfileAndCertificate />
        </div>
      </div>
    </>
  );
}

export default SampleDashboard;
