import useAPICall from "hooks/useAPICall";
import {GET_EMPLOYEES_OVERVIEW } from "constants/apis";

const useGetEmployeesOverview = () => {
  const [data, error, isLoading, callGetEmployeesOverview, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getEmployeesOverview = (obj) => {
    const url = GET_EMPLOYEES_OVERVIEW
    const params={date:obj?.formattedDate, role:obj?.role, employee_name:obj?.employee_name}
    callGetEmployeesOverview({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      params
      // body,
    });
  };

  return [data, error, isLoading, getEmployeesOverview, setSuccessData, setError];
};

export default useGetEmployeesOverview;
